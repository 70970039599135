import { j as t } from "./jsx-runtime-DmTjM30f.js";
import { useContext as Z, useState as A, useEffect as ue, Children as Je, useMemo as qe, createContext as $e } from "react";
import { a as fe, M as P, P as ge, c as We, r as Xe, b as Pe, B as be, D as Ke, Q as et, e as ie, T as he, l as tt, R as Oe, m as nt, k as Me, I as at } from "./index.esm-G8KyR0tM.js";
import { F as me } from "./provider-Diwup2pn.js";
import { u as Te } from "./useConfiguration-B-lyaBB9.js";
import { i } from "./index-wI714H_N.js";
import { r as q } from "./sentry-CAR5o4wv.js";
import { AppContext as _e } from "./AppContext.js";
import { T as st } from "./Tnc-MBkdDEOv.js";
import { z as pe, aw as it, H as rt, u as ne, J as ot, I as lt, m as De, o as we, K as ct, s as dt, A as ut, r as mt, M as pt, L as _t } from "./utils-D5LKKDAj.js";
import { C as ft } from "./CopyLinkButton-Cgo4fV4p.js";
import { B as ht } from "./BankAccountListing-BCK16zSG.js";
import { ErrorPage as Se } from "./ErrorPage.js";
import { u as ze } from "./useMigratedUser-Bcz7gd73.js";
import { K as gt } from "./KycRequestStatus-CBEdY-hR.js";
import yt from "./KycEsign-Cb0-VX9L.js";
import bt from "./KycIpv-BHqPONQF.js";
import xt from "./KycFileUploads-DHlyuhNf.js";
import { N as wt } from "./NominationConsent-Dqr4RmOS.js";
import { N as Ct } from "./NomineeListing-BXjnn754.js";
import { C as vt, B as jt, F as St } from "./FinancialDetails-BtgCG24U.js";
import { m as Nt, f as Ce, g as Et, h as kt, r as ce, k as It, l as Ut, d as He } from "./validators-BArvL7cB.js";
import { R as Kt, F as re } from "./react-final-form.es-BQCbpI73.js";
const Tt = ({ user: e, onCompletion: o, isPartner: c, isGroupInvestment: r, folioDetails: s }) => {
  const { fpapi: d } = Z(me), { AppState: { tenant: v } } = Z(_e), [C, f] = A(null), [b, N] = A(null), [R, n] = A(!0), [h, x] = A(null), U = (_, m) => (_ == null ? void 0 : _.cancelled_cheque) && (m == null ? void 0 : m.status) === "completed" && (m == null ? void 0 : m.reason) === "digital_verification" && (m == null ? void 0 : m.confidence) === "zero", k = (_, m) => !(_ != null && _.cancelled_cheque) && // no cancelled cheque
  (m == null ? void 0 : m.status) === "completed" && // verification status is complete
  (m == null ? void 0 : m.reason) === "digital_verification" && // reason is digital_verification
  (m == null ? void 0 : m.confidence) === "zero", M = (_) => _.sort((m, D) => Number(new Date(D.updated_at)) - Number(new Date(m.updated_at))), g = async (_) => {
    var m;
    if (_) {
      const D = [], W = [], X = [], l = [], E = [], z = [], G = _ == null ? void 0 : _.map((O) => {
        const J = O.identifier ? String(O.identifier) : String(O.id);
        return d.fpClient.bank_account_verifications().fetchAll({
          bank_accounts: [
            J
          ]
        });
      });
      return (await Promise.allSettled(G)).forEach((O, J) => {
        var T;
        if (O.status === "fulfilled") {
          const Q = {
            ..._[J]
          }, te = (T = O == null ? void 0 : O.value) == null ? void 0 : T.data[0];
          te ? te.status === "completed" ? U(Q, te) ? X.push(Q) : k(Q, te) ? l.push(Q) : D.push(Q) : te.status === "pending" ? W.push(Q) : te.status === "failed" && E.push(Q) : z.push(Q);
        } else if (O.status === "rejected")
          throw window.debug.error(O), O;
      }), s && ((m = s == null ? void 0 : s.payout_details) == null || m.forEach((O) => {
        const J = _ == null ? void 0 : _.find((T) => {
          var Q;
          return (T == null ? void 0 : T.account_number) === ((Q = O == null ? void 0 : O.bank_account) == null ? void 0 : Q.number);
        });
        J && D.push(J);
      })), {
        COMPLETE: [
          ...M(D)
        ],
        PENDING: [
          ...M(W)
        ],
        MANUAL_VERIFICATION_PENDING: [
          ...M(X)
        ],
        COLLECT_CANCELLED_CHEQUE: [
          ...M(l)
        ],
        FAILED: [
          ...M(E)
        ],
        NOVERIFICATION: [
          ...M(z)
        ]
      };
    } else
      throw new Error(i._({
        id: "VhBqMw"
      }));
  }, Y = async () => {
    const _ = await d.fpClient.bank_accounts().fetchAll({
      profile: e == null ? void 0 : e.profile
    }), { COMPLETE: m, PENDING: D, MANUAL_VERIFICATION_PENDING: W, COLLECT_CANCELLED_CHEQUE: X, FAILED: l, NOVERIFICATION: E } = await g(_ == null ? void 0 : _.data);
    return m.length ? {
      stage: "verification_successful",
      lastUpdatedBankAccount: m[0]
    } : D.length ? {
      stage: "verification_pending",
      lastUpdatedBankAccount: D[0]
    } : W.length ? {
      stage: "manual_verification_pending",
      lastUpdatedBankAccount: W[0]
    } : X.length ? {
      stage: "cancelled_cheque_required",
      lastUpdatedBankAccount: X[0]
    } : l.length ? {
      stage: "verification_failed",
      lastUpdatedBankAccount: l[0]
    } : {
      stage: "no_verification",
      lastUpdatedBankAccount: E[0]
    };
  }, F = async (_) => {
    const m = e.id, W = {
      ...pe(e == null ? void 0 : e.annexure),
      completed_step: "bank_verification"
    };
    if (_)
      for (const [l, E] of Object.entries(_))
        E && (W[l] = E);
    const X = await d.updateUser(m, W, null, v);
    if (X)
      return X;
    throw new Error(X);
  }, V = (_) => {
    N(_);
  }, ae = async (_) => {
    await F({
      verified_bac: _ == null ? void 0 : _.id
    }), o();
  }, j = async () => await d.fpClient.investor_profiles().fetch(e == null ? void 0 : e.profile), L = async () => {
    const _ = await Y();
    switch (_.stage) {
      case "verification_successful": {
        await ae(_ == null ? void 0 : _.lastUpdatedBankAccount);
        break;
      }
      case "manual_verification_pending":
      case "verification_pending":
      case "cancelled_cheque_required":
      case "no_verification":
      case "verification_failed": {
        const m = await j();
        f(m), V("bank_account_listing");
        break;
      }
    }
  };
  ue(() => {
    (async () => {
      try {
        await L();
      } catch (_) {
        window.debug.error(_), x(i._({
          id: "DpIV8H"
        })), q(_);
      } finally {
        n(!1);
      }
    })();
  }, []);
  const w = (_) => {
    (async () => {
      try {
        n(!0), await ae(_);
      } catch (m) {
        window.debug.error(m), x(i._({
          id: "NKzNR1"
        })), q(m);
      } finally {
        n(!1);
      }
    })();
  }, B = () => r ? i._({
    id: "Ol3oy2"
  }) : c ? i._({
    id: "ZL/hhl"
  }) : i._({
    id: "zUm8oD"
  });
  return R ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : h ? /* @__PURE__ */ t.jsx(Se, {
    title: i._({
      id: "AsbZJy"
    }),
    description: h,
    showAction: !0,
    actionText: i._({
      id: "90uUa5"
    }),
    actionHandler: L
  }) : (() => {
    switch (b) {
      case "bank_account_listing":
        return /* @__PURE__ */ t.jsx(ht, {
          investorProfile: C,
          handleBankSelection: w,
          customPageTitle: i._({
            id: "q4PFMU"
          }),
          customPageSubtitle: B(),
          alertMessage: i._({
            id: "OVTWIa"
          })
        });
      default:
        return /* @__PURE__ */ t.jsx(fe, {
          variant: "fullpage"
        });
    }
  })();
}, At = ({ user: e, onCompletion: o, isGroupInvestment: c }) => {
  const { fpapi: r } = Z(me), { AppState: { tenant: s } } = Z(_e), [d, v] = A(null), [C, f] = A(!0), [b, N] = A(null);
  ue(() => {
    (async () => {
      try {
        const h = await r.fpClient.investor_profiles().fetch(e == null ? void 0 : e.profile);
        v(h);
      } catch (h) {
        window.debug.error(h), N("Error fetching investor profile"), q(h);
      } finally {
        f(!1);
      }
    })();
  }, [
    e == null ? void 0 : e.profile
  ]);
  const R = async () => {
    var h;
    try {
      f(!0);
      const x = await r.fetchUser(d.pan, s), k = {
        ...pe((h = x[0]) == null ? void 0 : h.annexure),
        completed_step: "nomination_consent"
      }, M = await r.updateUser(e.id, k, null, s);
      M ? o() : (window.debug.error(M), N("Error updating user"));
    } catch (x) {
      window.debug.error(x), N("Error updating user"), q(x);
    } finally {
      f(!1);
    }
  }, n = async () => {
    try {
      f(!0);
      const h = e.id, x = e == null ? void 0 : e.annexure;
      x.completed_step = "contact_details", await r.updateUser(h, x, null, s), o();
    } catch (h) {
      window.debug.error(h), q(h);
    } finally {
      f(!1);
    }
  };
  return C ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : b ? /* @__PURE__ */ t.jsx(Se, {
    title: "Error",
    description: b
  }) : /* @__PURE__ */ t.jsx(wt, {
    handleGoBack: n,
    investorProfile: d,
    onSuccess: R,
    isGroupInvestment: c
  });
}, Bt = ({ user: e, onCompletion: o, isPartner: c, isKycCompliant: r, folioDetails: s }) => {
  const { fpapi: d } = Z(me), { AppState: { tenant: v } } = Z(_e), [C, f] = A(null), [b, N] = A(!1), [R, n] = A([]), [h, x] = A(null), U = pe(e == null ? void 0 : e.annexure);
  ue(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const k = async (j) => {
    const L = e.id, w = {
      ...U,
      ...j
    };
    c ? w.partner_completed_step = "nominee_details" : w.completed_step = "nominee_details";
    const B = await d.updateUser(L, w, null, v);
    if (B)
      return B;
    throw B;
  }, M = (j, L) => {
    var B;
    let w = [];
    for (let H = 1; H <= 3; H++) {
      const _ = H, m = L == null ? void 0 : L[`nominee${_}`];
      m && w.push({
        name: m == null ? void 0 : m.name,
        date_of_birth: m == null ? void 0 : m.dob,
        relationship: (B = m == null ? void 0 : m.relationship) == null ? void 0 : B.toLowerCase()
      });
    }
    return j.length && (w = j), w;
  };
  ue(() => {
    (async () => {
      try {
        N(!0);
        const j = await d.fpClient.related_parties().fetchAll({
          profile: e == null ? void 0 : e.profile
        }), L = M(j == null ? void 0 : j.data, s), w = await g(e);
        w && x(w), n(L);
      } catch (j) {
        window.debug.error(j), f(i._({
          id: "cuvfTX"
        })), q(j);
      } finally {
        N(!1);
      }
    })();
  }, []);
  const g = async (j) => {
    var m;
    const L = (m = j == null ? void 0 : j.username) == null ? void 0 : m.toUpperCase(), w = j == null ? void 0 : j.profile;
    let B = null;
    const H = await d.fpClient.mf_investment_accounts().fetchAll({
      investor: L
    }), _ = H == null ? void 0 : H.data;
    return _ != null && _.length && (B = _.find((D) => D.primary_investor === w)), B;
  }, Y = async (j) => {
    var B, H, _, m, D, W;
    const L = h, w = it(j);
    return await d.fpClient.mf_investment_accounts().update({
      id: L == null ? void 0 : L.id,
      folio_defaults: {
        nominee1: ((B = w[0]) == null ? void 0 : B.id) || null,
        nominee1_allocation_percentage: ((H = w[0]) == null ? void 0 : H.allocationPercentage) || null,
        nominee2: ((_ = w[1]) == null ? void 0 : _.id) || null,
        nominee2_allocation_percentage: ((m = w[1]) == null ? void 0 : m.allocationPercentage) || null,
        nominee3: ((D = w[2]) == null ? void 0 : D.id) || null,
        nominee3_allocation_percentage: ((W = w[2]) == null ? void 0 : W.allocationPercentage) || null
      }
    });
  }, F = async ({ skip_nomination: j, nominees: L }) => {
    try {
      if (N(!0), j)
        await k({
          skip_nomination: !0
        }), await Y([]), o();
      else {
        const w = [], B = [];
        L.forEach((m) => {
          m != null && m.id || w.push(d.fpClient.related_parties().create({
            profile: e == null ? void 0 : e.profile,
            name: m.name,
            relationship: m.relationship
          }));
        });
        const H = await Promise.allSettled(w), _ = L.filter((m) => m == null ? void 0 : m.id);
        H.forEach((m) => {
          m.status === "rejected" ? B.push(m) : m.status === "fulfilled" && _.push(m.value);
        }), B.length ? (window.debug.error(B), f(i._({
          id: "cuvfTX"
        }))) : (await k(), await Y([
          ..._
        ]), o());
      }
    } catch (w) {
      window.debug.error(w), f(i._({
        id: "cuvfTX"
      })), q(w);
    } finally {
      N(!1);
    }
  }, V = async () => {
    try {
      N(!0);
      const j = e.id, L = e == null ? void 0 : e.annexure;
      c ? r ? L.partner_completed_step = "banking_details" : L.partner_completed_step = "financial_details" : L.completed_step = "banking_details", await d.updateUser(j, L, null, v), o();
    } catch (j) {
      window.debug.error(j), f(i._({
        id: "qMcu4/"
      })), q(j);
    } finally {
      N(!1);
    }
  };
  return b ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      C && /* @__PURE__ */ t.jsx(P, {
        type: "error",
        variant: "field",
        title: C
      }),
      /* @__PURE__ */ t.jsx(Ct, {
        handleNomineeSelection: (j) => {
          F(j);
        },
        mfia: h,
        isPartner: c,
        nomineeList: R,
        investorProfile: null,
        handleGoBack: V
      })
    ]
  });
};
function Lt({ onConsent: e, user: o, isKycCompliant: c }) {
  var n, h;
  const { fpapi: r } = Z(me), { AppState: { tenant: s } } = Z(_e), { tenantConfig: d } = Te(), [v, C] = A(!1), [f, b] = A(!1), N = async () => {
    if (o) {
      const x = +/* @__PURE__ */ new Date(), U = {
        ...o == null ? void 0 : o.annexure,
        employeeDeclarationTimeStamp: x,
        completed_step: "employee_declaration"
      };
      await r.updateUser(o == null ? void 0 : o.id, U, null, s) ? e({
        employeeDeclarationTimeStamp: x
      }) : window.debug.error("Cannot update the details");
    }
  }, R = async () => {
    try {
      if (o) {
        const x = o.id, U = o == null ? void 0 : o.annexure;
        U.completed_step = "contact_details", await r.updateUser(x, U, null, s), e();
      }
    } catch (x) {
      window.debug.error(x), q(x);
    }
  };
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(ge, {
        title: i._({
          id: "mAF+1j"
        }),
        customClass: "mb-3",
        semiBold: !0,
        icon: c ? "Back" : null,
        onClick: R
      }),
      /* @__PURE__ */ t.jsx(ge, {
        variant: "sub",
        title: i._({
          id: "GgxZVs"
        })
      }),
      /* @__PURE__ */ t.jsx(We, {
        border: !0,
        symbol: ((n = d == null ? void 0 : d.groupInvestment) == null ? void 0 : n.groupPartnerLogo) || (d == null ? void 0 : d.fallbackLogo),
        label: (h = d == null ? void 0 : d.groupInvestment) == null ? void 0 : h.groupPartnerName,
        customClass: "mb-6"
      }),
      /* @__PURE__ */ t.jsxs("div", {
        className: "dark:text-neutral-40 mb-6 leading-relaxed",
        children: [
          /* @__PURE__ */ t.jsx("p", {
            className: "pb-2",
            children: i._({
              id: "C95tsa"
            })
          }),
          /* @__PURE__ */ t.jsx("p", {
            className: "pb-2",
            children: i._({
              id: "nOHGAn"
            })
          })
        ]
      }),
      /* @__PURE__ */ t.jsxs(Xe, {
        id: "terms-id",
        checked: v,
        onChange: (x) => {
          x.stopPropagation(), C(!v);
        },
        customClass: "mb-3",
        children: [
          i._({
            id: "9nnBWQ"
          }),
          /* @__PURE__ */ t.jsx("a", {
            onClick: (x) => {
              x.preventDefault(), x.stopPropagation(), b(!0);
            },
            className: "text-size-14",
            children: i._({
              id: "wt7bu+"
            })
          })
        ]
      }),
      /* @__PURE__ */ t.jsx(Pe, {
        variant: "fullPage",
        title: i._({
          id: "4yYTaY"
        }),
        isOpen: f,
        toggleModal: b,
        contentHeightMax: !0,
        children: /* @__PURE__ */ t.jsx(st, {
          disableBackButton: !0
        })
      }),
      /* @__PURE__ */ t.jsx(be, {
        variant: v ? "primary" : "disabled",
        onClick: N,
        type: "button",
        fullwidth: !0,
        children: i._({
          id: "2iVSoY"
        })
      })
    ]
  });
}
const Ot = ({ handleInvestNow: e, error: o, handleGoBack: c, user: r }) => {
  const { fpapi: s } = Z(me), { AppState: { tenant: d } } = Z(_e), v = () => {
    const f = new URLSearchParams(window.location.search).get("isin"), b = new URL(`${rt(d)}/profile-details`);
    return f && b.searchParams.set("isin", f), b == null ? void 0 : b.toString();
  }, C = async () => {
    try {
      const f = r.id, b = r == null ? void 0 : r.annexure;
      b.partner_completed_step = "contact_details", b.isonboarded = "false", await s.updateUser(f, b, null, d), c();
    } catch (f) {
      window.debug.error(f), q(f);
    }
  };
  return /* @__PURE__ */ t.jsxs("div", {
    children: [
      /* @__PURE__ */ t.jsx(ge, {
        title: i._({
          id: "vYVuHo"
        }),
        icon: "Back",
        onClick: C
      }),
      /* @__PURE__ */ t.jsx(Ke, {
        type: "text",
        text: i._({
          id: "T8tJKY"
        })
      }),
      /* @__PURE__ */ t.jsx(t.Fragment, {
        children: /* @__PURE__ */ t.jsx(ft, {
          hyperlink: v(),
          buttonText: "consent link",
          variant: "secondary",
          customClass: "mb-2"
        })
      }),
      /* @__PURE__ */ t.jsx(be, {
        variant: "secondary",
        onClick: e,
        fullwidth: !0,
        customClass: "mb-2",
        children: i._({
          id: "4U6CpR"
        })
      }),
      o && /* @__PURE__ */ t.jsx(P, {
        type: "error",
        variant: "flash",
        title: o,
        customClass: "mb-5"
      })
    ]
  });
}, Mt = ({ title: e, subTitle: o, steps: c, onSummaryComplete: r }) => {
  var f;
  const s = (f = c.find((b) => b.status === "resume")) == null ? void 0 : f.stepValue, d = () => {
    var b;
    return ((b = c == null ? void 0 : c[0]) == null ? void 0 : b.status) === "resume" ? i._({
      id: "ZYprs/"
    }) : i._({
      id: "D6NUdw"
    });
  }, v = (b, N) => {
    if (!(N === 0 && b.displayStatus === "resume"))
      return b.displayStatus;
  }, C = () => Je.toArray(c.map((b, N) => b.displayIsHidden ? null : /* @__PURE__ */ t.jsx(et, {
    status: v(b, N),
    children: /* @__PURE__ */ t.jsx("h5", {
      children: b.displayStepName
    })
  })));
  return /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(ge, {
        title: e
      }),
      /* @__PURE__ */ t.jsx(Ke, {
        type: "text",
        text: o
      }),
      /* @__PURE__ */ t.jsx("div", {
        children: C()
      }),
      /* @__PURE__ */ t.jsx(be, {
        fullwidth: !0,
        onClick: () => r(s),
        customClass: "mb-8",
        children: d()
      })
    ]
  });
}, Ae = /* @__PURE__ */ $e(null), Ht = ({ children: e }) => {
  const [o, c] = A(!1), [r, s] = A(null), d = (f) => {
    s((b) => ({
      ...b,
      ...f
    }));
  }, v = (f) => c(f), C = qe(() => ({
    loading: o,
    errors: r,
    handleSetLoading: v,
    handleSetErrors: d
  }), [
    o,
    r
  ]);
  return /* @__PURE__ */ t.jsx(Ae.Provider, {
    value: C,
    children: e
  });
}, Vt = i._({
  id: "uR3xiz"
}), Rt = i._({
  id: "HiZNu3"
}), qt = i._({
  id: "568G4c"
}), Dt = i._({
  id: "r3WMz0"
}), zt = i._({
  id: "KooMEc"
}), Ft = i._({
  id: "s1n7ez"
}), Gt = i._({
  id: "pg+XG6"
}), Qt = i._({
  id: "cvvS9u"
}), Zt = i._({
  id: "KgGQqC"
}), Yt = i._({
  id: "m53GGj"
}), Jt = i._({
  id: "QSdDcn"
}), $t = i._({
  id: "DwufZH"
}), Wt = i._({
  id: "q2sTsF"
}), Xt = i._({
  id: "a7XFrx"
}), Pt = i._({
  id: "CJG120"
}), Ne = {
  male: i._({
    id: "mhekaN"
  }),
  female: i._({
    id: "MbGkoQ"
  }),
  transgender: i._({
    id: "WRKGRB"
  })
}, Ee = {
  married: i._({
    id: "FWqaip"
  }),
  unmarried: i._({
    id: "BTcVOG"
  }),
  others: i._({
    id: "bzmQKJ"
  })
}, Ve = {
  father: i._({
    id: "VAD7Ul"
  }),
  spouse: i._({
    id: "zwY2/g"
  })
}, ke = {
  notApplicable: i._({
    id: "6BjygT"
  }),
  pepRelated: i._({
    id: "0n+8KZ"
  }),
  pepExposed: i._({
    id: "JpLyV/"
  })
}, en = i._({
  id: "DH1QQr"
}), tn = i._({
  id: "kAQzzC"
}), Ie = i._({
  id: "lEyTi5"
}), nn = i._({
  id: "qMcu4/"
}), an = i._({
  id: "Sto4kq"
}), sn = i._({
  id: "AtXdm9"
}), rn = i._({
  id: "Ih9v5T"
}), on = i._({
  id: "C4aY37"
}), ln = i._({
  id: "n+VFc3"
}), ve = /* @__PURE__ */ new Date();
ve.setFullYear(ve.getFullYear() - 18);
const oe = [
  {
    label: Ne.male,
    value: "male"
  },
  {
    label: Ne.female,
    value: "female"
  },
  {
    label: Ne.transgender,
    value: "transgender"
  }
], Ue = [
  {
    label: Ee.married,
    value: "married"
  },
  {
    label: Ee.unmarried,
    value: "unmarried"
  },
  {
    label: Ee.others,
    value: "others"
  }
], je = [
  {
    label: Ve.father,
    value: "father"
  },
  {
    label: Ve.spouse,
    value: "spouse"
  }
], ye = [
  {
    label: ke.notApplicable,
    value: "not_applicable"
  },
  {
    label: ke.pepRelated,
    value: "pep_related"
  },
  {
    label: ke.pepExposed,
    value: "pep_exposed"
  }
], cn = (e) => {
  const o = [], c = [];
  return e.forEach((r) => {
    const s = {
      id: r.ansi_code,
      ansi_code: r.ansi_code,
      label: r.name
    };
    r.ansi_code === "IN" ? c[0] = s : r.ansi_code === "US" ? c[1] = s : r.ansi_code === "GB" ? c[2] = s : r.ansi_code === "AE" ? c[3] = s : o.push(s);
  }), [
    ...c,
    ...o
  ];
}, dn = (e) => () => {
  var r;
  if (!e) return;
  const o = {
    name: e == null ? void 0 : e.primary_investor_name,
    date_of_birth: e != null && e.primary_investor_dob ? new Date(e == null ? void 0 : e.primary_investor_dob) : null,
    gender: oe[ne(oe, ((r = e == null ? void 0 : e.primary_investor_gender) == null ? void 0 : r.toLowerCase()) || "")]
  };
  let c;
  for (c in o)
    o[c] || delete o[c];
  return o;
}, un = (e, o) => () => {
  if (!e || !o) return;
  const c = e == null ? void 0 : e.entity_details, r = {
    name: c == null ? void 0 : c.name,
    date_of_birth: o.annexure.dob ? new Date(o.annexure.dob) : null,
    gender: c != null && c.gender ? Nt(c == null ? void 0 : c.gender) && oe[ne(oe, c == null ? void 0 : c.gender)] : null,
    tax_status: (c == null ? void 0 : c.residential_status) === "resident_individual" ? c == null ? void 0 : c.residential_status : null
  };
  let s;
  for (s in r)
    r[s] || delete r[s];
  return r;
}, mn = (e, o) => () => {
  var s;
  if (!e) return;
  const c = {
    name: e == null ? void 0 : e.name,
    date_of_birth: e == null ? void 0 : e.date_of_birth,
    gender: oe[ne(oe, e == null ? void 0 : e.gender)],
    country_of_birth: o[ne(o, (s = e == null ? void 0 : e.country_of_birth) == null ? void 0 : s.toUpperCase(), {
      customProperty: "ansi_code"
    })],
    relation_name: ot(e),
    relation_type: je[ne(je, lt(e) || "")],
    marital_status: Ue[ne(Ue, e == null ? void 0 : e.marital_status)],
    mother_name: e == null ? void 0 : e.mother_name,
    tax_status: e == null ? void 0 : e.residential_status
  };
  let r;
  for (r in c)
    c[r] || delete c[r];
  return c;
}, pn = (e, o) => () => {
  var s;
  if (!e) return;
  const c = {
    name: e == null ? void 0 : e.name,
    date_of_birth: e != null && e.date_of_birth ? new Date(e == null ? void 0 : e.date_of_birth) : null,
    gender: oe[ne(oe, (e == null ? void 0 : e.gender) || "")],
    country_of_birth: o[ne(o, ((s = e == null ? void 0 : e.country_of_birth) == null ? void 0 : s.toUpperCase()) || "", {
      customProperty: "ansi_code"
    })],
    place_of_birth: (e == null ? void 0 : e.place_of_birth) === "IN" ? "India" : e == null ? void 0 : e.place_of_birth,
    pep_details: ye[ne(ye, (e == null ? void 0 : e.pep_details) || "")],
    tax_status: e == null ? void 0 : e.tax_status
  };
  let r;
  for (r in c)
    c[r] || delete c[r];
  return c;
}, Re = (e) => {
  if (e)
    return e instanceof Date ? De(e, "yyyy-mm-dd") : e;
}, _n = ({ user: e, isKycCompliant: o, isKycOnly: c }) => {
  const { fpapi: r } = Z(me), s = Z(Ae), [d, v] = A(null), [C, f] = A(null), [b, N] = A(null), [R, n] = A(null), [h, x] = A([]), U = s == null ? void 0 : s.handleSetLoading, k = s == null ? void 0 : s.handleSetErrors;
  return ue(() => {
    (async () => {
      var M;
      try {
        U == null || U(!0), k == null || k(null);
        try {
          const { countries: F } = await r.fpClient.master_data().fetchCountries();
          if (F.length) {
            const V = cn(F);
            x(V);
          } else {
            k == null || k({
              fieldErrors: {
                country_of_birth: Ie
              }
            });
            return;
          }
        } catch (F) {
          window.debug.error(F), k == null || k({
            fieldErrors: {
              country_of_birth: Ie
            }
          }), q(F);
          return;
        }
        const g = await r.fpClient.investor_profiles().fetch(e.profile);
        if (v(g), c || !o) {
          const F = [
            r.fpClient.kyc_requests().fetchAll({
              pan: e.username
            }),
            r.fpClient.email_addresses().fetchAll({
              profile: g == null ? void 0 : g.id
            }),
            r.fpClient.phone_numbers().fetchAll({
              profile: g == null ? void 0 : g.id
            })
          ], [V, ae, j] = await Promise.all(F);
          f((M = V == null ? void 0 : V.data) == null ? void 0 : M[0]), N(ae == null ? void 0 : ae.data[0]), n(j == null ? void 0 : j.data[0]);
        }
      } catch (g) {
        window.debug.error(g), k == null || k({
          fullPageError: {
            title: an
          }
        }), q(g);
      } finally {
        U == null || U(!1);
      }
    })();
  }, []), {
    investorProfile: d,
    kycReq: C,
    emailData: b,
    phoneData: R,
    countries: h
  };
}, fn = (e) => {
  var n;
  const { user: o, kycCheckData: c, kycReq: r, investorProfile: s, countries: d } = e, { migratedFolioList: v } = ze({
    user: o
  }), { tenantConfig: C } = Te(), f = (n = C == null ? void 0 : C.data_sources_usage_policy) == null ? void 0 : n.KYC, b = f === "strict" || f === "flexible", N = v == null ? void 0 : v[0];
  return qe(() => {
    var k;
    let h = {
      name: null,
      date_of_birth: null,
      gender: null,
      country_of_birth: d.find((M) => M.ansi_code === "IN"),
      place_of_birth: null,
      pep_details: ye[ne(ye, "not_applicable")],
      tax_status: "resident_individual",
      relation_type: null,
      relation_name: null,
      marital_status: null,
      mother_name: null
    };
    const x = {
      folio: dn(N),
      kra: b ? un(c, o) : () => {
      },
      kyc: mn(r, d),
      invp: pn(s, d)
    };
    let U;
    for (U in x)
      h = {
        ...h,
        ...x[U]() ?? {}
      };
    return (k = o == null ? void 0 : o.annexure) != null && k.dob && (h.date_of_birth = new Date(o.annexure.dob)), h;
  }, [
    s,
    c,
    r,
    d,
    N
  ]);
}, hn = ({ user: e, isKycCompliant: o, isKycOnly: c }) => {
  var C;
  const r = !!((C = e == null ? void 0 : e.annexure) != null && C.dob);
  return c ? {
    name: !1,
    date_of_birth: r,
    gender: !1,
    country_of_birth: !1,
    marital_status: !1,
    relation_name: !1,
    relation_type: !1,
    mother_name: !1,
    place_of_birth: !0,
    pep_details: !0,
    tax_status: !0
  } : o ? {
    name: !1,
    date_of_birth: r,
    gender: !1,
    country_of_birth: !1,
    place_of_birth: !1,
    pep_details: !1,
    tax_status: !1,
    marital_status: !0,
    relation_name: !0,
    relation_type: !0,
    mother_name: !0
  } : {
    name: !1,
    date_of_birth: r,
    gender: !1,
    country_of_birth: !1,
    marital_status: !1,
    relation_name: !1,
    relation_type: !1,
    mother_name: !1,
    place_of_birth: !1,
    pep_details: !1,
    tax_status: !1
  };
}, gn = ({ investorProfile: e, isKycCompliant: o }) => {
  var v;
  const { tenantConfig: c } = Te(), r = (v = c == null ? void 0 : c.data_sources_usage_policy) == null ? void 0 : v.KYC, d = (r === "strict" || r === "flexible") && o;
  return {
    name: d,
    gender: d,
    tax_status: !0,
    date_of_birth: !!(e != null && e.date_of_birth),
    country_of_birth: !!(e != null && e.country_of_birth),
    place_of_birth: !!(e != null && e.country_of_birth)
  };
}, yn = ({ countriesOptions: e, formInitialValues: o, handleSubmit: c, disabledFields: r, hiddenFields: s, errors: d }) => {
  ue(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const v = d == null ? void 0 : d.formError, C = d == null ? void 0 : d.fieldErrors;
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: /* @__PURE__ */ t.jsx(Kt, {
      onSubmit: c,
      subscription: {
        submitting: !0,
        values: !0,
        errors: !0
      },
      initialValues: o,
      children: (f) => {
        var b, N, R;
        return /* @__PURE__ */ t.jsxs("form", {
          onSubmit: f.handleSubmit,
          children: [
            s != null && s.name ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Vt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "name",
                  validate: Ce(ce, kt(4), Et(120)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(he, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: n.input.onChange,
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (h) => {
                          h.target.value = h.target.value.replace(we("A-Za-z "), "");
                        },
                        disabled: r == null ? void 0 : r.name,
                        placeholder: Wt,
                        maxLength: 70,
                        status: n.meta.error && n.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.date_of_birth ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Rt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "date_of_birth",
                  validate: Ce(ce, Ut, It(en)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(tt, {
                        name: n.input.name,
                        variant: "keyboardInputDOB",
                        placeholder: Xt,
                        format: "dd-MMM-yyyy",
                        selectedDate: n.input.value || null,
                        maxDate: ve,
                        initialFocusedDate: ve,
                        error: n.meta.error && n.meta.touched,
                        helperText: null,
                        onKeyDown: (h) => h.preventDefault(),
                        handleDateChange: (h) => n.input.onChange(h),
                        disabled: r == null ? void 0 : r.date_of_birth
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.gender ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: qt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "gender",
                  validate: ce,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (n) => {
                    var h, x, U;
                    return /* @__PURE__ */ t.jsxs(t.Fragment, {
                      children: [
                        /* @__PURE__ */ t.jsx(Oe, {
                          name: n.input.name,
                          size: "sm",
                          checked: ne(oe, (h = n.input.value) == null ? void 0 : h.value),
                          onChange: (k, M) => n.input.onChange(M),
                          group: oe,
                          disabled: r != null && r.gender && ((x = o == null ? void 0 : o.gender) != null && x.value) ? ct(oe, ((U = o == null ? void 0 : o.gender) == null ? void 0 : U.value) || "") : []
                        }),
                        n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(P, {
                          type: "error",
                          variant: "field",
                          title: n.meta.error
                        })
                      ]
                    });
                  }
                })
              ]
            }),
            s != null && s.country_of_birth ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Dt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "country_of_birth",
                  validate: ce,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(nt, {
                        value: n.input.value,
                        //TODO check the types of pixel
                        onChange: (h) => n.input.onChange(h),
                        options: e,
                        searchAttribute: "label",
                        variant: "autocomplete",
                        disabled: r == null ? void 0 : r.country_of_birth
                      }),
                      (n.meta.error && n.meta.touched || (C == null ? void 0 : C.country_of_birth)) && /* @__PURE__ */ t.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: C != null && C.country_of_birth ? Ie : n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.marital_status ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: zt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "marital_status",
                  validate: ce,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(Me, {
                        name: n.input.name,
                        value: n.input.value,
                        onChange: (h) => n.input.onChange(h),
                        options: Ue,
                        status: n.meta.error && n.meta.touched ? "error" : void 0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.relation_name ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: ((N = (b = f.values) == null ? void 0 : b.marital_status) == null ? void 0 : N.value) === "married" ? Ft : Gt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "relation_name",
                  validate: Ce(ce, He(tn)),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(he, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: n.input.onChange,
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (h) => {
                          h.target.value = h.target.value.replace(we("A-Za-z "), "");
                        },
                        maxLength: 70,
                        status: n.meta.error && n.meta.touched ? "error" : void 0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                }),
                ((R = f.values.marital_status) == null ? void 0 : R.value) === "married" && /* @__PURE__ */ t.jsxs("div", {
                  className: "pt-3",
                  children: [
                    /* @__PURE__ */ t.jsx(ie, {
                      message: Jt
                    }),
                    /* @__PURE__ */ t.jsx(re, {
                      name: "relation_type",
                      validate: ce,
                      validateFields: [],
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (n) => {
                        var h;
                        return /* @__PURE__ */ t.jsxs(t.Fragment, {
                          children: [
                            /* @__PURE__ */ t.jsx(Oe, {
                              name: n.input.name,
                              size: "sm",
                              value: n.input.value,
                              checked: ne(je, (h = n.input.value) == null ? void 0 : h.value),
                              onChange: (x, U) => {
                                n.input.onChange(U);
                              },
                              group: je
                            }),
                            n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(P, {
                              type: "error",
                              variant: "field",
                              title: n.meta.error
                            })
                          ]
                        });
                      }
                    })
                  ]
                })
              ]
            }),
            s != null && s.mother_name ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: $t
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "mother_name",
                  validate: Ce(ce, He("Invalid Name")),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(he, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: n.input.onChange,
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (h) => {
                          h.target.value = h.target.value.replace(we("A-Za-z "), "");
                        },
                        maxLength: 70,
                        status: n.meta.error && n.meta.touched ? "error" : void 0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.place_of_birth ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Qt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "place_of_birth",
                  validate: ce,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(he, {
                        id: n.input.name,
                        autoComplete: "off",
                        name: n.input.name,
                        value: n.input.value,
                        onChange: n.input.onChange,
                        onBlur: () => {
                          n.input.onChange(n.input.value.trim());
                        },
                        onInput: (h) => {
                          h.target.value = h.target.value.replace(we("A-Za-z "), "");
                        },
                        placeholder: Pt,
                        maxLength: 60,
                        status: n.meta.error && n.meta.touched ? "error" : void 0,
                        disabled: r == null ? void 0 : r.place_of_birth
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.pep_details ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Zt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "pep_details",
                  validate: ce,
                  validateFields: [],
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (n) => /* @__PURE__ */ t.jsxs(t.Fragment, {
                    children: [
                      /* @__PURE__ */ t.jsx(Me, {
                        name: n.input.name,
                        value: n.input.value,
                        onChange: (h) => {
                          n.input.onChange(h);
                        },
                        options: ye,
                        status: n.meta.error && n.meta.touched ? "error" : void 0
                      }),
                      n.meta.error && n.meta.touched && /* @__PURE__ */ t.jsx(P, {
                        type: "error",
                        variant: "field",
                        title: n.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            s != null && s.tax_status ? null : /* @__PURE__ */ t.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ t.jsx(ie, {
                  message: Yt
                }),
                /* @__PURE__ */ t.jsx(re, {
                  name: "tax_status",
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  children: (n) => /* @__PURE__ */ t.jsx(t.Fragment, {
                    children: /* @__PURE__ */ t.jsx(he, {
                      id: n.input.name,
                      autoComplete: "off",
                      name: n.input.name,
                      value: "Resident Indian",
                      disabled: !0
                    })
                  })
                })
              ]
            }),
            v && /* @__PURE__ */ t.jsx(P, {
              type: "error",
              variant: "field",
              title: v
            }),
            /* @__PURE__ */ t.jsx(be, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const n = Object.keys(f.errors || {});
                n.length && dt(n[0]);
              },
              customClass: "mb-8",
              children: sn
            })
          ]
        });
      }
    })
  });
}, bn = (e) => {
  var X;
  const { user: o, isPartner: c, isGroupInvestment: r, isKycCompliant: s, isKycOnly: d, onCompletion: v } = e, { fpapi: C } = Z(me), f = Z(Ae), { AppState: { username: b, employer: N, tenant: R }, setUsername: n } = Z(_e), h = f == null ? void 0 : f.loading, x = (X = f == null ? void 0 : f.errors) == null ? void 0 : X.fullPageError, U = f == null ? void 0 : f.handleSetLoading, k = f == null ? void 0 : f.handleSetErrors, { countries: M, investorProfile: g, kycReq: Y, emailData: F, phoneData: V } = _n(e), ae = fn({
    ...e,
    investorProfile: g,
    kycReq: Y,
    countries: M
  }), j = gn({
    ...e,
    investorProfile: g,
    kycReq: Y
  }), L = hn(e), w = (l) => {
    var ee, O, J, T, Q, te;
    const E = {
      name: l.name,
      pan: (ee = o == null ? void 0 : o.username) == null ? void 0 : ee.toUpperCase(),
      email: F == null ? void 0 : F.email,
      date_of_birth: Re(l == null ? void 0 : l.date_of_birth),
      mobile: {
        isd: `+${V == null ? void 0 : V.isd}`,
        number: V == null ? void 0 : V.number
      },
      gender: (O = l == null ? void 0 : l.gender) == null ? void 0 : O.value,
      country_of_birth: (J = l == null ? void 0 : l.country_of_birth) == null ? void 0 : J.ansi_code,
      residential_status: l == null ? void 0 : l.tax_status,
      marital_status: (T = l == null ? void 0 : l.marital_status) == null ? void 0 : T.value,
      mother_name: l == null ? void 0 : l.mother_name
    }, z = ((Q = l == null ? void 0 : l.marital_status) == null ? void 0 : Q.value) !== "married", G = {
      spouse: "spouse_name",
      father: "father_name"
    };
    return z ? E[G.father] = l == null ? void 0 : l.relation_name : E[G[(te = l == null ? void 0 : l.relation_type) == null ? void 0 : te.value]] = l == null ? void 0 : l.relation_name, Y ? C.fpClient.kyc_requests().update(Y == null ? void 0 : Y.id, E) : C.fpClient.kyc_requests().create(E);
  }, B = (l) => {
    var G, ee, O;
    const E = {
      id: o == null ? void 0 : o.profile,
      name: l == null ? void 0 : l.name,
      date_of_birth: Re(l == null ? void 0 : l.date_of_birth),
      country_of_birth: (G = l == null ? void 0 : l.country_of_birth) == null ? void 0 : G.ansi_code,
      place_of_birth: l == null ? void 0 : l.place_of_birth,
      gender: (ee = l == null ? void 0 : l.gender) == null ? void 0 : ee.value,
      pep_details: (O = l == null ? void 0 : l.pep_details) == null ? void 0 : O.value,
      tax_status: l == null ? void 0 : l.tax_status
    };
    r && (N != null && N.id) && (E.employer = N == null ? void 0 : N.id);
    const z = {
      tax_status: g == null ? void 0 : g.tax_status,
      date_of_birth: g == null ? void 0 : g.date_of_birth,
      country_of_birth: g == null ? void 0 : g.country_of_birth,
      place_of_birth: g == null ? void 0 : g.place_of_birth,
      employer: g == null ? void 0 : g.employer
    };
    for (const [J, T] of Object.entries(z))
      T && delete E[J];
    return C.fpClient.investor_profiles().update(E);
  }, H = async () => {
    const l = o.id, E = {
      ...o == null ? void 0 : o.annexure,
      completed_step: "personal_details"
    };
    c && (E.partner_completed_step = "personal_details");
    const z = await C.updateUser(l, E, null, R);
    if (z)
      return z;
    throw new Error(z);
  }, _ = async (l) => {
    const z = {
      primary_investor: l == null ? void 0 : l.profile,
      holding_pattern: "single"
    };
    return await m(l) ? null : await C.fpClient.mf_investment_accounts().create(z);
  }, m = async (l) => {
    var J;
    const E = (J = l == null ? void 0 : l.username) == null ? void 0 : J.toUpperCase(), z = l == null ? void 0 : l.profile;
    let G;
    const ee = await C.fpClient.mf_investment_accounts().fetchAll({
      investor: E
    }), O = ee == null ? void 0 : ee.data;
    return O != null && O.length && (G = O.find((T) => T.primary_investor === z)), G;
  }, D = async (l) => {
    try {
      if (U == null || U(!0), k == null || k(null), d) {
        const E = await w(l);
        v({
          updatedKycDetails: E
        });
      } else if (s) {
        const E = await B(l);
        await _(o), await H(), !b && n(E == null ? void 0 : E.name), v();
      } else {
        const E = [
          w(l),
          B(l)
        ], [z, G] = await Promise.all(E);
        await _(o), await H(), n(G == null ? void 0 : G.name), v({
          updatedKycDetails: z
        });
      }
    } catch (E) {
      window.debug.error(E), k == null || k({
        formError: nn
      }), q(E), ut(E) && window.location.reload();
    } finally {
      U == null || U(!1);
    }
  };
  return h ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : x ? /* @__PURE__ */ t.jsx(Se, {
    title: x.title,
    description: x.description,
    actionText: x.retryText,
    actionHandler: () => window.location.reload()
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(ge, {
        title: rn
      }),
      /* @__PURE__ */ t.jsx(Ke, {
        type: "text",
        text: c ? on : ln
      }),
      /* @__PURE__ */ t.jsx(yn, {
        countriesOptions: M,
        formInitialValues: ae,
        hiddenFields: L,
        disabledFields: j,
        handleSubmit: D,
        errors: f == null ? void 0 : f.errors
      })
    ]
  });
}, xn = (e) => /* @__PURE__ */ t.jsx(Ht, {
  children: /* @__PURE__ */ t.jsx(bn, {
    ...e
  })
}), Fn = ({ user: e, setUser: o, onBoardingComplete: c, isGroupInvestment: r = !1, isPartner: s }) => {
  const { fpapi: d } = Z(me), { AppState: { tenant: v } } = Z(_e), { migratedFolioList: C } = ze({
    user: e
  }), [f, b] = A(null), [N, R] = A({
    open: !1,
    msg: ""
  }), [n, h] = A(""), [x, U] = A([]), [k, M] = A(null), [g, Y] = A(null), [F, V] = A(!1), [ae, j] = A([]), [L, w] = A(!1), [B, H] = A(null), _ = !!k, m = C == null ? void 0 : C[0], D = async (a) => {
    var S, I;
    const u = a == null ? void 0 : a.profile;
    let y, p;
    if (s)
      y = (S = a == null ? void 0 : a.annexure) == null ? void 0 : S.email_address, p = (I = a == null ? void 0 : a.annexure) == null ? void 0 : I.phone_number;
    else {
      const K = await d.fpClient.ciam_user_management().searchUser(`fpa_${v}`, u);
      y = K == null ? void 0 : K.email, p = K == null ? void 0 : K.phone_number;
    }
    if (y && p) {
      const { data: K } = await d.fpClient.email_addresses().fetchAll({
        profile: u
      }), { data: $ } = await d.fpClient.phone_numbers().fetchAll({
        profile: u
      }), le = K.some((de) => de.email === y), se = $.some((de) => de.number === p);
      le || await d.fpClient.email_addresses().create({
        profile: u,
        email: y,
        belongs_to: "self"
      }), se || await d.fpClient.phone_numbers().create({
        profile: u,
        isd: "91",
        number: p,
        belongs_to: "self"
      });
    }
  }, W = async (a) => {
    const u = pe(a == null ? void 0 : a.annexure);
    if (u != null && u.kycCheckId) {
      const y = await d.fpClient.kyc_checks().fetch(u == null ? void 0 : u.kycCheckId);
      return pt(y.updated_at) ? await d.fpClient.kyc_checks().refetch(u == null ? void 0 : u.kycCheckId) : y;
    } else {
      const y = a == null ? void 0 : a.id, p = {
        pan: a == null ? void 0 : a.username.toUpperCase()
      };
      if (u != null && u.dob) {
        const $ = De(u == null ? void 0 : u.dob, "yyyy-mm-dd");
        p.date_of_birth = $ || "";
      }
      const S = await d.fpClient.kyc_checks().create(p), I = {
        ...u,
        kycCheckId: S.id
      }, K = await d.updateUser(y, I, null, v);
      if (K)
        return S;
      throw K;
    }
  }, X = async (a) => {
    var p;
    const u = await d.fpClient.kyc_requests().fetchAll({
      pan: a
    });
    return (p = u == null ? void 0 : u.data) == null ? void 0 : p[0];
  }, l = () => {
    (async () => {
      try {
        w(!0), await D(e);
        try {
          const a = await W(e);
          if (!a.status && a.action === "disallowed")
            R({
              open: !0,
              msg: i._({
                id: "aRH6E5"
              })
            });
          else {
            const y = {
              unavailable: 1,
              rejected: 1,
              legacy: 1,
              unknown: 1
            }, p = [
              "personal_details",
              "financial_details",
              "banking_details",
              "contact_details",
              "nominee_details",
              "nomination_consent",
              "bank_verification"
            ], S = [
              "personal_details",
              "financial_details",
              "banking_details",
              "contact_details",
              "nominee_details",
              "nomination_consent",
              "kyc_file_uploads",
              "kyc_ipv",
              "kyc_esign",
              "bank_verification"
            ];
            if (r && (p.splice(p.length - 1, 0, "employee_declaration"), S.splice(S.length - 1, 0, "employee_declaration")), !a.status && y[a.reason]) {
              const I = await X(e == null ? void 0 : e.username);
              Y(I), U(S);
            } else if (!a.status && !y[a.reason]) {
              R({
                open: !0,
                msg: i._({
                  id: "aRH6E5"
                })
              });
              return;
            } else
              M(a), U(p);
          }
        } catch (a) {
          window.debug.error(a), H({
            title: i._({
              id: "ekpG96"
            }),
            description: i._({
              id: "qy/D1g"
            })
          }), q(a);
        }
      } catch (a) {
        window.debug.error(a), H({
          title: i._({
            id: "AsbZJy"
          }),
          description: i._({
            id: "FUy5qx"
          })
        }), q(a);
      } finally {
        w(!1);
      }
    })();
  }, E = (a) => {
    const u = x.indexOf(a);
    return u === -1 ? x[0] : u === x.length - 1 ? "complete" : x[u + 1];
  };
  ue(() => {
    R({
      open: !1,
      msg: ""
    }), l();
  }, []);
  const z = (a, u) => {
    const y = {
      personal_details: {
        displayStepName: i._({
          id: "8OIPLE"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "personal_details",
        status: "default"
      },
      financial_details: {
        displayStepName: i._({
          id: "SVi5q9"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "financial_details",
        status: "default"
      },
      banking_details: {
        displayStepName: i._({
          id: "uZr1Gq"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "banking_details",
        status: "default"
      },
      contact_details: {
        displayStepName: i._({
          id: "pQFNyT"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "contact_details",
        status: "default"
      },
      nominee_details: {
        displayStepName: i._({
          id: "s5ouGu"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "nominee_details",
        status: "default"
      },
      nomination_consent: {
        displayStepName: i._({
          id: "3CN1Zg"
        }),
        displayStatus: "default",
        displayIsHidden: !0,
        isSubStepOf: "nominee_details",
        stepValue: "nomination_consent",
        status: "default"
      },
      kyc_file_uploads: {
        displayStepName: i._({
          id: "4yTZPQ"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "kyc_file_uploads",
        status: "default"
      },
      kyc_ipv: {
        displayStepName: i._({
          id: "UMLkVw"
        }),
        displayStatus: "default",
        displayIsHidden: !0,
        isSubStepOf: "kyc_file_uploads",
        stepValue: "kyc_ipv",
        status: "default"
      },
      kyc_esign: {
        displayStepName: i._({
          id: "CFyuuK"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "kyc_esign",
        status: "default"
      },
      employee_declaration: {
        displayStepName: i._({
          id: "Ql7sRy"
        }),
        displayStatus: "default",
        displayIsHidden: !1,
        stepValue: "employee_declaration",
        status: "default"
      },
      bank_verification: {
        displayStepName: i._({
          id: "BR53Z/"
        }),
        displayStatus: "default",
        displayIsHidden: !0,
        stepValue: "bank_verification",
        status: "default"
      }
    }, p = [];
    for (const K of a)
      K in y && p.push(y[K]);
    const S = p.findIndex((K) => K.stepValue === u), I = [];
    return p.forEach((K, $) => {
      if ($ < S)
        I.push({
          ...K,
          status: "success",
          displayStatus: "success"
        });
      else if ($ === S) {
        if (I.push({
          ...K,
          status: "resume",
          displayStatus: "resume"
        }), K.displayIsHidden && K.isSubStepOf) {
          const se = I.findIndex((de) => de.stepValue === K.isSubStepOf);
          se && (I[se] = {
            ...I[se],
            displayStatus: "resume"
          });
        }
      } else
        I.push({
          ...K
        });
    }), I;
  };
  ue(() => {
    if (x.length) {
      w(!0);
      const a = e == null ? void 0 : e.annexure, u = s ? a == null ? void 0 : a.partner_completed_step : a == null ? void 0 : a.completed_step, y = E(u);
      if (s && (a == null ? void 0 : a.partner_completed_step) === "nominee_details") {
        b("partner_actions"), w(!1);
        return;
      }
      y === "complete" ? T() : (V(!mt.isRedirected()), j(z(x, y)), b(y), w(!1));
    }
  }, [
    x
  ]), ue(() => {
    [
      "rejected",
      "expired"
    ].includes(g == null ? void 0 : g.status) && (V(!1), b(`kyc_${g == null ? void 0 : g.status}`));
  }, [
    g == null ? void 0 : g.status
  ]);
  const G = () => {
    var u;
    const a = window.location.pathname + window.location.search;
    (u = d == null ? void 0 : d.userAuthService) == null || u.initiateLogout(a).then((y) => {
      window.location.href = y.userState;
    });
  }, ee = async () => {
    var a;
    if (s && (e != null && e.username)) {
      let u = null, y = null;
      try {
        const [{ data: p }, S] = await Promise.all([
          d.fpClient.mf_investment_accounts().fetchAll({
            investor: e.username.toUpperCase()
          }),
          d.fetchUser(e.username, v)
        ]);
        p != null && p.length && S.length && (u = p[0], y = S[0]);
      } catch (p) {
        window.debug.log(p), q(p);
      } finally {
        const p = ((a = y == null ? void 0 : y.annexure) == null ? void 0 : a.isonboarded) === "true";
        if (u && p) {
          const S = await d.fpClient.investor_profiles().fetch(e == null ? void 0 : e.profile);
          S && c(S, [
            u
          ], y);
        } else
          h(`The investor is not onboarded as per our records. ${k ? "Investor will be required complete nominee consent" : "Investor will be required to complete nominee consent and KYC"} to complete onboarding.`);
      }
    }
  }, O = async (a) => {
    var K;
    const u = (K = a == null ? void 0 : a.username) == null ? void 0 : K.toUpperCase(), y = a == null ? void 0 : a.profile;
    let p;
    const S = await d.fpClient.mf_investment_accounts().fetchAll({
      investor: u
    }), I = S == null ? void 0 : S.data;
    return I != null && I.length && (p = I.find(($) => $.primary_investor === y)), p;
  }, J = async (a) => {
    const u = a.id, y = a == null ? void 0 : a.profile, p = pe(a == null ? void 0 : a.annexure), S = p == null ? void 0 : p.verified_bac, I = [
      d.fpClient.investor_profiles().fetch(y)
    ], [K] = await Promise.all(I);
    let $;
    const le = await O(a);
    le && ($ = await d.fpClient.mf_investment_accounts().update({
      id: le == null ? void 0 : le.id,
      folio_defaults: {
        payout_bank_account: S || null
      }
    }));
    const se = {
      fp_identifier: y,
      fp_user_type: "investor",
      isonboarded: !0
    };
    r && (p != null && p.employeeDeclarationTimeStamp) && (se.employeeDeclarationTimeStamp = p == null ? void 0 : p.employeeDeclarationTimeStamp);
    const de = {
      id: u,
      annexure: {
        ...se
      }
    };
    try {
      const xe = await d.fpClient.ciam_user_management().upda(`fpa_${v}`, u, de);
      return {
        investorProfile: K,
        investmentAccount: $,
        updatedUser: xe
      };
    } catch {
      throw new Error("Failed to update user");
    }
  }, T = (a) => {
    const u = a == null ? void 0 : a.updatedKycDetails;
    if ([
      "rejected",
      "expired"
    ].includes(u == null ? void 0 : u.status)) {
      Y(u);
      return;
    }
    (async () => {
      var y;
      try {
        w(!0);
        const p = await d.fetchUser(e.username, v);
        if (p.length) {
          const S = pe((y = p[0]) == null ? void 0 : y.annexure), I = s ? S.partner_completed_step : S.completed_step, K = E(I);
          if (s && S.partner_completed_step === "nominee_details") {
            b("partner_actions");
            return;
          }
          if (K === "complete") {
            const { investorProfile: $, investmentAccount: le, updatedUser: se } = await J(p[0]);
            c($, [
              le
            ], se);
          } else
            b(K), o(p[0]), u && Y(u);
        }
      } catch (p) {
        window.debug.error(p), H({
          title: i._({
            id: "AsbZJy"
          }),
          description: i._({
            id: "bARUyC"
          })
        }), q(p);
      } finally {
        w(!1);
      }
    })();
  }, Q = async (a) => {
    const u = _t(a);
    return d.fpClient.kyc_requests().create(u);
  }, te = (a, u) => {
    const y = a.indexOf(u);
    if (y !== -1)
      return y - 1 < 0 ? a[0] : a[y - 1];
  }, Be = async (a) => {
    const u = e == null ? void 0 : e.id, p = {
      ...pe(e == null ? void 0 : e.annexure),
      completed_step: te(x, a)
    };
    if (s && a) {
      const I = te([
        "personal_details",
        "financial_details",
        "banking_details",
        "nominee_details"
      ], a);
      I && (p.partner_completed_step = I);
    }
    return d.updateUser(u, p, null, v);
  }, Le = (a) => {
    var u, y;
    if (a) {
      if ([
        "expired",
        "rejected"
      ].includes(a == null ? void 0 : a.status))
        return `kyc_${a == null ? void 0 : a.status}`;
      if ((u = a == null ? void 0 : a.requirements) != null && u.fields_needed.length) {
        const p = (y = a == null ? void 0 : a.requirements) == null ? void 0 : y.fields_needed, de = {
          personal_details: [
            "name",
            "date_of_birth",
            "gender",
            "country_of_birth",
            "gender",
            "residential_status",
            "marital_status",
            "father_name_or_spouse_name",
            "mother_name"
          ],
          financial_details: [
            "occupation_type"
          ],
          banking_details: [
            "bank_account"
          ],
          contact_details: [
            "address",
            "aadhaar_number"
          ],
          kyc_file_uploads: [
            "identity_proof",
            "photo",
            "signature"
          ],
          kyc_ipv: [
            "geolocation",
            "ipv_video"
          ]
        };
        for (const [xe, Ze] of Object.entries(de))
          for (const Ye of Ze)
            if (p.includes(Ye))
              return xe;
        return;
      } else
        return "kyc_esign";
    } else return "personal_details";
  }, Fe = async () => {
    var a;
    try {
      w(!0);
      const u = await d.fpClient.kyc_requests().fetchAll({
        pan: g == null ? void 0 : g.pan
      }), y = (a = u == null ? void 0 : u.data) == null ? void 0 : a.find((I) => [
        "pending",
        "esign_required",
        "submitted",
        "successful"
      ].includes(I == null ? void 0 : I.status));
      let p;
      y ? p = y : p = await Q(g);
      const S = Le(p);
      S && [
        "contact_details",
        "kyc_ipv"
      ].includes(S) ? (await Be(S), T({
        updatedKycDetails: p
      })) : T({
        updatedKycDetails: p
      });
    } catch (u) {
      window.debug.error(u), H({
        title: i._({
          id: "41NoE9"
        }),
        description: i._({
          id: "7QMExb"
        })
      }), q(u), w(!1);
    }
  }, Ge = async () => {
    var a;
    try {
      w(!0);
      const u = await d.fpClient.kyc_requests().fetchAll({
        pan: g == null ? void 0 : g.pan
      }), y = (a = u == null ? void 0 : u.data) == null ? void 0 : a.find((I) => [
        "pending",
        "esign_required",
        "submitted",
        "successful"
      ].includes(I == null ? void 0 : I.status));
      let p;
      y ? p = y : p = await Q(g);
      const S = Le(p);
      S && (await Be(S), T({
        updatedKycDetails: p
      }));
    } catch (u) {
      window.debug.error(u), H({
        title: i._({
          id: "41NoE9"
        }),
        description: i._({
          id: "7QMExb"
        })
      }), q(u), w(!1);
    }
  };
  function Qe() {
    switch (f) {
      case "personal_details":
        return /* @__PURE__ */ t.jsx(xn, {
          user: e,
          onCompletion: T,
          kycCheckData: k,
          isPartner: s,
          isGroupInvestment: r,
          isKycCompliant: _
        });
      case "financial_details":
        return /* @__PURE__ */ t.jsx(St, {
          user: e,
          onCompletion: T,
          isGroupInvestment: r,
          isPartner: s,
          isKycCompliant: _,
          kycDetails: g,
          folioDetails: m
        });
      case "banking_details":
        return /* @__PURE__ */ t.jsx(jt, {
          user: e,
          onCompletion: T,
          isGroupInvestment: r,
          isPartner: s,
          isKycCompliant: _,
          kycDetails: g,
          folioDetails: m
        });
      case "contact_details":
        return /* @__PURE__ */ t.jsx(vt, {
          user: e,
          onCompletion: T,
          kycCheckData: k,
          isPartner: s,
          isGroupInvestment: r,
          isKycCompliant: _,
          kycDetails: g
        });
      case "nominee_details":
        return /* @__PURE__ */ t.jsx(Bt, {
          user: e,
          onCompletion: T,
          isKycCompliant: _,
          isPartner: s,
          folioDetails: m
        });
      case "nomination_consent":
        return /* @__PURE__ */ t.jsx(At, {
          user: e,
          onCompletion: T,
          isGroupInvestment: r
        });
      case "kyc_file_uploads":
        return /* @__PURE__ */ t.jsx(xt, {
          user: e,
          kycDetails: g,
          onCompletion: T
        });
      case "kyc_ipv":
        return /* @__PURE__ */ t.jsx(bt, {
          user: e,
          kycDetails: g,
          onCompletion: T
        });
      case "kyc_esign":
        return /* @__PURE__ */ t.jsx(yt, {
          user: e,
          kycDetails: g,
          onCompletion: T
        });
      case "bank_verification":
        return /* @__PURE__ */ t.jsx(Tt, {
          user: e,
          folioDetails: m,
          isPartner: s,
          isGroupInvestment: r,
          onCompletion: T
        });
      case "employee_declaration":
        return /* @__PURE__ */ t.jsx(Lt, {
          user: e,
          isKycCompliant: _,
          onConsent: () => {
            T();
          }
        });
      case "partner_actions":
        return /* @__PURE__ */ t.jsx(Ot, {
          user: e,
          handleInvestNow: ee,
          handleGoBack: T,
          error: n
        });
      case "kyc_rejected":
      case "kyc_expired":
        return /* @__PURE__ */ t.jsx(gt, {
          kycRequestObject: g,
          handleRejectedAction: Ge,
          handleExpiredAction: Fe
        });
      default:
        return;
    }
  }
  return L ? /* @__PURE__ */ t.jsx(fe, {
    variant: "fullpage"
  }) : B ? /* @__PURE__ */ t.jsx(t.Fragment, {
    children: /* @__PURE__ */ t.jsx(Se, {
      title: B == null ? void 0 : B.title,
      description: B == null ? void 0 : B.description,
      showAction: !0,
      actionText: i._({
        id: "90uUa5"
      }),
      actionHandler: () => window.location.reload()
    })
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      !N.open && F ? /* @__PURE__ */ t.jsx(Mt, {
        steps: ae,
        onSummaryComplete: (a) => {
          V(!1), b(a);
        },
        title: i._({
          id: "KfaM8Q"
        }),
        subTitle: i._({
          id: "g0UOmR"
        })
      }) : Qe(),
      N.open && /* @__PURE__ */ t.jsx(t.Fragment, {
        children: /* @__PURE__ */ t.jsxs("div", {
          children: [
            /* @__PURE__ */ t.jsx(at, {
              name: "Error",
              className: "mx-auto mb-4 block h-12 w-12"
            }),
            /* @__PURE__ */ t.jsx("h3", {
              className: "text-primary-DN0 text-size-20 font-opensans dark:text-primary-DN110 mb-6 text-center font-semibold",
              children: N.msg
            }),
            /* @__PURE__ */ t.jsx("div", {
              children: /* @__PURE__ */ t.jsx("div", {
                children: /* @__PURE__ */ t.jsx(be, {
                  variant: "secondary",
                  onClick: G,
                  fullwidth: !0,
                  children: i._({
                    id: "4f668r"
                  })
                })
              })
            })
          ]
        })
      })
    ]
  });
};
export {
  Fn as O
};
