import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useContext as f, useState as n, useEffect as F } from "react";
import { AppContext as G } from "./AppContext.js";
import { F as N } from "./provider-Diwup2pn.js";
import { LoginContext as K } from "./LoginContext.js";
import { P as M, a as Z } from "./index.esm-G8KyR0tM.js";
import { i as a } from "./index-wI714H_N.js";
import { u as q } from "./useConfiguration-B-lyaBB9.js";
import { r as d } from "./sentry-CAR5o4wv.js";
import { e as z } from "./utils-D5LKKDAj.js";
import { W as B } from "./Welcome-C4-GwyyJ.js";
import { ErrorPage as D } from "./ErrorPage.js";
import { P as H } from "./Product-Jr0LJCwm.js";
import { O as J } from "./OrderStatus-BFxH2wTa.js";
import { A as Q } from "./Auth-BeDpKAhM.js";
function de({ order_id: i }) {
  const { fpapi: c } = f(N), { loggedInUser: I } = f(K), { AppState: { showWelcomePage: u }, setShowWelcomePage: L } = f(G), l = z(i), [P, p] = n(null), [h, _] = n([]), [v, g] = n(!0), [w, r] = n(""), { tenantConfig: s, amcs: y } = q(), A = s == null ? void 0 : s.fallbackLogo, C = s == null ? void 0 : s.showSchemeLogo, [O, x] = n(j), b = [
    {
      step: 1,
      content: a._({
        id: "w079KC"
      })
    },
    {
      step: 2,
      content: a._({
        id: "oIU9/P"
      })
    }
  ];
  F(() => {
    (async () => I === void 0 ? (g(!1), r("login")) : (l === "mf_purchase" ? await E(i) : l === "mf_redemption" ? await W(i) : r("invalidOrderId"), g(!1)))();
  }, []);
  async function W(o) {
    let t;
    try {
      t = await c.fpClient.mf_redemptions().fetch(o), p(t);
    } catch (m) {
      r("invalidOrderId"), d(m);
      return;
    }
    await S(t.scheme);
  }
  async function E(o) {
    let t;
    try {
      t = await c.fpClient.mf_purchases().fetch(o), p(t);
    } catch (m) {
      r("invalidOrderId"), d(m);
      return;
    }
    t && await S(t.scheme);
  }
  async function S(o) {
    try {
      const t = await c.fpClient.master_data().fetchScheme(o);
      R(t), r("orderStatus");
    } catch (t) {
      r("schemeNotFound"), window.debug.log(t), d(t);
    }
  }
  function R(o) {
    if (!o)
      r("schemeNotFound");
    else {
      const t = C ? {
        ...o,
        schemeLogo: y[o == null ? void 0 : o.amc_id] || A
      } : {
        ...o
      };
      _(t);
    }
  }
  F(() => {
    x(j());
  }, [
    u
  ]);
  function j() {
    return u ? "welcome" : "status";
  }
  function T() {
    L(!1), x("status");
  }
  const U = () => {
    if (w)
      switch (w) {
        default:
        case "login":
          return /* @__PURE__ */ e.jsx(e.Fragment, {
            children: /* @__PURE__ */ e.jsx(Q, {
              redirectURL: window.location.href,
              allowRegistration: !1,
              isGroupInvestment: !0,
              showTestPan: {
                onboarded: !0
              }
            })
          });
        case "orderStatus":
          return /* @__PURE__ */ e.jsxs(e.Fragment, {
            children: [
              /* @__PURE__ */ e.jsx(H, {
                scheme: h
              }),
              /* @__PURE__ */ e.jsx(J, {
                scheme: h,
                mf_order: P,
                isGroupInvestment: !0
              })
            ]
          });
        case "invalidOrderId":
          return /* @__PURE__ */ e.jsx(D, {
            title: a._({
              id: "ZdjdxA"
            }),
            description: a._({
              id: "As9Umu"
            }),
            showDetails: !1,
            showAction: !1
          });
      }
  };
  function k() {
    return O === "welcome" ? /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(M, {
          title: a._({
            id: "n342c5"
          })
        }),
        /* @__PURE__ */ e.jsx(B, {
          onAck: T,
          welcomeSteps: b
        })
      ]
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: v ? /* @__PURE__ */ e.jsx(Z, {
        variant: "fullpage"
      }) : /* @__PURE__ */ e.jsx(e.Fragment, {
        children: U()
      })
    });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: k()
  });
}
export {
  de as GroupInvestmentStatus
};
