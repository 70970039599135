import { j as t } from "./jsx-runtime-DmTjM30f.js";
import { useState as p, useContext as ft, useEffect as ct, useRef as Ut, useMemo as Vt } from "react";
import { a as lt, P as at, c as ot, R as zt, S as Lt, B as gt, D as Ct, M as ut, r as $t, b as ht, e as Ht, F as Jt } from "./index.esm-G8KyR0tM.js";
import { u as kt } from "./useConfiguration-B-lyaBB9.js";
import { F as pt } from "./provider-Diwup2pn.js";
import { r as J } from "./sentry-CAR5o4wv.js";
import { W as Xt, f as Yt, C as xt, B as Kt, i as rt, X as Qt, Y as Zt, Z as qt, _ as At, $ as Gt } from "./utils-D5LKKDAj.js";
import { u as Nt, O as Et } from "./OtpForm-BTd51sDR.js";
import { i as a } from "./index-wI714H_N.js";
import { NotFoundPage as Pt } from "./NotFoundPage.js";
import { P as mt } from "./Product-Jr0LJCwm.js";
import { O as It, r as wt, M as Ft, s as te, g as ee, a as se } from "./OrderStatus-BFxH2wTa.js";
import { m as Bt, M as Tt, r as ne } from "./MandateListing-D8XscYA_.js";
import { o as ie } from "./redirection-8g5vvfzH.js";
import { C as St, S as Dt } from "./StatusMessage-BwNUc0rO.js";
import { B as re } from "./BankAccountListing-BCK16zSG.js";
import { T as ae } from "./Tnc-MBkdDEOv.js";
import { ErrorPage as Rt } from "./ErrorPage.js";
import { f as oe, r as de } from "./validators-BArvL7cB.js";
import { R as ce, F as le } from "./react-final-form.es-BQCbpI73.js";
import { LoginContext as me } from "./LoginContext.js";
import { M as fe } from "./MandateSelector-Dz6yT0QG.js";
function ue({ scheme: o, investorProfile: b, mf_purchase: N, onCompletion: C }) {
  var $, G;
  const { tenantConfig: D, banks: P } = kt(), T = D == null ? void 0 : D.fallbackLogo, h = N != null && N.amount ? N.amount : 0, [j, w] = p(null), [k, S] = p(!0), [X, m] = p(!1), [M, F] = p("initial"), [f, Z] = p(!1), [r, nt] = p(null), [u, s] = p(null), { fpapi: d } = ft(pt), [E, U] = p([]);
  let O = (($ = D == null ? void 0 : D.app_constants) == null ? void 0 : $.paymentMethods) || [];
  O = h >= 1e5 ? O : O == null ? void 0 : O.filter((i) => i !== "mandate");
  const [z, q] = p((O || [])[0]), [A, tt] = p(!1), B = Xt(O), [l, v] = p(""), [R, W] = p(""), x = (G = D == null ? void 0 : D.app_constants) == null ? void 0 : G.paymentGateway;
  ct(() => {
    K();
  }, []), ct(() => {
    dt(), Y();
  }, [
    j
  ]);
  const K = async () => {
    try {
      if (b != null && b.id) {
        tt(!0);
        const i = await d.fpClient.bank_accounts().fetchAll({
          profile: b.id
        });
        w(i.data), tt(!1);
      }
    } catch (i) {
      tt(!1), window.debug.error(i), J(i);
    }
  }, dt = async () => {
    const i = j == null ? void 0 : j.map((H) => H.old_id);
    Yt(i) || d.fpClient.mandates().fetchAll({
      bank_account_id: i == null ? void 0 : i.join(","),
      size: 100
    }).then((H) => {
      const n = H.mandates;
      if (n.sort((y, L) => new Date(L.created_at).valueOf() - new Date(y.created_at).valueOf()), n.length > 0) {
        j == null || j.forEach((g) => {
          n == null || n.forEach((et) => {
            et.bank_account_id === g.old_id && (et.bank = {
              bank_name: g.bank_name,
              ifsc: g.ifsc_code,
              number: g.account_number
            });
          });
        });
        const y = [];
        n.forEach((g) => {
          g.mandate_status === "APPROVED" && y.push(g);
        });
        const L = y.filter((g) => g.mandate_limit >= h);
        y.length && L[0] ? s(L[0]) : s(null);
      }
    });
  }, Y = async () => (j != null && j.length && (U(j), nt(j[0])), j), jt = async () => {
    try {
      const i = await d.fpClient.email_addresses().fetchAll({
        profile: b == null ? void 0 : b.id
      }), H = await d.fpClient.phone_numbers().fetchAll({
        profile: b == null ? void 0 : b.id
      });
      return {
        emailData: i,
        phoneData: H
      };
    } catch (i) {
      window.debug.log(i), J(i);
    }
  }, yt = (i) => {
    i.stopPropagation(), S(!k);
  }, _t = (i) => (i == null ? void 0 : i.mandate_status) !== "APPROVED" ? a._({
    id: "n8egj/"
  }) : (i == null ? void 0 : i.mandate_limit) < h ? a._({
    id: "1go36W"
  }) : "", e = (i) => {
    C({
      success: !0,
      isWindowBlocked: !1,
      paymentId: i
    });
  }, c = (i) => {
    C({
      success: !1,
      isWindowBlocked: !0,
      paymentId: i
    });
  }, _ = (i) => {
    C({
      success: !0,
      isWindowBlocked: !1,
      paymentId: i
    });
  };
  function I(i) {
    i.preventDefault();
    const H = r.old_id;
    if (z === "mandate") {
      const n = _t(u);
      v(n), n || (F("initiated"), d.fpClient.payments().createNachPayment({
        mandate_id: u.id,
        amc_order_ids: [
          N.old_id
        ]
      }).then((y) => {
        C({
          success: !0,
          isWindowBlocked: !1,
          paymentId: y.id
        });
      }).catch((y) => {
        var L, g;
        J(y), y && ((g = (L = y == null ? void 0 : y.data) == null ? void 0 : L.error) == null ? void 0 : g.message) === "consent_not_captured" ? C({
          success: !1,
          isWindowBlocked: !1,
          error: y
        }) : (W("Something went wrong"), F("initial"));
      }));
    } else
      x && x === "BILLDESK" ? (F("initiated"), d.fpClient.payments().createNetbankingPayment({
        amc_order_ids: [
          N.old_id
        ],
        method: z,
        bank_account_id: H,
        payment_postback_url: `${window.location.origin + Kt}/redirection`,
        provider_name: "BILLDESK"
      }).then((n) => {
        ie(n == null ? void 0 : n.token_url, "payment-billdesk", () => e(n == null ? void 0 : n.id), () => c(n == null ? void 0 : n.id), () => _(n == null ? void 0 : n.id));
      }).catch((n) => {
        var y, L;
        J(n), n && ((L = (y = n == null ? void 0 : n.data) == null ? void 0 : y.error) == null ? void 0 : L.message) === "consent_not_captured" ? C({
          success: !1,
          isWindowBlocked: !1,
          error: n
        }) : (W("Something went wrong"), F("initial"));
      })) : (F("initiated"), d.fpClient.payments().createNetbankingPayment({
        amc_order_ids: [
          N.old_id
        ],
        method: z,
        bank_account_id: H
      }).then((n) => {
        jt().then((y) => {
          var Mt, Ot;
          const { emailData: L, phoneData: g } = y, et = n.id, it = n.sdk_options.razorpay;
          let vt = null;
          for (const st in it.method)
            it.method[st] && (vt = st);
          const Wt = {
            amount: it.amount,
            currency: "INR",
            email: (Mt = L == null ? void 0 : L.data[0]) == null ? void 0 : Mt.email,
            contact: (Ot = g == null ? void 0 : g.data[0]) == null ? void 0 : Ot.number,
            method: vt,
            bank: it.bank_code,
            order_id: it.order_id
          }, bt = ne(it.key);
          bt.on("payment.success", function(st) {
            d.submitPayment(it.callback_url, st.razorpay_payment_id, st.razorpay_order_id, st.razorpay_signature).then(() => {
              C({
                success: !0,
                isWindowBlocked: !1,
                paymentId: et
              });
            });
          }), bt.on("payment.error", function(st) {
            d.submitPaymentError(it.callback_url, "error", st.error.code, st.error.description).then(() => {
              C({
                success: !1,
                isWindowBlocked: !1,
                paymentId: et
              });
            });
          }), bt.createPayment(Wt);
        });
      }).catch((n) => {
        var y, L;
        J(n), n && ((L = (y = n == null ? void 0 : n.data) == null ? void 0 : y.error) == null ? void 0 : L.message) === "consent_not_captured" ? C({
          success: !1,
          isWindowBlocked: !1,
          error: n
        }) : (W("Something went wrong"), F("initial"));
      }));
  }
  const V = () => {
    var i, H, n, y, L;
    return /* @__PURE__ */ t.jsx(t.Fragment, {
      children: A ? /* @__PURE__ */ t.jsx(lt, {
        variant: "fullpage"
      }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
        children: [
          /* @__PURE__ */ t.jsx(St, {
            check: N.plan,
            children: /* @__PURE__ */ t.jsx(at, {
              title: a._({
                id: "LgSBEi"
              })
            })
          }),
          o && /* @__PURE__ */ t.jsx(mt, {
            scheme: o
          }),
          /* @__PURE__ */ t.jsx(ot, {
            label: `${N.plan ? a._({
              id: "/DJBcB"
            }) : a._({
              id: "KqeLJ1"
            })}`,
            value: `₹ ${xt(h)}`,
            direction: "row",
            border: !0,
            customClass: "mb-7"
          }),
          /* @__PURE__ */ t.jsx("form", {
            onSubmit: I,
            className: "flex flex-1 flex-col",
            children: /* @__PURE__ */ t.jsxs("div", {
              className: "flex flex-1 flex-col justify-between",
              children: [
                /* @__PURE__ */ t.jsxs("div", {
                  children: [
                    /* @__PURE__ */ t.jsx("div", {
                      className: "mb-8",
                      children: /* @__PURE__ */ t.jsxs("div", {
                        children: [
                          /* @__PURE__ */ t.jsx(at, {
                            variant: "sub",
                            title: a._({
                              id: "pCX9tn"
                            }),
                            customClass: "mb-3"
                          }),
                          /* @__PURE__ */ t.jsx(zt, {
                            variant: "iconBased",
                            name: "radioname",
                            checked: O.indexOf(z),
                            group: B,
                            onChange: (g, et) => {
                              q(et.value), v("");
                            }
                          })
                        ]
                      })
                    }),
                    z === "mandate" && h >= 1e5 ? /* @__PURE__ */ t.jsxs("div", {
                      className: "mb-8 mt-4",
                      children: [
                        /* @__PURE__ */ t.jsx(at, {
                          variant: "sub",
                          title: a._({
                            id: "tJ7I/4"
                          })
                        }),
                        u ? /* @__PURE__ */ t.jsx(Lt, {
                          children: /* @__PURE__ */ t.jsxs("div", {
                            className: "flex flex-wrap items-center justify-between",
                            children: [
                              /* @__PURE__ */ t.jsx("div", {
                                className: "w-full",
                                children: /* @__PURE__ */ t.jsx(ot, {
                                  label: a._({
                                    id: "L/u9Dz"
                                  }),
                                  value: u ? `${(i = u == null ? void 0 : u.bank) == null ? void 0 : i.bank_name} - ****${(H = u == null ? void 0 : u.bank) == null ? void 0 : H.number.slice(-4)}` : "-",
                                  logo: P[(y = (n = u == null ? void 0 : u.bank) == null ? void 0 : n.ifsc) == null ? void 0 : y.slice(0, 4)] || T
                                })
                              }),
                              /* @__PURE__ */ t.jsx("div", {
                                children: /* @__PURE__ */ t.jsx(ot, {
                                  label: a._({
                                    id: "x4Fb5/"
                                  }),
                                  value: u ? `₹${xt(u == null ? void 0 : u.mandate_limit)}` : "-"
                                })
                              }),
                              /* @__PURE__ */ t.jsx("div", {
                                children: /* @__PURE__ */ t.jsx(ot, {
                                  label: a._({
                                    id: "MhEf8P"
                                  }),
                                  value: u ? Bt[u.mandate_status].value : "-",
                                  icon: u ? Bt[u.mandate_status].icon : "",
                                  iconPlacement: "left"
                                })
                              }),
                              /* @__PURE__ */ t.jsx("div", {
                                children: /* @__PURE__ */ t.jsx(gt, {
                                  variant: "text",
                                  onClick: (g) => {
                                    g.preventDefault(), g.stopPropagation(), Z(!0);
                                  },
                                  children: a._({
                                    id: "ShFidp"
                                  })
                                })
                              })
                            ]
                          })
                        }) : /* @__PURE__ */ t.jsx(t.Fragment, {
                          children: /* @__PURE__ */ t.jsx(Ct, {
                            type: "notes",
                            text: a._({
                              id: "o+Qbf0"
                            }),
                            children: /* @__PURE__ */ t.jsx(gt, {
                              variant: "text",
                              onClick: (g) => {
                                g.preventDefault(), g.stopPropagation(), Z(!0);
                              },
                              children: a._({
                                id: "uhQPR2"
                              })
                            })
                          })
                        }),
                        l && /* @__PURE__ */ t.jsx("div", {
                          children: /* @__PURE__ */ t.jsx(ut, {
                            type: "error",
                            variant: "field",
                            title: l,
                            customClass: ""
                          })
                        })
                      ]
                    }) : /* @__PURE__ */ t.jsxs("div", {
                      children: [
                        /* @__PURE__ */ t.jsx(at, {
                          variant: "sub",
                          title: a._({
                            id: "bu9VTL"
                          }),
                          customClass: "mb-3"
                        }),
                        /* @__PURE__ */ t.jsx(Ct, {
                          type: r ? "borderedNotes" : "notes",
                          // text="ICICI BANK (*****9872)"
                          text: r ? `${r.bank_name} - ****${r.account_number.slice(-4)}` : a._({
                            id: "Hgankl"
                          }),
                          logo: r ? P[(L = r == null ? void 0 : r.ifsc_code) == null ? void 0 : L.slice(0, 4)] : T
                        })
                      ]
                    })
                  ]
                }),
                /* @__PURE__ */ t.jsxs("div", {
                  children: [
                    /* @__PURE__ */ t.jsxs($t, {
                      id: "terms-id",
                      checked: k,
                      onChange: yt,
                      customClass: "mb-3",
                      children: [
                        a._({
                          id: "PFNsn4"
                        }),
                        /* @__PURE__ */ t.jsx("span", {
                          children: " "
                        }),
                        /* @__PURE__ */ t.jsx("a", {
                          onClick: (g) => {
                            g.preventDefault(), g.stopPropagation(), m(!0);
                          },
                          className: "text-size-14",
                          children: a._({
                            id: "863m1W"
                          })
                        })
                      ]
                    }),
                    R && /* @__PURE__ */ t.jsx(ut, {
                      type: "error",
                      variant: "field",
                      title: "Something went wrong"
                    }),
                    /* @__PURE__ */ t.jsx(gt, {
                      variant: k && E.length ? "primary" : "disabled",
                      fullwidth: !0,
                      customClass: "mb-8",
                      type: "submit",
                      children: a._({
                        id: "AIDv2f",
                        values: {
                          0: xt(h)
                        }
                      })
                    })
                  ]
                })
              ]
            })
          }),
          /* @__PURE__ */ t.jsx(ht, {
            variant: "fullPage",
            title: a._({
              id: "s4HNy/"
            }),
            isOpen: X,
            toggleModal: m,
            contentHeightMax: !0,
            children: /* @__PURE__ */ t.jsx(ae, {
              disableBackButton: !0
            })
          }),
          /* @__PURE__ */ t.jsx(ht, {
            variant: "fullPage",
            title: a._({
              id: "XeOA76"
            }),
            isOpen: f,
            toggleModal: () => {
              Z(!1);
            },
            contentHeightMax: !1,
            children: /* @__PURE__ */ t.jsx(Tt, {
              investorProfile: b,
              status: [
                "approved"
              ],
              limit: h,
              handleMandateSelection: (g) => {
                const et = _t(g);
                v(et), s(g), Z(!1);
              }
            })
          })
        ]
      })
    });
  }, Q = () => {
    switch (M) {
      case "initial":
        return V();
      case "initiated":
        return /* @__PURE__ */ t.jsx(lt, {
          variant: "fullpage"
        });
      case "listing_bankaccount":
        return /* @__PURE__ */ t.jsx(re, {
          investorProfile: b,
          handleBankSelection: (i) => {
            nt(i), U([
              i
            ]), F("initial"), K();
          }
        });
      default:
        return V();
    }
  };
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: Q()
  });
}
function Re({ investorProfile: o, id: b, showSchemeDetails: N = !0, isGroupInvestment: C = !1, action: D, onSuccess: P }) {
  const { fpapi: T } = ft(pt), [h, j] = p(!1), [w, k] = p(""), [S, X] = p(), [m, M] = p(null), { tenantConfig: F, amcs: f } = kt(), Z = F == null ? void 0 : F.fallbackLogo, r = F == null ? void 0 : F.showSchemeLogo, [nt, u] = p({}), { collectedAcquisition: s, initiateSendOTP: d } = Nt();
  ct(() => {
    E();
  }, []);
  const E = async () => {
    var R, W;
    const B = b == null ? void 0 : b.split(",");
    j(!0);
    let l, v;
    try {
      if (l = await T.fpClient.mf_purchases().fetch(b.split(",")[0]), v = !rt(l) && await T.fpClient.payments().fetchAll({
        amc_order_ids: l == null ? void 0 : l.old_id
      }), N) {
        const K = await T.fpClient.master_data().fetchScheme(l.scheme);
        U(K);
      }
      M(l), l.state === "pending" ? k("consent") : k("consent_not_required");
      const x = {
        given_for_object: l == null ? void 0 : l.object,
        action: D,
        given_for: B
      };
      await d(x), j(!1);
    } catch (x) {
      window.debug.log(x, "error"), !rt(l) && (l == null ? void 0 : l.state) !== "pending" && ((R = v == null ? void 0 : v.payments) != null && R.length) ? (u((W = v == null ? void 0 : v.payments) == null ? void 0 : W[0]), k("finished")) : k("error"), j(!1);
    }
  }, U = (B) => {
    const l = r ? {
      ...B,
      schemeLogo: f[B == null ? void 0 : B.amc_id] || Z
    } : {
      ...B
    };
    X(l);
  }, O = async () => {
    var B, l, v, R, W, x, K, dt;
    if (j(!0), P)
      try {
        const Y = await T.fpClient.mf_purchases().update({
          id: m == null ? void 0 : m.id,
          consent: {
            email: (B = s == null ? void 0 : s.acquisition_details) == null ? void 0 : B.email,
            isd_code: (l = s == null ? void 0 : s.acquisition_details) == null ? void 0 : l.isd,
            mobile: (v = s == null ? void 0 : s.acquisition_details) == null ? void 0 : v.mobile_number
          }
        });
        P(Y), j(!1);
      } catch {
        k("error"), j(!1);
      }
    else
      try {
        const Y = await T.fpClient.mf_purchases().update({
          id: m == null ? void 0 : m.id,
          consent: {
            email: (R = s == null ? void 0 : s.acquisition_details) == null ? void 0 : R.email,
            isd_code: (W = s == null ? void 0 : s.acquisition_details) == null ? void 0 : W.isd,
            mobile: (x = s == null ? void 0 : s.acquisition_details) == null ? void 0 : x.mobile_number
          }
        });
        M(Y), k("payment"), j(!1);
      } catch (Y) {
        if (Y && ((dt = (K = Y == null ? void 0 : Y.data) == null ? void 0 : K.error) == null ? void 0 : dt.message) === "failed to update investor consent, already exists") {
          k("payment"), j(!1);
          return;
        }
        k("error"), j(!1);
      }
  }, z = () => /* @__PURE__ */ t.jsxs("div", {
    children: [
      /* @__PURE__ */ t.jsx(at, {
        title: a._({
          id: "ol5JJc"
        }),
        semiBold: !0
      }),
      /* @__PURE__ */ t.jsx(Lt, {
        customClass: "mb-8",
        children: /* @__PURE__ */ t.jsxs("div", {
          className: "-mr-2 -ml-2  flex flex-wrap items-center",
          children: [
            /* @__PURE__ */ t.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ t.jsx(ot, {
                label: a._({
                  id: "4Zu577"
                }),
                value: m != null && m.amount ? `₹ ${xt(m == null ? void 0 : m.amount, 2)}` : "-"
              })
            }),
            /* @__PURE__ */ t.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ t.jsx(ot, {
                label: a._({
                  id: "rs9/UI"
                }),
                value: "One time"
              })
            }),
            /* @__PURE__ */ t.jsx("div", {
              className: "mb-3 basis-1/2 pr-2 pl-2",
              children: /* @__PURE__ */ t.jsx(ot, {
                label: a._({
                  id: "cXcPd1"
                }),
                value: m != null && m.folio_number ? m == null ? void 0 : m.folio_number : "New folio"
              })
            })
          ]
        })
      })
    ]
  }), q = () => /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(at, {
        title: a._({
          id: "72BAzM"
        })
      }),
      /* @__PURE__ */ t.jsx(mt, {
        scheme: S
      })
    ]
  });
  function A({ success: B, isWindowBlocked: l, paymentId: v }) {
    (async () => {
      if (v)
        try {
          const R = await T.fpClient.payments().fetch(Number(v));
          u(R);
          const W = await T.fpClient.mf_purchases().fetch(m.id);
          M(W);
        } catch (R) {
          window.debug.log(R), J(R);
        }
      k(B && !l || !B && !l ? "finished" : "finished_error");
    })();
  }
  return h ? /* @__PURE__ */ t.jsx(lt, {
    variant: "fullpage"
  }) : (() => {
    switch (w) {
      case "consent":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            N && q(),
            z(),
            /* @__PURE__ */ t.jsx(Et, {
              handleSubmit: O,
              pageTitle: a._({
                id: "s/ORP1"
              }),
              isGroupInvestment: C,
              investorProfile: o,
              collectAcquisition: s
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            N && q(),
            z(),
            /* @__PURE__ */ t.jsx(Dt, {
              title: a._({
                id: "8Erf1o"
              }),
              description: a._({
                id: "dYdyvO"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            N && q(),
            z(),
            /* @__PURE__ */ t.jsx(Rt, {
              title: a._({
                id: "sS+OUW"
              }),
              showAction: !0,
              actionText: a._({
                id: "RvsoP+"
              }),
              actionHandler: () => {
                k("payment");
              }
            })
          ]
        });
      case "payment":
        return !rt(o) && !rt(m) ? /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(mt, {
              scheme: S
            }),
            /* @__PURE__ */ t.jsx(ue, {
              investorProfile: o,
              mf_purchase: m,
              onCompletion: A
            })
          ]
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "finished":
        return !rt(S) && !rt(m) ? /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(mt, {
              scheme: S
            }),
            /* @__PURE__ */ t.jsx(It, {
              investorProfile: o,
              scheme: S,
              mf_order: m,
              payment: nt,
              isGroupInvestment: C
            })
          ]
        }) : /* @__PURE__ */ t.jsx(t.Fragment, {});
      case "not_allowed":
        return /* @__PURE__ */ t.jsx(Pt, {});
      default:
        return /* @__PURE__ */ t.jsx(t.Fragment, {});
    }
  })();
}
function _e({ mfPurchasePlan: o, handleSetupMandateCompletion: b, partner: N, groupRecurringDateObject: C, isFirstInstallmentPayment: D, isGroupInvestment: P }) {
  const { loggedInUser: T } = ft(me), { fpapi: h } = ft(pt), [j, w] = p(!1), [k, S] = p(!1), [X, m] = p(null), M = Ut(null), [F, f] = p(null);
  ct(() => {
    (async () => {
      var s;
      if (X && (M != null && M.current) && (o != null && o.payment_source))
        try {
          const d = await h.fpClient.bank_accounts().fetchAll({
            profile: X == null ? void 0 : X.id
          }), E = d == null ? void 0 : d.data;
          if (!(E != null && E.length))
            return;
          const U = await h.fpClient.mandates().fetch(o == null ? void 0 : o.payment_source);
          E == null || E.forEach((O) => {
            (U == null ? void 0 : U.bank_account_id) === O.old_id && (U.bank = {
              bank_name: O.bank_name,
              number: O.account_number,
              ifsc: O.ifsc_code
            });
          }), (s = M == null ? void 0 : M.current) == null || s.input.onChange(U);
        } catch (d) {
          J(d);
        }
    })();
  }, [
    o == null ? void 0 : o.payment_source,
    X,
    M
  ]);
  const Z = Vt(() => ({
    mandate: null
  }), []);
  ct(() => {
    r();
  }, []);
  const r = async () => {
    var s;
    try {
      w(!0);
      const d = await h.getInvestorProfile((s = T == null ? void 0 : T.profile) == null ? void 0 : s.fp_identifier);
      m(d), w(!1);
    } catch (d) {
      window.debug.error(d), J(d);
    }
  }, nt = async ({ mandate: s }) => {
    var d, E, U, O, z, q, A, tt, B, l, v, R, W;
    if (((d = s == null ? void 0 : s.id) == null ? void 0 : d.toString()) === ((E = o == null ? void 0 : o.payment_source) == null ? void 0 : E.toString())) {
      b();
      return;
    }
    w(!0);
    try {
      let x = {};
      x = {
        id: o == null ? void 0 : o.id,
        payment_method: "mandate",
        payment_source: s == null ? void 0 : s.id
      }, await h.fpClient.mf_purchase_plans().update(x), b(), w(!1);
    } catch (x) {
      if (Zt(x)) {
        const K = (z = (O = (U = x == null ? void 0 : x.data) == null ? void 0 : U.error) == null ? void 0 : O.errors) != null && z.length && ((B = (tt = (A = (q = x == null ? void 0 : x.data) == null ? void 0 : q.error) == null ? void 0 : A.errors) == null ? void 0 : tt[0]) != null && B.message) ? qt((v = (l = x == null ? void 0 : x.data) == null ? void 0 : l.error) == null ? void 0 : v.errors) : At((W = (R = x == null ? void 0 : x.data) == null ? void 0 : R.error) == null ? void 0 : W.message, "Something went wrong. Please try with another mandate or try again later");
        f(K);
      } else
        f("Something went wrong. Please try with another mandate or try again later");
      w(!1), J(F);
    }
  };
  function u() {
    return /* @__PURE__ */ t.jsxs("div", {
      className: "pb-6",
      children: [
        wt(o, N, C, P, D),
        /* @__PURE__ */ t.jsx(ce, {
          onSubmit: nt,
          initialValues: Z,
          validate: () => ({}),
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (s) => /* @__PURE__ */ t.jsxs("form", {
            id: "editOrderForm",
            onSubmit: s.handleSubmit,
            children: [
              /* @__PURE__ */ t.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ t.jsx(Ht, {
                    message: a._({
                      id: "lFCwug"
                    })
                  }),
                  /* @__PURE__ */ t.jsx(le, {
                    name: "mandate",
                    validate: oe(de),
                    children: (d) => (M.current = d, /* @__PURE__ */ t.jsxs(t.Fragment, {
                      children: [
                        /* @__PURE__ */ t.jsx(fe, {
                          mandate: d.input.value,
                          handleMandateModalChange: (E) => {
                            E.preventDefault(), S(!k);
                          }
                        }),
                        Qt(d) && /* @__PURE__ */ t.jsx("div", {
                          className: "mb-2 mt-2",
                          children: /* @__PURE__ */ t.jsx(ut, {
                            type: "error",
                            variant: "field",
                            title: d.meta.error
                          })
                        })
                      ]
                    }))
                  })
                ]
              }),
              /* @__PURE__ */ t.jsx(Jt, {
                children: /* @__PURE__ */ t.jsxs("div", {
                  className: "mb-4 pt-1",
                  children: [
                    /* @__PURE__ */ t.jsx(St, {
                      check: !!F,
                      children: /* @__PURE__ */ t.jsx(ut, {
                        type: "error",
                        variant: "field",
                        title: F
                      })
                    }),
                    /* @__PURE__ */ t.jsx(gt, {
                      variant: "primary",
                      type: "submit",
                      fullwidth: !0,
                      children: a._({
                        id: "2iVSoY"
                      })
                    })
                  ]
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ t.jsx(ht, {
          variant: "fullPage",
          title: "Select mandate",
          isOpen: k,
          toggleModal: () => {
            S(!1);
          },
          contentHeightMax: !0,
          children: /* @__PURE__ */ t.jsx(Tt, {
            investorProfile: X,
            handleMandateSelection: (s) => {
              var d;
              S(!1), f(null), (d = M == null ? void 0 : M.current) == null || d.input.onChange(s);
            },
            limit: o == null ? void 0 : o.amount,
            isPartner: !1
          })
        })
      ]
    });
  }
  return j ? /* @__PURE__ */ t.jsx(lt, {
    variant: "fullpage"
  }) : u();
}
function We({ investorProfile: o, id: b, showSchemeDetails: N = !0, isGroupInvestment: C = !1, onSuccess: D, action: P, isFirstInstallmentPayment: T }) {
  const { fpapi: h } = ft(pt), [j, w] = p(!1), [k, S] = p(""), [X, m] = p(), [M, F] = p(null), [f, Z] = p(null), { tenantConfig: r, amcs: nt, banks: u } = kt(), { collectedAcquisition: s, initiateSendOTP: d } = Nt(), [E, U] = p(), [O, z] = p(null), q = r == null ? void 0 : r.fallbackLogo, A = new URLSearchParams(window.location.search), tt = window.location.pathname.includes("/payment"), B = (e) => {
    var c, _, I, V, Q, $;
    if (C) {
      const G = (_ = (c = r == null ? void 0 : r.fundList) == null ? void 0 : c.find((i) => i.isin === (e == null ? void 0 : e.scheme))) == null ? void 0 : _.nfo;
      return ((I = r == null ? void 0 : r.groupInvestment) == null ? void 0 : I.days) && ((V = r == null ? void 0 : r.groupInvestment) == null ? void 0 : V.cutOffDays) && Gt({
        salaryDay: (Q = r == null ? void 0 : r.groupInvestment) == null ? void 0 : Q.days[0],
        salaryCutoffDay: ($ = r == null ? void 0 : r.groupInvestment) == null ? void 0 : $.cutOffDays[0],
        NFO: G,
        purchaseDate: e.created_at
      });
    }
  };
  ct(() => {
    v();
  }, [
    o
  ]);
  const l = async (e) => {
    if (!rt(e))
      try {
        const c = await (h == null ? void 0 : h.fpClient.partners().fetchById(e == null ? void 0 : e.partner));
        U(c);
      } catch (c) {
        J(c);
      }
  }, v = async () => {
    try {
      w(!0);
      const e = await h.fpClient.mf_purchase_plans().fetch(b);
      if (Z(e), N) {
        const c = await h.fpClient.master_data().fetchScheme(e == null ? void 0 : e.scheme);
        W(c);
      }
      await l(e), !(e != null && e.payment_method) && !(e != null && e.payment_source) ? S("setup_mandate") : await Y(e), (e == null ? void 0 : e.payment_method) === "mandate" && (e != null && e.payment_source) && R(e);
    } catch (e) {
      window.debug.log(e), w(!1), S("error"), J(e);
    } finally {
      w(!1);
    }
  }, R = async (e) => {
    const c = b == null ? void 0 : b.split(",");
    if ((e == null ? void 0 : e.payment_method) === "mandate" && (e != null && e.payment_source))
      try {
        if (w(!0), (e == null ? void 0 : e.state) === "active" || (e == null ? void 0 : e.state) === "completed") {
          S("success"), w(!1);
          return;
        }
        const _ = {
          given_for_object: e == null ? void 0 : e.object,
          action: P,
          given_for: c
        };
        await d(_), S("consent"), F(B(e)), w(!1);
      } catch (_) {
        window.debug.log(_), w(!1), S("error"), J(_);
      }
  }, W = (e) => {
    const c = r != null && r.showSchemeLogo ? {
      ...e,
      schemeLogo: nt[e == null ? void 0 : e.amc_id] || (r == null ? void 0 : r.fallbackLogo)
    } : {
      ...e
    };
    m(c);
  }, x = (e) => {
    const c = new Date(e);
    return c.getDay() === 5 ? 3 : c.getDay() === 6 ? 2 : 1;
  }, K = async (e) => {
    let c;
    try {
      c = await h.fpClient.mf_purchases().fetchAll({
        plan: e == null ? void 0 : e.id
      });
    } catch (i) {
      window.debug.log(i), J(i);
    }
    if (!c.data.length)
      return;
    const _ = c.data[0], I = e != null && e.created_at ? new Date(e == null ? void 0 : e.created_at) : /* @__PURE__ */ new Date(), V = new Date(new Date(I).setDate(I.getDate() + 1)), Q = new Date(new Date(I).setDate(I.getDate() + x(I))), $ = new Date(_ == null ? void 0 : _.scheduled_on);
    if (new Date(_ == null ? void 0 : _.created_at).toDateString() === I.toDateString() || $.toDateString() === V.toDateString() || $.toDateString() === Q.toDateString())
      return _;
  }, dt = async () => {
    var e, c, _, I, V, Q, $, G, i, H;
    if (D) {
      w(!0);
      try {
        await h.fpClient.mf_purchase_plans().update({
          id: f == null ? void 0 : f.id,
          consent: {
            email: (e = s == null ? void 0 : s.acquisition_details) == null ? void 0 : e.email,
            isd_code: (c = s == null ? void 0 : s.acquisition_details) == null ? void 0 : c.isd,
            mobile: (_ = s == null ? void 0 : s.acquisition_details) == null ? void 0 : _.mobile_number
          }
        });
        const n = await K(f);
        D(!0, n), w(!1);
      } catch (n) {
        if (n && ((V = (I = n == null ? void 0 : n.data) == null ? void 0 : I.error) == null ? void 0 : V.message) === "consent is already exists for the plan") {
          const y = await K(f);
          D(!0, y), w(!1);
          return;
        }
        S("error"), w(!1);
      }
    } else {
      w(!0);
      try {
        const n = await h.fpClient.mf_purchase_plans().update({
          id: f == null ? void 0 : f.id,
          consent: {
            email: (Q = s == null ? void 0 : s.acquisition_details) == null ? void 0 : Q.email,
            isd_code: ($ = s == null ? void 0 : s.acquisition_details) == null ? void 0 : $.isd,
            mobile: (G = s == null ? void 0 : s.acquisition_details) == null ? void 0 : G.mobile_number
          }
        });
        Z(n), S("success"), w(!1);
      } catch (n) {
        if (n && ((H = (i = n == null ? void 0 : n.data) == null ? void 0 : i.error) == null ? void 0 : H.message) === "consent is already exists for the plan") {
          S("success"), w(!1);
          return;
        }
        S("error"), w(!1);
      }
    }
  }, Y = async (e) => {
    o && (e == null ? void 0 : e.payment_method) === "mandate" && (e != null && e.payment_source) && h.fpClient.bank_accounts().fetchAll({
      profile: o == null ? void 0 : o.id
    }).then(async (c) => {
      var Q, $, G;
      const _ = c == null ? void 0 : c.data;
      if (!_.length)
        return;
      const V = {
        ...await h.fpClient.mandates().fetch(e == null ? void 0 : e.payment_source),
        bank: {
          bank_name: (Q = _[0]) == null ? void 0 : Q.bank_name,
          number: ($ = _[0]) == null ? void 0 : $.account_number,
          ifsc: (G = _[0]) == null ? void 0 : G.ifsc_code
        }
      };
      z(V);
    }).catch((c) => {
      J(c);
    });
  }, jt = () => {
    let e = "";
    const c = A.get("step_up_count"), _ = A.get("step_up_frequency"), I = A.get("step_up_type"), V = A.get("step_up_type_value");
    return (I === "amount" || I === "percentage") && V && (_ === "half-yearly" || _ === "yearly") && (f != null && f.frequency) && (e = ee(Number(f == null ? void 0 : f.amount), se(Number(f == null ? void 0 : f.installment_day), tt), I, V, _, Number(c))), e;
  }, yt = () => /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      /* @__PURE__ */ t.jsx(at, {
        title: a._({
          id: "u6vc2m"
        }),
        icon: k === "consent" ? "Back" : void 0,
        onClick: () => {
          k === "consent" && S("setup_mandate");
        }
      }),
      /* @__PURE__ */ t.jsx(mt, {
        scheme: X
      })
    ]
  }), _t = () => {
    switch (k) {
      case "setup_mandate":
        return /* @__PURE__ */ t.jsx(_e, {
          mfPurchasePlan: f,
          handleSetupMandateCompletion: () => {
            S(""), v();
          },
          partner: E,
          groupRecurringDateObject: M,
          isGroupInvestment: C,
          isFirstInstallmentPayment: T
        });
      case "consent":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            wt(f, E, M, C, T),
            /* @__PURE__ */ t.jsx(St, {
              check: te(),
              children: /* @__PURE__ */ t.jsx(ut, {
                type: "helper",
                variant: "flash",
                title: jt(),
                customClass: "mb-5"
              })
            }),
            /* @__PURE__ */ t.jsx(Ft, {
              mandate: O,
              banks: u,
              fallbackLogo: q
            }),
            /* @__PURE__ */ t.jsx(Et, {
              handleSubmit: dt,
              pageTitle: a._({
                id: "nvtnXn"
              }),
              investorProfile: o,
              isGroupInvestment: C,
              collectAcquisition: s
            })
          ]
        });
      case "consent_not_required":
        return /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            wt(f, E, M, C, T),
            /* @__PURE__ */ t.jsx(Ft, {
              mandate: O,
              banks: u,
              fallbackLogo: q
            }),
            /* @__PURE__ */ t.jsx(Dt, {
              title: a._({
                id: "8Erf1o"
              }),
              description: a._({
                id: "dYdyvO"
              }),
              iconName: "successInfo"
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ t.jsx(Rt, {
          title: a._({
            id: "kCTQHV"
          }),
          description: a._({
            id: "oahseM"
          }),
          showDetails: !1
        });
      case "success":
        return /* @__PURE__ */ t.jsx(t.Fragment, {
          children: /* @__PURE__ */ t.jsx(It, {
            investorProfile: o,
            scheme: X,
            mf_order: f
          })
        });
      default:
        return /* @__PURE__ */ t.jsx(lt, {
          variant: "fullpage"
        });
    }
  };
  return j ? /* @__PURE__ */ t.jsx(lt, {
    variant: "fullpage"
  }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
    children: [
      N && yt(),
      _t()
    ]
  });
}
export {
  We as P,
  Re as a,
  ue as b
};
