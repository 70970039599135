import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useContext as D, useState as _, useMemo as ue, useEffect as H } from "react";
import { a as ne, P as ee, D as oe, e as U, T as W, M as B, l as de, B as Z, f as G, h as z } from "./index.esm-G8KyR0tM.js";
import { F as ie } from "./provider-Diwup2pn.js";
import { r as q } from "./sentry-CAR5o4wv.js";
import { AppContext as re } from "./AppContext.js";
import { R as te, F as K } from "./react-final-form.es-BQCbpI73.js";
import { i as o } from "./index-wI714H_N.js";
import { u as le } from "./useConfiguration-B-lyaBB9.js";
import { f as $, a as me, r as Q, y as xe, b as fe, k as ge, l as he, z as se } from "./validators-BArvL7cB.js";
import { m as ve, o as P, t as ae, s as ce, S as je } from "./utils-D5LKKDAj.js";
import { L as _e } from "./LanguageSelector-Cxyk2l5L.js";
import { C as J } from "./CopyButton-Cx3WveFv.js";
const pe = ({ loginDetails: x, onSuccessfulRegistration: A, isGroupInvestment: w = !1, isPartner: F = !1 }) => {
  var I, M;
  const i = D(ie), g = i == null ? void 0 : i.fpapi, { tenantConfig: s } = le(), { AppState: { tenant: E } } = D(re), [p, b] = _(""), [k, y] = _(!1), C = (I = s == null ? void 0 : s.groupInvestment) == null ? void 0 : I.allowedEmailDomains, S = (M = s == null ? void 0 : s.data_sources_usage_policy) == null ? void 0 : M.KYC, L = S === "strict" || S === "flexible", c = /* @__PURE__ */ new Date();
  c.setFullYear(c.getFullYear() - 18);
  const j = ue(() => ({
    pan: (x == null ? void 0 : x.loginHint) || "",
    dob: "",
    mobile: "",
    email: ""
  }), []), O = async (R) => {
    var r, t, d, v, X, f, m, l;
    const { pan: a, email: h, mobile: N, dob: u } = R;
    try {
      y(!0);
      const n = {
        pan: a,
        email: h,
        phone_number: N,
        annexure: {
          add: {
            dob: u ? ve(new Date(u), "yyyy-mm-dd") : "",
            // kycCheckId: KYCData?.id,
            completed_step: "registration_details",
            isonboarded: String(!1)
          }
        }
      };
      F && (n.annexure.add.email_address = h, n.annexure.add.phone_number = N), await (g == null ? void 0 : g.semiPublicFpClient.ciam_user_management().createUser(`fpa_${E}`, n)) ? A({
        user: n
      }) : (window.debug.log("User creation failed"), b(o._({
        id: "44q+Ew"
      })));
    } catch (n) {
      window.debug.error(n), (t = (r = n == null ? void 0 : n.data) == null ? void 0 : r.error) != null && t.errorMessage ? b((v = (d = n == null ? void 0 : n.data) == null ? void 0 : d.error) == null ? void 0 : v.errorMessage) : (f = (X = n == null ? void 0 : n.data) == null ? void 0 : X.error) != null && f.message ? b((l = (m = n == null ? void 0 : n.data) == null ? void 0 : m.error) == null ? void 0 : l.message) : b(o._({
        id: "44q+Ew"
      })), q(n);
    } finally {
      y(!1);
    }
  }, V = (R) => /* @__PURE__ */ e.jsxs("form", {
    onSubmit: R.handleSubmit,
    children: [
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(U, {
            message: "PAN"
          }),
          /* @__PURE__ */ e.jsx(K, {
            initialValue: j.pan,
            name: "pan",
            children: (a) => /* @__PURE__ */ e.jsx(e.Fragment, {
              children: /* @__PURE__ */ e.jsx(W, {
                id: a.input.name,
                autoComplete: "off",
                name: a.input.name,
                value: a.input.value,
                disabled: !0
              })
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(U, {
            message: "Mobile number"
          }),
          /* @__PURE__ */ e.jsx(K, {
            name: "mobile",
            initialValue: j.mobile,
            validate: $(Q, me("Enter valid 10 digit mobile number")),
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            validateFields: [],
            children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx(W, {
                  id: a.input.name,
                  autoComplete: "off",
                  name: a.input.name,
                  value: a.input.value,
                  onChange: a.input.onChange,
                  onBlur: () => {
                    a.input.onChange(a.input.value.trim());
                  },
                  placeholder: o._({
                    id: "NaiXS3"
                  }),
                  onInput: (h) => {
                    h.target.value = h.target.value.replace(P("0-9"), "");
                  },
                  maxLength: 10,
                  status: a.meta.error && a.meta.touched ? "error" : void 0,
                  autoFocus: !0
                }),
                a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(B, {
                  type: "error",
                  variant: "field",
                  title: a.meta.error
                })
              ]
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(U, {
            message: o._({
              id: "GJrVBj"
            })
          }),
          /* @__PURE__ */ e.jsx(K, {
            name: "email",
            initialValue: j.email,
            validate: $(Q, fe("Enter valid email"), xe(w, C || {}, "Invalid official email")),
            validateFields: [],
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx(W, {
                  id: a.input.name,
                  autoComplete: "off",
                  name: a.input.name,
                  value: a.input.value,
                  onChange: a.input.onChange,
                  onBlur: () => {
                    a.input.onChange(a.input.value.trim());
                  },
                  onInput: (h) => {
                    h.target.value = h.target.value.replace(P("A-Za-z0-9._\\-@+"), "");
                  },
                  placeholder: o._({
                    id: "oNJ6PI"
                  }),
                  maxLength: 50,
                  status: a.meta.error && a.meta.touched ? "error" : void 0
                }),
                a.meta.error && a.meta.touched ? /* @__PURE__ */ e.jsx(B, {
                  type: "error",
                  variant: "field",
                  title: a.meta.error
                }) : ae.isStagingOrSandbox && w && C && /* @__PURE__ */ e.jsx(B, {
                  type: "helper",
                  variant: "field",
                  title: o._({
                    id: "V7IZw2"
                  })
                })
              ]
            })
          })
        ]
      }),
      L ? /* @__PURE__ */ e.jsxs("div", {
        className: "mb-8",
        children: [
          /* @__PURE__ */ e.jsx(U, {
            message: "Date of birth"
          }),
          /* @__PURE__ */ e.jsx(K, {
            name: "dob",
            initialValue: j.dob,
            validate: $(Q, he, ge("Age should be above 18")),
            subscription: {
              touched: !0,
              error: !0,
              value: !0
            },
            validateFields: [],
            children: (a) => /* @__PURE__ */ e.jsxs(e.Fragment, {
              children: [
                /* @__PURE__ */ e.jsx(de, {
                  name: a.input.name,
                  variant: "keyboardInputDOB",
                  placeholder: o._({
                    id: "l33YLP"
                  }),
                  format: "dd-MMM-yyyy",
                  selectedDate: a.input.value || null,
                  maxDate: c,
                  initialFocusedDate: c,
                  error: a.meta.error && a.meta.touched,
                  helperText: null,
                  onKeyDown: (h) => h.preventDefault(),
                  handleDateChange: (h) => a.input.onChange(h)
                }),
                a.meta.error && a.meta.touched && /* @__PURE__ */ e.jsx(B, {
                  type: "error",
                  variant: "field",
                  title: a.meta.error
                })
              ]
            })
          })
        ]
      }) : null,
      p && /* @__PURE__ */ e.jsx(B, {
        type: "error",
        variant: "flash",
        title: p
      }),
      /* @__PURE__ */ e.jsx(Z, {
        text: "Continue",
        type: "submit",
        fullwidth: !0,
        onClick: () => {
          const a = Object.keys(R.errors);
          a.length && ce(a[0]);
        },
        customClass: "mb-8 mt-2",
        children: o._({
          id: "2iVSoY"
        })
      })
    ]
  });
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      k && /* @__PURE__ */ e.jsx(ne, {
        variant: "fullpage"
      }),
      /* @__PURE__ */ e.jsx(ee, {
        title: F ? o._({
          id: "PtDtCh"
        }) : o._({
          id: "sR2H4g"
        })
      }),
      /* @__PURE__ */ e.jsx(oe, {
        type: "text",
        text: F ? o._({
          id: "BdZ0cJ"
        }) : o._({
          id: "tkI5Cu"
        })
      }),
      /* @__PURE__ */ e.jsx(te, {
        onSubmit: O,
        validate: () => ({}),
        children: V
      })
    ]
  });
}, be = () => {
  const [x, A] = _(!1), [w, F] = _(0), [i, g] = _(!1);
  return H(() => {
    if (x) {
      const p = setInterval(() => {
        w > 0 ? F(w - 1) : (clearInterval(p), g(!0));
      }, 1e3);
      return () => clearInterval(p);
    }
  }, [
    x,
    w
  ]), {
    start: (p) => {
      A(!0), F(p), g(!1);
    },
    stop: () => {
      A(!1), g(!0);
    },
    value: w,
    hasStopped: i
  };
};
function ye({ authChallengeData: x, loginDetails: A, isGroupInvestment: w, onSuccessfulAuthChallengeVerification: F, onSuccessfulAuthentication: i }) {
  var X, f;
  const g = be(), { fpapi: s } = D(ie), { tenantConfig: E } = le(), [p, b] = _(""), [k, y] = _(!1), [C, S] = _(!1), [L, c] = _({
    session_code: x.session_code,
    client_id: x.client_id,
    execution: x.execution,
    auth_session_id: x.auth_session_id,
    tab_id: x.tab_id
  }), [j, O] = _(""), [V, I] = _(!0), [M, R] = _(0), a = "123456", h = (X = E == null ? void 0 : E.app_constants) != null && X.otp_cooldown_interval ? E.app_constants.otp_cooldown_interval / 1e3 : 0, N = (f = E == null ? void 0 : E.app_constants) == null ? void 0 : f.otp_maximum_resend_attempts;
  H(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []), H(() => {
    y(!0), u();
  }, [
    A.loginHint
  ]), H(() => {
    g.hasStopped && I(!1);
  }, [
    g.hasStopped
  ]), H(() => {
    V && g.start(h), !V && !g.hasStopped && g.stop();
  }, [
    V
  ]);
  const u = async () => {
    let m = "";
    const l = x == null ? void 0 : x.email_address;
    x != null && x.challenge && (m = l ? o._({
      id: "iJMWZn",
      values: {
        maskedEmail: l
      }
    }) : ""), w && (m = l ? o._({
      id: "iJMWZn",
      values: {
        maskedEmail: l
      }
    }) : ""), O(m), y(!1);
  };
  function r() {
    var m;
    y(!0), b(""), (m = s == null ? void 0 : s.userAuthService) == null || m.initiateSignIn(A.loginHint, A.context).then((l) => {
      c({
        session_code: l.session_code,
        client_id: l.client_id,
        execution: l.execution,
        auth_session_id: l.auth_session_id,
        tab_id: l.tab_id
      }), I(!0), R(M + 1), y(!1), F({
        username: A.loginHint,
        nextAuthChallengeData: l
      });
    }).catch((l) => {
      var n, Y;
      I(!1), y(!1), ((Y = (n = l == null ? void 0 : l.data) == null ? void 0 : n.error) == null ? void 0 : Y.message) === "user_temporarily_disabled" && S(!0);
    });
  }
  function t({ otp: m }) {
    var l;
    y(!0), (l = s == null ? void 0 : s.userAuthService) == null || l.getChallenge("otp").verify(L, m).then((n) => {
      var Y;
      n.challenge ? (F({
        username: A.loginHint,
        nextAuthChallengeData: n
      }), y(!1)) : (Y = s == null ? void 0 : s.userAuthService) == null || Y.verifyAuthorizationCode(n.code).then((T) => {
        i({
          authTokenPayload: T
        });
      }).catch(() => {
        b(o._({
          id: "OkdJaJ"
        })), y(!1), I(!1);
      });
    }).catch((n) => {
      var Y, T;
      (Y = n == null ? void 0 : n.error) != null && Y.message ? b((T = n == null ? void 0 : n.error) == null ? void 0 : T.message) : b(o._({
        id: "OkdJaJ"
      })), y(!1), I(!1);
    });
  }
  function d(m, l) {
    l.fields.otp.change(m);
  }
  function v() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ee, {
          title: o._({
            id: "xHuYmx"
          })
        }),
        C && /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(B, {
              type: "alert",
              variant: "flash",
              title: o._({
                id: "9q6HTu"
              }),
              customClass: "mb-5"
            }),
            /* @__PURE__ */ e.jsx(Z, {
              variant: "primary",
              fullwidth: !0,
              onClick: () => {
                var l;
                const m = window.location.pathname + window.location.search;
                (l = s == null ? void 0 : s.userAuthService) == null || l.initiateLogout(m).then((n) => {
                  window.location.href = n.userState;
                });
              },
              children: "Sign in"
            })
          ]
        }),
        !C && /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(oe, {
              type: "text",
              text: j
            }),
            /* @__PURE__ */ e.jsx(te, {
              onSubmit: t,
              mutators: {
                prepopulateOtp: d
              },
              children: (m) => /* @__PURE__ */ e.jsxs("form", {
                onSubmit: m.handleSubmit,
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-8",
                    children: /* @__PURE__ */ e.jsx(K, {
                      name: "otp",
                      validate: $(Q),
                      children: (l) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(W, {
                            id: l.input.name,
                            autoComplete: "off",
                            name: l.input.name,
                            value: l.input.value,
                            onChange: (n) => {
                              l.input.onChange(n.target.value);
                            },
                            status: p ? "error" : void 0,
                            placeholder: o._({
                              id: "zVc3EA"
                            }),
                            maxLength: 6,
                            onInput: (n) => {
                              n.target.value = n.target.value.replace(P("0-9"), "");
                            },
                            onBlur: () => {
                              l.input.onChange(l.input.value.trim());
                            },
                            autoFocus: !0
                          }),
                          /* @__PURE__ */ e.jsxs("div", {
                            className: "flex justify-between",
                            children: [
                              /* @__PURE__ */ e.jsxs("div", {
                                children: [
                                  (l.meta.touched && l.meta.error || p) && /* @__PURE__ */ e.jsx(B, {
                                    variant: "field",
                                    type: "error",
                                    title: p || l.meta.error
                                  }),
                                  V && N && M < N && /* @__PURE__ */ e.jsx(B, {
                                    variant: "field",
                                    type: "important",
                                    title: o._({
                                      id: "Z6Q0xB",
                                      values: {
                                        0: je(g.value)
                                      }
                                    })
                                  })
                                ]
                              }),
                              !V && N && M < N && /* @__PURE__ */ e.jsx("div", {
                                children: /* @__PURE__ */ e.jsx(Z, {
                                  customClass: "text-size-14",
                                  variant: "text",
                                  fullwidth: !0,
                                  type: "button",
                                  onClick: (n) => {
                                    n.preventDefault(), n.stopPropagation(), r();
                                  },
                                  children: o._({
                                    id: "+BdH9W"
                                  })
                                })
                              })
                            ]
                          })
                        ]
                      })
                    })
                  }),
                  /* @__PURE__ */ e.jsx(Z, {
                    variant: p ? "disabled" : "primary",
                    fullwidth: !0,
                    onClick: () => {
                      const l = Object.keys(m.errors);
                      l.length && (ce(l[0]), I(!1));
                    },
                    children: o._({
                      id: "2iVSoY"
                    })
                  }),
                  N && M >= N && /* @__PURE__ */ e.jsx("div", {
                    className: "flex justify-end",
                    children: /* @__PURE__ */ e.jsx(Z, {
                      type: "button",
                      variant: "text",
                      fullwidth: !0,
                      customClass: "mt-4 text-size-14 font-semibold",
                      onClick: () => {
                        window.location.reload();
                      },
                      children: o._({
                        id: "OZ0wo9"
                      })
                    })
                  }),
                  ae.isStagingOrSandbox ? /* @__PURE__ */ e.jsx(G, {
                    customClass: "mt-4",
                    children: /* @__PURE__ */ e.jsx(z, {
                      title: o._({
                        id: "a2WrrL"
                      }),
                      label: a,
                      children: /* @__PURE__ */ e.jsx(J, {
                        value: a,
                        onClick: () => m.form.mutators.prepopulateOtp(a)
                      })
                    })
                  }) : null
                ]
              })
            })
          ]
        })
      ]
    });
  }
  return k ? /* @__PURE__ */ e.jsx(ne, {
    variant: "fullpage"
  }) : v();
}
function Ce({ redirectURL: x, onPreviewData: A, allowRegistration: w = !1, isGroupInvestment: F = !1, showTestPan: i, isPartner: g, onSuccessfulLoginInitiation: s, onNewUserDetected: E }) {
  var N;
  const { AppState: { tenant: p } } = D(re), [b, k] = _(""), [y, C] = _(!1), { tenantConfig: S } = le(), { fpapi: L } = D(ie), c = F ? (N = S == null ? void 0 : S.groupInvestment) == null ? void 0 : N.testPan : S == null ? void 0 : S.test_pan, j = "XXXPX3751X", O = "XXXPX3753X";
  H(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  async function V(u, r) {
    var t, d, v, X;
    try {
      if (g) {
        A({
          pan: u
        });
        return;
      }
      const f = await ((t = L == null ? void 0 : L.userAuthService) == null ? void 0 : t.initiateSignIn(u, r));
      C(!1), s({
        username: u,
        authChallengeData: f
      });
    } catch (f) {
      C(!1), ((d = f == null ? void 0 : f.data) == null ? void 0 : d.error) === "user_not_found" ? k(o._({
        id: "S8L13d"
      })) : ((X = (v = f == null ? void 0 : f.data) == null ? void 0 : v.error) == null ? void 0 : X.message) === "user_temporarily_disabled" ? k(o._({
        id: "zsVWX+"
      })) : (k(o._({
        id: "OkdJaJ"
      })), window.debug.error(f)), q(f);
    }
  }
  async function I({ pan: u }) {
    var r;
    try {
      C(!0), await L.semiPublicFpClient.ciam_user_management().searchUser(`fpa_${p}`, u), V(u, x);
    } catch (t) {
      (t == null ? void 0 : t.status) === 404 ? (C(!1), w ? E({
        username: u
      }) : k(o._({
        id: "Iz29Fk"
      }))) : (C(!1), k((r = t == null ? void 0 : t.data) == null ? void 0 : r.message), window.debug.error(t), q(t));
    }
  }
  function M(u, r) {
    r.fields.pan.change(u.length && u[0]);
  }
  function R() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ee, {
          title: o._({
            id: "7YtaRf"
          })
        }),
        /* @__PURE__ */ e.jsx(U, {
          message: o._({
            id: "jSDcEn"
          })
        }),
        /* @__PURE__ */ e.jsx(te, {
          onSubmit: I,
          mutators: {
            prepopulatePAN: M
          },
          children: (u) => /* @__PURE__ */ e.jsxs("form", {
            onSubmit: u.handleSubmit,
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-8",
                children: /* @__PURE__ */ e.jsx(K, {
                  name: "pan",
                  validate: $(Q, se("Enter in a valid format")),
                  parse: (r) => r.toUpperCase(),
                  children: (r) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(W, {
                        id: r.input.name,
                        autoComplete: "off",
                        name: r.input.name,
                        value: r.input.value,
                        onChange: (t) => {
                          const d = t.target.selectionStart, v = t.target;
                          window.requestAnimationFrame(() => {
                            v.selectionStart = d, v.selectionEnd = d;
                          }), r.input.onChange(t.target.value);
                        },
                        onBlur: () => {
                          r.input.onChange(r.input.value.trim());
                        },
                        onInput: (t) => {
                          t.target.value = t.target.value.replace(P("A-Za-z0-9"), "");
                        },
                        placeholder: o._({
                          id: "3SA8Xd"
                        }),
                        status: r.meta.error && r.meta.touched ? "error" : void 0,
                        maxLength: 10,
                        autoFocus: !0
                      }),
                      (r.meta.error && r.meta.touched || b) && /* @__PURE__ */ e.jsx(B, {
                        type: "error",
                        variant: "field",
                        title: b || r.meta.error
                      })
                    ]
                  })
                })
              }),
              /* @__PURE__ */ e.jsx(Z, {
                type: "submit",
                variant: "primary",
                fullwidth: !0,
                onClick: () => {
                  Object.keys(u.errors).length;
                },
                children: "Continue"
              }),
              ae.isStagingOrSandbox ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsx(G, {
                    title: o._({
                      id: "4z3T2w"
                    }),
                    customClass: "mt-4",
                    children: (i == null ? void 0 : i.onboarded) && /* @__PURE__ */ e.jsx(z, {
                      label: c,
                      customClass: "mb-3",
                      children: /* @__PURE__ */ e.jsx(J, {
                        value: c || "",
                        onClick: () => u.form.mutators.prepopulatePAN(c)
                      })
                    })
                  }),
                  i != null && i.kycCompliant || i != null && i.kycNonCompliant ? /* @__PURE__ */ e.jsxs(G, {
                    title: o._({
                      id: "IVpF4V"
                    }),
                    customClass: "mt-4",
                    children: [
                      " ",
                      (i == null ? void 0 : i.kycCompliant) && /* @__PURE__ */ e.jsx(z, {
                        title: "KYC completed. Replace X with any alphabet",
                        label: /* @__PURE__ */ e.jsxs("span", {
                          children: [
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "XXX"
                            }),
                            j.slice(3, 9),
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "X"
                            })
                          ]
                        }),
                        customClass: "mb-3",
                        children: /* @__PURE__ */ e.jsx(J, {
                          value: j,
                          onClick: () => u.form.mutators.prepopulatePAN(j)
                        })
                      }),
                      (i == null ? void 0 : i.kycNonCompliant) && /* @__PURE__ */ e.jsx(z, {
                        title: "KYC not completed. Replace X with any alphabet",
                        label: /* @__PURE__ */ e.jsxs("span", {
                          children: [
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "XXX"
                            }),
                            O.slice(3, 9),
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "X"
                            })
                          ]
                        }),
                        children: /* @__PURE__ */ e.jsx(J, {
                          value: O,
                          onClick: () => u.form.mutators.prepopulatePAN(O)
                        })
                      })
                    ]
                  }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                ]
              }) : null
            ]
          })
        })
      ]
    });
  }
  function a() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(ee, {
          title: "Get started",
          semiBold: !0
        }),
        /* @__PURE__ */ e.jsx(U, {
          message: o._({
            id: "pNb/19"
          })
        }),
        /* @__PURE__ */ e.jsx(te, {
          onSubmit: I,
          mutators: {
            prepopulatePAN: M
          },
          children: (u) => /* @__PURE__ */ e.jsxs("form", {
            onSubmit: u.handleSubmit,
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-8",
                children: /* @__PURE__ */ e.jsx(K, {
                  name: "pan",
                  validate: $(Q, se("Enter in a valid format")),
                  parse: (r) => r.toUpperCase(),
                  children: (r) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(W, {
                        id: r.input.name,
                        autoComplete: "off",
                        name: r.input.name,
                        value: r.input.value,
                        onChange: (t) => {
                          const d = t.target.selectionStart, v = t.target;
                          window.requestAnimationFrame(() => {
                            v.selectionStart = d, v.selectionEnd = d;
                          }), r.input.onChange(t.target.value);
                        },
                        onBlur: () => {
                          r.input.onChange(r.input.value.trim());
                        },
                        onInput: (t) => {
                          t.target.value = t.target.value.replace(P("A-Za-z0-9"), "");
                        },
                        placeholder: o._({
                          id: "afY/DE"
                        }),
                        status: r.meta.error && r.meta.touched ? "error" : void 0,
                        maxLength: 10,
                        autoFocus: !0
                      }),
                      (r.meta.error && r.meta.touched || b) && /* @__PURE__ */ e.jsx(B, {
                        type: "error",
                        variant: "field",
                        title: b || r.meta.error
                      })
                    ]
                  })
                })
              }),
              /* @__PURE__ */ e.jsx(_e, {}),
              /* @__PURE__ */ e.jsx(Z, {
                type: "submit",
                variant: "primary",
                fullwidth: !0,
                onClick: () => {
                  Object.keys(u.errors).length;
                },
                children: "Continue"
              }),
              ae.isStagingOrSandbox ? /* @__PURE__ */ e.jsxs(e.Fragment, {
                children: [
                  /* @__PURE__ */ e.jsx(G, {
                    title: o._({
                      id: "4z3T2w"
                    }),
                    customClass: "mt-4",
                    children: (i == null ? void 0 : i.onboarded) && /* @__PURE__ */ e.jsx(z, {
                      label: c,
                      customClass: "mb-3",
                      children: /* @__PURE__ */ e.jsx(J, {
                        value: c || "",
                        onClick: () => u.form.mutators.prepopulatePAN(c)
                      })
                    })
                  }),
                  i != null && i.kycCompliant || i != null && i.kycNonCompliant ? /* @__PURE__ */ e.jsxs(G, {
                    title: o._({
                      id: "IVpF4V"
                    }),
                    customClass: "mt-4",
                    children: [
                      " ",
                      (i == null ? void 0 : i.kycCompliant) && /* @__PURE__ */ e.jsx(z, {
                        title: "KYC completed. Replace X with any alphabet",
                        label: /* @__PURE__ */ e.jsxs("span", {
                          children: [
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "XXX"
                            }),
                            j.slice(3, 9),
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "X"
                            })
                          ]
                        }),
                        customClass: "mb-3",
                        children: /* @__PURE__ */ e.jsx(J, {
                          value: j,
                          onClick: () => u.form.mutators.prepopulatePAN(j)
                        })
                      }),
                      (i == null ? void 0 : i.kycNonCompliant) && /* @__PURE__ */ e.jsx(z, {
                        title: "KYC not completed. Replace X with any alphabet",
                        label: /* @__PURE__ */ e.jsxs("span", {
                          children: [
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "XXX"
                            }),
                            O.slice(3, 9),
                            /* @__PURE__ */ e.jsx("span", {
                              className: "text-neutral-40",
                              children: "X"
                            })
                          ]
                        }),
                        children: /* @__PURE__ */ e.jsx(J, {
                          value: O,
                          onClick: () => u.form.mutators.prepopulatePAN(O)
                        })
                      })
                    ]
                  }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
                ]
              }) : null
            ]
          })
        })
      ]
    });
  }
  function h() {
    return g ? R() : a();
  }
  return y ? /* @__PURE__ */ e.jsx(ne, {
    variant: "fullpage"
  }) : h();
}
function Ve({ redirectURL: x, onPreviewData: A = null, allowRegistration: w = !1, isGroupInvestment: F = !1, isPartner: i = !1, showTestPan: g }) {
  const { fpapi: s, rebuildFpApi: E } = D(ie), { AppState: { tenant: p } } = D(re), [b, k] = _(!1), [y, C] = _("login"), [S, L] = _({
    challenge: null,
    session_code: null,
    execution: null,
    auth_session_id: null,
    tab_id: null
  }), [c, j] = _({
    username: null,
    mobile: null,
    email: null,
    dob: null
  });
  H(() => {
    s.investor_token && !i && R();
  }, [
    s
  ]);
  const O = ({ username: t, authChallengeData: d }) => {
    j({
      ...c,
      username: t
    }), L(d), C("challenge");
  }, V = ({ username: t }) => {
    j({
      ...c,
      username: t
    }), C("register");
  }, I = async ({ user: t }) => {
    var d, v;
    j({
      ...c,
      username: (t == null ? void 0 : t.username) || null,
      mobile: (t == null ? void 0 : t.phone_number) || null,
      email: (t == null ? void 0 : t.email_address) || null,
      dob: ((d = t == null ? void 0 : t.annexure) == null ? void 0 : d.dob) || null
    });
    try {
      if (k(!0), i) {
        await h({
          disableAuthFlowComplete: !0
        }), A({
          pan: t == null ? void 0 : t.pan
        });
        return;
      }
      k(!0);
      const X = (s == null ? void 0 : s.userAuthService) && await ((v = s == null ? void 0 : s.userAuthService) == null ? void 0 : v.initiateSignIn(t == null ? void 0 : t.username, x));
      k(!1), L(X), C("challenge");
    } catch (X) {
      k(!1), window.debug.error(X), q(X);
    } finally {
      k(!1);
    }
  }, M = async () => {
    E();
  }, R = async () => {
    const { profile: t } = await s.userAuthService.getAuthenticatedUser();
    !!((t == null ? void 0 : t.type) === "investor" && (t != null && t.fp_identifier)) ? N() : await h({
      disableAuthFlowComplete: !1
    });
  }, a = ({ username: t, nextAuthChallengeData: d }) => {
    j({
      ...c,
      username: t
    }), L(d), C("challenge");
  }, h = async ({ disableAuthFlowComplete: t = !1 }) => {
    try {
      const { data: d } = await s.fpClient.ciam_user_management().listAllUsers(`fpa_${p}`), v = d[0], { data: X } = await s.fpClient.investor_profiles().fetchAll({
        pan: c == null ? void 0 : c.username
      });
      let f = X[0];
      if (!f)
        try {
          const m = {
            type: "individual",
            pan: c.username
          };
          f = await s.fpClient.investor_profiles().create(m);
        } catch (m) {
          return q(m), m;
        }
      try {
        await s.updateUser(v.id, v.annexure, f.id, p), !t && N();
      } catch (m) {
        window.debug.error(m), q(m);
      }
    } catch (d) {
      window.debug.log(d);
    }
  }, N = () => {
    var t;
    (t = s == null ? void 0 : s.userAuthService) == null || t.refreshToken().then(() => {
      window.location.href = x;
    }).catch((d) => {
      q(d);
    });
  }, u = () => {
    switch (y) {
      case "login":
        return /* @__PURE__ */ e.jsx(Ce, {
          redirectURL: x,
          onPreviewData: A,
          allowRegistration: w,
          showTestPan: g,
          onSuccessfulLoginInitiation: O,
          onNewUserDetected: V,
          isPartner: i,
          isGroupInvestment: F
        });
      case "register":
        return /* @__PURE__ */ e.jsx(pe, {
          loginDetails: {
            loginHint: c != null && c.username ? c == null ? void 0 : c.username : "",
            context: x
          },
          onSuccessfulRegistration: I,
          isGroupInvestment: F,
          isPartner: i
        });
      case "challenge":
        return r(S == null ? void 0 : S.challenge);
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  }, r = (t) => {
    switch (t) {
      case "sms_otp":
      case "email_otp":
      case "sms_or_email_otp":
        return /* @__PURE__ */ e.jsx(ye, {
          authChallengeData: S,
          loginDetails: {
            loginHint: c.username,
            context: x
          },
          onSuccessfulAuthChallengeVerification: a,
          isGroupInvestment: F,
          onSuccessfulAuthentication: M
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  };
  return b ? /* @__PURE__ */ e.jsx(ne, {
    variant: "fullpage"
  }) : u();
}
export {
  Ve as A,
  be as u
};
