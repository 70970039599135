import { j as n } from "./jsx-runtime-DmTjM30f.js";
import { useContext as z, useState as f, useMemo as Ae, useLayoutEffect as Ce, useEffect as xe } from "react";
import { a as _e, M as X, B as se, O as oe, S as he, p as Ye, C as R, c as A, H as Ge, J as Je, K as Qe, e as Ke, T as Re } from "./index.esm-G8KyR0tM.js";
import { u as me } from "./useConfiguration-B-lyaBB9.js";
import { F as ve } from "./provider-Diwup2pn.js";
import { r as de } from "./sentry-CAR5o4wv.js";
import { ak as Pe, H as ne, G as pe, C as M, m as Q, U as et, j as ge, _ as ye, au as We, Q as tt, E as st, i as Xe, f as nt, aa as at, av as Fe, a6 as it, X as He, o as ot, a9 as ie, am as lt, at as qe, al as Ee, $ as rt, B as ut, aj as dt, c as ct, k as Oe, n as Ve } from "./utils-D5LKKDAj.js";
import { i as s } from "./index-wI714H_N.js";
import { AppContext as le } from "./AppContext.js";
import { C as ae } from "./CopyLinkButton-Cgo4fV4p.js";
import { S as P, C as ue } from "./StatusMessage-BwNUc0rO.js";
import { m as Le, M as bt } from "./MandateListing-D8XscYA_.js";
import { F as ft } from "./react-final-form.es-BQCbpI73.js";
import { f as yt, r as xt } from "./validators-BArvL7cB.js";
const _t = [
  "pending",
  "cancelled",
  "confirmed",
  "submitted",
  "successful",
  "failed",
  "reversed"
], mt = [
  "pending",
  "success",
  "failed",
  "timedout",
  "initiated",
  "approved",
  "rejected",
  "submitted",
  ""
], vt = [
  [
    "awaitingPayment",
    "underProcess",
    "failed",
    "",
    "awaitingPayment",
    "",
    "",
    "",
    "paymentNotFound"
  ],
  [
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "",
    "cancelled"
  ],
  [
    "",
    "underProcess",
    "",
    "",
    "underProcess",
    "underProcess",
    "",
    "underProcess",
    "underProcess"
  ],
  [
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "",
    "underProcess"
  ],
  [
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "",
    "successful"
  ],
  [
    "",
    "",
    "paymentUnsuccessful",
    "",
    "",
    "",
    "paymentUnsuccessful",
    "",
    "failed"
  ],
  [
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "",
    "reversed"
  ]
], gt = [
  [
    "awaitingPayment",
    "underProcess",
    "",
    "",
    "awaitingPayment",
    "",
    "",
    "underProcess",
    "paymentNotFound"
  ],
  [
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled",
    "cancelled"
  ],
  [
    "",
    "underProcess",
    "",
    "",
    "underProcess",
    "underProcess",
    "",
    "underProcess",
    "underProcess"
  ],
  [
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess",
    "underProcess"
  ],
  [
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful",
    "successful"
  ],
  [
    "",
    "",
    "paymentUnsuccessful",
    "",
    "",
    "",
    "paymentUnsuccessful",
    "",
    "failed"
  ],
  [
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed",
    "reversed"
  ]
], wt = (t) => {
  const e = `Your purchase reference is ${t}`;
  return {
    paymentNotFound: {
      title: "Awaiting payment",
      description: e,
      buttonText: "Complete payment",
      icon: "Alert",
      helpText: "Your order will be processed after payment completion",
      helpTextType: "alert"
    },
    awaitingPayment: {
      title: "Payment processing",
      description: e,
      buttonText: "Invest again",
      icon: "Alert",
      helpText: "We are waiting for payment confirmation from your bank. You will be able to complete payment after 10 minutes if the payment is not successful.",
      helpTextType: "alert"
    },
    underProcess: {
      title: "Under process",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    successful: {
      title: "Successful",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    cancelled: {
      title: "Cancelled",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    reversed: {
      title: "Reversed",
      description: e,
      buttonText: "Invest again",
      icon: "successInfo"
    },
    paymentUnsuccessful: {
      title: "Payment failed",
      description: e,
      buttonText: "Retry payment",
      icon: "Error",
      helpText: "The amount debited from your bank account will be refunded within 5 working days. You can retry payment after 30 mins to complete this order.",
      helpTextType: "alert"
    },
    expired: {
      title: "Expired",
      description: e,
      buttonText: "Invest again",
      icon: "Error",
      helpText: "Order expired due to pending payment",
      helpTextType: "important"
    },
    failed: {
      title: "Failed",
      description: e,
      buttonText: "Invest again",
      icon: "Error"
    }
  };
};
function Ze({ investorProfile: t, scheme: e, mf_purchase: a, payment: i, handleInvestAgain: o, handleRetry: l, showSchemeName: r, isGroupInvestment: d, isPartner: u, isFotOrder: w }) {
  var fe;
  const { AppState: { tenant: c } } = z(le), m = a.state.toLowerCase(), N = i ? (fe = i == null ? void 0 : i.status) == null ? void 0 : fe.toLowerCase() : "", T = a.failure_code, [E, j] = f(s._({
    id: "s0Estp"
  })), [L, D] = f(""), [b, B] = f("Error"), [$, H] = f(s._({
    id: "wd9ucl"
  })), [K, G] = f(""), [Y, p] = f(""), [W, S] = f(null), [O, h] = f(!1), [ee, _] = f(null), { fpapi: te } = z(ve), { tenantConfig: C, banks: ce, amcs: Ie } = me(), F = Ae(() => {
    var U, q, y, x, g, v;
    const I = (q = (U = C == null ? void 0 : C.fundList) == null ? void 0 : U.find((k) => k.isin === e.isin)) == null ? void 0 : q.nfo;
    return ((y = C == null ? void 0 : C.groupInvestment) == null ? void 0 : y.days) && ((x = C == null ? void 0 : C.groupInvestment) == null ? void 0 : x.cutOffDays) && Pe({
      salaryDay: (g = C == null ? void 0 : C.groupInvestment) == null ? void 0 : g.days[0],
      salaryCutoffDay: (v = C == null ? void 0 : C.groupInvestment) == null ? void 0 : v.cutOffDays[0],
      NFO: I,
      purchaseDate: a.created_at
    });
  }, [
    e
  ]);
  Ce(() => {
    d ? Se() : je();
  }, []);
  const we = (I) => d ? "Salary" : I ? et(I) : "-";
  async function je() {
    if (i && t) {
      h(!0);
      try {
        const { data: x } = await te.fpClient.bank_accounts().fetchAll({
          profile: t == null ? void 0 : t.id
        }), g = x.find((v) => v.old_id === (i == null ? void 0 : i.from_bank_account_id));
        _(g);
      } catch (x) {
        de(x);
      } finally {
        h(!1);
      }
    }
    const I = _t.indexOf(m), U = mt.indexOf(N);
    if (I === -1 || U === -1) return;
    let q;
    (i == null ? void 0 : i.method) === "EMANDATE" ? q = gt[I][U] : q = vt[I][U], q === "failed" && T === "order_expiry" && (q = "expired");
    const y = wt(a == null ? void 0 : a.id)[q];
    y && (j(y.title), D(u ? s._({
      id: "znLBZG",
      values: {
        0: a.id
      }
    }) : s._({
      id: "sNrEAc",
      values: {
        0: a.id
      }
    })), H(u ? s._({
      id: "quOB9l"
    }) : y.buttonText), B(y.icon), G(u ? s._({
      id: "5SyDtU"
    }) : y.helpText), p(y.helpTextType));
  }
  async function Se() {
    h(!0);
    try {
      const I = await te.fpClient.mf_settlement_details().fetchAll({
        mf_purchase: a.id
      });
      S(I);
    } catch (I) {
      window.debug.error(I), de(I);
    }
    h(!1), m === "created" || m === "pending" || m === "submitted" || m === "confirmed" ? (D(`Your purchase reference is ${a.id}`), j(s._({
      id: "oiliCI"
    })), H(s._({
      id: "quOB9l"
    })), B("successInfo")) : m === "successful" ? (j(s._({
      id: "EbM80r"
    })), D(s._({
      id: "sNrEAc",
      values: {
        0: a.id
      }
    })), H(s._({
      id: "quOB9l"
    })), B("successInfo")) : m === "failed" && (j(s._({
      id: "mVq3N+"
    })), D(s._({
      id: "WX839d"
    })), H(s._({
      id: "quOB9l"
    })), B("Error"));
  }
  function be() {
    return d ? m === "failed" ? l : o : u ? o : m === "pending" && !N || m === "failed" && N === "failed" ? l : o;
  }
  function Te() {
    return !!(d && !W && m === "pending");
  }
  function De(I, U, q) {
    var x;
    const y = [
      {
        label: s._({
          id: "4Zu577"
        }),
        value: `₹ ${M(I.amount)}`
      },
      {
        label: s._({
          id: "rs9/UI"
        }),
        value: "One time"
      },
      {
        label: s._({
          id: "ItmT+q"
        }),
        value: I.created_at ? Q(new Date(I.created_at), "dd-MMM-yyyy") : "-"
      },
      {
        label: s._({
          id: "cXcPd1"
        }),
        value: I.folio_number ? I.folio_number : "New folio"
      },
      !u && {
        label: s._({
          id: "lFCwug"
        }),
        value: we(U == null ? void 0 : U.method)
      },
      !u && {
        label: s._({
          id: "0EjvnG"
        }),
        value: d ? W ? W.id : "-" : U != null && U.id ? U.id : "-"
      }
    ];
    return q && !d && y.push({
      label: s._({
        id: "/dl6Or"
      }),
      colSpan: "basis-full",
      value: q ? `${q.bank_name} (*****${q.account_number.slice(-4)})` : "-",
      logo: ce[(x = q == null ? void 0 : q.ifsc_code) == null ? void 0 : x.slice(0, 4)] || (C == null ? void 0 : C.fallbackLogo)
    }), y;
  }
  function Ne() {
    return /* @__PURE__ */ n.jsx(oe, {
      title: r && e.name,
      tableData: De(a, i, ee)
    });
  }
  return O ? /* @__PURE__ */ n.jsx(_e, {
    variant: "fullpage"
  }) : /* @__PURE__ */ n.jsxs(n.Fragment, {
    children: [
      " ",
      /* @__PURE__ */ n.jsx(P, {
        title: E,
        description: L,
        iconName: b,
        children: Ne()
      }),
      Te() && /* @__PURE__ */ n.jsx(X, {
        type: "helper",
        variant: "flash",
        title: s._({
          id: "Gi+PAV",
          values: {
            0: F != null && F.consideredSalaryDate ? `in ${new Date(F == null ? void 0 : F.consideredSalaryDate).toLocaleString("default", {
              month: "long"
            })} ${new Date(F == null ? void 0 : F.consideredSalaryDate).getFullYear()}` : ""
          }
        }),
        customClass: "mb-5"
      }),
      K && /* @__PURE__ */ n.jsx(X, {
        type: Y,
        variant: "flash",
        title: K,
        customClass: "mb-5"
      }),
      u && !w && /* @__PURE__ */ n.jsx(ae, {
        hyperlink: ne(c) + `/consents?object_type=mf_purchase&action=confirm&id=${a.id}`,
        buttonText: "consent & payment link",
        customClass: "mb-2"
      }),
      (o || l && pe(i == null ? void 0 : i.created_at)) && /* @__PURE__ */ n.jsx(se, {
        variant: "secondary",
        fullwidth: !0,
        onClick: be(),
        customClass: "mb-2",
        children: $
      })
    ]
  });
}
const jt = (t, e) => {
  var w;
  const a = /* @__PURE__ */ new Date(), i = (/* @__PURE__ */ new Date()).getDate(), o = a.getFullYear(), l = a.getMonth(), r = new Date(o, l, t), d = new Date(o, l + 1, t);
  let u;
  return t ? (t <= i ? u = new Date(d) : t > i ? u = new Date(r) : u = new Date(r), e && (u = /* @__PURE__ */ new Date()), u == null ? void 0 : u.toString()) : (w = /* @__PURE__ */ new Date()) == null ? void 0 : w.toString();
}, Gt = (t, e, a, i, o) => {
  var l;
  return /* @__PURE__ */ n.jsxs("div", {
    style: {
      marginTop: "-20px"
    },
    children: [
      /* @__PURE__ */ n.jsx(he, {
        customClass: "mb-8",
        children: /* @__PURE__ */ n.jsxs(Ye, {
          children: [
            /* @__PURE__ */ n.jsx(R, {
              span: 6,
              children: /* @__PURE__ */ n.jsx(A, {
                label: s._({
                  id: "4Zu577"
                }),
                value: t != null && t.amount ? `₹ ${M(t == null ? void 0 : t.amount, 2)}` : "-"
              })
            }),
            /* @__PURE__ */ n.jsx(R, {
              span: 6,
              children: /* @__PURE__ */ n.jsx(A, {
                label: s._({
                  id: "UMXPdU"
                }),
                value: t != null && t.frequency ? (l = ge(t == null ? void 0 : t.frequency)) == null ? void 0 : l.replace(/_/g, " ") : "-"
              })
            }),
            /* @__PURE__ */ n.jsx(R, {
              span: 6,
              children: /* @__PURE__ */ n.jsx(A, {
                label: s._({
                  id: "cXcPd1"
                }),
                value: t != null && t.folio_number ? t == null ? void 0 : t.folio_number : "New folio"
              })
            }),
            /* @__PURE__ */ n.jsx(R, {
              span: 6,
              children: /* @__PURE__ */ n.jsx(A, {
                label: s._({
                  id: "ZS3Gyi"
                }),
                value: ye(t == null ? void 0 : t.number_of_installments)
              })
            }),
            /* @__PURE__ */ n.jsx(R, {
              span: 6,
              children: /* @__PURE__ */ n.jsx(A, {
                label: s._({
                  id: "cki4uJ"
                }),
                value: We(t == null ? void 0 : t.frequency, jt(t == null ? void 0 : t.installment_day, o))
              })
            }),
            /* @__PURE__ */ n.jsx(ue, {
              check: !!(t != null && t.end_date),
              children: /* @__PURE__ */ n.jsx(R, {
                span: 6,
                children: /* @__PURE__ */ n.jsx(A, {
                  label: s._({
                    id: "gqWUiZ"
                  }),
                  value: t != null && t.end_date ? tt(t == null ? void 0 : t.end_date) : "-"
                })
              })
            }),
            /* @__PURE__ */ n.jsxs(ue, {
              check: !!e,
              children: [
                /* @__PURE__ */ n.jsx(R, {
                  span: 6,
                  children: /* @__PURE__ */ n.jsx(A, {
                    label: s._({
                      id: "6SUdWB"
                    }),
                    value: ye(e == null ? void 0 : e.name)
                  })
                }),
                /* @__PURE__ */ n.jsx(R, {
                  span: 6,
                  children: /* @__PURE__ */ n.jsx(A, {
                    label: s._({
                      id: "cCU8Ub"
                    }),
                    value: ye(e == null ? void 0 : e.license_code)
                  })
                })
              ]
            })
          ]
        })
      }),
      /* @__PURE__ */ n.jsx(ue, {
        check: !!o,
        children: /* @__PURE__ */ n.jsx(X, {
          type: "important",
          variant: "flash",
          title: s._({
            id: "JbUeSI"
          }),
          customClass: "mb-5"
        })
      })
    ]
  });
}, St = ({ mandate: t, banks: e, fallbackLogo: a }) => {
  var l, r, d, u;
  const [i, o] = f(!1);
  return t ? /* @__PURE__ */ n.jsx("div", {
    className: "mb-4",
    children: /* @__PURE__ */ n.jsxs(Ge, {
      expanded: i,
      onChange: () => o(!i),
      variant: "panel",
      children: [
        /* @__PURE__ */ n.jsx(Je, {
          active: !0,
          variant: "panel",
          children: /* @__PURE__ */ n.jsx("div", {
            className: "flex items-baseline",
            children: /* @__PURE__ */ n.jsx("span", {
              className: "mr-2",
              children: "Mandate details"
            })
          })
        }),
        /* @__PURE__ */ n.jsx(Qe, {
          variant: "panel",
          children: /* @__PURE__ */ n.jsx(he, {
            children: /* @__PURE__ */ n.jsxs(Ye, {
              children: [
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-full pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(A, {
                    label: s._({
                      id: "JVRi6u"
                    }),
                    value: `${(l = t.bank) == null ? void 0 : l.bank_name} (*****${(r = t.bank) == null ? void 0 : r.number.slice(-4)})`,
                    logo: e[(u = (d = t.bank) == null ? void 0 : d.ifsc) == null ? void 0 : u.slice(0, 4)] || a
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(A, {
                    label: s._({
                      id: "OzNl8x"
                    }),
                    value: t ? Le[t.mandate_status].value : "-",
                    icon: t ? Le[t.mandate_status].icon : "",
                    iconPlacement: "left"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(A, {
                    label: s._({
                      id: "nlXCcY"
                    }),
                    value: "NACH"
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(A, {
                    label: s._({
                      id: "Zp6kam"
                    }),
                    value: `₹ ${M(t.mandate_limit)}`
                  })
                }),
                /* @__PURE__ */ n.jsx("div", {
                  className: "mb-3 basis-1/2 pr-2 pl-2",
                  children: /* @__PURE__ */ n.jsx(A, {
                    label: s._({
                      id: "0OJ5oD"
                    }),
                    value: Q(new Date(t.valid_from), "dd-MMM-yyyy")
                  })
                })
              ]
            })
          }, t.id)
        })
      ]
    })
  }) : /* @__PURE__ */ n.jsx(n.Fragment, {});
}, Be = () => {
  const t = new URLSearchParams(window.location.search), e = t.get("step_up_count"), a = t.get("step_up_frequency"), i = t.get("step_up_type"), o = t.get("step_up_type_value");
  return !!(e && a && i && o);
}, Jt = (t) => {
  switch (t) {
    case "daily":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "4UB9pl"
          }),
          value: "number_of_days"
        }
      ];
    case "day_in_a_week":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "cpH9Hi"
          }),
          value: "number_of_weeks"
        }
      ];
    case "four_times_a_month":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "0A81Au"
          }),
          value: "number_of_instalments"
        }
      ];
    case "half-yearly":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "cpH9Hi"
          }),
          value: "number_of_weeks"
        },
        {
          label: s._({
            id: "Xtu/a+"
          }),
          value: "number_of_years"
        }
      ];
    case "monthly":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "fBXQWJ"
          }),
          value: "number_of_months"
        },
        {
          label: s._({
            id: "Xtu/a+"
          }),
          value: "number_of_years"
        }
      ];
    case "quarterly":
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "UpUWDH"
          }),
          value: "number_of_quarters"
        },
        {
          label: s._({
            id: "Xtu/a+"
          }),
          value: "number_of_years"
        }
      ];
    default:
      return [
        {
          label: s._({
            id: "S4zG4Q"
          }),
          value: "perpetual"
        },
        {
          label: s._({
            id: "vEYjLl"
          }),
          value: "installments"
        }
      ];
  }
}, Qt = (t, e) => {
  var a;
  return !Xe(t) && (t != null && t.dp_id) || !nt(e) && ((a = e[0].folio_defaults) != null && a.demat_account) ? "Demat" : "Physical";
}, Me = (t, e) => {
  const a = /* @__PURE__ */ new Date(), i = a.getFullYear(), o = a.getMonth(), l = (/* @__PURE__ */ new Date()).getDate(), r = new Date(i, o + 1, t), d = new Date(i, o, t);
  let u;
  return t <= l ? u = new Date(r) : t > l ? u = new Date(d) : u = new Date(d), e && (u = /* @__PURE__ */ new Date()), u;
}, Kt = (t, e, a) => {
  let i = Me(t, e);
  const o = /* @__PURE__ */ new Date();
  o.setDate(t);
  const l = new Date(o.setDate(o.getDate() + 1));
  return a === "daily" && !e && t === (/* @__PURE__ */ new Date()).getDate() && (i = l), s._({
    id: "wGedtw",
    values: {
      0: Q(i, "dd-MMM-yyyy")
    }
  });
}, Rt = (t, e) => {
  const a = (/* @__PURE__ */ new Date()).getDate(), i = new Date((/* @__PURE__ */ new Date()).setDate((/* @__PURE__ */ new Date()).getDate() + 2)).getDate();
  return !((t < a || t >= i) && e === "daily");
};
function ke(t, e) {
  const a = (/* @__PURE__ */ new Date()).getFullYear(), o = a % 4 === 0 && a % 100 !== 0 || a % 400 === 0 ? 366 : 365, l = {
    daily: o,
    day_in_a_week: o / 7,
    four_times_a_month: 4 * 12,
    day_in_a_fortnight: o / 14,
    twice_a_month: 2 * 12,
    monthly: 12,
    quarterly: 4,
    "half-yearly": 2,
    yearly: 1
  };
  return Math.ceil(e / l[t]);
}
const Tt = (t, e, a) => {
  let i = `Minimum number of installments is ${t}`;
  const o = ke("monthly", t);
  return e === "daily" ? i = `The minimum duration for creating a SIP is ${M(t)} ${t > 1 ? "days" : "day"}` : e === "monthly" && a === "number_of_months" ? i = `The minimum duration for creating a SIP is ${M(t)} months` : e === "monthly" && a === "number_of_years" ? i = `The minimum duration for creating a SIP is ${M(o)} ${o > 1 ? "years" : "year"}` : e === "quarterly" && a === "number_of_quarters" ? i = `The minimum duration for creating a SIP is ${M(t)} quarters` : e === "quarterly" && a === "number_of_years" ? i = `The minimum duration for creating a SIP is ${M(o)} ${o > 1 ? "years" : "year"}` : e === "day_in_a_week" && a === "number_of_weeks" ? i = `The minimum duration for creating a SIP is ${M(t)} weeks` : e === "half-yearly" && a === "number_of_installments" ? i = `Minimum number of installment is ${M(t)}` : e === "half-yearly" && a === "number_of_years" ? i = `The minimum duration for creating a SIP is ${M(o)} ${o > 1 ? "years" : "year"}` : e === "four_times_a_month" && a === "number_of_installments" ? i = `Minimum number of installment is ${M(t)}` : e === "twice_a_month" && a === "number_of_installments" ? i = `Minimum number of installment is ${M(t)}` : e === "day_in_a_fortnight" && a === "number_of_installments" ? i = `Minimum number of installment is ${M(t)}` : i = `Minimum number of installment is ${M(t)}`, i;
}, Dt = (t, e) => {
  const a = `${t}_${e}`, i = {
    daily_number_of_days: "Enter number of days",
    monthly_number_of_months: "Enter number of months",
    quarterly_number_of_years: "Enter number of years",
    "half-yearly_number_of_years": "Enter number of years",
    monthly_number_of_years: "Enter number of years",
    quarterly_number_of_quarters: "Enter number of quarters",
    day_in_a_week_number_of_weeks: "Enter number of weeks",
    "half-yearly_number_of_installments": "Enter number of installments",
    four_times_a_month_number_of_installments: "Enter number of installments",
    twice_a_month_number_of_installments: "Enter number of installments",
    day_in_a_fortnight_number_of_installments: "Enter number of installments"
  };
  return i[a] ? s._({
    id: "ev7haI",
    values: {
      0: i[a]
    }
  }) : s._({
    id: "LQjwJ/"
  });
}, Pt = (t, e) => {
  const a = [];
  return t.forEach((i) => {
    i.amc === e && a.push(i);
  }), a;
}, pt = (t, e, a, i, o) => {
  var d, u, w, c, m, N, T, E, j, L, D;
  const l = {}, r = (d = e == null ? void 0 : e.sip_frequency_specific_data[a]) == null ? void 0 : d.min_installments;
  return t.amount || (l.amount = s._({
    id: "c0D/2W"
  })), t.amount && t.amount < Number((u = e == null ? void 0 : e.sip_frequency_specific_data[a]) == null ? void 0 : u.min_installment_amount) && (l.amount = s._({
    id: "9LeqUy",
    values: {
      0: M(Number((w = e == null ? void 0 : e.sip_frequency_specific_data[a]) == null ? void 0 : w.min_installment_amount))
    }
  })), t.amount && t.amount > Number((c = e == null ? void 0 : e.sip_frequency_specific_data[a]) == null ? void 0 : c.max_installment_amount) && (l.amount = s._({
    id: "DJzP4H",
    values: {
      0: M(Number((m = e == null ? void 0 : e.sip_frequency_specific_data[a]) == null ? void 0 : m.max_installment_amount))
    }
  })), at(t.amount, Number((N = e == null ? void 0 : e.sip_frequency_specific_data[a]) == null ? void 0 : N.amount_multiples)) === !1 && (l.amount = s._({
    id: "O/Jkev",
    values: {
      0: Number((T = e == null ? void 0 : e.sip_frequency_specific_data[a]) == null ? void 0 : T.amount_multiples)
    }
  })), t.installments && Number(t.installments) < Number((E = e == null ? void 0 : e.sip_frequency_specific_data[a]) == null ? void 0 : E.min_installments) && (l.installments = s._({
    id: "NeTEIk",
    values: {
      0: Number((j = e == null ? void 0 : e.sip_frequency_specific_data[a]) == null ? void 0 : j.min_installments)
    }
  })), t.number_of_days && Number(t.number_of_days) < Number(r) && (l.number_of_days = s._({
    id: "Hs2tma",
    values: {
      currentSchemeMinimumInstallments: r
    }
  })), t.number_of_weeks && Number(t.number_of_weeks) < Number(r) && (l.number_of_weeks = s._({
    id: "5ZYiKc",
    values: {
      currentSchemeMinimumInstallments: r
    }
  })), t.number_of_installments && Number(t.number_of_installments) < Number(r) && (l.number_of_installments = s._({
    id: "JFGyQW",
    values: {
      currentSchemeMinimumInstallments: r
    }
  })), t.number_of_months && Number(t.number_of_months) < Number(r) && (l.number_of_months = s._({
    id: "iuifCd",
    values: {
      currentSchemeMinimumInstallments: r
    }
  })), t.number_of_quarters && Number(t.number_of_quarters) < Number(r) && (l.number_of_quarters = s._({
    id: "8NKzOT",
    values: {
      currentSchemeMinimumInstallments: r
    }
  })), t.number_of_years && Number(t == null ? void 0 : t.number_of_years) < ke(t.frequency.value, Number(r)) && (l.number_of_years = s._({
    id: "joH0Rd",
    values: {
      0: ke(t.frequency.value, Number(r))
    }
  })), t.number_of_years && Number(t == null ? void 0 : t.number_of_years) > 100 && (l.number_of_years = s._({
    id: "LJwidR"
  })), t.installments > 1200 && (l.installments = s._({
    id: "kX+aWa"
  })), t.number_of_days > 1200 && (l.number_of_days = s._({
    id: "kX+aWa"
  })), t.number_of_months > 1200 && (l.number_of_months = s._({
    id: "kX+aWa"
  })), t.number_of_quarters > 1200 && (l.number_of_quarters = s._({
    id: "kX+aWa"
  })), t.number_of_weeks > 1200 && (l.number_of_weeks = s._({
    id: "kX+aWa"
  })), t.number_of_installments > 1200 && (l.number_of_installments = s._({
    id: "kX+aWa"
  })), i && (o != null && o.installment_date) && !((L = e == null ? void 0 : e.sip_frequency_specific_data[a]) != null && L.dates.includes(o == null ? void 0 : o.installment_date.getDate())) && (l.frequency = s._({
    id: "S+LyGb"
  })), t.is_step_up_enabled && ((D = t.step_up_count) == null ? void 0 : D[0]) <= 0 && (l.step_up_count = s._({
    id: "i5ZE0v"
  })), l;
}, es = (t, e, a, i, o, l, r) => {
  const d = {};
  return t.amount && t.amount > Number(e == null ? void 0 : e[Fe(o).maxInvestmentAmount]) && (d.amount = s._({
    id: "DJzP4H",
    values: {
      0: M(Number(e == null ? void 0 : e[Fe(o).maxInvestmentAmount]))
    }
  })), t.installments && t.installments < Number(l) && (d.installments = s._({
    id: "fY6D71",
    values: {
      nonSipMinimumInstallments: l
    }
  })), i && (r != null && r.installment_date) && !it(a).includes(r == null ? void 0 : r.installment_date.getDate()) && (d.frequency = s._({
    id: "S+LyGb"
  })), d;
}, Nt = (t, e, a = !0) => He(t) ? /* @__PURE__ */ n.jsx("div", {
  className: "mb-2 mt-2",
  children: /* @__PURE__ */ n.jsx(X, {
    type: "error",
    variant: "field",
    title: t.meta.error
  })
}) : /* @__PURE__ */ n.jsx(ue, {
  check: a,
  children: /* @__PURE__ */ n.jsx(X, {
    type: "helper",
    variant: "field",
    title: s._({
      id: "2dlTkQ",
      values: {
        message: e
      }
    })
  })
}), ts = (t, e, a, i, o) => /* @__PURE__ */ n.jsxs("div", {
  className: "mb-8",
  children: [
    /* @__PURE__ */ n.jsx(Ke, {
      message: `${s._({
        id: "hfWJYZ",
        values: {
          label: t
        }
      })}`
    }),
    /* @__PURE__ */ n.jsx(ft, {
      name: e,
      subscription: {
        touched: !0,
        error: !0,
        value: !0
      },
      validate: yt(xt),
      children: (l) => {
        var r, d, u, w, c, m, N;
        return /* @__PURE__ */ n.jsxs(n.Fragment, {
          children: [
            /* @__PURE__ */ n.jsx(Re, {
              id: l.input.name,
              autoComplete: "off",
              name: l.input.name,
              onChange: (T) => {
                a.form.change("step_up_count", [
                  0
                ]), l.input.onChange(T.target.value);
              },
              placeholder: Dt((d = (r = a == null ? void 0 : a.values) == null ? void 0 : r.frequency) == null ? void 0 : d.value, (w = (u = a == null ? void 0 : a.values) == null ? void 0 : u.sip_instl_type) == null ? void 0 : w.value),
              status: He(l) ? "error" : void 0,
              onInput: (T) => {
                T.target.value = T.target.value.replace(ot("0-9"), "");
              },
              inputMode: "numeric",
              autoFocus: !0
            }),
            Nt(l, Tt(Number((c = i == null ? void 0 : i.sip_frequency_specific_data[o]) == null ? void 0 : c.min_installments), (N = (m = a == null ? void 0 : a.values) == null ? void 0 : m.frequency) == null ? void 0 : N.value, e))
          ]
        });
      }
    })
  ]
}), ss = (t, e, a) => {
  if (t === "perpetual" && a === "half-yearly")
    return {
      max: 199,
      min: 1
    };
  if (t === "perpetual" && a === "yearly")
    return {
      max: 99,
      min: 1
    };
  if (e > 6)
    if (a === "half-yearly") {
      const i = Math.floor(e / 6), o = i - 1 > 0 ? i - 1 : 0, l = o <= 0 ? 0 : 1;
      return {
        max: o,
        min: l
      };
    } else {
      const i = Math.floor(e / 12), o = i - 1 > 0 ? i - 1 : 0, l = o <= 0 ? 0 : 1;
      return {
        max: o,
        min: l
      };
    }
  return {
    max: 0,
    min: 0
  };
}, Mt = (t, e, a, i, o) => {
  let l = Number(t);
  if (e === "amount") {
    for (let r = 1; r <= Number(o); r++)
      l += Number(a);
    return l;
  } else {
    for (let r = 1; r <= Number(o); r++)
      l += Number(l) * Number(a) / 100;
    return l;
  }
}, ns = (t, e, a, i, o, l, r, d) => {
  let u = 0;
  return t === "perpetual" ? u = 1200 : a || i || o || l || r ? u = [
    ie.daily * a,
    ie.day_in_a_week * i,
    ie.monthly * o,
    ie.monthly * l,
    ie.quarterly * r
  ].find((c) => c != null && !isNaN(c)) ?? 0 : d && (u = ie[e] * lt(e, d)), u;
}, Ue = (t, e, a, i, o, l) => {
  if (!t)
    return "";
  let r;
  const d = o === "half-yearly" ? "every 6 months" : "every year", u = Mt(t, a, i, o, l);
  return o === "half-yearly" ? r = new Date(e).setMonth(new Date(e).getMonth() + 6 * l) : r = new Date(e).setFullYear(new Date(e).getFullYear() + 1 * l), `SIP would be step up by ${a === "amount" ? "₹" : ""}${i}${a === "percentage" ? "%" : ""}  ${d} till ${Q(new Date(r), "dd-MMM-yyyy")}
and the final sip amount would be ₹${kt(u)}`;
}, kt = (t) => {
  if (t = Number(t), t < 1e9)
    return st(t);
  const e = [
    "",
    "K",
    "M",
    "B",
    "T",
    "P",
    "E"
  ];
  let a = 0;
  for (; Math.abs(t) >= 1e3 && a < e.length - 1; )
    t /= 1e3, a++;
  return `${t.toFixed(2)}${e[a]}`;
};
function Ct({ scheme: t, mf_purchase_plan: e, payment: a, handleInvestAgain: i, handleRetry: o, showSchemeName: l, isGroupInvestment: r, isPartner: d, investorProfile: u, isFotOrder: w }) {
  var q;
  let c = e.state.toLowerCase();
  const m = a != null && a.status ? (q = a == null ? void 0 : a.status) == null ? void 0 : q.toLowerCase() : "", [N, T] = f(""), [E, j] = f(""), [L, D] = f(""), [b, B] = f(s._({
    id: "wd9ucl"
  })), [$, H] = f(null), [K, G] = f(!1), [Y, p] = f(!1), { fpapi: W } = z(ve), { tenantConfig: S, banks: O } = me(), h = S == null ? void 0 : S.fallbackLogo, ee = qe.get(), _ = Ee.get(), te = new URLSearchParams(window.location.search), { AppState: { tenant: C } } = z(le), [ce, Ie] = f(s._({
    id: "bjf3K2"
  })), F = Ae(() => {
    var y, x, g, v, k, V;
    if (r) {
      const Z = (x = (y = S == null ? void 0 : S.fundList) == null ? void 0 : y.find((re) => re.isin === t.isin)) == null ? void 0 : x.nfo;
      return ((g = S == null ? void 0 : S.groupInvestment) == null ? void 0 : g.days) && ((v = S == null ? void 0 : S.groupInvestment) == null ? void 0 : v.cutOffDays) && rt({
        salaryDay: (k = S == null ? void 0 : S.groupInvestment) == null ? void 0 : k.days[0],
        salaryCutoffDay: (V = S == null ? void 0 : S.groupInvestment) == null ? void 0 : V.cutOffDays[0],
        NFO: Z,
        purchaseDate: e == null ? void 0 : e.created_at
      });
    }
  }, [
    t
  ]), we = () => {
    if (_ != null && _.is_step_up_enabled) {
      const y = _.step_up_count, x = _.step_up_frequency, g = _.step_up_type, v = _.step_up_type_value;
      return `/consents?object_type=mf_purchase_plan&action=confirm&id=${e.id}&step_up_count=${y}&step_up_frequency=${x}&step_up_type=${g}&step_up_type_value=${v}`;
    } else
      return `/consents?object_type=mf_purchase_plan&action=confirm&id=${e.id}`;
  }, je = () => {
    if (_ != null && _.is_step_up_enabled) {
      const y = _.step_up_count, x = _.step_up_frequency, g = _.step_up_type, v = _.step_up_type_value;
      return `/payment?order_id=${e.id}&step_up_count=${y}&step_up_frequency=${x}&step_up_type=${g}&step_up_type_value=${v}`;
    } else
      return `/payment?order_id=${e.id}`;
  }, Se = () => {
    let y = "";
    if (d && (_ != null && _.is_step_up_enabled)) {
      const x = _ == null ? void 0 : _.step_up_count, g = _ == null ? void 0 : _.step_up_frequency, v = _ == null ? void 0 : _.step_up_type, k = _ == null ? void 0 : _.step_up_type_value;
      y = Ue(e == null ? void 0 : e.amount, Me(e == null ? void 0 : e.installment_day, ee), v, k, g, x);
    } else if (Be()) {
      const x = te.get("step_up_count"), g = te.get("step_up_frequency"), v = te.get("step_up_type"), k = te.get("step_up_type_value");
      (v === "amount" || v === "percentage") && k && (g === "half-yearly" || g === "yearly") && (y = Ue(e == null ? void 0 : e.amount, e == null ? void 0 : e.start_date, v, k, g, Number(x)));
    }
    return y;
  };
  xe(() => {
    r ? De() : fe();
  }, []), xe(() => {
    be(Y) && Ie("SIP activates once the investor sets up a payment method and gives consent");
  }, [
    Y
  ]);
  const be = (y) => !!(y && (y.mandate_status.toUpperCase() === "CREATED" || y.mandate_status.toUpperCase() === "RECEIVED")), Te = async (y) => {
    var x, g, v;
    if (y)
      try {
        const k = await W.fpClient.mandates().fetch(Number(y)), V = await W.fpClient.bank_accounts().fetchAll({
          profile: u == null ? void 0 : u.id
        }), Z = V == null ? void 0 : V.data;
        return Z.length ? {
          ...k,
          bank: {
            bank_name: (x = Z[0]) == null ? void 0 : x.bank_name,
            number: (g = Z[0]) == null ? void 0 : g.account_number,
            ifsc: (v = Z[0]) == null ? void 0 : v.ifsc_code
          }
        } : k;
      } catch (k) {
        window.debug.log(k), de(k);
      }
  }, De = () => {
    c === "created" || c === "active" || c === "completed" ? (T("SIP registered"), j(s._({
      id: "u9h4AN",
      values: {
        0: e.id
      }
    })), B(s._({
      id: "quOB9l"
    })), D("successInfo")) : c === "failed" && (T("SIP Failed"), j(s._({
      id: "tNBTdB"
    })), B(s._({
      id: "quOB9l"
    })), D("Error"));
  }, Ne = async (y) => {
    var re, $e;
    let x;
    try {
      x = await W.fpClient.mf_purchases().fetchAll({
        plan: y == null ? void 0 : y.id
      });
    } catch (J) {
      window.debug.log(J), de(J);
    }
    if (!((re = x == null ? void 0 : x.data) != null && re.length))
      return null;
    let g;
    ($e = x == null ? void 0 : x.data) != null && $e.length && (g = x.data.filter((J) => J.state === "confirmed" || J.state === "successful" || J.state === "submitted" || J.state === "pending").sort((J, ze) => new Date(J.scheduled_on).getTime() - new Date(ze.scheduled_on).getTime())[0]);
    const v = new Date(y == null ? void 0 : y.created_at), k = new Date(new Date(v).setDate(v.getDate() + 1)), V = new Date(new Date(v).setDate(v.getDate() + ct(v))), Z = new Date(g == null ? void 0 : g.scheduled_on);
    return Z.toDateString() === v.toDateString() || Z.toDateString() === k.toDateString() || Z.toDateString() === V.toDateString() ? g : null;
  }, fe = async () => {
    var k;
    G(!0), c = e.state.toLowerCase();
    const y = e == null ? void 0 : e.payment_source, x = await Ne(e), g = (k = window.location.pathname) == null ? void 0 : k.includes("payment");
    !Xe(x) && g ? (H(x), c === "created" || c === "active" || c === "completed" ? (T(s._({
      id: "c6uuOj"
    })), B(s._({
      id: "quOB9l"
    })), j(d ? s._({
      id: "8d5Ob9",
      values: {
        0: e.id
      }
    }) : s._({
      id: "u9h4AN",
      values: {
        0: e.id
      }
    })), D("successInfo")) : c === "failed" && (T(s._({
      id: "uXDSGu"
    })), j(s._({
      id: "tNBTdB"
    })), B(s._({
      id: "quOB9l"
    })), D("Error"))) : c === "created" || c === "active" || c === "completed" ? (T(s._({
      id: "c6uuOj"
    })), B(s._({
      id: "quOB9l"
    })), j(d ? s._({
      id: "8d5Ob9",
      values: {
        0: e.id
      }
    }) : s._({
      id: "u9h4AN",
      values: {
        0: e.id
      }
    })), D("successInfo")) : c === "failed" && (T(s._({
      id: "uXDSGu"
    })), j(s._({
      id: "tNBTdB"
    })), B(s._({
      id: "quOB9l"
    })), D("Error"));
    const v = await Te(y);
    p(v), m === "failed" && B(s._({
      id: "vgDUPm"
    })), G(!1);
  };
  function I() {
    m === "failed" ? o() : i();
  }
  const U = () => {
    var g, v;
    return [
      {
        label: s._({
          id: "rd49u+"
        }),
        value: `₹ ${M(e.amount)}`
      },
      {
        label: s._({
          id: "UMXPdU"
        }),
        value: d ? (g = ge(e.frequency)) == null ? void 0 : g.replace(/_/g, " ") : We(e == null ? void 0 : e.frequency, (v = Me(e == null ? void 0 : e.installment_day, !!$)) == null ? void 0 : v.toString())
      },
      e != null && e.start_date ? {
        label: s._({
          id: "NdlT2Q"
        }),
        value: ye(Q(new Date(e == null ? void 0 : e.start_date), "dd-MMM-yyyy"))
      } : {},
      {
        label: s._({
          id: "ZS3Gyi"
        }),
        value: (e == null ? void 0 : e.number_of_installments) >= 1200 ? "Ongoing until stopped" : e == null ? void 0 : e.number_of_installments
      },
      {
        label: s._({
          id: "cXcPd1"
        }),
        value: e.folio_number ? e.folio_number : "New folio"
      }
    ].filter((k) => {
      var V;
      return ((V = Object.keys(k)) == null ? void 0 : V.length) > 0;
    });
  };
  return K ? /* @__PURE__ */ n.jsx(_e, {
    variant: "fullpage"
  }) : /* @__PURE__ */ n.jsxs(n.Fragment, {
    children: [
      /* @__PURE__ */ n.jsx(P, {
        title: N,
        description: E,
        iconName: L,
        message: ce && d ? /* @__PURE__ */ n.jsx(X, {
          type: "alert",
          variant: "flash",
          title: ce,
          customClass: "mb-5"
        }) : null,
        children: /* @__PURE__ */ n.jsx(oe, {
          title: l && t.name,
          tableData: U()
        })
      }),
      /* @__PURE__ */ n.jsx(ue, {
        check: !!(d && (_ != null && _.is_step_up_enabled)) || Be(),
        children: /* @__PURE__ */ n.jsx(X, {
          type: "helper",
          variant: "flash",
          title: Se(),
          customClass: "mb-5"
        })
      }),
      /* @__PURE__ */ n.jsx(St, {
        mandate: Y,
        banks: O,
        fallbackLogo: h
      }),
      $ && !d && /* @__PURE__ */ n.jsxs(n.Fragment, {
        children: [
          /* @__PURE__ */ n.jsx(A, {
            value: s._({
              id: "R2xBSf"
            }),
            background: !0,
            customClass: "mb-4 items-center"
          }),
          /* @__PURE__ */ n.jsx(Ze, {
            scheme: t,
            mf_purchase: $,
            isFotOrder: w,
            showSchemeName: l,
            isPartner: d,
            payment: a
          })
        ]
      }),
      be(Y) ? /* @__PURE__ */ n.jsx("div", {
        style: {
          marginBottom: "-4rem"
        },
        children: /* @__PURE__ */ n.jsx(P, {
          title: "Mandate details",
          iconName: "",
          children: /* @__PURE__ */ n.jsx(bt, {
            investorProfile: u,
            mandate_id: e == null ? void 0 : e.payment_source,
            status: [
              ""
            ],
            limit: null,
            isPartner: d
          })
        })
      }) : /* @__PURE__ */ n.jsx(n.Fragment, {}),
      r && /* @__PURE__ */ n.jsx(X, {
        type: "helper",
        variant: "flash",
        title: s._({
          id: "/tWtLh",
          values: {
            0: F != null && F.consideredSalaryDate ? ` ${new Date(F == null ? void 0 : F.consideredSalaryDate).toLocaleString("default", {
              month: "long"
            })} ${new Date(F == null ? void 0 : F.consideredSalaryDate).getFullYear()}` : ""
          }
        }),
        customClass: "mb-5"
      }),
      d && !w && !ee && /* @__PURE__ */ n.jsx(ae, {
        hyperlink: ne(C) + we(),
        buttonText: "activation link",
        customClass: "mb-2"
      }),
      d && (w || ee) && /* @__PURE__ */ n.jsx(ae, {
        hyperlink: ne(C) + je(),
        buttonText: "activation & payment link",
        customClass: "mb-2"
      }),
      (i || o) && /* @__PURE__ */ n.jsxs("div", {
        className: "flex items-center",
        children: [
          /* @__PURE__ */ n.jsx(se, {
            variant: "secondary",
            fullwidth: !0,
            onClick: () => {
              I(), qe.remove(), Ee.remove();
            },
            customClass: "flex-1 px-6 mb-4",
            children: b
          }),
          r && /* @__PURE__ */ n.jsx(se, {
            variant: "secondary",
            fullwidth: !0,
            onClick: () => {
              window.location.href = window.location.origin + ut + `/plans-list?isGroupInvestment=true&${dt(window.location.search)}`;
            },
            customClass: "flex-1 ml-2 mb-4",
            children: s._({
              id: "Pk/Rga"
            })
          })
        ]
      })
    ]
  });
}
function It({ scheme: t, mf_redemption_plan: e, handleRetry: a, handleInvestAgain: i, showSchemeName: o, isPartner: l }) {
  var b;
  const { AppState: { tenant: r } } = z(le), d = e.state.toLowerCase(), [u, w] = f(""), [c, m] = f(""), [N, T] = f(""), [E, j] = f(""), [L] = f("Plan will be activated after investor provides consent");
  xe(() => {
    d === "active" || d === "pending" || d === "confirmed" || d === "submitted" || d === "successful" || d === "created" ? (w(s._({
      id: "M0nG+B"
    })), m(l ? s._({
      id: "9RjYo2",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : s._({
      id: "hIAwc2",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), j(s._({
      id: "K7WMZD"
    })), T("successInfo")) : d === "failed" && (w(s._({
      id: "jcgQHg"
    })), m(l ? s._({
      id: "9RjYo2",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : s._({
      id: "hIAwc2",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), j(s._({
      id: "JGhVxc"
    })), T("Error"));
  }, []);
  function D() {
    return d === "failed" ? a : i;
  }
  return /* @__PURE__ */ n.jsxs(n.Fragment, {
    children: [
      /* @__PURE__ */ n.jsx(P, {
        title: u,
        description: c,
        iconName: N,
        children: /* @__PURE__ */ n.jsx(oe, {
          title: o && t.name,
          tableData: [
            {
              label: s._({
                id: "uzz6vZ"
              }),
              value: e != null && e.amount ? `₹ ${M(e.amount)}` : "-"
            },
            {
              label: s._({
                id: "Sgxaf5"
              }),
              value: e.frequency ? `${(b = ge(e.frequency)) == null ? void 0 : b.replace(/_/g, " ")}` : "-"
            },
            {
              label: s._({
                id: "cXcPd1"
              }),
              value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
            },
            {
              label: s._({
                id: "osv0u0"
              }),
              value: e != null && e.created_at ? `${Q(new Date(e.created_at), "dd-MMM-yyyy")}` : "-"
            },
            e != null && e.end_date ? {
              label: s._({
                id: "w3AOkt"
              }),
              value: e != null && e.end_date && (e != null && e.end_date) ? new Date(e == null ? void 0 : e.end_date).getFullYear() >= 2099 ? "Until you stop" : Q(new Date(e == null ? void 0 : e.end_date), "dd-MMM-yyyy") : "-"
            } : ""
          ]
        })
      }),
      L && l && /* @__PURE__ */ n.jsx(X, {
        type: "alert",
        variant: "flash",
        title: L,
        customClass: "mb-5"
      }),
      l && /* @__PURE__ */ n.jsx(ae, {
        hyperlink: ne(r) + `/consents?object_type=mf_redemption_plan&action=confirm&id=${e == null ? void 0 : e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      (a || i) && /* @__PURE__ */ n.jsxs(se, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: D(),
        children: [
          E,
          " "
        ]
      })
    ]
  });
}
function $t({ scheme: t, mf_redemption: e, handleInvestAgain: a, showSchemeName: i, isPartner: o }) {
  const { AppState: { tenant: l } } = z(le), r = e.state.toLowerCase(), [d, u] = f(""), [w, c] = f(""), [m, N] = f(""), [T, E] = f(""), [j] = f("Order will be processed after investor provides consent");
  return xe(() => {
    r === "pending" || r === "confirmed" || r === "submitted" || r === "successful" ? (u(s._({
      id: "9CS0ZY"
    })), c(o ? s._({
      id: "lKqNAW",
      values: {
        0: e == null ? void 0 : e.id
      }
    }) : s._({
      id: "/8rqV/",
      values: {
        0: e == null ? void 0 : e.id
      }
    })), E(s._({
      id: "K7WMZD"
    })), N("successInfo")) : r === "failed" && (u(s._({
      id: "4qtFW3"
    })), c(o ? s._({
      id: "FvapA5"
    }) : s._({
      id: "CsBuck"
    })), E(s._({
      id: "K7WMZD"
    })), N("Error"));
  }, []), /* @__PURE__ */ n.jsxs(n.Fragment, {
    children: [
      /* @__PURE__ */ n.jsx(P, {
        title: d,
        description: w,
        iconName: m,
        children: /* @__PURE__ */ n.jsx(oe, {
          title: i && t.name,
          tableData: [
            {
              label: e != null && e.amount ? s._({
                id: "uzz6vZ"
              }) : s._({
                id: "7P4OtI"
              }),
              value: e.amount ? `₹ ${M(e.amount)}` : e.units ? e.units : "-"
            },
            {
              label: s._({
                id: "cXcPd1"
              }),
              value: e != null && e.folio_number ? e == null ? void 0 : e.folio_number : "New folio"
            },
            {
              label: s._({
                id: "1/YYB1"
              }),
              value: e.created_at ? Q(new Date(e.created_at), "dd-MMM-yyyy") : "-"
            }
          ]
        })
      }),
      j && o && /* @__PURE__ */ n.jsx(X, {
        type: "alert",
        variant: "flash",
        title: j,
        customClass: "mb-5"
      }),
      o && /* @__PURE__ */ n.jsx(ae, {
        hyperlink: ne(l) + `/consents?object_type=mf_redemption&action=confirm&id=${e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      a && /* @__PURE__ */ n.jsx(se, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: a,
        children: T
      })
    ]
  });
}
function Ft({ scheme: t, mf_switch: e, handleInvestAgain: a, showSchemeName: i, isPartner: o }) {
  const { AppState: { tenant: l } } = z(le), r = e.state.toLowerCase(), [d, u] = f(""), [w, c] = f(""), [m, N] = f(""), [T, E] = f(!1), [j, L] = f(""), { fpapi: D } = z(ve), [b, B] = f({}), { tenantConfig: $, amcs: H } = me(), K = $ == null ? void 0 : $.fallbackLogo, G = $ == null ? void 0 : $.showSchemeLogo, [Y] = f("Order will be processed after investor provides consent"), p = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  return Ce(() => {
    async function W() {
      E(!0);
      try {
        const S = await D.fpClient.master_data().fetchScheme(e.switch_in_scheme), O = await D.fpClient.master_data().fetchScheme(e.switch_out_scheme), h = G && H[O == null ? void 0 : O.amc_id] || K;
        B({
          ...e,
          schemeLogo: h,
          switch_in_scheme_name: S.name,
          switch_out_scheme_name: O.name
        }), r === "pending" || r === "confirmed" || r === "submitted" || r === "successful" ? (u(s._({
          id: "9CS0ZY"
        })), c(o ? s._({
          id: "QZntO/",
          values: {
            0: e == null ? void 0 : e.id
          }
        }) : s._({
          id: "7q2PJy",
          values: {
            0: e == null ? void 0 : e.id
          }
        })), L(s._({
          id: "K7WMZD"
        })), N("successInfo")) : r === "failed" && (u(s._({
          id: "4qtFW3"
        })), c(s._({
          id: "h3ZVI0"
        })), L(s._({
          id: "Oem4yT"
        })), N("Error")), E(!1);
      } catch (S) {
        u(s._({
          id: "LnNz4Q"
        })), c(s._({
          id: "F05jOJ"
        })), L(s._({
          id: "Oem4yT"
        })), N("Error"), de(S);
      }
    }
    W();
  }, [
    D,
    e,
    r
  ]), T ? /* @__PURE__ */ n.jsx(_e, {
    variant: "fullpage"
  }) : /* @__PURE__ */ n.jsxs(n.Fragment, {
    children: [
      /* @__PURE__ */ n.jsx(P, {
        title: d,
        description: w,
        iconName: m,
        children: /* @__PURE__ */ n.jsx(oe, {
          title: i && t.name,
          tableData: [
            {
              label: s._({
                id: "6Xqu8f"
              }),
              value: b != null && b.switch_out_scheme_name ? /* @__PURE__ */ n.jsx(n.Fragment, {
                children: /* @__PURE__ */ n.jsxs("div", {
                  className: "mb-2 flex",
                  children: [
                    /* @__PURE__ */ n.jsx("img", {
                      src: b == null ? void 0 : b.schemeLogo,
                      alt: "logo",
                      className: p
                    }),
                    /* @__PURE__ */ n.jsxs("div", {
                      className: "flex flex-col",
                      children: [
                        /* @__PURE__ */ n.jsx("div", {
                          children: `${Oe(b.switch_out_scheme_name)}`
                        }),
                        /* @__PURE__ */ n.jsx("div", {
                          className: "text-primary-N40 text-size-12 block pr-1",
                          children: t.investment_option && Ve(t.investment_option)
                        })
                      ]
                    })
                  ]
                })
              }) : "-",
              colSpan: "basis-full"
            },
            {
              label: b.amount ? s._({
                id: "TSMEuV"
              }) : s._({
                id: "hKtDqN"
              }),
              value: b.amount ? `₹ ${M(b.amount)}` : b.units ? b.units : "-"
            },
            {
              label: s._({
                id: "cXcPd1"
              }),
              value: b.folio_number ? b.folio_number : "New folio"
            }
          ]
        })
      }),
      Y && o && /* @__PURE__ */ n.jsx(X, {
        type: "alert",
        variant: "flash",
        title: Y,
        customClass: "mb-5"
      }),
      o && /* @__PURE__ */ n.jsx(ae, {
        hyperlink: ne(l) + `/consents?object_type=mf_switch&action=confirm&id=${e == null ? void 0 : e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      a && /* @__PURE__ */ n.jsx(se, {
        variant: "secondary",
        fullwidth: !0,
        onClick: a,
        customClass: "mt-2 mb-4",
        children: j
      })
    ]
  });
}
function qt({ scheme: t, mf_switch: e, handleInvestAgain: a, showSchemeName: i, isPartner: o }) {
  var W;
  const { AppState: { tenant: l } } = z(le), r = e.state.toLowerCase(), [d, u] = f(""), [w, c] = f(""), [m, N] = f(""), [T, E] = f(!1), [j, L] = f(""), { fpapi: D } = z(ve), [b, B] = f({}), { tenantConfig: $, amcs: H } = me(), K = $ == null ? void 0 : $.fallbackLogo, G = $ == null ? void 0 : $.showSchemeLogo, [Y] = f("Plan will be activated after investor provides consent"), p = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  return Ce(() => {
    async function S() {
      E(!0);
      try {
        const O = await D.fpClient.master_data().fetchScheme(e.switch_in_scheme), h = await D.fpClient.master_data().fetchScheme(e.switch_out_scheme), ee = G && H[h == null ? void 0 : h.amc_id] || K;
        B({
          ...e,
          schemeLogo: ee,
          switch_in_scheme_name: O.name,
          switch_out_scheme_name: h.name
        }), r === "active" || r === "created" || r === "pending" || r === "confirmed" || r === "submitted" || r === "successful" ? (u(s._({
          id: "PAOIq9"
        })), c(s._({
          id: "1DWHoM",
          values: {
            0: e == null ? void 0 : e.id
          }
        })), L(s._({
          id: "K7WMZD"
        })), N("successInfo")) : r === "failed" && (u(s._({
          id: "rP1ZG1"
        })), c(s._({
          id: "jLiWEA"
        })), L(s._({
          id: "Oem4yT"
        })), N("Error")), E(!1);
      } catch {
        u(s._({
          id: "LnNz4Q"
        })), c(s._({
          id: "F05jOJ"
        })), L(s._({
          id: "Oem4yT"
        })), N("Error");
      }
    }
    S();
  }, [
    D,
    e,
    r
  ]), T ? /* @__PURE__ */ n.jsx(_e, {
    variant: "fullpage"
  }) : /* @__PURE__ */ n.jsxs(n.Fragment, {
    children: [
      /* @__PURE__ */ n.jsx(P, {
        title: d,
        description: w,
        iconName: m,
        children: /* @__PURE__ */ n.jsx(oe, {
          title: i && t.name,
          tableData: [
            {
              label: s._({
                id: "6Xqu8f"
              }),
              value: b != null && b.switch_out_scheme_name ? /* @__PURE__ */ n.jsx(n.Fragment, {
                children: /* @__PURE__ */ n.jsxs("div", {
                  className: "flex",
                  children: [
                    /* @__PURE__ */ n.jsx("img", {
                      src: b == null ? void 0 : b.schemeLogo,
                      alt: "logo",
                      className: p
                    }),
                    /* @__PURE__ */ n.jsxs("div", {
                      className: "flex flex-col",
                      children: [
                        /* @__PURE__ */ n.jsx("div", {
                          children: `${Oe(b.switch_out_scheme_name)}`
                        }),
                        /* @__PURE__ */ n.jsx("div", {
                          className: "text-primary-N40 text-size-12 block pr-1",
                          children: t.investment_option && Ve(t.investment_option)
                        })
                      ]
                    })
                  ]
                })
              }) : "-",
              colSpan: "basis-full"
            },
            {
              label: s._({
                id: "TSMEuV"
              }),
              value: b.amount ? `₹ ${M(b.amount)}` : "-"
            },
            {
              label: s._({
                id: "12iDzS"
              }),
              value: b.frequency ? (W = ge(b.frequency)) == null ? void 0 : W.replace(/_/g, " ") : "-"
            },
            {
              label: s._({
                id: "cXcPd1"
              }),
              value: b.folio_number ? b.folio_number : "New folio"
            },
            b.end_date ? {
              label: s._({
                id: "w3AOkt"
              }),
              value: b.end_date && (b != null && b.end_date) ? new Date(b.end_date).getFullYear() >= 2099 ? "Until you stop" : Q(new Date(b.end_date), "dd-MMM-yyyy") : "-"
            } : ""
          ]
        })
      }),
      Y && o && /* @__PURE__ */ n.jsx(X, {
        type: "alert",
        variant: "flash",
        title: Y,
        customClass: "mb-5"
      }),
      o && /* @__PURE__ */ n.jsx(ae, {
        hyperlink: ne(l) + `/consents?object_type=mf_switch_plan&action=confirm&id=${e == null ? void 0 : e.id}`,
        buttonText: "consent link",
        customClass: "mb-2"
      }),
      a ? /* @__PURE__ */ n.jsx(se, {
        variant: "secondary",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: a,
        children: j
      }) : /* @__PURE__ */ n.jsx(n.Fragment, {})
    ]
  });
}
function as({ investorProfile: t, scheme: e, mf_order: a, payment: i, showSchemeName: o, isGroupInvestment: l, handleInvestAgain: r, handleRetry: d, isPartner: u, isFotOrder: w }) {
  const c = a == null ? void 0 : a.object;
  function m() {
    switch (c) {
      case "mf_purchase":
        return /* @__PURE__ */ n.jsx(Ze, {
          investorProfile: t,
          scheme: e,
          mf_purchase: a,
          payment: i,
          handleInvestAgain: r,
          handleRetry: d,
          showSchemeName: o,
          isGroupInvestment: l,
          isPartner: u
        });
      case "mf_switch":
        return /* @__PURE__ */ n.jsx(Ft, {
          scheme: e,
          mf_switch: a,
          handleInvestAgain: r,
          showSchemeName: o,
          isPartner: u
        });
      case "mf_redemption":
        return /* @__PURE__ */ n.jsx($t, {
          scheme: e,
          mf_redemption: a,
          handleInvestAgain: r,
          showSchemeName: o,
          isPartner: u
        });
      case "mf_purchase_plan":
        return /* @__PURE__ */ n.jsx(Ct, {
          scheme: e,
          mf_purchase_plan: a,
          payment: i,
          handleInvestAgain: r,
          handleRetry: d,
          showSchemeName: o,
          isGroupInvestment: l,
          isPartner: u,
          investorProfile: t,
          isFotOrder: w
        });
      case "mf_switch_plan":
        return /* @__PURE__ */ n.jsx(qt, {
          scheme: e,
          mf_switch: a,
          handleInvestAgain: r,
          showSchemeName: o,
          isPartner: u
        });
      case "mf_redemption_plan":
        return /* @__PURE__ */ n.jsx(It, {
          scheme: e,
          mf_redemption_plan: a,
          handleInvestAgain: r,
          handleRetry: d,
          showSchemeName: o,
          isPartner: u
        });
      default:
        return /* @__PURE__ */ n.jsx(n.Fragment, {
          children: /* @__PURE__ */ n.jsx(P, {
            title: s._({
              id: "1nq6/U"
            }),
            description: s._({
              id: "AHVSVV"
            }),
            iconName: "Alert"
          })
        });
    }
  }
  return m();
}
export {
  St as M,
  as as O,
  Me as a,
  Kt as b,
  Jt as c,
  Nt as d,
  pt as e,
  Rt as f,
  Ue as g,
  ts as h,
  ss as i,
  ns as j,
  Qt as k,
  Pt as l,
  es as m,
  Gt as r,
  Be as s
};
