import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useEffect as B, useMemo as X, useContext as q, useState as N } from "react";
import { P as y, D as O, e as T, T as Z, M as g, k as z, B as C, S as V, c as b, d as G } from "./index.esm-G8KyR0tM.js";
import { R as H, F as k } from "./react-final-form.es-BQCbpI73.js";
import { f as A, o as R, d as J, r as L } from "./validators-BArvL7cB.js";
import { o as K, s as Q, j as Y, T as F } from "./utils-D5LKKDAj.js";
import { i as a } from "./index-wI714H_N.js";
import { F as $ } from "./provider-Diwup2pn.js";
function P({ nominees: s, onCompletion: c, isPartner: x, handleGoBack: l }) {
  const n = /* @__PURE__ */ new Date();
  n.setFullYear(n.getFullYear() - 18), B(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const m = [
    {
      label: a._({
        id: "OOtqcU"
      }),
      value: "father"
    },
    {
      label: a._({
        id: "FV6ey6"
      }),
      value: "mother"
    },
    {
      label: a._({
        id: "A/XUaC"
      }),
      value: "spouse"
    },
    {
      label: a._({
        id: "WfNDiX"
      }),
      value: "brother"
    },
    {
      label: a._({
        id: "088rcx"
      }),
      value: "sister"
    }
  ], j = X(() => ({
    nominee_relationship: null
  }), []), o = ({ nominee_name: i, nominee_relationship: t }) => {
    c({
      success: !0,
      data: {
        name: i,
        relationship: t == null ? void 0 : t.value
      }
    });
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(y, {
        title: "Nominee details",
        icon: "Back",
        onClick: l
      }),
      /* @__PURE__ */ e.jsx(O, {
        type: "text",
        text: x ? a._({
          id: "0oHZMh"
        }) : a._({
          id: "5bwEwD"
        })
      }),
      /* @__PURE__ */ e.jsx(H, {
        onSubmit: o,
        validate: (i) => ({}),
        subscription: {
          submitting: !0,
          values: !0,
          errors: !0
        },
        children: (i) => /* @__PURE__ */ e.jsxs("form", {
          onSubmit: i.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(T, {
                  message: a._({
                    id: "FvxmEF"
                  })
                }),
                /* @__PURE__ */ e.jsx(k, {
                  name: "nominee_name",
                  validate: A(L, J(a._({
                    id: "ewxRGu"
                  })), R({
                    data: s,
                    property: "name",
                    customMsg: a._({
                      id: "v997IR"
                    })
                  })),
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validateFields: [],
                  children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Z, {
                        id: t.input.name,
                        autoComplete: "off",
                        name: t.input.name,
                        value: t.input.value,
                        onChange: t.input.onChange,
                        onBlur: () => {
                          t.input.onChange(t.input.value.trim());
                        },
                        onInput: (r) => {
                          r.target.value = r.target.value.replace(K("A-Za-z "), "");
                        },
                        placeholder: a._({
                          id: "13PYvi"
                        }),
                        maxLength: 35,
                        status: t.meta.error && t.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(g, {
                        type: "error",
                        variant: "field",
                        title: t.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(T, {
                  message: a._({
                    id: "pI9t3O"
                  })
                }),
                /* @__PURE__ */ e.jsx(k, {
                  name: "nominee_relationship",
                  initialValue: j.nominee_relationship,
                  subscription: {
                    touched: !0,
                    error: !0,
                    value: !0
                  },
                  validate: A(L, R({
                    data: s,
                    property: "relationship",
                    customMsg: a._({
                      id: "y88IZY"
                    })
                  })),
                  validateFields: [],
                  children: (t) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(z, {
                        name: t.input.name,
                        value: t.input.value,
                        onChange: (r) => t.input.onChange(r),
                        options: m,
                        status: t.meta.error && t.meta.touched ? "error" : void 0
                      }),
                      t.meta.error && t.meta.touched && /* @__PURE__ */ e.jsx(g, {
                        type: "error",
                        variant: "field",
                        title: t.meta.error
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(C, {
              type: "submit",
              fullwidth: !0,
              onClick: () => {
                const t = Object.keys(i.errors);
                t.length && Q(t[0]);
              },
              customClass: "mb-28",
              children: a._({
                id: "AtXdm9"
              })
            })
          ]
        })
      })
    ]
  });
}
function oe({ investorProfile: s, handleNomineeSelection: c, mfia: x = null, isPartner: l = !1, nomineeList: n = [], handleGoBack: m }) {
  q($);
  const [j, o] = N("initial"), [i, t] = N([]), [r, h] = N([]), v = !s && c ? "NOMINEE_SELECTION" : "NOMINEE_LISTING", p = (d, _) => {
    if (d && _) {
      const f = [];
      _.forEach((u, E) => {
        var S, w, M;
        (((S = d == null ? void 0 : d.folio_defaults) == null ? void 0 : S.nominee1) === (u == null ? void 0 : u.id) || ((w = d == null ? void 0 : d.folio_defaults) == null ? void 0 : w.nominee2) === (u == null ? void 0 : u.id) || ((M = d == null ? void 0 : d.folio_defaults) == null ? void 0 : M.nominee3) === (u == null ? void 0 : u.id)) && f.push(E);
      }), h(f);
    }
  };
  B(() => {
    (async () => (t(n), p(x, n)))();
  }, [
    x
  ]);
  const I = () => {
    switch (v) {
      case "NOMINEE_LISTING":
        return /* @__PURE__ */ e.jsx(D, {
          investorProfile: s,
          nominees: i,
          isPartner: l,
          handleGoBack: m
        });
      case "NOMINEE_SELECTION":
        return /* @__PURE__ */ e.jsx(ee, {
          nominees: i,
          handleNomineeSelection: c,
          setStage: o,
          selectedNominees: r,
          setSelectedNominees: h,
          isPartner: l,
          handleGoBack: m
        });
      default:
        return /* @__PURE__ */ e.jsx(D, {
          investorProfile: s,
          isPartner: l,
          handleGoBack: m
        });
    }
  }, U = (d) => {
    const _ = [
      ...i,
      d.data
    ];
    if (t(_), _.length > 0 && r.length < 3) {
      const f = [
        ...r,
        r.length ? r[r.length - 1] + 1 : 0
      ].sort((u, E) => u - E);
      h(f);
    }
    o("initial");
  }, W = () => {
    switch (j) {
      case "initial":
        return I();
      case "add-nominee":
        return /* @__PURE__ */ e.jsx(P, {
          nominees: i,
          onCompletion: U,
          isPartner: l,
          handleGoBack: () => o("initial")
        });
      default:
        return I();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: W()
  });
}
const D = ({ investorProfile: s, nominees: c, isPartner: x, handleGoBack: l }) => /* @__PURE__ */ e.jsxs(e.Fragment, {
  children: [
    /* @__PURE__ */ e.jsx(y, {
      title: a._({
        id: "QxIen5"
      }),
      semiBold: !0,
      icon: l ? "Back" : null,
      onClick: l
    }),
    /* @__PURE__ */ e.jsx(O, {
      type: "text",
      text: x ? a._({
        id: "k3Lpr3"
      }) : a._({
        id: "DAxRvI"
      })
    }),
    s && /* @__PURE__ */ e.jsx(e.Fragment, {
      children: c.length ? /* @__PURE__ */ e.jsx(e.Fragment, {
        children: c.map((n, m) => {
          var j, o;
          return /* @__PURE__ */ e.jsx("div", {
            className: "mb-4",
            children: /* @__PURE__ */ e.jsx(V, {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "-mr-2 -ml-2 flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 w-full pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(b, {
                      label: a._({
                        id: "UMs2dC"
                      }),
                      value: n.name
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(b, {
                      label: a._({
                        id: "pI9t3O"
                      }),
                      value: n.relationship ? Y(n.relationship) : null
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(b, {
                      label: a._({
                        id: "92x2v3"
                      }),
                      value: (o = (j = F) == null ? void 0 : j[c.length - 1]) == null ? void 0 : o[m]
                    })
                  })
                ]
              })
            })
          }, n.id || m);
        })
      }) : /* @__PURE__ */ e.jsx(g, {
        type: "important",
        variant: "flash",
        title: a._({
          id: "isRn+c"
        }),
        customClass: "mb-8"
      })
    })
  ]
}), ee = ({ nominees: s, setStage: c, handleNomineeSelection: x, selectedNominees: l, setSelectedNominees: n, isPartner: m, handleGoBack: j }) => {
  const [o, i] = N(null), t = () => {
    if (i(null), !s.length || !l.length)
      i(a._({
        id: "kl/WwV"
      }));
    else if (l.length > 3)
      i(a._({
        id: "AiW7+t"
      }));
    else {
      const h = l.map((v, p) => ({
        ...s[v],
        nominee_allocation: F[l.length - 1][p]
      }));
      x({
        skip_nomination: !1,
        nominees: h
      });
    }
  }, r = () => {
    x({
      skip_nomination: !0,
      nominees: []
    });
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsxs("div", {
        className: "flex items-center justify-between",
        children: [
          /* @__PURE__ */ e.jsx(y, {
            title: a._({
              id: "ARSTMr"
            }),
            icon: j ? "Back" : null,
            onClick: j
          }),
          /* @__PURE__ */ e.jsx(C, {
            variant: "text",
            onClick: () => {
              r();
            },
            children: a._({
              id: "P9Fjs/"
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsx(O, {
        type: "text",
        text: m ? a._({
          id: "k3Lpr3"
        }) : a._({
          id: "d3TATn"
        })
      }),
      /* @__PURE__ */ e.jsx("div", {
        className: "mb-4",
        children: /* @__PURE__ */ e.jsx(g, {
          type: "important",
          variant: "flash",
          title: a._({
            id: "t8j4C+"
          })
        })
      }),
      !!s.length && /* @__PURE__ */ e.jsx(G, {
        variant: "multiSelectable",
        multiSelected: l,
        onChange: (h) => {
          n([
            ...h.target.value
          ].sort((v, p) => v - p));
        },
        children: s.map((h, v) => /* @__PURE__ */ e.jsxs(V, {
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "flex flex-wrap items-center",
              children: [
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(b, {
                    label: "Nominee name",
                    value: h.name
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "flex-1",
                  children: /* @__PURE__ */ e.jsx(b, {
                    label: "Relation",
                    value: Y(h.relationship)
                  })
                })
              ]
            }),
            l.indexOf(v) !== -1 && l.length < 4 && /* @__PURE__ */ e.jsx(g, {
              type: "important",
              variant: "field",
              title: a._({
                id: "hKBR/a",
                values: {
                  0: F[l.length - 1][l.indexOf(v)]
                }
              })
            })
          ]
        }, v))
      }),
      o && /* @__PURE__ */ e.jsx("div", {
        className: "mb-4",
        children: /* @__PURE__ */ e.jsx(g, {
          type: "error",
          variant: "field",
          title: o
        })
      }),
      /* @__PURE__ */ e.jsx("div", {
        className: "mb-4",
        children: /* @__PURE__ */ e.jsx(C, {
          variant: l.length >= 3 ? "disabled" : "secondary",
          fullwidth: !0,
          onClick: () => {
            c("add-nominee");
          },
          children: a._({
            id: "spw7O5"
          })
        })
      }),
      !!s.length && /* @__PURE__ */ e.jsx(C, {
        variant: l.length >= 1 && l.length <= 3 ? "primary" : "disabled",
        customClass: "mb-4",
        fullwidth: !0,
        onClick: t,
        children: a._({
          id: "J48M2C"
        })
      })
    ]
  });
};
export {
  oe as N
};
