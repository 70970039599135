import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useState as k, useContext as je, useMemo as Be, useCallback as ze, useEffect as Ie, useLayoutEffect as jt } from "react";
import { a as He, e as ge, T as Fe, M as Je, R as $e, c as St, B as Ae, b as Ce, k as dt, I as ut, v as ct, r as rt, w as kt, x as Ft, q as Mt, P as Nt } from "./index.esm-G8KyR0tM.js";
import { u as Ue } from "./useConfiguration-B-lyaBB9.js";
import { F as We } from "./provider-Diwup2pn.js";
import { LoginContext as Ze } from "./LoginContext.js";
import { r as ke } from "./sentry-CAR5o4wv.js";
import { ak as Ct, i as K, f as _t, C as Le, E as Re, a5 as Ge, o as Ee, m as qe, ac as ot, a9 as vt, X as Ke, ad as ht, u as Ye, ab as yt, $ as xt, j as ft, al as Dt, am as gt, an as Ve, B as it } from "./utils-D5LKKDAj.js";
import { i as r } from "./index-wI714H_N.js";
import { AppContext as Oe } from "./AppContext.js";
import { c as It } from "./checkMigratedUser-D-TLMNz-.js";
import { p as Tt } from "./partiallyOnboardMigratedUser-Do2b2Ofg.js";
import { ErrorPage as xe } from "./ErrorPage.js";
import { K as At } from "./KycCheckStatus-CuPG6c--.js";
import { S as Ut, a as $t } from "./SwitchPlanConsent-C2-0e1aD.js";
import { b as he, c as Ne, e as Lt, d as Me, f as Te, h as ye, i as Qe, j as Xe, g as Et, a as Pe, k as mt, l as Ht, m as qt, O as Bt } from "./OrderStatus-BFxH2wTa.js";
import { b as pt, P as Kt, a as Yt } from "./PurchasePlanConsent-D48kuOSA.js";
import { R as et, F as _e } from "./react-final-form.es-BQCbpI73.js";
import { C as E } from "./StatusMessage-BwNUc0rO.js";
import { u as pe } from "./useMigratedUser-Bcz7gd73.js";
import { FolioDetails as tt } from "./FolioDetails.js";
import { F as at } from "./FolioListing-CxYnvVWM.js";
import { P as zt } from "./PurchaseOrderList-mO3KSFgi.js";
import { f as be, r as we } from "./validators-BArvL7cB.js";
import { B as Vt } from "./BankAccountListing-BCK16zSG.js";
import { O as Wt } from "./Onboard-B97HV6Qg.js";
import { A as Zt } from "./Auth-BeDpKAhM.js";
function Ot({ scheme: a, investment_account_id: T, investorProfile: C, onSuccess: F, isGroupInvestment: o, isPartner: b, investment_accounts: h, user: ne, holdings: q, assistedWorkflowPAN: oe, assistedWorkflowFolioNumber: ue }) {
  const [y, j] = k(null), [U, B] = k(!1), [R, Y] = k(!1), [G, N] = k(!1), [O, M] = k(null), [z, ie] = k(null), [W, le] = k(null), [V, fe] = k([]), { fpapi: $ } = je(We), { tenantConfig: S } = Ue(), { loggedInPartner: L } = je(Ze), [Q, P] = k(null), [se, t] = k(!1), [ee, X] = k(null), [ve, s] = k(!1), [te, Z] = k("initial"), { isMigratedUser: w } = pe({
    user: ne
  }), [_, g] = k(!1), { AppState: { employer: x } } = je(Oe), f = Be(() => {
    var c, v, m, A, I, H;
    const n = (v = (c = S == null ? void 0 : S.fundList) == null ? void 0 : c.find((Se) => Se.isin === (a == null ? void 0 : a.isin))) == null ? void 0 : v.nfo;
    return ((m = S == null ? void 0 : S.groupInvestment) == null ? void 0 : m.days) && ((A = S == null ? void 0 : S.groupInvestment) == null ? void 0 : A.cutOffDays) && Ct({
      salaryDay: (I = S == null ? void 0 : S.groupInvestment) == null ? void 0 : I.days[0],
      salaryCutoffDay: (H = S == null ? void 0 : S.groupInvestment) == null ? void 0 : H.cutOffDays[0],
      NFO: n,
      purchaseDate: null
    });
  }, [
    a
  ]), D = S == null ? void 0 : S.groupInvestment, p = ze((n, c) => {
    const v = [];
    return n.data.forEach((m) => {
      m.amc === c && v.push(m);
    }), v;
  }, []), J = ze((n) => {
    const c = [];
    return n.data.forEach((v) => {
      v.number === ue && c.push(v);
    }), c;
  }, []);
  Ie(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []), Ie(() => {
    var n;
    if (!o && ee) {
      const c = ee.filter((v) => {
        const m = v.scheme === (a == null ? void 0 : a.isin) && !v.plan && (v.state === "pending" || v.state === "failed" && v.failure_code === "payment_failure");
        return O ? m && v.folio_number === O : m;
      });
      t(!!c.length);
    }
    (n = a == null ? void 0 : a.delivery_mode) != null && n.includes(ae().toUpperCase()) || Z("scheme_not_allowed");
  }, [
    O
  ]), Ie(() => {
    (async () => {
      N(!0);
      try {
        const n = $.fpClient.mf_folios().fetchAll({
          mf_investment_account: T
        }), c = $.fpClient.master_data().fetchAmcs();
        let v;
        o || (v = $.fpClient.mf_purchases().fetchAll({
          mf_investment_account: T,
          states: "pending,failed"
        }));
        const m = await Promise.all([
          n,
          c,
          v
        ]), A = m[1].amcs.find((H) => H.amc_id === (a == null ? void 0 : a.amc_id));
        let I;
        if (b ? I = J(m[0]) : I = p(m[0], A.amc_code), !o) {
          const H = m[2].data.filter((me) => {
            const De = me.scheme === (a == null ? void 0 : a.isin) && !me.plan && (me.state === "pending" || me.state === "failed" && me.failure_code === "payment_failure");
            return I.length ? De && me.folio_number === I[0].number : De;
          }), Se = H.length ? H[0] : null;
          t(!!Se), X(m[2].data);
        }
        fe(I), le(A.amc_code), P(A == null ? void 0 : A.amc_id), I.length ? (ie(I[0]), M(I[0].number), ce(I[0])) : w && g(!0);
      } catch (n) {
        ke(n);
      } finally {
        N(!1);
      }
    })();
  }, [
    w,
    q
  ]);
  const ae = () => {
    var n;
    return !K(z) && (z != null && z.dp_id) || !_t(h) && ((n = h[0].folio_defaults) != null && n.demat_account) ? "Demat" : "Physical";
  }, ce = (n) => {
    var c, v;
    if (w) {
      const m = (c = q == null ? void 0 : q.folios) == null ? void 0 : c.filter((I) => I.folio_number === (n == null ? void 0 : n.number)), A = m != null && m.length ? (v = m[0].schemes) == null ? void 0 : v.find((I) => I.isin === (a == null ? void 0 : a.isin)) : [];
      K(A) ? g(!0) : g(!1);
    }
  };
  async function re({ amount: n }) {
    var v, m, A;
    const c = {
      mf_investment_account: T,
      scheme: a == null ? void 0 : a.isin,
      amount: +n,
      folio_number: O,
      order_gateway: "rta"
    };
    o && (f != null && f.scheduled_on) && (c.scheduled_on = qe(f == null ? void 0 : f.scheduled_on, "yyyy-mm-dd")), b && L && (c.partner = L), o && !K((v = S == null ? void 0 : S.groupInvestment) == null ? void 0 : v.employerPartner) && ((m = S == null ? void 0 : S.groupInvestment) != null && m.employerPartner[x == null ? void 0 : x.id]) && (c.partner = (A = S == null ? void 0 : S.groupInvestment) == null ? void 0 : A.employerPartner[x == null ? void 0 : x.id]), $.fpClient.mf_purchases().create(c).then((I) => {
      F({
        mf_order: I,
        shouldOnboardMigratedUser: _
      });
    }).catch(function(I) {
      j(r._({
        id: "HbQ3GE"
      })), ke(I);
    });
  }
  function de() {
    return /* @__PURE__ */ e.jsx(Ce, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${r._({
        id: "50eZGd"
      })}`,
      isOpen: R,
      toggleModal: Y,
      contentHeightMax: !0,
      children: /* @__PURE__ */ e.jsx(tt, {
        investorProfile: C,
        investment_accounts: h
      })
    });
  }
  function u() {
    return /* @__PURE__ */ e.jsx(Ce, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${r._({
        id: "845eUq"
      })}`,
      isOpen: U,
      toggleModal: B,
      contentHeightMax: !1,
      children: /* @__PURE__ */ e.jsx(at, {
        investorProfile: C,
        amc_code: W,
        amcId: Q,
        handleFolioSelection: (n) => {
          ce(n), ie(n), M(n.number), B(!U);
        },
        handleFolioCreation: () => {
          w && g(!0), M(null), ie(null), B(!U);
        },
        scheme_details: void 0,
        isPartner: b,
        assistedWorkflowPAN: oe
      })
    });
  }
  function i() {
    return /* @__PURE__ */ e.jsx(Ce, {
      customClass: "md:max-w-29",
      variant: "fullPage",
      title: `${r._({
        id: "ohPawJ"
      })}`,
      isOpen: ve,
      toggleModal: s,
      contentHeightMax: !0,
      children: /* @__PURE__ */ e.jsx(zt, {
        isGroupInvestment: o,
        investor: C,
        mfInvestmentAccount: h == null ? void 0 : h[0],
        isPartner: b,
        searchParams: {
          type: "purchase",
          status: "pending,failed",
          folio_number: O,
          isin: a == null ? void 0 : a.isin
        }
      })
    });
  }
  const l = () => y ? /* @__PURE__ */ e.jsx(xe, {
    title: y,
    description: `${r._({
      id: "I4jL42"
    })}`,
    showDetails: !1,
    showAction: !0,
    actionText: `${r._({
      id: "Oem4yT"
    })}`,
    actionHandler: () => {
      j(null);
    }
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(et, {
        onSubmit: re,
        validate: (n) => {
          const c = {};
          return n.amount || (c.amount = r._({
            id: "c0D/2W"
          })), n.amount && n.amount < Number(a == null ? void 0 : a.min_initial_investment) && (c.amount = r._({
            id: "9LeqUy",
            values: {
              0: Le(Number(a == null ? void 0 : a.min_initial_investment))
            }
          })), n.amount && n.amount > Number(a == null ? void 0 : a.max_initial_investment) && (c.amount = r._({
            id: "DJzP4H",
            values: {
              0: Le(Number(a == null ? void 0 : a.max_initial_investment))
            }
          })), c;
        },
        children: (n) => /* @__PURE__ */ e.jsxs("form", {
          id: "orderForm",
          onSubmit: n.handleSubmit,
          children: [
            /* @__PURE__ */ e.jsxs("div", {
              className: "mb-8",
              children: [
                /* @__PURE__ */ e.jsx(ge, {
                  message: `${r._({
                    id: "4NN3aK"
                  })}`
                }),
                /* @__PURE__ */ e.jsx(_e, {
                  name: "amount",
                  children: (c) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      /* @__PURE__ */ e.jsx(Fe, {
                        id: c.input.name,
                        autoComplete: "off",
                        type: "text",
                        name: c.input.name,
                        value: Re(c.input.value),
                        onChange: (v) => c.input.onChange(Ge(v.target.value)),
                        onInput: (v) => {
                          v.target.value = v.target.value.replace(Ee("0-9"), "");
                        },
                        placeholder: `${r._({
                          id: "cRSGbK"
                        })}`,
                        prefix: "₹",
                        status: c.meta.error && c.meta.touched ? "error" : void 0,
                        autoFocus: !0
                      }),
                      c.meta.error && c.meta.touched ? /* @__PURE__ */ e.jsx("div", {
                        className: "mb-2 mt-2",
                        children: /* @__PURE__ */ e.jsx(Je, {
                          type: "error",
                          variant: "field",
                          title: c.meta.error
                        })
                      }) : /* @__PURE__ */ e.jsx(Je, {
                        type: "helper",
                        variant: "field",
                        title: r._({
                          id: "bYw+YE",
                          values: {
                            0: Le(Number(a == null ? void 0 : a.min_initial_investment))
                          }
                        })
                      })
                    ]
                  })
                })
              ]
            }),
            /* @__PURE__ */ e.jsx(E, {
              check: !!o,
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "mb-8",
                children: [
                  /* @__PURE__ */ e.jsx(ge, {
                    message: `${r._({
                      id: "5vGD9a"
                    })}`
                  }),
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "flex items-start",
                    children: [
                      /* @__PURE__ */ e.jsx($e, {
                        variant: "iconBased",
                        name: "paymentMethodRadio",
                        group: [
                          {
                            icon: "Salary",
                            label: "Salary"
                          }
                        ],
                        disabled: []
                      }),
                      /* @__PURE__ */ e.jsx(St, {
                        border: !0,
                        symbol: (D == null ? void 0 : D.groupPartnerLogo) || (S == null ? void 0 : S.fallbackLogo),
                        label: D == null ? void 0 : D.groupPartnerName,
                        value: `${r._({
                          id: "yFOTgq",
                          values: {
                            0: f != null && f.consideredSalaryDate ? ` in ${new Date(f == null ? void 0 : f.consideredSalaryDate).toLocaleString("default", {
                              month: "long"
                            })} ${new Date(f == null ? void 0 : f.consideredSalaryDate).getFullYear()}` : ""
                          }
                        })}`,
                        customClass: "ml-3 flex-1"
                      })
                    ]
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "pt-8",
              children: /* @__PURE__ */ e.jsx(Ae, {
                type: "submit",
                fullwidth: !0,
                children: !o && !b ? r._({
                  id: "Ffad/b"
                }) : r._({
                  id: "H/E6fZ"
                })
              })
            })
          ]
        })
      }),
      u(),
      de(),
      i()
    ]
  }), d = () => {
    switch (te) {
      case "initial":
        return l();
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(xe, {
          title: r._({
            id: "WddszM"
          }),
          description: r._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: r._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return l();
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: G ? /* @__PURE__ */ e.jsx(He, {
      variant: "fullpage"
    }) : d()
  });
}
const lt = [
  {
    label: "Amount",
    value: "amount"
  },
  {
    label: "Percentage",
    value: "percentage"
  }
], st = [
  {
    label: "Half Yearly",
    value: "half-yearly"
  },
  {
    label: "Yearly",
    value: "yearly"
  }
];
function Qt({ frequencyOptions: a, submitStatus: T, handleSubmitStatus: C, scheme: F, placeRecurringOrder: o, isGroupInvestment: b, groupRecurringDateObject: h, isPartner: ne, investorProfile: q, amcCode: oe, handleShowOnboardingToMigratedUser: ue, handleSelectedFolioObj: y, handleFolioNumber: j, user: U, handleShouldOnboardMigratedUser: B, assistedWorkflowPAN: R, investmentAccounts: Y }) {
  const { isMigratedUser: G } = pe({
    user: U
  }), [N, O] = k(a[0].value), M = /* @__PURE__ */ new Date(), z = new Date((/* @__PURE__ */ new Date()).setDate(M.getDate())).getDate(), [ie, W] = k(he(z, !1)), [le, V] = k(!1), [fe, $] = k(!1), [S, L] = k(!1), Q = (t) => {
    V(t);
  }, P = (t, ee, X) => {
    t.stopPropagation(), W(he(ee, t.target.checked, X));
  }, se = Be(() => {
    var t, ee;
    return {
      frequency: N ? a == null ? void 0 : a.filter((X) => (X == null ? void 0 : X.value) === N)[0] : a[0],
      sip_start_date: z,
      sip_instl_type: (t = Ne(N)) == null ? void 0 : t[0],
      sip_end_max_date: ot(N),
      group_sip_start_date: h == null ? void 0 : h.installment_date,
      group_sip_end_date: h != null && h.installment_date ? new Date(h == null ? void 0 : h.installment_date.getFullYear(), (h == null ? void 0 : h.installment_date.getMonth()) + vt[N] * Number((ee = F == null ? void 0 : F.sip_frequency_specific_data[N]) == null ? void 0 : ee.min_installments), h == null ? void 0 : h.installment_date.getDate()) : null,
      is_step_up_enabled: !1,
      step_up_type: {
        label: "Amount",
        value: "amount"
      },
      step_up_frequency: {
        label: "Half Yearly",
        value: "half-yearly"
      },
      step_up_count: [
        1
      ]
    };
  }, [
    N
  ]);
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(E, {
        check: !!T,
        children: /* @__PURE__ */ e.jsx(xe, {
          title: T,
          description: `${r._({
            id: "mLEhv8"
          })}`,
          showDetails: !1,
          showAction: !0,
          actionText: `${r._({
            id: "RvsoP+"
          })}`,
          actionHandler: () => {
            C(""), O(a[0].value);
          }
        })
      }),
      /* @__PURE__ */ e.jsx(E, {
        check: !T,
        children: /* @__PURE__ */ e.jsx(et, {
          keepDirtyOnReinitialize: !0,
          onSubmit: o,
          validate: (t) => Lt(t, F, N, b, h),
          initialValues: se,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (t) => {
            var ee, X, ve, s, te, Z, w, _, g, x, f, D, p, J, ae, ce, re, de;
            return /* @__PURE__ */ e.jsxs("form", {
              id: "recurringOrderForm",
              onSubmit: t.handleSubmit,
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(ge, {
                      message: `${r._({
                        id: "MznISI"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(_e, {
                      name: "amount",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: be(we),
                      children: (u) => {
                        var i;
                        return /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(Fe, {
                              id: u.input.name,
                              autoComplete: "off",
                              type: "text",
                              name: u.input.name,
                              value: Re(u.input.value),
                              onChange: (l) => u.input.onChange(Ge(l.target.value)),
                              onInput: (l) => {
                                l.target.value = l.target.value.replace(Ee("0-9"), "");
                              },
                              placeholder: `${r._({
                                id: "fK0Zpc"
                              })}`,
                              prefix: "₹",
                              status: Ke(u) ? "error" : void 0,
                              autoFocus: !0
                            }),
                            Me(u, `Minimum installment amount is ${`₹${Le(Number((i = F == null ? void 0 : F.sip_frequency_specific_data[N]) == null ? void 0 : i.min_installment_amount))}`} `)
                          ]
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(ge, {
                      message: `${r._({
                        id: "n30BLK"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(_e, {
                      name: "frequency",
                      initialValue: se.frequency,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: be(we),
                      children: (u) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(dt, {
                            name: u.input.name,
                            value: u.input.value,
                            onChange: (i) => {
                              var l, d, n, c, v, m;
                              O(i == null ? void 0 : i.value), t.form.change("sip_instl_type", Ne(i == null ? void 0 : i.value)[0]), (i == null ? void 0 : i.value) === "daily" && (Te((l = t == null ? void 0 : t.values) == null ? void 0 : l.sip_start_date, "daily") ? W(he((n = t == null ? void 0 : t.values) == null ? void 0 : n.sip_start_date, (c = t == null ? void 0 : t.values) == null ? void 0 : c.is_generate_first_installment, i == null ? void 0 : i.value)) : (t.form.change("is_generate_first_installment", !1), W(he((d = t == null ? void 0 : t.values) == null ? void 0 : d.sip_start_date, !1, i == null ? void 0 : i.value)))), (i == null ? void 0 : i.value) !== "daily" && W(he((v = t == null ? void 0 : t.values) == null ? void 0 : v.sip_start_date, (m = t == null ? void 0 : t.values) == null ? void 0 : m.is_generate_first_installment, i == null ? void 0 : i.value)), u.input.onChange(i);
                            },
                            options: a
                          }),
                          Me(u, "Installments will be created on every business day", u.input.value.value === "daily")
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(ge, {
                      message: `${r._({
                        id: "onTXId"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(_e, {
                      name: "sip_start_date",
                      validate: be(we),
                      allowNull: !0,
                      children: (u) => /* @__PURE__ */ e.jsxs(E, {
                        check: !b,
                        children: [
                          /* @__PURE__ */ e.jsx(Fe, {
                            type: "text",
                            placeholder: "Select the day",
                            value: u.input.value !== null ? ht(u.input.value, N) : "",
                            onChange: () => null,
                            suffix: /* @__PURE__ */ e.jsx(Ae, {
                              type: "button",
                              variant: "text",
                              onClick: () => Q(!0),
                              children: /* @__PURE__ */ e.jsx(ut, {
                                name: "Date"
                              })
                            })
                          }),
                          /* @__PURE__ */ e.jsx(ct, {
                            name: u.input.name,
                            value: u.input.value,
                            isOpen: le,
                            toggleDaySelector: Q,
                            onSelectDay: (i) => {
                              var l, d, n, c, v, m, A;
                              W(he(i, (l = t == null ? void 0 : t.values) == null ? void 0 : l.is_generate_first_installment, (n = (d = t == null ? void 0 : t.values) == null ? void 0 : d.frequency) == null ? void 0 : n.value)), Te(i, (v = (c = t == null ? void 0 : t.values) == null ? void 0 : c.frequency) == null ? void 0 : v.value) || (W(he(i, !1, (A = (m = t == null ? void 0 : t.values) == null ? void 0 : m.frequency) == null ? void 0 : A.value)), t.form.change("is_generate_first_installment", !1)), u.input.onChange(i);
                            },
                            selectedDay: u.input.value,
                            title: "Select SIP day"
                          }),
                          Me(u, ie)
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(ge, {
                      message: `${r._({
                        id: "kLHce5"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(_e, {
                      name: "sip_instl_type",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: be(we),
                      children: (u) => {
                        var i, l;
                        return /* @__PURE__ */ e.jsx($e, {
                          name: u.input.name,
                          value: u.input.value,
                          group: Ne(N),
                          size: "sm",
                          checked: Ye(Ne(N), (l = (i = u == null ? void 0 : u.input) == null ? void 0 : i.value) == null ? void 0 : l.value),
                          onChange: (d, n) => {
                            t.form.change("number_of_days", void 0), t.form.change("number_of_installments", void 0), t.form.change("number_of_months", void 0), t.form.change("number_of_quarters", void 0), t.form.change("number_of_weeks", void 0), t.form.change("number_of_years", void 0), t.form.change("installments", void 0), t.form.change("step_up_count", [
                              1
                            ]), u.input.onChange(n);
                          }
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((X = (ee = t == null ? void 0 : t.values) == null ? void 0 : ee.sip_instl_type) == null ? void 0 : X.value) === "installments",
                  children: ye("Number of installments", "installments", t, F, N)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((s = (ve = t == null ? void 0 : t.values) == null ? void 0 : ve.sip_instl_type) == null ? void 0 : s.value) === "number_of_days",
                  children: ye("Number of weeks", "number_of_days", t, F, N)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((Z = (te = t == null ? void 0 : t.values) == null ? void 0 : te.sip_instl_type) == null ? void 0 : Z.value) === "number_of_weeks",
                  children: ye("Number of weeks", "number_of_weeks", t, F, N)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((_ = (w = t == null ? void 0 : t.values) == null ? void 0 : w.sip_instl_type) == null ? void 0 : _.value) === "number_of_installments",
                  children: ye("Number of instalments", "number_of_installments", t, F, N)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((x = (g = t == null ? void 0 : t.values) == null ? void 0 : g.sip_instl_type) == null ? void 0 : x.value) === "number_of_months",
                  children: ye("Number of months", "number_of_months", t, F, N)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((D = (f = t == null ? void 0 : t.values) == null ? void 0 : f.sip_instl_type) == null ? void 0 : D.value) === "number_of_quarters",
                  children: ye("Number of quarters", "number_of_quarters", t, F, N)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((J = (p = t == null ? void 0 : t.values) == null ? void 0 : p.sip_instl_type) == null ? void 0 : J.value) === "number_of_years",
                  children: ye("Number of years", "number_of_years", t, F, N)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: Te((ae = t == null ? void 0 : t.values) == null ? void 0 : ae.sip_start_date, (re = (ce = t == null ? void 0 : t.values) == null ? void 0 : ce.frequency) == null ? void 0 : re.value),
                  children: /* @__PURE__ */ e.jsx(_e, {
                    name: "is_generate_first_installment",
                    validateFields: [],
                    type: "checkbox",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (u) => /* @__PURE__ */ e.jsx(rt, {
                      checked: u.input.checked,
                      variant: "cardType",
                      description: r._({
                        id: "ia1tKm",
                        values: {
                          0: ne ? "Investor" : "You"
                        }
                      }),
                      id: u.input.name,
                      onChange: (i) => {
                        var l, d;
                        P(i, t.values.sip_start_date, (d = (l = t == null ? void 0 : t.values) == null ? void 0 : l.frequency) == null ? void 0 : d.value), u.input.onChange(i);
                      },
                      children: /* @__PURE__ */ e.jsxs("span", {
                        className: "dark:text-primary-DN110",
                        children: [
                          "Start first installment today",
                          " "
                        ]
                      })
                    })
                  })
                }),
                /* @__PURE__ */ e.jsxs(E, {
                  check: t.values.frequency.value !== "daily",
                  children: [
                    /* @__PURE__ */ e.jsx("div", {
                      className: "mb-8",
                      children: /* @__PURE__ */ e.jsx(_e, {
                        name: "is_step_up_enabled",
                        type: "checkbox",
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: (u) => /* @__PURE__ */ e.jsxs("div", {
                          className: "flex items-center ",
                          children: [
                            /* @__PURE__ */ e.jsx("div", {
                              className: "mr-6",
                              children: "Automatic Step Up"
                            }),
                            /* @__PURE__ */ e.jsx(kt, {
                              enabled: !!u.input.checked,
                              onChange: (i) => {
                                u.input.onChange(i);
                              }
                            })
                          ]
                        })
                      })
                    }),
                    /* @__PURE__ */ e.jsxs(E, {
                      check: (de = t == null ? void 0 : t.values) == null ? void 0 : de.is_step_up_enabled,
                      children: [
                        /* @__PURE__ */ e.jsxs("div", {
                          children: [
                            /* @__PURE__ */ e.jsx(ge, {
                              message: `${r._({
                                id: "wq7DOb"
                              })}`
                            }),
                            /* @__PURE__ */ e.jsx(_e, {
                              name: "step_up_type",
                              subscription: {
                                touched: !0,
                                error: !0,
                                value: !0
                              },
                              validate: be(we),
                              children: (u) => {
                                var i, l;
                                return /* @__PURE__ */ e.jsx($e, {
                                  name: u.input.name,
                                  value: u.input.value,
                                  group: lt,
                                  size: "sm",
                                  checked: Ye(lt, (l = (i = u == null ? void 0 : u.input) == null ? void 0 : i.value) == null ? void 0 : l.value),
                                  onChange: (d, n) => {
                                    t.form.change("step_up_type_value", ""), u.input.onChange(n);
                                  }
                                });
                              }
                            })
                          ]
                        }),
                        /* @__PURE__ */ e.jsx("div", {
                          className: "mb-8",
                          children: /* @__PURE__ */ e.jsx(_e, {
                            name: "step_up_type_value",
                            subscription: {
                              touched: !0,
                              error: !0,
                              value: !0
                            },
                            validate: be(we),
                            children: (u) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                              children: [
                                /* @__PURE__ */ e.jsx(E, {
                                  check: t.values.step_up_type.value === "amount",
                                  children: /* @__PURE__ */ e.jsx(Fe, {
                                    id: u.input.name,
                                    autoComplete: "off",
                                    type: "text",
                                    name: u.input.name,
                                    value: u.input.value,
                                    onChange: (i) => {
                                      var n;
                                      const l = t.values.step_up_type.value === "amount", d = Number((n = F == null ? void 0 : F.sip_frequency_specific_data[N]) == null ? void 0 : n.max_installment_amount);
                                      Number(i.target.value) > d && l ? u.input.onChange(d) : u.input.onChange(i.target.value);
                                    },
                                    onInput: (i) => {
                                      i.target.value = i.target.value.replace(Ee("0-9"), "");
                                    },
                                    placeholder: `${r._({
                                      id: "fK0Zpc"
                                    })}`,
                                    prefix: "₹",
                                    status: Ke(u) ? "error" : void 0
                                  })
                                }),
                                /* @__PURE__ */ e.jsx(E, {
                                  check: t.values.step_up_type.value === "percentage",
                                  children: /* @__PURE__ */ e.jsx(Fe, {
                                    id: u.input.name,
                                    autoComplete: "off",
                                    type: "text",
                                    name: u.input.name,
                                    value: u.input.value,
                                    onChange: (i) => {
                                      const l = t.values.step_up_type.value === "percentage";
                                      Number(i.target.value) > 100 && l ? u.input.onChange(100) : u.input.onChange(i.target.value);
                                    },
                                    onInput: (i) => {
                                      i.target.value = i.target.value.replace(Ee("0-9"), "");
                                    },
                                    placeholder: `${r._({
                                      id: "wP3sjL"
                                    })}`,
                                    status: Ke(u) ? "error" : void 0
                                  })
                                }),
                                Me(u, "", !1)
                              ]
                            })
                          })
                        }),
                        /* @__PURE__ */ e.jsxs("div", {
                          className: "mb-8",
                          children: [
                            /* @__PURE__ */ e.jsx(ge, {
                              message: `${r._({
                                id: "DIfxw1"
                              })}`
                            }),
                            /* @__PURE__ */ e.jsx(_e, {
                              name: "step_up_frequency",
                              subscription: {
                                touched: !0,
                                error: !0,
                                value: !0
                              },
                              validate: be(we),
                              children: (u) => {
                                var i, l;
                                return /* @__PURE__ */ e.jsx($e, {
                                  name: u.input.name,
                                  value: u.input.value,
                                  group: st,
                                  size: "sm",
                                  checked: Ye(st, (l = (i = u == null ? void 0 : u.input) == null ? void 0 : i.value) == null ? void 0 : l.value),
                                  onChange: (d, n) => {
                                    t.form.change("step_up_count", [
                                      1
                                    ]), u.input.onChange(n);
                                  }
                                });
                              }
                            })
                          ]
                        }),
                        /* @__PURE__ */ e.jsxs("div", {
                          className: " mb-8",
                          children: [
                            /* @__PURE__ */ e.jsx(ge, {
                              message: `${r._({
                                id: "3yR4Tr"
                              })}`
                            }),
                            /* @__PURE__ */ e.jsx(_e, {
                              name: "step_up_count",
                              subscription: {
                                touched: !0,
                                error: !0,
                                value: !0
                              },
                              validate: be(we),
                              children: (u) => /* @__PURE__ */ e.jsxs("div", {
                                children: [
                                  /* @__PURE__ */ e.jsxs("div", {
                                    className: "flex items-center",
                                    children: [
                                      /* @__PURE__ */ e.jsx("div", {
                                        className: "flex-1",
                                        children: /* @__PURE__ */ e.jsx(Ft, {
                                          value: u.input.value,
                                          onValueChange: u.input.onChange,
                                          max: Qe(t.values.sip_instl_type.value, Xe(t.values.sip_instl_type.value, t.values.frequency.value, t.values.number_of_days, t.values.number_of_weeks, t.values.number_of_installments, t.values.number_of_months, t.values.number_of_quarters, t.values.number_of_years), t.values.step_up_frequency.value).max,
                                          min: Qe(t.values.sip_instl_type.value, Xe(t.values.sip_instl_type.value, t.values.frequency.value, t.values.number_of_days, t.values.number_of_weeks, t.values.number_of_installments, t.values.number_of_months, t.values.number_of_quarters, t.values.number_of_years), t.values.step_up_frequency.value).min,
                                          step: 1
                                        })
                                      }),
                                      /* @__PURE__ */ e.jsx("div", {
                                        className: "ml-4 w-1/4 shrink-0",
                                        children: /* @__PURE__ */ e.jsx(Fe, {
                                          id: u.input.name,
                                          autoComplete: "off",
                                          type: "text",
                                          name: u.input.name,
                                          value: u.input.value[0],
                                          onChange: (i) => {
                                            var n, c, v, m;
                                            const l = +i.target.value, d = Qe((c = (n = t == null ? void 0 : t.values) == null ? void 0 : n.sip_instl_type) == null ? void 0 : c.value, Xe(t.values.sip_instl_type.value, t.values.frequency.value, t.values.number_of_days, t.values.number_of_weeks, t.values.number_of_installments, t.values.number_of_months, t.values.number_of_quarters, t.values.number_of_years), (m = (v = t == null ? void 0 : t.values) == null ? void 0 : v.step_up_frequency) == null ? void 0 : m.value).max;
                                            Number.isNaN(l) || u.input.onChange(l > d ? [
                                              d
                                            ] : [
                                              l
                                            ]);
                                          }
                                        })
                                      })
                                    ]
                                  }),
                                  Me(u, "", !1)
                                ]
                              })
                            })
                          ]
                        }),
                        /* @__PURE__ */ e.jsx(E, {
                          check: !!(t.values.amount && t.values.step_up_type_value),
                          children: /* @__PURE__ */ e.jsx(Je, {
                            type: "helper",
                            variant: "flash",
                            title: Et(t.values.amount, Pe(t.values.sip_start_date, t.values.is_generate_first_installment), t.values.step_up_type.value, t.values.step_up_type_value, t.values.step_up_frequency.value, t.values.step_up_count[0]),
                            customClass: "mt-5 mb-5"
                          })
                        })
                      ]
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "pt-4",
                  children: /* @__PURE__ */ e.jsx(Ae, {
                    variant: "primary",
                    type: "submit",
                    fullwidth: !0,
                    children: "Confirm"
                  })
                })
              ]
            });
          }
        })
      }),
      /* @__PURE__ */ e.jsx(Ce, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Select Folios",
        isOpen: fe,
        toggleModal: () => {
          $(!1);
        },
        contentHeightMax: !1,
        children: /* @__PURE__ */ e.jsx(at, {
          investorProfile: q,
          amc_code: oe,
          handleFolioSelection: (t) => {
            ue(t), y(t), j(t.number), $(!fe);
          },
          handleFolioCreation: () => {
            G && B(!0), j(""), y(null), $(!fe);
          },
          assistedWorkflowPAN: R,
          isPartner: ne
        })
      }),
      /* @__PURE__ */ e.jsx(Ce, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Folio Details",
        isOpen: S,
        toggleModal: L,
        contentHeightMax: !0,
        children: /* @__PURE__ */ e.jsx(tt, {
          investorProfile: q,
          investment_accounts: Y
        })
      })
    ]
  });
}
function Xt({ scheme: a, investment_account_id: T, onSuccess: C, investorProfile: F, isGroupInvestment: o, isPartner: b, investment_accounts: h, user: ne, holdings: q, assistedWorkflowPAN: oe, assistedWorkflowFolioNumber: ue }) {
  var te, Z;
  const { fpapi: y } = je(We), { tenantConfig: j } = Ue(), { loggedInPartner: U } = je(Ze), { AppState: { employer: B } } = je(Oe), { isMigratedUser: R } = pe({
    user: ne
  }), [Y, G] = k(!1), [N, O] = k(""), [M, z] = k("initial"), [ie, W] = k(""), [le, V] = k(null), [fe, $] = k(null), S = (te = j == null ? void 0 : j.app_constants) == null ? void 0 : te.plans_mode;
  let L = yt(a == null ? void 0 : a.sip_frequency_specific_data, S);
  const Q = ((Z = j == null ? void 0 : j.groupInvestment) == null ? void 0 : Z.frequencyOptions) || [], P = Be(() => {
    var w, _, g, x, f, D;
    if (o) {
      const p = (_ = (w = j == null ? void 0 : j.fundList) == null ? void 0 : w.find((J) => J.isin === (a == null ? void 0 : a.isin))) == null ? void 0 : _.nfo;
      return ((g = j == null ? void 0 : j.groupInvestment) == null ? void 0 : g.days) && ((x = j == null ? void 0 : j.groupInvestment) == null ? void 0 : x.cutOffDays) && xt({
        salaryDay: (f = j == null ? void 0 : j.groupInvestment) == null ? void 0 : f.days[0],
        salaryCutoffDay: (D = j == null ? void 0 : j.groupInvestment) == null ? void 0 : D.cutOffDays[0],
        NFO: p,
        purchaseDate: null
      });
    }
  }, [
    a
  ]);
  if (o) {
    const w = [];
    a != null && a.sip_frequency_specific_data && Object.keys(a == null ? void 0 : a.sip_frequency_specific_data).forEach((_, g) => {
      var x;
      Q.includes(_) && w.push({
        id: g,
        label: ((x = ft(_)) == null ? void 0 : x.replace(/_/g, " ")) ?? "",
        value: _
      });
    }), L = w;
  }
  Ie(() => {
    var w;
    if (!((w = a == null ? void 0 : a.delivery_mode) != null && w.includes(mt(le, h).toUpperCase()))) {
      z("scheme_not_allowed");
      return;
    }
    !K(le) && t(le), ee();
  }, [
    R,
    T
  ]);
  const se = ze((w) => {
    var g;
    const _ = [];
    return (g = w == null ? void 0 : w.data) == null || g.forEach((x) => {
      x.number === ue && _.push(x);
    }), _;
  }, []), t = (w) => {
    var _, g;
    if (R) {
      const x = (_ = q == null ? void 0 : q.folios) == null ? void 0 : _.filter((D) => D.folio_number === (w == null ? void 0 : w.number)), f = x != null && x.length ? (g = x[0].schemes) == null ? void 0 : g.find((D) => D.isin === (a == null ? void 0 : a.isin)) : [];
      K(f) ? G(!0) : G(!1);
    }
  }, ee = async () => {
    try {
      const w = await y.fpClient.mf_folios().fetchAll({
        mf_investment_account: T
      }), g = (await y.fpClient.master_data().fetchAmcs()).amcs.find((f) => f.amc_id === (a == null ? void 0 : a.amc_id));
      let x;
      b ? x = se(w) : x = Ht(w.data, g.amc_code), $(g.amc_code), x.length ? (V(x[0]), W(x[0].number)) : R && G(!0);
    } catch (w) {
      window.debug.log(w), ke(w);
    }
  };
  async function X({ amount: w, frequency: _, installments: g, sip_start_date: x, sip_instl_type: f, number_of_days: D, number_of_weeks: p, number_of_installments: J, number_of_months: ae, number_of_quarters: ce, number_of_years: re, is_generate_first_installment: de, is_step_up_enabled: u, step_up_count: i, step_up_frequency: l, step_up_type: d, step_up_type_value: n }) {
    var v, m, A, I;
    const c = {
      step_up_count: (v = i[0]) == null ? void 0 : v.toString(),
      step_up_frequency: l.value,
      step_up_type: d.value,
      step_up_type_value: n,
      is_step_up_enabled: u
    };
    u && Dt.set(c), z("loading"), (f == null ? void 0 : f.value) === "perpetual" ? g = 1200 : D || p || J || ae || ce ? g = D ?? p ?? J ?? ae ?? ce : re && (g = gt(_.value, re));
    try {
      const H = {
        mf_investment_account: T.toString(),
        scheme: (a == null ? void 0 : a.isin) ?? "",
        frequency: _.value,
        folio_number: ie,
        amount: +w,
        installment_day: _.value === "daily" ? void 0 : +x,
        number_of_installments: +g,
        systematic: !0,
        generate_first_installment_now: de,
        auto_generate_installments: !0,
        initiated_by: "investor",
        initiated_via: "mobile_app",
        user_ip: null,
        server_ip: null
      };
      if ((_ == null ? void 0 : _.value) === "daily" && (x > (/* @__PURE__ */ new Date()).getDate() + 1 || x < (/* @__PURE__ */ new Date()).getDate())) {
        const me = new Date(Pe(+x, !1)), De = me.setDate(me.getDate() - 1);
        H.activate_after = qe(new Date(De), "yyyy-mm-dd");
      }
      b && U && (H.partner = U), o && !K((m = j == null ? void 0 : j.groupInvestment) == null ? void 0 : m.employerPartner) && ((A = j == null ? void 0 : j.groupInvestment) != null && A.employerPartner[B == null ? void 0 : B.id]) && (H.partner = (I = j == null ? void 0 : j.groupInvestment) == null ? void 0 : I.employerPartner[B == null ? void 0 : B.id]), o && (P != null && P.activate_after) && (H.activate_after = qe(P == null ? void 0 : P.activate_after, "yyy-mm-dd"));
      const Se = await y.fpClient.mf_purchase_plans().create(H);
      de && !o ? (z("initial"), C({
        mf_order: Se,
        isGenerateFirstInstallment: de,
        shouldOnboardMigratedUser: Y
      })) : (z("initial"), C({
        mf_order: Se,
        shouldOnboardMigratedUser: Y
      }));
    } catch (H) {
      z("initial"), O(r._({
        id: "HbQ3GE"
      })), ke(H);
    }
  }
  const ve = () => /* @__PURE__ */ e.jsx(Qt, {
    frequencyOptions: L,
    submitStatus: N,
    handleSubmitStatus: (w) => O(w),
    scheme: a,
    placeRecurringOrder: X,
    isGroupInvestment: o,
    groupRecurringDateObject: P,
    isPartner: b,
    investorProfile: F,
    amcCode: fe,
    handleShowOnboardingToMigratedUser: (w) => t(w),
    handleSelectedFolioObj: (w) => V(w),
    handleFolioNumber: (w) => W(w),
    user: ne,
    handleShouldOnboardMigratedUser: (w) => G(w),
    assistedWorkflowPAN: oe,
    investmentAccounts: h
  });
  return (() => {
    switch (M) {
      case "initial":
        return ve();
      case "loading":
        return /* @__PURE__ */ e.jsx(He, {
          variant: "fullpage"
        });
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(xe, {
          title: r._({
            id: "WddszM"
          }),
          description: r._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: r._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return ve();
    }
  })();
}
function Jt({ frequencyOptions: a, submitStatus: T, handleSubmitStatus: C, scheme: F, placeRecurringOrder: o, isGroupInvestment: b, groupRecurringDateObject: h, isPartner: ne, investorProfile: q, amcCode: oe, handleShowOnboardingToMigratedUser: ue, handleSelectedFolioObj: y, handleFolioNumber: j, user: U, handleShouldOnboardMigratedUser: B, assistedWorkflowPAN: R, investmentAccounts: Y, selectedFolio: G }) {
  var ve;
  const { tenantConfig: N } = Ue(), { isMigratedUser: O } = pe({
    user: U
  }), [M, z] = k(a[0].value), ie = /* @__PURE__ */ new Date(), W = new Date((/* @__PURE__ */ new Date()).setDate(ie.getDate())).getDate(), [le, V] = k(he(W, !1)), fe = ((ve = N == null ? void 0 : N.app_constants) == null ? void 0 : ve.non_systematic_min_installments) || 3, [$, S] = k(!1), [L, Q] = k(!1), [P, se] = k(!1), t = (s) => {
    S(s);
  }, ee = (s, te, Z) => {
    s.stopPropagation(), V(he(te, s.target.checked, Z));
  }, X = Be(() => {
    var s, te;
    return {
      frequency: M ? a == null ? void 0 : a.filter((Z) => (Z == null ? void 0 : Z.value) === M)[0] : a[0],
      sip_start_date: W,
      sip_instl_type: (s = Ne(M)) == null ? void 0 : s[0],
      sip_end_max_date: ot(M),
      group_sip_start_date: h == null ? void 0 : h.installment_date,
      group_sip_end_date: h != null && h.installment_date ? new Date(h == null ? void 0 : h.installment_date.getFullYear(), (h == null ? void 0 : h.installment_date.getMonth()) + vt[M] * Number((te = F == null ? void 0 : F.sip_frequency_specific_data[M]) == null ? void 0 : te.min_installments), h == null ? void 0 : h.installment_date.getDate()) : null
    };
  }, [
    M
  ]);
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(E, {
        check: !!T,
        children: /* @__PURE__ */ e.jsx(xe, {
          title: T,
          description: `${r._({
            id: "mLEhv8"
          })}`,
          showDetails: !1,
          showAction: !0,
          actionText: `${r._({
            id: "RvsoP+"
          })}`,
          actionHandler: () => {
            C(""), z(a[0].value);
          }
        })
      }),
      /* @__PURE__ */ e.jsx(E, {
        check: !T,
        children: /* @__PURE__ */ e.jsx(et, {
          keepDirtyOnReinitialize: !0,
          onSubmit: o,
          validate: (s) => qt(s, F, M, b, G, fe, h),
          initialValues: X,
          subscription: {
            submitting: !0,
            values: !0
          },
          children: (s) => {
            var te, Z, w, _, g, x, f, D, p, J, ae, ce, re, de, u, i, l;
            return /* @__PURE__ */ e.jsxs("form", {
              id: "recurringOrderForm",
              onSubmit: s.handleSubmit,
              children: [
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(ge, {
                      message: `${r._({
                        id: "MznISI"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(_e, {
                      name: "amount",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: be(we),
                      children: (d) => {
                        var n;
                        return /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(Fe, {
                              id: d.input.name,
                              autoComplete: "off",
                              type: "text",
                              name: d.input.name,
                              value: Re(d.input.value),
                              onChange: (c) => d.input.onChange(Ge(c.target.value)),
                              onInput: (c) => {
                                c.target.value = c.target.value.replace(Ee("0-9"), "");
                              },
                              placeholder: `${r._({
                                id: "fK0Zpc"
                              })}`,
                              prefix: "₹",
                              status: Ke(d) ? "error" : void 0,
                              autoFocus: !0
                            }),
                            Me(d, `Minimum installment amount is ${`₹${Le(Number((n = F == null ? void 0 : F.sip_frequency_specific_data[M]) == null ? void 0 : n.min_installment_amount))}`} `)
                          ]
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(ge, {
                      message: `${r._({
                        id: "n30BLK"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(_e, {
                      name: "frequency",
                      initialValue: X.frequency,
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: be(we),
                      children: (d) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                        children: [
                          /* @__PURE__ */ e.jsx(dt, {
                            name: d.input.name,
                            value: d.input.value,
                            onChange: (n) => {
                              var c, v, m, A, I, H;
                              z(n == null ? void 0 : n.value), s.form.change("sip_instl_type", Ne(n == null ? void 0 : n.value)[0]), (n == null ? void 0 : n.value) === "daily" && (Te((c = s == null ? void 0 : s.values) == null ? void 0 : c.sip_start_date, "daily") ? V(he((m = s == null ? void 0 : s.values) == null ? void 0 : m.sip_start_date, (A = s == null ? void 0 : s.values) == null ? void 0 : A.is_generate_first_installment, n == null ? void 0 : n.value)) : (s.form.change("is_generate_first_installment", !1), V(he((v = s == null ? void 0 : s.values) == null ? void 0 : v.sip_start_date, !1, n == null ? void 0 : n.value)))), (n == null ? void 0 : n.value) !== "daily" && V(he((I = s == null ? void 0 : s.values) == null ? void 0 : I.sip_start_date, (H = s == null ? void 0 : s.values) == null ? void 0 : H.is_generate_first_installment, n == null ? void 0 : n.value)), d.input.onChange(n);
                            },
                            options: a
                          }),
                          Me(d, "Installments will be created on every business day", d.input.value.value === "daily")
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(ge, {
                      message: `${r._({
                        id: "onTXId"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(_e, {
                      name: "sip_start_date",
                      validate: be(we),
                      allowNull: !0,
                      children: (d) => /* @__PURE__ */ e.jsxs(E, {
                        check: !b,
                        children: [
                          /* @__PURE__ */ e.jsx(Fe, {
                            type: "text",
                            placeholder: "Select the day",
                            value: d.input.value !== null ? ht(d.input.value, M) : "",
                            onChange: () => null,
                            suffix: /* @__PURE__ */ e.jsx(Ae, {
                              type: "button",
                              variant: "text",
                              onClick: () => t(!0),
                              children: /* @__PURE__ */ e.jsx(ut, {
                                name: "Date"
                              })
                            })
                          }),
                          /* @__PURE__ */ e.jsx(ct, {
                            name: d.input.name,
                            value: d.input.value,
                            isOpen: $,
                            toggleDaySelector: t,
                            onSelectDay: (n) => {
                              var c, v, m, A, I, H, Se;
                              V(he(n, (c = s == null ? void 0 : s.values) == null ? void 0 : c.is_generate_first_installment, (m = (v = s == null ? void 0 : s.values) == null ? void 0 : v.frequency) == null ? void 0 : m.value)), Te(n, (I = (A = s == null ? void 0 : s.values) == null ? void 0 : A.frequency) == null ? void 0 : I.value) || (V(he(n, !1, (Se = (H = s == null ? void 0 : s.values) == null ? void 0 : H.frequency) == null ? void 0 : Se.value)), s.form.change("is_generate_first_installment", !1)), d.input.onChange(n);
                            },
                            selectedDay: d.input.value,
                            title: "Select SIP day"
                          }),
                          Me(d, le)
                        ]
                      })
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsxs("div", {
                  className: "mb-8",
                  children: [
                    /* @__PURE__ */ e.jsx(ge, {
                      message: `${r._({
                        id: "kLHce5"
                      })}`
                    }),
                    /* @__PURE__ */ e.jsx(_e, {
                      name: "sip_instl_type",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      validate: be(we),
                      children: (d) => {
                        var n, c;
                        return /* @__PURE__ */ e.jsx($e, {
                          name: d.input.name,
                          value: d.input.value,
                          group: Ne(M),
                          size: "sm",
                          checked: Ye(Ne(M), (c = (n = d == null ? void 0 : d.input) == null ? void 0 : n.value) == null ? void 0 : c.value),
                          onChange: (v, m) => {
                            d.input.onChange(m);
                          }
                        });
                      }
                    })
                  ]
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((Z = (te = s == null ? void 0 : s.values) == null ? void 0 : te.sip_instl_type) == null ? void 0 : Z.value) === "installments",
                  children: ye("Number of installments", "installments", s, F, M)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((_ = (w = s == null ? void 0 : s.values) == null ? void 0 : w.sip_instl_type) == null ? void 0 : _.value) === "number_of_days",
                  children: ye("Number of weeks", "number_of_days", s, F, M)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((x = (g = s == null ? void 0 : s.values) == null ? void 0 : g.sip_instl_type) == null ? void 0 : x.value) === "number_of_weeks",
                  children: ye("Number of weeks", "number_of_weeks", s, F, M)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((D = (f = s == null ? void 0 : s.values) == null ? void 0 : f.sip_instl_type) == null ? void 0 : D.value) === "number_of_installments",
                  children: ye("Number of instalments", "number_of_installments", s, F, M)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((J = (p = s == null ? void 0 : s.values) == null ? void 0 : p.sip_instl_type) == null ? void 0 : J.value) === "number_of_months",
                  children: ye("Number of months", "number_of_months", s, F, M)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((ce = (ae = s == null ? void 0 : s.values) == null ? void 0 : ae.sip_instl_type) == null ? void 0 : ce.value) === "number_of_quarters",
                  children: ye("Number of quarters", "number_of_quarters", s, F, M)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: ((de = (re = s == null ? void 0 : s.values) == null ? void 0 : re.sip_instl_type) == null ? void 0 : de.value) === "number_of_years",
                  children: ye("Number of years", "number_of_years", s, F, M)
                }),
                /* @__PURE__ */ e.jsx(E, {
                  check: Te((u = s == null ? void 0 : s.values) == null ? void 0 : u.sip_start_date, (l = (i = s == null ? void 0 : s.values) == null ? void 0 : i.frequency) == null ? void 0 : l.value),
                  children: /* @__PURE__ */ e.jsx(_e, {
                    name: "is_generate_first_installment",
                    validateFields: [],
                    type: "checkbox",
                    subscription: {
                      touched: !0,
                      error: !0,
                      value: !0
                    },
                    children: (d) => /* @__PURE__ */ e.jsx(rt, {
                      checked: d.input.checked,
                      variant: "cardType",
                      description: r._({
                        id: "ia1tKm",
                        values: {
                          0: ne ? "Investor" : "You"
                        }
                      }),
                      id: "1",
                      onChange: (n) => {
                        var c, v;
                        ee(n, s.values.sip_start_date, (v = (c = s == null ? void 0 : s.values) == null ? void 0 : c.frequency) == null ? void 0 : v.value), d.input.onChange(n);
                      },
                      children: /* @__PURE__ */ e.jsxs("span", {
                        className: "dark:text-primary-DN110",
                        children: [
                          "Start first installment today",
                          " "
                        ]
                      })
                    })
                  })
                }),
                /* @__PURE__ */ e.jsx("div", {
                  className: "pt-4",
                  children: /* @__PURE__ */ e.jsx(Ae, {
                    variant: "primary",
                    type: "submit",
                    fullwidth: !0,
                    children: "Confirm"
                  })
                })
              ]
            });
          }
        })
      }),
      /* @__PURE__ */ e.jsx(Ce, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Select Folios",
        isOpen: L,
        toggleModal: () => {
          Q(!1);
        },
        contentHeightMax: !1,
        children: /* @__PURE__ */ e.jsx(at, {
          investorProfile: q,
          amc_code: oe,
          handleFolioSelection: (s) => {
            ue(s), y(s), j(s.number), Q(!L);
          },
          handleFolioCreation: () => {
            O && B(!0), j(""), y(null), Q(!L);
          },
          assistedWorkflowPAN: R,
          isPartner: ne
        })
      }),
      /* @__PURE__ */ e.jsx(Ce, {
        customClass: "md:max-w-29",
        variant: "fullPage",
        title: "Folio Details",
        isOpen: P,
        toggleModal: se,
        contentHeightMax: !0,
        children: /* @__PURE__ */ e.jsx(tt, {
          investorProfile: q,
          investment_accounts: Y
        })
      })
    ]
  });
}
function Rt({ scheme: a, investment_account_id: T, onSuccess: C, investorProfile: F, isGroupInvestment: o, isPartner: b, investment_accounts: h, user: ne, holdings: q, assistedWorkflowPAN: oe }) {
  var Z, w;
  const { fpapi: ue } = je(We), { tenantConfig: y } = Ue(), { loggedInPartner: j } = je(Ze), { AppState: { employer: U } } = je(Oe), { isMigratedUser: B } = pe({
    user: ne
  }), [R, Y] = k(!1), [G, N] = k(""), [O, M] = k("initial"), [z, ie] = k(""), [W, le] = k(null), [V, fe] = k(null), [$, S] = k([]), L = (Z = y == null ? void 0 : y.app_constants) == null ? void 0 : Z.plans_mode;
  let Q = yt(a == null ? void 0 : a.sip_frequency_specific_data, L);
  const P = ((w = y == null ? void 0 : y.groupInvestment) == null ? void 0 : w.frequencyOptions) || [], se = Be(() => {
    var _, g, x, f, D, p;
    if (o) {
      const J = (g = (_ = y == null ? void 0 : y.fundList) == null ? void 0 : _.find((ae) => ae.isin === (a == null ? void 0 : a.isin))) == null ? void 0 : g.nfo;
      return ((x = y == null ? void 0 : y.groupInvestment) == null ? void 0 : x.days) && ((f = y == null ? void 0 : y.groupInvestment) == null ? void 0 : f.cutOffDays) && xt({
        salaryDay: (D = y == null ? void 0 : y.groupInvestment) == null ? void 0 : D.days[0],
        salaryCutoffDay: (p = y == null ? void 0 : y.groupInvestment) == null ? void 0 : p.cutOffDays[0],
        NFO: J,
        purchaseDate: null
      });
    }
  }, [
    a
  ]);
  if (o) {
    const _ = [];
    L === "systematic" ? (a != null && a.sip_frequency_specific_data && Object.keys(a == null ? void 0 : a.sip_frequency_specific_data).forEach((g, x) => {
      var f;
      P.includes(g) && _.push({
        id: x,
        label: ((f = ft(g)) == null ? void 0 : f.replace(/_/g, " ")) ?? "",
        value: g
      });
    }), Q = _) : L === "nonSystematic" && (Object.values(Q).forEach((g) => {
      P.includes(g == null ? void 0 : g.value) && _.push(g);
    }), Q = _);
  }
  Ie(() => {
    var _;
    if (!((_ = a == null ? void 0 : a.delivery_mode) != null && _.includes(mt(W, h).toUpperCase()))) {
      M("scheme_not_allowed");
      return;
    }
    !K(W) && t(W), X();
  }, [
    B
  ]);
  const t = (_) => {
    var g, x;
    if (B) {
      const f = (g = q == null ? void 0 : q.folios) == null ? void 0 : g.filter((p) => p.folio_number === (_ == null ? void 0 : _.number)), D = f != null && f.length ? (x = f[0].schemes) == null ? void 0 : x.find((p) => p.isin === (a == null ? void 0 : a.isin)) : [];
      K(D) ? Y(!0) : Y(!1);
    }
  }, ee = ze((_, g) => {
    const x = [];
    return _.forEach((f) => {
      f.amc === g && x.push(f);
    }), x;
  }, []), X = async () => {
    try {
      const _ = await ue.fpClient.mf_folios().fetchAll({
        mf_investment_account: T
      }), x = (await ue.fpClient.master_data().fetchAmcs()).amcs.find((D) => D.amc_id === (a == null ? void 0 : a.amc_id)), f = ee(_.data, x.amc_code);
      S(f), fe(x.amc_code), f.length ? (le(f[0]), ie(f[0].number)) : B && Y(!0);
    } catch (_) {
      window.debug.log(_), ke(_);
    }
  };
  async function ve({ amount: _, frequency: g, installments: x, sip_start_date: f, sip_instl_type: D, number_of_days: p, number_of_weeks: J, number_of_installments: ae, number_of_months: ce, number_of_quarters: re, number_of_years: de, is_generate_first_installment: u }) {
    var i, l, d;
    M("loading"), (D == null ? void 0 : D.value) === "perpetual" ? x = 1200 : p || J || ae || ce || re ? x = p ?? J ?? ae ?? ce ?? re : de && (x = gt(g.value, de));
    try {
      const n = {
        mf_investment_account: T.toString(),
        scheme: (a == null ? void 0 : a.isin) ?? "",
        frequency: g.value,
        folio_number: z,
        amount: +_,
        installment_day: g.value === "daily" ? void 0 : +f,
        number_of_installments: +x,
        systematic: L === "systematic",
        generate_first_installment_now: u,
        auto_generate_installments: !0,
        initiated_by: "investor",
        initiated_via: "mobile_app",
        user_ip: null,
        server_ip: null
      };
      if ((g == null ? void 0 : g.value) === "daily" && (f > (/* @__PURE__ */ new Date()).getDate() + 1 || f < (/* @__PURE__ */ new Date()).getDate())) {
        const v = new Date(Pe(+f, !1)), m = v.setDate(v.getDate() - 1);
        n.activate_after = qe(new Date(m), "yyyy-mm-dd");
      }
      b && j && (n.partner = j), o && !K((i = y == null ? void 0 : y.groupInvestment) == null ? void 0 : i.employerPartner) && ((l = y == null ? void 0 : y.groupInvestment) != null && l.employerPartner[U == null ? void 0 : U.id]) && (n.partner = (d = y == null ? void 0 : y.groupInvestment) == null ? void 0 : d.employerPartner[U == null ? void 0 : U.id]), o && (se != null && se.activate_after) && (n.activate_after = qe(se == null ? void 0 : se.activate_after, "yyy-mm-dd"));
      const c = await ue.fpClient.mf_purchase_plans().create(n);
      u && !o ? (M("initial"), C({
        mf_order: c,
        isGenerateFirstInstallment: u,
        shouldOnboardMigratedUser: R
      })) : (M("initial"), C({
        mf_order: c,
        shouldOnboardMigratedUser: R
      }));
    } catch (n) {
      M("initial"), N(r._({
        id: "HbQ3GE"
      })), ke(n);
    }
  }
  const s = () => /* @__PURE__ */ e.jsx(Jt, {
    frequencyOptions: Q,
    submitStatus: G,
    handleSubmitStatus: (_) => N(_),
    scheme: a,
    placeRecurringOrder: ve,
    isGroupInvestment: o,
    groupRecurringDateObject: se,
    isPartner: b,
    investorProfile: F,
    amcCode: V,
    handleShowOnboardingToMigratedUser: (_) => t(_),
    handleSelectedFolioObj: (_) => le(_),
    handleFolioNumber: (_) => ie(_),
    user: ne,
    handleShouldOnboardMigratedUser: (_) => Y(_),
    assistedWorkflowPAN: oe,
    investmentAccounts: h,
    selectedFolio: z
  });
  return (() => {
    switch (O) {
      case "initial":
        return s();
      case "loading":
        return /* @__PURE__ */ e.jsx(He, {
          variant: "fullpage"
        });
      case "scheme_not_allowed":
        return /* @__PURE__ */ e.jsx(xe, {
          title: r._({
            id: "WddszM"
          }),
          description: r._({
            id: "MlKR3B"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: r._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      default:
        return s();
    }
  })();
}
const Gt = (a, T) => Number(Ve.get()) ? Number(Ve.get()) : a && T ? null : a ? 1 : 0;
function Pt(a) {
  var q, oe, ue, y, j, U, B;
  const { tenantConfig: T } = Ue(), { scheme: C } = a, F = ((ue = (oe = (q = T == null ? void 0 : T.app_constants) == null ? void 0 : q.disabled_orders_mode) == null ? void 0 : oe.purchase) == null ? void 0 : ue.one_time) || !(C != null && C.purchase_allowed), o = ((U = (j = (y = T == null ? void 0 : T.app_constants) == null ? void 0 : y.disabled_orders_mode) == null ? void 0 : j.purchase) == null ? void 0 : U.recurring) || !(C != null && C.sip_allowed), b = ((B = T == null ? void 0 : T.app_constants) == null ? void 0 : B.plans_mode) === "systematic", h = Gt(F, o);
  return (() => {
    const R = () => /* @__PURE__ */ e.jsx(Ot, {
      ...a
    }), Y = () => b ? /* @__PURE__ */ e.jsx(Xt, {
      ...a
    }) : /* @__PURE__ */ e.jsx(Rt, {
      ...a
    }), G = () => /* @__PURE__ */ e.jsx(xe, {
      title: r._({
        id: "JHDCYv"
      }),
      description: r._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    }), N = () => /* @__PURE__ */ e.jsx(xe, {
      title: r._({
        id: "FL10+5"
      }),
      description: r._({
        id: "feiPbW"
      }),
      showDetails: !1,
      showAction: !1
    }), O = () => {
      var M, z, ie, W, le, V;
      return F && o ? [] : F ? [
        {
          title: r._({
            id: "DUs4hi"
          }),
          content: (M = a == null ? void 0 : a.scheme) != null && M.purchase_allowed ? R() : G(),
          disabled: !0,
          disabledMessage: "One time is not allowed in this scheme"
        },
        {
          title: r._({
            id: "1VbNN2"
          }),
          content: (z = a == null ? void 0 : a.scheme) != null && z.sip_allowed ? Y() : N()
        }
      ] : o ? [
        {
          title: r._({
            id: "DUs4hi"
          }),
          content: (ie = a == null ? void 0 : a.scheme) != null && ie.purchase_allowed ? R() : G()
        },
        {
          title: r._({
            id: "1VbNN2"
          }),
          content: (W = a == null ? void 0 : a.scheme) != null && W.sip_allowed ? Y() : N(),
          disabled: !0,
          disabledMessage: "SIP is not allowed in this scheme"
        }
      ] : [
        {
          title: r._({
            id: "DUs4hi"
          }),
          content: (le = a == null ? void 0 : a.scheme) != null && le.purchase_allowed ? R() : G()
        },
        {
          title: r._({
            id: "1VbNN2"
          }),
          content: (V = a == null ? void 0 : a.scheme) != null && V.sip_allowed ? Y() : N()
        }
      ];
    };
    return /* @__PURE__ */ e.jsx(Mt, {
      selected: h,
      onChange: (M) => {
        M === 0 ? Ve.set("0") : Ve.set("1");
      },
      group: O()
    });
  })();
}
function Ca({ scheme: a, switch_out: T, isGroupInvestment: C }) {
  var re, de, u;
  const [F, o] = k("order"), [b, h] = k(null), [ne, q] = k(null), [oe, ue] = k(null), [y, j] = k(null), [U, B] = k(null), [R, Y] = k(!1), [G, N] = k("purchase"), [O, M] = k(null), [z, ie] = k(!1), [W, le] = k(null), { AppState: { employer: V, tenant: fe } } = je(Oe), { fpapi: $ } = je(We), { loggedInUser: S } = je(Ze), { tenantConfig: L } = Ue(), Q = (re = L == null ? void 0 : L.groupInvestment) == null ? void 0 : re.allowedEmailDomains;
  (de = L == null ? void 0 : L.app_constants) != null && de.kyc_refetch_interval_in_months;
  const P = a != null && a.purchase_allowed ? ((u = L == null ? void 0 : L.app_constants) == null ? void 0 : u.plans_mode) || "" : "systematic";
  Ie(() => {
    ve();
  }, [
    L,
    S,
    a
  ]), jt(() => {
    const i = sessionStorage.getItem("product"), l = a == null ? void 0 : a.isin;
    l && (sessionStorage.setItem("product", l), i !== l && _());
  }, []);
  const se = (i) => K(i) ? {
    success: !1,
    stageToRender: "scheme_not_found"
  } : !(i != null && i.active) || !(i != null && i.purchase_allowed) && !(i != null && i.sip_allowed) ? {
    success: !1,
    stageToRender: "purchase_not_allowed"
  } : {
    success: !0
  }, t = ({ tenantConfig: i, user: l, investorProfile: d }) => {
    var c;
    return (Q == null ? void 0 : Q[(c = l == null ? void 0 : l.email_address) == null ? void 0 : c.split("@")[1]]) ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "invalid_email_domain"
    };
  }, ee = ({ kycCheckObj: i, user: l, mfia: d }) => {
    var m, A;
    const n = ((m = l == null ? void 0 : l.annexure) == null ? void 0 : m.isonboarded) === "true";
    return !n || !(d != null && d.primary_investor) ? {
      success: !1,
      stageToRender: "onboard"
    } : n && !i.status ? {
      success: !1,
      stageToRender: "kyc_non_compliant"
    } : (A = d == null ? void 0 : d.folio_defaults) != null && A.payout_bank_account ? {
      success: !0,
      stageToRender: ""
    } : {
      success: !1,
      stageToRender: "bank_verification"
    };
  }, X = async (i) => {
    try {
      const l = await $.fpClient.investor_reports().fetchHoldings({
        investment_account_id: i == null ? void 0 : i.old_id
      });
      le(l);
    } catch (l) {
      window.debug.log(l, "Error");
    }
  }, ve = async () => {
    var i, l, d, n;
    try {
      if (Y(!0), !S) {
        o("auth");
        return;
      }
      const c = se(a);
      if (!c.success) {
        o(c.stageToRender);
        return;
      }
      const v = [
        $.fetchUser(S.profile.preferred_username, fe),
        $.getInvestorProfile((i = S == null ? void 0 : S.profile) == null ? void 0 : i.fp_identifier),
        $.fpClient.mf_investment_accounts().fetchAll({
          investor: (d = (l = S == null ? void 0 : S.profile) == null ? void 0 : l.preferred_username) == null ? void 0 : d.toUpperCase()
        })
      ], [m, A, I] = await Promise.all(v);
      if (!A) {
        window.debug.error("Investor profile is not present"), o("error_page");
        return;
      }
      const H = m == null ? void 0 : m[0], Se = (n = I == null ? void 0 : I.data) == null ? void 0 : n[0];
      if (C) {
        const nt = t({
          tenantConfig: L,
          user: H,
          investorProfile: A
        });
        if (!nt.success) {
          o(nt.stageToRender);
          return;
        }
      }
      const [me, { isMigratedUser: De, migratedMfiaList: bt, migratedFolioList: wt }] = await Promise.all([
        ae(H),
        It(H, $)
      ]);
      De ? (X(Se), await Tt($, H, bt, wt), me.status ? o("order") : o("onboard")) : o("order"), q(H), j(A), B(I == null ? void 0 : I.data), M(me);
    } catch (c) {
      o("error_page"), window.debug.error(c), ke(c);
    } finally {
      Y(!1);
    }
  };
  async function s(i, l) {
    if (K(l)) {
      o("finished"), h(i);
      return;
    }
    Y(!0);
    try {
      const d = await $.fpClient.mf_purchases().fetch(l == null ? void 0 : l.id);
      h(d);
    } catch (d) {
      window.debug.log(d), ke(d);
    }
    o("payment"), Y(!1);
  }
  const te = (i) => {
    K(i) || (C ? o("finished") : (b == null ? void 0 : b.object) === "mf_purchase" && (h(b), o("payment")));
  }, Z = async (i, l) => {
    if (i) {
      if (C)
        o("finished");
      else if (b.object === "mf_purchase_plan") {
        if (!K(l) && P === "nonSystematic") {
          const { emailData: d, phoneData: n } = await $.fetchEmailAndPhone(y);
          await $.fpClient.mf_purchases().update({
            id: l == null ? void 0 : l.id,
            consent: {
              email: d.email,
              isd_code: n.isd,
              mobile: n.number
            }
          });
        }
        s(b, l);
      }
    }
  };
  function w(i) {
    const { mf_order: l, isGenerateFirstInstallment: d, shouldOnboardMigratedUser: n } = i;
    if (n) {
      h(l), o("onboard_migrated_user");
      return;
    }
    if ((l == null ? void 0 : l.object) === "mf_purchase") {
      h(l), o("purchase_confirmation");
      return;
    }
    if ((l == null ? void 0 : l.object) === "mf_purchase_plan") {
      h(l), ie(d), o("purchase_plan_confirmation");
      return;
    }
    (l == null ? void 0 : l.object) === "mf_switch" && (h(l), o("switch_confirmation")), (l == null ? void 0 : l.object) === "mf_switch_plan" && (h(l), o("switch_plan_confirmation"));
  }
  function _() {
    b && (b.object === "mf_purchase" || b.object === "mf_purchase_plan" ? N("purchase") : N("switch")), h({}), o("order");
  }
  function g() {
    C ? o("order") : b.object === "mf_purchase" && $.fpClient.mf_purchases().retry(b == null ? void 0 : b.id).then(() => {
      window.debug.log("Order Retry successful"), o("payment");
    }).catch((i) => {
      _(), ke(i);
    });
  }
  function x({ success: i, isWindowBlocked: l, paymentId: d }) {
    (async () => {
      if (d)
        try {
          const n = await $.fpClient.payments().fetch(Number(d));
          ue(n);
          let c;
          b.plan ? c = await $.fpClient.mf_purchase_plans().fetch(b == null ? void 0 : b.plan) : c = await $.fpClient.mf_purchases().fetch(b.id), h(c);
        } catch (n) {
          window.debug.log(n), ke(n);
        }
      o(i && !l || !i && !l ? "finished" : "finished_error");
    })();
  }
  const f = async (i, l, d) => {
    try {
      Y(!0);
      const n = await ae(d), c = ee({
        kycCheckObj: n,
        user: d,
        mfia: l == null ? void 0 : l[0]
      });
      c.success ? o("order") : o(c.stageToRender), q(d), M(n), j(i), B(l);
    } catch (n) {
      window.debug.error(n), ke(n), o("error_page");
    } finally {
      Y(!1);
    }
  }, D = (i) => {
    h(i), o("finished");
  }, p = () => {
    var i;
    (i = $ == null ? void 0 : $.userAuthService) == null || i.initiateLogout(window.location.href).then((l) => {
      window.location.href = l.userState;
    });
  }, J = async (i) => {
    var l;
    if (U != null && U.length)
      try {
        const d = (l = U == null ? void 0 : U[0]) == null ? void 0 : l.id, n = await $.fpClient.mf_investment_accounts().update({
          id: d,
          folio_defaults: {
            payout_bank_account: i == null ? void 0 : i.id
          }
        }), c = U.map((v) => {
          if (v.id === n.id) {
            const m = v;
            return m.folio_defaults.payout_bank_account = n.folio_defaults.payout_bank_account, m;
          } else
            return v;
        });
        B(c), o("order");
      } catch (d) {
        window.debug.error(d);
      }
  }, ae = async (i) => {
    const n = `${(/* @__PURE__ */ new Date()).toISOString().split(".")[0]}+05:30`;
    return {
      id: "5620fd1f-eb14-442e-b0ee-da96a6c305c0",
      source_ref_id: null,
      pan: "BNBPP9384M",
      entity_details: {
        name: "Tony Soprano"
      },
      status: !0,
      constraints: null,
      sources: [
        {
          name: "kra-cams",
          fetched_at: n
        }
      ],
      created_at: n,
      updated_at: n,
      action: null,
      reason: null
    };
  };
  function ce() {
    switch (F) {
      default:
        return /* @__PURE__ */ e.jsx(He, {
          variant: "fullpage"
        });
      case "auth":
        return /* @__PURE__ */ e.jsx(Zt, {
          redirectURL: window.location.href,
          allowRegistration: !0,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !0,
            kycNonCompliant: !0
          },
          isGroupInvestment: C
        });
      case "onboard":
        return /* @__PURE__ */ e.jsx(Wt, {
          user: ne,
          isGroupInvestment: C || !1,
          setUser: q,
          onBoardingComplete: f,
          isPartner: !1
        });
      case "bank_verification":
        return /* @__PURE__ */ e.jsx(Vt, {
          investorProfile: y,
          handleBankSelection: J,
          customPageSubtitle: r._({
            id: "KM15wf"
          })
        });
      case "order":
        return _t(U) ? /* @__PURE__ */ e.jsx(e.Fragment, {}) : /* @__PURE__ */ e.jsx(Pt, {
          scheme: a,
          switch_out: T,
          investment_account_id: U[0].id,
          investment_accounts: U,
          defaultInvestmentType: G,
          investorProfile: y,
          isGroupInvestment: C,
          onSuccess: w,
          user: ne,
          holdings: W
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(ea, {
          onContinue: () => {
            window.location.href = `${window.location.origin + it}/onboarding?${C ? `isGroupInvestment=${C}` : ""}${C ? `&employer=${V == null ? void 0 : V.id}` : ""}&redirectURL=${encodeURIComponent(`${window.location.origin + it}/${C ? "group-checkout" : "checkout"}/?isin=${a == null ? void 0 : a.isin}${C ? `&employer=${V == null ? void 0 : V.id}` : ""}`)}`;
          }
        });
      case "purchase_confirmation":
        return !K(y) && !K(b) ? /* @__PURE__ */ e.jsx(Yt, {
          investorProfile: y,
          id: b.id,
          showSchemeDetails: !1,
          isGroupInvestment: C,
          onSuccess: te,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "purchase_plan_confirmation":
        return !K(y) && !K(b) ? /* @__PURE__ */ e.jsx(Kt, {
          investorProfile: y,
          id: b.id,
          showSchemeDetails: !1,
          isGroupInvestment: C,
          onSuccess: (i, l) => Z(i, l),
          isFirstInstallmentPayment: z,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "payment":
        return !K(y) && !K(b) ? /* @__PURE__ */ e.jsx(pt, {
          investorProfile: y,
          mf_purchase: b,
          onCompletion: x
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished":
        return !K(a) && !K(b) ? /* @__PURE__ */ e.jsx(Bt, {
          investorProfile: y,
          scheme: a,
          mf_order: b,
          payment: oe,
          isGroupInvestment: C,
          handleRetry: g,
          handleInvestAgain: _
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "finished_error":
        return /* @__PURE__ */ e.jsx(xe, {
          title: r._({
            id: "sS+OUW"
          }),
          showAction: !0,
          actionText: r._({
            id: "RvsoP+"
          }),
          actionHandler: () => {
            o("payment");
          }
        });
      case "purchase_not_allowed":
        return /* @__PURE__ */ e.jsx(xe, {
          title: r._({
            id: "YzYf5v"
          }),
          description: r._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: r._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "scheme_not_found":
        return /* @__PURE__ */ e.jsx(xe, {
          title: r._({
            id: "+PjKpk"
          }),
          description: r._({
            id: "Y0g8oc"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: r._({
            id: "y6Ks4i"
          }),
          actionHandler: () => window.location.href = window.location.origin + "/checkout"
        });
      case "switch_confirmation":
        return !K(a) && !K(b) ? /* @__PURE__ */ e.jsx($t, {
          id: b == null ? void 0 : b.id,
          investorProfile: y,
          showSchemeDetails: !1,
          onSuccess: D,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "switch_plan_confirmation":
        return !K(a) && !K(b) ? /* @__PURE__ */ e.jsx(Ut, {
          id: b.id,
          investorProfile: y,
          onSuccess: D,
          showSchemeDetails: !1,
          action: "confirm"
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "invalid_email_domain":
        return /* @__PURE__ */ e.jsx(xe, {
          title: r._({
            id: "ZXjii8"
          }),
          description: r._({
            id: "mpazQQ"
          }),
          showDetails: !1,
          actionText: r._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: p
        });
      case "invalid_employer":
        return /* @__PURE__ */ e.jsx(xe, {
          title: r._({
            id: "8gLo60"
          }),
          description: r._({
            id: "REewWf"
          }),
          showDetails: !1,
          actionText: r._({
            id: "IO46nP"
          }),
          showAction: !0,
          actionHandler: p
        });
      case "kyc_non_compliant":
        return O ? /* @__PURE__ */ e.jsx(At, {
          kycCheckObject: O,
          customMessage: {
            unverified: r._({
              id: "V2UMyt"
            })
          }
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "error_page":
        return /* @__PURE__ */ e.jsx(xe, {
          title: "Something went wrong",
          description: "",
          showDetails: !1,
          showAction: !1
        });
    }
  }
  return R ? /* @__PURE__ */ e.jsx(He, {
    variant: "fullpage"
  }) : ce();
}
const ea = ({ onContinue: a }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(Nt, {
      title: r._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(Ae, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => a(),
        children: "Continue"
      })
    })
  ]
});
export {
  Ca as I,
  Pt as O
};
