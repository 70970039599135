import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { i as n } from "./index-wI714H_N.js";
import { o as Z, _ as D, a as oe, M as I, F as me, B as q, P as M, e as ae, k as je, r as de, T as xe, S as ce, c as $, i as le, O as _e, D as fe, d as Ce, p as we, C as te } from "./index.esm-G8KyR0tM.js";
import * as Ae from "react";
import Ee, { useMemo as Se, useContext as X, useState as U, useEffect as Q } from "react";
import { AppContext as ye } from "./AppContext.js";
import { u as ke } from "./useAuth-DVvZvJzE.js";
import { F as Y } from "./provider-Diwup2pn.js";
import { ErrorPage as H } from "./ErrorPage.js";
import { w as Fe, s as Ue, l as re } from "./utils-D5LKKDAj.js";
import { f as Ve, u as Ne, a as Re, v as Oe, b as Te, A as We, R as Le, F as J } from "./react-final-form.es-BQCbpI73.js";
import { f as ne, r as ve, B as $e } from "./validators-BArvL7cB.js";
import { r as Be } from "./sentry-CAR5o4wv.js";
import { S as qe } from "./StatusMessage-BwNUc0rO.js";
import { C as De } from "./CopyButton-Cx3WveFv.js";
import { W as Ie } from "./Welcome-C4-GwyyJ.js";
const Me = () => [
  {
    label: n._({
      id: "h/SVxh"
    }),
    value: "kyc_request"
  },
  {
    label: n._({
      id: "lY+AyJ"
    }),
    value: "mf_purchase"
  },
  {
    label: n._({
      id: "M3wIAA"
    }),
    value: "mf_redemption"
  },
  {
    label: n._({
      id: "1CjeQI"
    }),
    value: "mf_switch"
  },
  {
    label: n._({
      id: "RPyRsM"
    }),
    value: "mandate"
  },
  {
    label: n._({
      id: "7x6g1/"
    }),
    value: "payment"
  }
], se = (u) => {
  switch (u) {
    case "kyc_request":
      return [
        {
          label: n._({
            id: "QLAA3o"
          }),
          value: "esign_required"
        },
        {
          label: n._({
            id: "iNOhlR"
          }),
          value: "successful"
        },
        {
          label: n._({
            id: "UT8ybd"
          }),
          value: "rejected"
        },
        {
          label: n._({
            id: "H7+JJU"
          }),
          value: "expired"
        }
      ];
    case "mf_purchase":
      return [
        {
          label: n._({
            id: "pCXpjR"
          }),
          value: "created"
        },
        {
          label: n._({
            id: "o2A1dx"
          }),
          value: "confirmed"
        },
        {
          label: n._({
            id: "GCAwA3"
          }),
          value: "submitted"
        },
        {
          label: n._({
            id: "iNOhlR"
          }),
          value: "successful"
        },
        {
          label: n._({
            id: "2jeBjU"
          }),
          value: "failed"
        },
        {
          label: n._({
            id: "KfUxpU"
          }),
          value: "cancelled"
        },
        {
          label: n._({
            id: "3LgEx9"
          }),
          value: "reversed"
        }
      ];
    case "mf_redemption":
      return [
        {
          label: n._({
            id: "pCXpjR"
          }),
          value: "created"
        },
        {
          label: n._({
            id: "o2A1dx"
          }),
          value: "confirmed"
        },
        {
          label: n._({
            id: "GCAwA3"
          }),
          value: "submitted"
        },
        {
          label: n._({
            id: "iNOhlR"
          }),
          value: "successful"
        },
        {
          label: n._({
            id: "2jeBjU"
          }),
          value: "failed"
        },
        {
          label: n._({
            id: "KfUxpU"
          }),
          value: "cancelled"
        },
        {
          label: n._({
            id: "3LgEx9"
          }),
          value: "reversed"
        }
      ];
    case "mf_switch":
      return [
        {
          label: n._({
            id: "pCXpjR"
          }),
          value: "created"
        },
        {
          label: n._({
            id: "o2A1dx"
          }),
          value: "confirmed"
        },
        {
          label: n._({
            id: "GCAwA3"
          }),
          value: "submitted"
        },
        {
          label: n._({
            id: "iNOhlR"
          }),
          value: "successful"
        },
        {
          label: n._({
            id: "2jeBjU"
          }),
          value: "failed"
        },
        {
          label: n._({
            id: "KfUxpU"
          }),
          value: "cancelled"
        },
        {
          label: n._({
            id: "3LgEx9"
          }),
          value: "reversed"
        }
      ];
    case "mandate":
      return [
        {
          label: n._({
            id: "pCXpjR"
          }),
          value: "created"
        },
        {
          label: n._({
            id: "iZ0VOI"
          }),
          value: "received"
        },
        {
          label: n._({
            id: "GCAwA3"
          }),
          value: "submitted"
        },
        {
          label: n._({
            id: "yknWLT"
          }),
          value: "approved"
        },
        {
          label: n._({
            id: "UT8ybd"
          }),
          value: "rejected"
        },
        {
          label: n._({
            id: "KfUxpU"
          }),
          value: "cancelled"
        }
      ];
    case "payment":
      return [
        {
          label: n._({
            id: "ZzO3pA"
          }),
          value: "pending"
        },
        {
          label: n._({
            id: "d8CKgV"
          }),
          value: "success"
        },
        {
          label: n._({
            id: "2jeBjU"
          }),
          value: "failed"
        },
        {
          label: n._({
            id: "GCAwA3"
          }),
          value: "submitted"
        },
        {
          label: n._({
            id: "221Xe3"
          }),
          value: "initiated"
        },
        {
          label: n._({
            id: "yknWLT"
          }),
          value: "approved"
        },
        {
          label: n._({
            id: "UT8ybd"
          }),
          value: "rejected"
        }
      ];
    default:
      return [];
  }
};
var Xe = ["render", "children", "component"];
function Je(u, r) {
  var t = u.render, s = u.children, i = u.component, l = Z(u, Xe);
  if (i)
    return /* @__PURE__ */ Ae.createElement(i, D({}, l, {
      children: s,
      render: t
    }));
  if (t)
    return t(s === void 0 ? l : D({}, l, {
      children: s
    }));
  if (typeof s != "function")
    throw new Error("Must specify either a render prop, a render function as children, or a component prop to " + r);
  return s(l);
}
var Ze = function(r, t) {
  return r === t || Array.isArray(r) && Array.isArray(t) && r.length === t.length && !r.some(function(s, i) {
    return s !== t[i];
  });
};
function Qe(u) {
  var r = Ee.useRef();
  return r.current || (r.current = u()), r.current;
}
var Ye = ["length"], He = ["meta", "input"], ze = Ve.reduce(function(u, r) {
  return u[r] = !0, u;
}, {}), Ge = function(r, t) {
  var s = t === void 0 ? {} : t, i = s.subscription, l = i === void 0 ? ze : i, h = s.defaultValue, b = s.initialValue, p = s.isEqual, g = p === void 0 ? Ze : p, j = s.validate, f = Ne("useFieldArray"), c = f.mutators, d = !!(c && c.push && c.pop);
  if (!d)
    throw new Error("Array mutators not found. You need to provide the mutators from final-form-arrays to your form");
  var o = Se(function() {
    return (
      // curry the field name onto all mutator calls
      Object.keys(c).reduce(function(L, N) {
        return L[N] = function() {
          for (var R = arguments.length, y = new Array(R), V = 0; V < R; V++)
            y[V] = arguments[V];
          return c[N].apply(c, [r].concat(y));
        }, L;
      }, {})
    );
  }, [r, c]), x = Qe(function() {
    return function(L, N, R) {
      if (j) {
        var y = j(L, N, R);
        if (!y || Array.isArray(y))
          return y;
        var V = [];
        return V[We] = y, V;
      }
    };
  }), C = Re(r, {
    subscription: D({}, l, {
      length: !0
    }),
    defaultValue: h,
    initialValue: b,
    isEqual: g,
    validate: x,
    format: function(N) {
      return N;
    }
  }), A = C.meta, T = A.length, W = Z(A, Ye), G = C.input, K = Z(C, He), P = function(N) {
    for (var R = T || 0, y = 0; y < R; y++)
      N(r + "[" + y + "]", y);
  }, ee = function(N) {
    for (var R = T || 0, y = [], V = 0; V < R; V++)
      y.push(N(r + "[" + V + "]", V));
    return y;
  };
  return {
    fields: D({
      name: r,
      forEach: P,
      length: T || 0,
      map: ee
    }, o, K, {
      value: G.value
    }),
    meta: W
  };
}, Ke = "3.1.3", Pe = ["name", "subscription", "defaultValue", "initialValue", "isEqual", "validate"], ea = {
  "final-form": Oe,
  "react-final-form": Te,
  "react-final-form-arrays": Ke
}, he = function(r) {
  var t = r.name, s = r.subscription, i = r.defaultValue, l = r.initialValue, h = r.isEqual, b = r.validate, p = Z(r, Pe), g = Ge(t, {
    subscription: s,
    defaultValue: i,
    initialValue: l,
    isEqual: h,
    validate: b
  }), j = g.fields, f = g.meta;
  return Je(D({
    fields: j,
    meta: D({}, f, {
      __versions: ea
    })
  }, p), "FieldArray(" + t + ")");
};
function ie() {
  return ie = Object.assign || function(u) {
    for (var r = 1; r < arguments.length; r++) {
      var t = arguments[r];
      for (var s in t)
        Object.prototype.hasOwnProperty.call(t, s) && (u[s] = t[s]);
    }
    return u;
  }, ie.apply(this, arguments);
}
function B(u, r, t, s) {
  t[s] = ie({}, u[r], {
    name: s,
    // prevent functions from being overwritten
    // if the newFields[newKey] does not exist, it will be created
    // when that field gets registered, with its own change/blur/focus callbacks
    change: u[s] && u[s].change,
    blur: u[s] && u[s].blur,
    focus: u[s] && u[s].focus,
    lastFieldState: void 0
    // clearing lastFieldState forces renotification
  }), t[s].change || delete t[s].change, t[s].blur || delete t[s].blur, t[s].focus || delete t[s].focus;
}
var z = function(r) {
  return r.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}, be = function(r, t, s) {
  var i = r[0], l = r[1], h = r[2], b = s.changeValue;
  b(t, i, function(j) {
    var f = [].concat(j || []);
    return f.splice(l, 0, h), f;
  });
  var p = new RegExp("^" + z(i) + "\\[(\\d+)\\](.*)"), g = {};
  Object.keys(t.fields).forEach(function(j) {
    var f = p.exec(j);
    if (f) {
      var c = Number(f[1]);
      if (c >= l) {
        var d = i + "[" + (c + 1) + "]" + f[2];
        B(t.fields, j, g, d);
        return;
      }
    }
    g[j] = t.fields[j];
  }), t.fields = g;
}, aa = function(r, t, s) {
  var i = r[0], l = r[1], h = s.changeValue;
  h(t, i, function(b) {
    return b ? [].concat(b, l) : l;
  });
}, ta = function(r, t, s) {
  var i = r[0], l = r[1], h = r[2], b = s.changeValue;
  if (l !== h) {
    b(t, i, function(d) {
      var o = [].concat(d || []), x = o[l];
      return o.splice(l, 1), o.splice(h, 0, x), o;
    });
    var p = {}, g = new RegExp("^" + z(i) + "\\[(\\d+)\\](.*)"), j, f, c;
    l > h ? (j = h, f = l, c = 1) : (j = l, f = h, c = -1), Object.keys(t.fields).forEach(function(d) {
      var o = g.exec(d);
      if (o) {
        var x = Number(o[1]);
        if (x === l) {
          var C = i + "[" + h + "]" + o[2];
          B(t.fields, d, p, C);
          return;
        }
        if (j <= x && x <= f) {
          var A = i + "[" + (x + c) + "]" + o[2];
          B(t.fields, d, p, A);
          return;
        }
      }
      p[d] = t.fields[d];
    }), t.fields = p;
  }
}, ue = function(r, t, s) {
  var i = r[0], l = r[1], h = s.changeValue, b = s.getIn, p = s.setIn, g;
  h(t, i, function(c) {
    if (!c)
      return c;
    var d = [].concat(c);
    return g = d[l], d.splice(l, 1), d.length > 0 ? d : void 0;
  });
  var j = new RegExp("^" + z(i) + "\\[(\\d+)\\](.*)"), f = {};
  return Object.keys(t.fields).forEach(function(c) {
    var d = j.exec(c);
    if (d) {
      var o = Number(d[1]);
      if (o === l) {
        if (c === i + "[" + l + "]") {
          var x = "formState.submitErrors." + i, C = b(t, x);
          Array.isArray(C) && (C.splice(l, 1), t = p(t, x, C));
        }
        return;
      }
      if (o > l) {
        var A = i + "[" + (o - 1) + "]" + d[2];
        B(t.fields, c, f, A);
        return;
      }
    }
    f[c] = t.fields[c];
  }), t.fields = f, g;
}, ra = function(r, t, s) {
  var i = r[0], l = s.getIn, h = l(t.formState.values, i);
  return h && h.length > 0 ? ue([i, h.length - 1], t, s) : void 0;
}, na = function(r, t, s) {
  var i = r[0], l = r[1], h = s.changeValue;
  h(t, i, function(b) {
    return b ? [].concat(b, [l]) : [l];
  });
}, sa = function(r, t) {
  for (var s = 0, i = r.length - 1, l = 0; s <= i; ) {
    if (l = Math.floor((s + i) / 2), r[l] === t)
      return l;
    r[l] > t ? i = l - 1 : s = l + 1;
  }
  return ~s;
}, la = function(r, t, s) {
  var i = r[0], l = r[1], h = s.changeValue;
  if (l.length === 0)
    return [];
  var b = [].concat(l);
  b.sort();
  for (var p = b.length - 1; p > 0; p -= 1)
    b[p] === b[p - 1] && b.splice(p, 1);
  var g = [];
  h(t, i, function(c) {
    if (g = l.map(function(C) {
      return c && c[C];
    }), !c)
      return c;
    for (var d = [].concat(c), o = b.length - 1; o >= 0; o -= 1) {
      var x = b[o];
      d.splice(x, 1);
    }
    return d.length > 0 ? d : void 0;
  });
  var j = new RegExp("^" + z(i) + "\\[(\\d+)\\](.*)"), f = {};
  return Object.keys(t.fields).forEach(function(c) {
    var d = j.exec(c);
    if (d) {
      var o = Number(d[1]), x = sa(b, o);
      if (x >= 0)
        return;
      if (o > b[0]) {
        var C = i + "[" + (o - ~x) + "]" + d[2];
        B(t.fields, c, f, C);
        return;
      }
    }
    f[c] = t.fields[c];
  }), t.fields = f, g;
}, ia = function(r, t, s) {
  var i = r[0];
  return ue([i, 0], t, s);
}, oa = function(r, t, s) {
  var i = r[0], l = r[1], h = r[2], b = s.changeValue;
  if (l !== h) {
    b(t, i, function(f) {
      var c = [].concat(f || []), d = c[l];
      return c[l] = c[h], c[h] = d, c;
    });
    var p = i + "[" + l + "]", g = i + "[" + h + "]", j = {};
    Object.keys(t.fields).forEach(function(f) {
      if (f.substring(0, p.length) === p) {
        var c = f.substring(p.length), d = g + c;
        B(t.fields, f, j, d);
      } else if (f.substring(0, g.length) === g) {
        var o = f.substring(g.length), x = p + o;
        B(t.fields, f, j, x);
      } else
        j[f] = t.fields[f];
    }), t.fields = j;
  }
}, ca = function(r, t, s) {
  var i = r[0], l = r[1];
  return be([i, 0, l], t, s);
}, ua = function(r, t, s) {
  var i = r[0], l = r[1], h = r[2], b = s.changeValue;
  b(t, i, function(p) {
    var g = [].concat(p || []);
    return g.splice(l, 1, h), g;
  });
}, da = {
  insert: be,
  concat: aa,
  move: ta,
  pop: ra,
  push: na,
  remove: ue,
  removeBatch: la,
  shift: ia,
  swap: oa,
  unshift: ca,
  update: ua
};
const va = ({ onCompletion: u, handleGoBack: r }) => {
  const { fpapi: t } = X(Y), [s, i] = U(!1), [l, h] = U(null), [b, p] = U([]), [g, j] = U("show_form"), [f, c] = U(""), [d, o] = U([]), [x, C] = U(""), A = Me();
  Q(() => {
    K();
  }, []);
  const T = (a, m) => a.map((v) => b.includes(`${m}.${v.value}`) ? {
    ...v,
    checked: !0,
    disabled: !0
  } : {
    ...v,
    checked: !1,
    disabled: !1
  }), W = (a, m) => a.map((v) => ({
    label: v.label,
    value: m ?? ""
  })), G = {
    eventType: A[0],
    eventStatus: T(se(A[0].value), A[0].value),
    eventUrls: W(se(A[0].value)),
    useCommonUrl: !0
  }, K = async () => {
    i(!0);
    try {
      const a = await t.fpClient.notification_webhooks().fetchAll();
      a != null && a.data && p(a == null ? void 0 : a.data.map((m) => m.event)), c(""), j("show_form");
    } catch {
      c(n._({
        id: "TqDAdG"
      })), j("show_error");
    } finally {
      i(!1);
    }
  }, P = (a) => a.some((m) => m.checked && !m.disabled) ? void 0 : "Please select atleast one option", ee = async ({ eventType: a, eventStatus: m, eventUrls: v }) => {
    i(!0);
    try {
      const E = [];
      m.forEach((F, ge) => {
        F.checked && !F.disabled && E.push({
          url: v[ge].value,
          event: `${a.value}.${F.value}`,
          status: "enabled"
        });
      });
      const S = await Promise.allSettled(E.map((F) => t.fpClient.notification_webhooks().create(F))), _ = N(E, S), w = _ == null ? void 0 : _.filter((F) => F == null ? void 0 : F.statusCode), k = _ == null ? void 0 : _.filter((F) => !(F != null && F.statusCode)), O = k.concat(w);
      w != null && w.length && !(k != null && k.length) ? C("We could not enable notifications for events") : w != null && w.length && (k != null && k.length) && C("We could not enable notifications for some events"), o(O), j("show_status");
    } catch (E) {
      h(n._({
        id: "fnNyuh"
      })), window.debug.error(E), Be(E);
    } finally {
      i(!1);
    }
  }, L = (a) => {
    var E, S, _, w, k, O;
    const m = (_ = (S = (E = a == null ? void 0 : a.reason) == null ? void 0 : E.data) == null ? void 0 : S.error) == null ? void 0 : _.message, v = (O = (k = (w = a == null ? void 0 : a.reason) == null ? void 0 : w.data) == null ? void 0 : k.errors) == null ? void 0 : O.event;
    return m ? Array(m) : v;
  }, N = (a, m) => m.map((v, E) => {
    let S, _, w, k, O, F = null;
    return v.status === "fulfilled" ? (S = v.value.id, _ = v.value.event, w = v.value.url, k = v.status) : (_ = a[E].event, w = a[E].url, k = v.status, O = v.reason.status, F = L(v)), {
      id: S,
      event: _,
      url: w,
      status: k === "fulfilled" ? "Enabled" : "Disabled",
      statusCode: O,
      errorReason: F
    };
  }), R = (a) => /* @__PURE__ */ e.jsxs(ce, {
    children: [
      /* @__PURE__ */ e.jsx("div", {
        className: "mb-3 pr-2 pl-2",
        children: /* @__PURE__ */ e.jsx($, {
          label: n._({
            id: "BvgXJD"
          }),
          value: a == null ? void 0 : a.event
        })
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-3 pr-2 pl-2",
        children: [
          /* @__PURE__ */ e.jsx($, {
            label: n._({
              id: "e64E2+"
            }),
            value: a == null ? void 0 : a.url
          }),
          /* @__PURE__ */ e.jsx($, {
            label: n._({
              id: "8TAzDt"
            }),
            value: /* @__PURE__ */ e.jsx(le, {
              variant: "outline",
              message: a == null ? void 0 : a.status,
              status: (a == null ? void 0 : a.status) === "Enabled" ? "success" : "error"
            })
          })
        ]
      })
    ]
  }), y = (a) => /* @__PURE__ */ e.jsx(_e, {
    view: "error",
    tableData: [
      {
        label: "Event",
        value: a == null ? void 0 : a.event,
        colSpan: "basis-full"
      },
      {
        label: "Notification status",
        value: /* @__PURE__ */ e.jsx(le, {
          variant: "outline",
          message: a == null ? void 0 : a.status,
          status: (a == null ? void 0 : a.status) === "Enabled" ? "success" : "error"
        }),
        colSpan: "basis-full"
      },
      {
        label: "URL",
        value: a == null ? void 0 : a.url,
        colSpan: "basis-full"
      },
      a != null && a.errorReason ? {
        label: "Reason",
        value: a == null ? void 0 : a.errorReason,
        colSpan: "basis-full"
      } : ""
    ]
  }), V = (a) => a.every((v) => (v == null ? void 0 : v.statusCode) && (v == null ? void 0 : v.statusCode) >= 500 && (v == null ? void 0 : v.statusCode) < 599 || v.statusCode === null), pe = () => {
    let a = !1;
    return d != null && d.length && (a = d.some((m) => m == null ? void 0 : m.statusCode)), a ? "Alert" : "successInfo";
  };
  return s ? /* @__PURE__ */ e.jsx(oe, {
    variant: "fullpage"
  }) : (() => {
    switch (g) {
      case "show_form":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(M, {
              title: n._({
                id: "YQCYEZ"
              }),
              icon: r ? "Back" : null,
              onClick: r
            }),
            /* @__PURE__ */ e.jsx(Le, {
              onSubmit: ee,
              subscription: {
                submitting: !0,
                values: !0,
                pristine: !0,
                errors: !0,
                touched: !0
              },
              initialValues: G,
              mutators: {
                mutateField: Fe,
                ...da
              },
              children: (a) => /* @__PURE__ */ e.jsxs("form", {
                onSubmit: a.handleSubmit,
                children: [
                  /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ e.jsx(ae, {
                        message: n._({
                          id: "BvgXJD"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(J, {
                        name: "eventType",
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        validate: ne(ve),
                        children: (m) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                          children: [
                            /* @__PURE__ */ e.jsx(je, {
                              name: m.input.name,
                              value: m.input.value,
                              onChange: (v) => {
                                const E = se(v == null ? void 0 : v.value);
                                a.form.mutators.mutateField("eventStatus", T(E, v == null ? void 0 : v.value)), a.form.mutators.mutateField("eventUrls", W(E, a.values.useCommonUrl ? a.values.eventUrls[0].value : "")), a.form.mutators.mutateField("useCommonUrl", !0), h(null), m.input.onChange(v);
                              },
                              options: A
                            }),
                            m.meta.error && m.meta.touched && /* @__PURE__ */ e.jsx(I, {
                              type: "error",
                              variant: "field",
                              title: m.meta.error
                            })
                          ]
                        })
                      })
                    ]
                  }),
                  a.values.eventStatus.length > 0 && /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-8",
                    children: [
                      /* @__PURE__ */ e.jsx(ae, {
                        message: n._({
                          id: "NMkyRg"
                        })
                      }),
                      /* @__PURE__ */ e.jsx(he, {
                        name: "eventStatus",
                        validate: ne(P),
                        subscription: {
                          touched: !0,
                          error: !0,
                          value: !0
                        },
                        children: ({ fields: m, meta: v }) => {
                          const E = m.map((S, _) => /* @__PURE__ */ e.jsx("div", {
                            className: "mb-2",
                            children: /* @__PURE__ */ e.jsx(J, {
                              name: `${S}.checked`,
                              component: "input",
                              type: "checkbox",
                              subscription: {
                                touched: !0,
                                error: !0,
                                value: !0
                              },
                              children: (w) => /* @__PURE__ */ e.jsx(de, {
                                id: `${S}.checked`,
                                checked: w.input.checked,
                                onChange: (k) => {
                                  w.input.onChange(k);
                                },
                                disabled: a.values.eventStatus[_].disabled,
                                children: /* @__PURE__ */ e.jsx("p", {
                                  children: a.values.eventStatus[_].label
                                })
                              })
                            })
                          }, _));
                          return v.error && v.touched && E.push(/* @__PURE__ */ e.jsx(I, {
                            type: "error",
                            variant: "field",
                            title: v.error
                          }, "error")), E;
                        }
                      })
                    ]
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-2",
                    children: /* @__PURE__ */ e.jsx(J, {
                      name: "useCommonUrl",
                      component: "input",
                      type: "checkbox",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: (m) => /* @__PURE__ */ e.jsx(de, {
                        id: "useCommonUrl",
                        checked: m.input.checked,
                        onChange: (v) => {
                          a.values.eventStatus.forEach((E, S) => {
                            a.form.mutators.mutateField(`eventUrls[${S}].value`, "");
                          }), m.input.onChange(v);
                        },
                        children: /* @__PURE__ */ e.jsx("p", {
                          className: "font-medium",
                          children: "Use one URL for all selected status"
                        })
                      })
                    })
                  }),
                  a.values.eventUrls.length > 0 && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-8",
                    children: /* @__PURE__ */ e.jsx(he, {
                      name: "eventUrls",
                      subscription: {
                        touched: !0,
                        error: !0,
                        value: !0
                      },
                      children: ({ fields: m }) => m.map((E, S) => {
                        if (!(a.values.useCommonUrl && S !== 0 || !a.values.useCommonUrl && (a.values.eventStatus[S].disabled || !a.values.eventStatus[S].checked)))
                          return a.values.useCommonUrl || `${a.values.eventUrls[S].label}`, /* @__PURE__ */ e.jsxs("div", {
                            className: "mb-2",
                            children: [
                              /* @__PURE__ */ e.jsx(ae, {
                                message: n._({
                                  id: "O96iWX"
                                })
                              }),
                              /* @__PURE__ */ e.jsx(J, {
                                name: `${E}.value`,
                                validate: ne(ve, $e(n._({
                                  id: "KqZB69"
                                }))),
                                subscription: {
                                  touched: !0,
                                  error: !0,
                                  value: !0
                                },
                                validateFields: [],
                                children: (_) => /* @__PURE__ */ e.jsxs(e.Fragment, {
                                  children: [
                                    /* @__PURE__ */ e.jsx(xe, {
                                      name: _.input.name,
                                      value: _.input.value,
                                      onChange: (w) => {
                                        _.input.onChange(w.target.value);
                                      },
                                      onBlur: () => {
                                        const w = _.input.value.trim();
                                        a.values.useCommonUrl && a.values.eventStatus.forEach((k, O) => {
                                          S !== O && a.form.mutators.mutateField(`eventUrls[${O}].value`, w);
                                        }), _.input.onChange(w), _.input.onBlur();
                                      },
                                      placeholder: n._({
                                        id: "yZm2Ni"
                                      }),
                                      status: _.meta.error && _.meta.touched ? "error" : void 0
                                    }),
                                    _.meta.error && _.meta.touched && /* @__PURE__ */ e.jsx(I, {
                                      type: "error",
                                      variant: "field",
                                      title: _.meta.error
                                    })
                                  ]
                                })
                              })
                            ]
                          }, S);
                      })
                    })
                  }),
                  l && /* @__PURE__ */ e.jsx(I, {
                    type: "error",
                    variant: "field",
                    title: l
                  }),
                  /* @__PURE__ */ e.jsx(q, {
                    type: "submit",
                    fullwidth: !0,
                    onClick: () => {
                      if (a.errors) {
                        const m = Object.keys(a.errors);
                        m.length && Ue(m[0]);
                      }
                    },
                    customClass: "mb-8",
                    children: n._({
                      id: "H/E6fZ"
                    })
                  })
                ]
              })
            })
          ]
        });
      case "show_error":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(M, {
              title: n._({
                id: "YQCYEZ"
              }),
              icon: r ? "Back" : null,
              onClick: r
            }),
            /* @__PURE__ */ e.jsx(H, {
              title: f,
              description: n._({
                id: "F05jOJ"
              }),
              showAction: !1,
              actionText: n._({
                id: "Oem4yT"
              })
            })
          ]
        });
      case "show_status":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsxs(qe, {
              iconName: pe(),
              title: "Notification webhooks created",
              customClass: "mb-28",
              children: [
                (d == null ? void 0 : d.length) > 0 ? d == null ? void 0 : d.map((a, m) => a != null && a.statusCode ? /* @__PURE__ */ e.jsx("div", {
                  children: y(a)
                }, m) : /* @__PURE__ */ e.jsxs("div", {
                  children: [
                    " ",
                    R(a),
                    " "
                  ]
                }, a == null ? void 0 : a.id)) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
                x && /* @__PURE__ */ e.jsx(I, {
                  type: "alert",
                  variant: "flash",
                  title: n._({
                    id: "rxK9E7"
                  }),
                  customClass: "mb-5"
                })
              ]
            }),
            /* @__PURE__ */ e.jsxs(me, {
              children: [
                /* @__PURE__ */ e.jsx(q, {
                  fullwidth: !0,
                  customClass: "mb-4",
                  onClick: () => u(),
                  children: n._({
                    id: "JgAlm3"
                  })
                }),
                V(d) ? /* @__PURE__ */ e.jsx(q, {
                  variant: "secondary",
                  fullwidth: !0,
                  onClick: () => j("show_form"),
                  children: n._({
                    id: "Oem4yT"
                  })
                }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
              ]
            })
          ]
        });
    }
  })();
}, ha = () => {
  const { fpapi: u } = X(Y), [r, t] = U(!1), [s, i] = U([]), [l, h] = U("list_secrets"), [b, p] = U("");
  Q(() => {
    g();
  }, []);
  const g = async () => {
    t(!0);
    try {
      const c = await u.fpClient.notification_webhook_secrets().fetchAll();
      c != null && c.data && (h("list_secrets"), p(""), i(c.data.reverse()));
    } catch {
      p(n._({
        id: "fti0kj"
      })), h("show_error");
    } finally {
      t(!1);
    }
  }, j = async () => {
    t(!0), await u.fpClient.notification_webhook_secrets().create(), g();
  };
  return r ? /* @__PURE__ */ e.jsx(oe, {
    variant: "fullpage"
  }) : (() => {
    switch (l) {
      case "list_secrets":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(M, {
              title: n._({
                id: "WSDPAr"
              })
            }),
            /* @__PURE__ */ e.jsxs("div", {
              className: "pb-20",
              children: [
                /* @__PURE__ */ e.jsx(fe, {
                  type: "text",
                  text: /* @__PURE__ */ e.jsxs(e.Fragment, {
                    children: [
                      n._({
                        id: "t+8tCQ"
                      }),
                      /* @__PURE__ */ e.jsx("a", {
                        href: "https://docs.fintechprimitives.com/upcoming/beta/webhook-implementation/#setup-webhook-notification",
                        className: "pl-1 text-size-14",
                        target: "_blank",
                        rel: "noreferrer",
                        children: "Learn more"
                      })
                    ]
                  })
                }),
                /* @__PURE__ */ e.jsx(q, {
                  variant: "secondary",
                  fullwidth: !0,
                  customClass: "mb-6",
                  onClick: j,
                  children: n._({
                    id: "etBh63"
                  })
                }),
                s.length > 0 && s.map((c) => /* @__PURE__ */ e.jsx(ce, {
                  children: /* @__PURE__ */ e.jsxs("div", {
                    className: "mb-3 pr-2 pl-2",
                    children: [
                      /* @__PURE__ */ e.jsx("div", {
                        className: "flex justify-end",
                        children: /* @__PURE__ */ e.jsx(De, {
                          value: c.secret
                        })
                      }),
                      /* @__PURE__ */ e.jsx($, {
                        label: n._({
                          id: "R2386v"
                        }),
                        value: c.secret
                      })
                    ]
                  })
                }, c.id))
              ]
            })
          ]
        });
      case "show_error":
        return /* @__PURE__ */ e.jsx(H, {
          title: b,
          description: n._({
            id: "uHsknt"
          }),
          showAction: !0,
          actionText: n._({
            id: "Oem4yT"
          }),
          actionHandler: () => {
            window.location.reload();
          }
        });
    }
  })();
}, ma = ({ handleGoBack: u }) => {
  const [r, t] = U(!1), [s, i] = U("initial"), { fpapi: l } = X(Y), [h, b] = U({});
  Q(() => {
    p();
  }, []);
  const p = async () => {
    try {
      t(!0);
      const o = await l.fpClient.notification_webhooks().fetchAll();
      if (o != null && o.data) {
        const x = /* @__PURE__ */ new Set();
        o == null || o.data.forEach((A) => x.add(A.event.split(".")[0]));
        const C = {};
        Array.from(x).forEach((A) => C[A] = []), o == null || o.data.forEach((A) => C[A.event.split(".")[0]].push(A)), b(C);
      } else
        i("no_notification");
      t(!1);
    } catch (o) {
      window.debug.log(o, "Error"), t(!1);
    }
  }, g = (o) => {
    const x = o.split("_");
    return re(x.join(" "));
  }, j = (o) => {
    const x = o.split("_");
    return re(x.join(" "));
  }, f = (o) => {
    var x;
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(te, {
          span: 6,
          children: /* @__PURE__ */ e.jsx($, {
            customClass: "p-2",
            label: "Event status",
            value: j((x = o == null ? void 0 : o.event) == null ? void 0 : x.split(".")[1])
          })
        }),
        /* @__PURE__ */ e.jsx(te, {
          span: 6,
          children: /* @__PURE__ */ e.jsx($, {
            customClass: "p-2",
            label: "Notification status",
            value: /* @__PURE__ */ e.jsx(le, {
              variant: "outline",
              message: `${re(o == null ? void 0 : o.status)}`,
              status: (o == null ? void 0 : o.status) === "enabled" ? "success" : "error"
            })
          })
        }),
        /* @__PURE__ */ e.jsx(te, {
          span: 12,
          children: /* @__PURE__ */ e.jsx($, {
            customClass: "p-2",
            label: "URL",
            value: o == null ? void 0 : o.url
          })
        })
      ]
    });
  }, c = () => {
    var o, x;
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(M, {
          title: n._({
            id: "+QrEDh"
          }),
          icon: u ? "Back" : null,
          onClick: u
        }),
        /* @__PURE__ */ e.jsx(fe, {
          type: "text",
          text: n._({
            id: "PhgUXJ"
          })
        }),
        (o = Object.keys(h)) != null && o.length ? /* @__PURE__ */ e.jsx("div", {
          className: "mb-8",
          children: /* @__PURE__ */ e.jsx(Ce, {
            variant: "selectable",
            selected: 0,
            children: (x = Object.keys(h)) == null ? void 0 : x.map((C, A) => {
              var T;
              return /* @__PURE__ */ e.jsx(ce, {
                header: {
                  title: /* @__PURE__ */ e.jsx("span", {
                    className: "neutral-dark-0 font-open-sans text-size-18  inline-block font-semibold",
                    children: n._({
                      id: "moFZxg",
                      values: {
                        0: g(C)
                      }
                    })
                  })
                },
                children: (T = h[C]) == null ? void 0 : T.map((W) => /* @__PURE__ */ e.jsx("div", {
                  className: "border-b last:border-b-0 ",
                  children: /* @__PURE__ */ e.jsx(we, {
                    children: f(W)
                  })
                }, W == null ? void 0 : W.id))
              }, A);
            })
          })
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {})
      ]
    });
  };
  return r ? /* @__PURE__ */ e.jsx(oe, {
    variant: "fullpage"
  }) : (() => {
    switch (s) {
      case "initial":
        return c();
      case "no_notification":
        return /* @__PURE__ */ e.jsx(H, {
          title: n._({
            id: "8DTD29"
          })
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}, Va = () => {
  const { AppState: { showWelcomePage: u }, setShowWelcomePage: r } = X(ye), { isFpapiLoading: t } = X(Y), s = ke(), [i, l] = U("");
  Q(() => {
    if (u)
      return l("welcome");
    s.isLoading || (s.isAuthenticated ? t || l("show_secrets") : s.login());
  }, [
    s.isAuthenticated,
    s.isLoading,
    u,
    t
  ]);
  const h = () => [
    {
      step: 1,
      content: n._({
        id: "jcT8lf"
      })
    },
    {
      step: 2,
      content: n._({
        id: "NXHaIH"
      })
    }
  ], b = () => {
    r(!1);
  }, p = () => {
    l("view_events");
  }, g = () => {
    switch (i) {
      case "welcome":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(M, {
              title: n._({
                id: "UC7V7l"
              })
            }),
            /* @__PURE__ */ e.jsx(Ie, {
              onAck: b,
              welcomeSteps: h()
            })
          ]
        });
      case "show_secrets":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(ha, {}),
            /* @__PURE__ */ e.jsx(me, {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "flex justify-evenly",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-2",
                    children: /* @__PURE__ */ e.jsx(q, {
                      variant: "secondary",
                      fullwidth: !0,
                      onClick: () => {
                        l("create");
                      },
                      children: n._({
                        id: "Q3kKhu"
                      })
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-2",
                    children: /* @__PURE__ */ e.jsx(q, {
                      variant: "secondary",
                      fullwidth: !0,
                      onClick: () => {
                        l("view_events");
                      },
                      children: n._({
                        id: "JgAlm3"
                      })
                    })
                  })
                ]
              })
            })
          ]
        });
      case "create":
        return /* @__PURE__ */ e.jsx(va, {
          onCompletion: p,
          handleGoBack: () => {
            l("show_secrets");
          }
        });
      case "view_events":
        return /* @__PURE__ */ e.jsx(ma, {
          handleGoBack: () => {
            l("show_secrets");
          }
        });
      case "error":
        return /* @__PURE__ */ e.jsx(H, {
          title: n._({
            id: "4KeZDP"
          }),
          description: n._({
            id: "kWdyq+"
          }),
          showDetails: !1,
          showAction: !1
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: g()
  });
};
export {
  Va as NotificationWebhook
};
