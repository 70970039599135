import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useContext as q, useState as k, useEffect as F, Suspense as I, lazy as D, useCallback as V } from "react";
import { a as g, P as le, B as $ } from "./index.esm-G8KyR0tM.js";
import { ErrorPage as X } from "./ErrorPage.js";
import { W as de } from "./Welcome-C4-GwyyJ.js";
import { AppContext as fe } from "./AppContext.js";
import { u as pe } from "./useConfiguration-B-lyaBB9.js";
import { F as Q } from "./provider-Diwup2pn.js";
import { LoginContext as me } from "./LoginContext.js";
import { z as M, N as ue } from "./utils-D5LKKDAj.js";
import { i as l } from "./index-wI714H_N.js";
import { r as S } from "./sentry-CAR5o4wv.js";
import { S as J } from "./StatusMessage-BwNUc0rO.js";
import { A as _e } from "./Auth-BeDpKAhM.js";
import { N as ye } from "./NominationConsent-Dqr4RmOS.js";
const he = /* @__PURE__ */ D(() => import("./KycDetails-DE2xMRe6.js")), we = /* @__PURE__ */ D(() => import("./KycFileUploads-DHlyuhNf.js")), ge = /* @__PURE__ */ D(() => import("./KycIpv-BHqPONQF.js")), ke = /* @__PURE__ */ D(() => import("./KycEsign-Cb0-VX9L.js")), xe = /* @__PURE__ */ D(() => import("./KycPoa-CabXey34.js")), Ce = ({ user: f, investor: d, onKycComplete: u }) => {
  const { fpapi: v } = q(Q), [s, K] = k(null), [p, C] = k(null), [R, E] = k(!0), L = (n) => {
    var a, w;
    if (n)
      if ((a = n == null ? void 0 : n.requirements) != null && a.fields_needed.length) {
        const b = (w = n == null ? void 0 : n.requirements) == null ? void 0 : w.fields_needed, U = {
          kyc_details: [
            "aadhaar_number",
            "marital_status",
            "father_name_or_spouse_name",
            "mother_name",
            "gender",
            "occupation_type",
            "residential_status"
          ],
          kyc_poa: [
            "address"
          ],
          kyc_file_uploads: [
            "identity_proof",
            "photo",
            "bank_account",
            "signature"
          ],
          kyc_ipv: [
            "geolocation",
            "ipv_video"
          ]
        };
        for (const [P, z] of Object.entries(U))
          for (const A of z)
            if (b.includes(A))
              return P;
      } else
        return "kyc_esign";
    else return "kyc_details";
  }, x = (n) => {
    var a;
    if (n)
      switch ((a = n == null ? void 0 : n.verification) == null ? void 0 : a.status) {
        case "submitted":
          u(s);
          break;
        case "successful":
          u(s);
          break;
        case "rejected":
          window.debug.error(n == null ? void 0 : n.verification), C("kyc_rejected");
          break;
        default:
          return;
      }
    else
      return;
  };
  F(() => {
    (async () => {
      var n;
      if (d)
        try {
          const a = await v.fpClient.kyc_requests().fetchAll({
            pan: d == null ? void 0 : d.pan
          }), w = (n = a == null ? void 0 : a.data) == null ? void 0 : n[0];
          w && (x(w), K(w));
          const b = L(w);
          C(String(b)), E(!1);
        } catch (a) {
          E(!1), window.debug.error(a), S(a);
        }
      else
        window.debug.error("Investor object not available");
    })();
  }, []), F(() => {
    (async () => {
      if (s && Number(/* @__PURE__ */ new Date()) > Number(new Date(s == null ? void 0 : s.expires_at)))
        try {
          const n = await v.fpClient.kyc_requests().create(s);
          K(n);
        } catch (n) {
          window.debug.error(n), S(n);
        }
    })();
  }, [
    s
  ]);
  const j = (n) => {
    const a = L(n);
    C(String(a)), K(n);
  }, h = () => {
    switch (p) {
      case "kyc_details":
        return /* @__PURE__ */ e.jsx(I, {
          fallback: /* @__PURE__ */ e.jsx(g, {
            variant: "fullpage"
          }),
          children: /* @__PURE__ */ e.jsx(he, {
            investor: d,
            onCompletion: j,
            kycDetails: s
          })
        });
      case "kyc_poa":
        return /* @__PURE__ */ e.jsx(I, {
          fallback: /* @__PURE__ */ e.jsx(g, {
            variant: "fullpage"
          }),
          children: /* @__PURE__ */ e.jsx(xe, {
            investor: d,
            kycDetails: s,
            onCompletion: j
          })
        });
      case "kyc_file_uploads":
        return /* @__PURE__ */ e.jsx(I, {
          fallback: /* @__PURE__ */ e.jsx(g, {
            variant: "fullpage"
          }),
          children: /* @__PURE__ */ e.jsx(we, {
            user: f,
            investor: d,
            kycDetails: s,
            onCompletion: j
          })
        });
      case "kyc_ipv":
        return /* @__PURE__ */ e.jsx(I, {
          fallback: /* @__PURE__ */ e.jsx(g, {
            variant: "fullpage"
          }),
          children: /* @__PURE__ */ e.jsx(ge, {
            kycDetails: s,
            onCompletion: j
          })
        });
      case "kyc_esign":
        return /* @__PURE__ */ e.jsxs(I, {
          fallback: /* @__PURE__ */ e.jsx(g, {
            variant: "fullpage"
          }),
          children: [
            /* @__PURE__ */ e.jsx(ke, {
              kycDetails: s
            }),
            ";"
          ]
        });
      case "kyc_rejected":
        return /* @__PURE__ */ e.jsx(X, {
          title: "KYC request rejected",
          description: "Your KYC request has been rejected. Kindly retry",
          showAction: !0,
          actionText: "Create a new KYC request",
          actionHandler: () => {
            C("kyc_details");
          }
        });
      default:
        return /* @__PURE__ */ e.jsx(g, {
          variant: "fullpage"
        });
    }
  };
  return /* @__PURE__ */ e.jsx("div", {
    children: R ? /* @__PURE__ */ e.jsx(g, {
      variant: "fullpage"
    }) : h()
  });
}, ze = () => {
  const { fpapi: f } = q(Q), { tenantConfig: d } = pe(), { loggedInUser: u } = q(me), { AppState: { showWelcomePage: v, tenant: s }, setShowWelcomePage: K } = q(fe), [p, C] = k(null), [R, E] = k(null), [L, x] = k(null), [j, h] = k(null), [n, a] = k(!0), [w, b] = k(O), G = [
    {
      step: 1,
      content: l._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: l._({
        id: "xwuL3C"
      })
    }
  ], B = d == null ? void 0 : d.test_isin, T = V(async (t) => {
    var _;
    const c = M(t == null ? void 0 : t.annexure);
    let o = null;
    if (c != null && c.kycCheckId)
      try {
        const m = await f.fpClient.kyc_checks().fetch(c == null ? void 0 : c.kycCheckId);
        if (Math.abs(Number(/* @__PURE__ */ new Date()) - Number(new Date(m.updated_at))) > 24 * 60 * 60 * 1e3)
          try {
            o = {
              ...await f.fpClient.kyc_checks().refetch(c == null ? void 0 : c.kycCheckId)
            };
          } catch (y) {
            throw window.debug.error(y), y;
          }
        else
          o = {
            ...m
          };
      } catch (m) {
        throw window.debug.error(m), m;
      }
    else if (t.id) {
      const m = t.id;
      try {
        o = {
          ...await f.fpClient.kyc_checks().create({
            pan: t != null && t.username ? (_ = t == null ? void 0 : t.username) == null ? void 0 : _.toUpperCase() : null
          })
        };
        const y = {
          ...c,
          kycCheckId: o.id
        };
        try {
          const i = await f.updateUser(m, y, null, s);
          if (!i)
            throw window.debug.error("USER UPDATION ERROR"), i;
        } catch (i) {
          throw window.debug.error(i), i;
        }
      } catch (r) {
        throw window.debug.error(r), r;
      }
    }
    return o;
  }, [
    u
  ]), N = V(() => {
    sessionStorage.getItem("nomination_consent") && sessionStorage.removeItem("nomination_consent"), window.location.href = `/checkout/?isin=${B}&mtm_campaign=staging`;
  }, []), U = (t) => {
    if (t.status)
      return "kyc_compliant";
    {
      const c = {
        unavailable: 1,
        rejected: 1,
        legacy: 1,
        unknown: 1
      };
      return t.action === "disallowed" || !c[t == null ? void 0 : t.reason] ? "kyc_disallowed" : "kyc_allowed";
    }
  };
  F(() => {
    (async () => {
      var t, c;
      if (!u)
        x("login"), a(!1);
      else
        try {
          const o = await f.fetchUser(u.profile.preferred_username, s);
          C(o == null ? void 0 : o[0]);
          try {
            const _ = await T(o == null ? void 0 : o[0]);
            switch (U(_)) {
              case "kyc_compliant":
                h(l._({
                  id: "IN6RGB"
                }));
                break;
              case "kyc_disallowed":
                h(l._({
                  id: "ZG6cou"
                }));
                break;
              case "kyc_allowed": {
                const r = M((t = o == null ? void 0 : o[0]) == null ? void 0 : t.annexure);
                if ([
                  "personal_details",
                  "financial_details",
                  "banking_details",
                  "contact_details"
                ].includes(r == null ? void 0 : r.completed_step))
                  h(l._({
                    id: "6P2M5W"
                  }));
                else if ((r == null ? void 0 : r.isonboarded) === "true")
                  N();
                else
                  try {
                    const i = await f.getInvestorProfile((c = u == null ? void 0 : u.profile) == null ? void 0 : c.fp_identifier);
                    E(i), x("nomination_consent"), h(null);
                  } catch (i) {
                    h(l._({
                      id: "2nIs2h"
                    })), window.debug.error(i), S(i);
                  }
                break;
              }
              default:
                break;
            }
          } catch (_) {
            a(!1), window.debug.error(_), h(l._({
              id: "9LHX94"
            })), S(_);
          }
        } catch (o) {
          h(l._({
            id: "9y3Wx0"
          })), window.debug.error(o), S(o);
        } finally {
          a(!1);
        }
    })();
  }, [
    u,
    T,
    N
  ]);
  const P = async (t) => {
    var c, o, _, m;
    try {
      const r = t.id, y = M(t == null ? void 0 : t.annexure), i = t == null ? void 0 : t.profile, ae = y == null ? void 0 : y.preferred_bank_account, ce = [
        f.fpClient.email_addresses().fetchAll({
          profile: i
        }),
        f.fpClient.phone_numbers().fetchAll({
          profile: i
        }),
        f.fpClient.addresses().fetchAll({
          profile: i
        })
      ], [se, W, Y] = await Promise.all(ce), Z = (c = d == null ? void 0 : d.groupInvestment) == null ? void 0 : c.allowedEmailDomains, H = Z ? ue(se, Z) : null, re = {
        primary_investor: i,
        holding_pattern: "single",
        folio_defaults: {
          communication_email_address: H == null ? void 0 : H.id,
          communication_mobile_number: (o = W == null ? void 0 : W.data[0]) == null ? void 0 : o.id,
          communication_address: (m = (_ = Y == null ? void 0 : Y.data) == null ? void 0 : _[0]) == null ? void 0 : m.id,
          payout_bank_account: ae
        }
      };
      await f.fpClient.mf_investment_accounts().create(re);
      const ie = {
        isonboarded: !0
      };
      await f.updateUser(r, ie, null, s) ? x("mfia_complete") : (window.debug.error("Cannot update the details"), x("mfia_failed"));
    } catch (r) {
      window.debug.error(r), x("mfia_failed"), S(r);
    }
  }, z = () => {
    P(p);
  }, A = () => {
    P(p);
  }, ee = ({ preferred_bank_account: t }) => {
    sessionStorage.setItem("nomination_consent", "true"), p != null && p.annexure && !("preferred_bank_account" in p.annexure) && C({
      ...p,
      annexure: {
        ...p.annexure,
        preferred_bank_account: `${t}`
      }
    }), x("kyc");
  };
  F(() => {
    b(O());
  }, [
    v
  ]);
  function O() {
    return v ? "welcome" : "kyc";
  }
  function te() {
    K(!1), b("kyc");
  }
  const ne = () => {
    switch (L) {
      case "login":
        return /* @__PURE__ */ e.jsx(_e, {
          redirectURL: window.location.href,
          allowRegistration: !1
        });
      case "nomination_consent":
        return /* @__PURE__ */ e.jsx(ye, {
          investorProfile: R,
          onSuccess: ee
        });
      case "kyc":
        return /* @__PURE__ */ e.jsx(Ce, {
          user: p,
          investor: R,
          onKycComplete: z
        });
      case "mfia_complete":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(J, {
              title: l._({
                id: "yyI4HG"
              }),
              description: l._({
                id: "JqaeZ1"
              }),
              iconName: "successInfo"
            }),
            /* @__PURE__ */ e.jsx($, {
              fullwidth: !0,
              onClick: N,
              children: l._({
                id: "1nrhR6"
              })
            })
          ]
        });
      case "mfia_failed":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(J, {
              title: l._({
                id: "5KrDgN"
              }),
              description: l._({
                id: "lb1l0c"
              }),
              iconName: "Error"
            }),
            /* @__PURE__ */ e.jsx($, {
              fullwidth: !0,
              onClick: A,
              children: l._({
                id: "Oem4yT"
              })
            })
          ]
        });
      default:
        return /* @__PURE__ */ e.jsx(g, {
          variant: "fullpage"
        });
    }
  };
  function oe() {
    return w === "welcome" ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx(de, {
        onAck: te,
        welcomeSteps: G
      })
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: n ? /* @__PURE__ */ e.jsx(g, {
        variant: "fullpage"
      }) : j ? /* @__PURE__ */ e.jsx(X, {
        title: "Error",
        description: j
      }) : ne()
    });
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(le, {
        title: l._({
          id: "kyVIEo"
        })
      }),
      oe()
    ]
  });
};
export {
  ze as ManageAssistedKyc
};
