import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useContext as s, useState as n, useEffect as d } from "react";
import { AppContext as S } from "./AppContext.js";
import { F } from "./provider-Diwup2pn.js";
import { LoginContext as N } from "./LoginContext.js";
import { P as _, a as v } from "./index.esm-G8KyR0tM.js";
import { r as W } from "./sentry-CAR5o4wv.js";
import { i as a } from "./index-wI714H_N.js";
import { W as E } from "./Welcome-C4-GwyyJ.js";
import { N as I } from "./NomineeListing-BXjnn754.js";
import { A as R } from "./Auth-BeDpKAhM.js";
function H() {
  const { fpapi: r } = s(F), { loggedInUser: o } = s(N), { AppState: { showWelcomePage: f }, setShowWelcomePage: u } = s(S), [x, m] = n(c), [g, h] = n(null), [j, l] = n(!0), [w, P] = n([]), A = [
    {
      step: 1,
      content: a._({
        id: "NsNdUa"
      })
    },
    {
      step: 2,
      content: a._({
        id: "5ukLfI"
      })
    }
  ];
  d(() => {
    var p;
    r && r.getInvestorProfile((p = o == null ? void 0 : o.profile) == null ? void 0 : p.fp_identifier).then(async (t) => {
      if (t) {
        h(t);
        const i = await r.fpClient.related_parties().fetchAll({
          profile: t == null ? void 0 : t.id
        });
        P((i == null ? void 0 : i.data) ?? []);
      }
      l(!1);
    }).catch((t) => {
      l(!1), W(t);
    });
  }, []), d(() => {
    m(c());
  }, [
    f
  ]);
  function c() {
    return f ? "welcome" : "nominee";
  }
  function L() {
    u(!1), m("nominee");
  }
  function C() {
    return x === "welcome" ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx(E, {
        onAck: L,
        welcomeSteps: A
      })
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: j ? /* @__PURE__ */ e.jsx(v, {
        variant: "fullpage"
      }) : o ? /* @__PURE__ */ e.jsx(I, {
        investorProfile: g,
        nomineeList: w
      }) : /* @__PURE__ */ e.jsx(R, {
        redirectURL: window.location.href,
        allowRegistration: !1,
        showTestPan: {
          onboarded: !0
        }
      })
    });
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(_, {
        title: a._({
          id: "xBj6DA"
        })
      }),
      C()
    ]
  });
}
export {
  H as ManageNominees
};
