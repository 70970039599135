import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useState as l, useContext as D, useEffect as P } from "react";
import { a as I, D as w, S as y, c as h, n as M, P as V } from "./index.esm-G8KyR0tM.js";
import { AppContext as B } from "./AppContext.js";
import { LoginContext as J } from "./LoginContext.js";
import { F as K } from "./provider-Diwup2pn.js";
import { f as T, j as W, O as m, i as z } from "./utils-D5LKKDAj.js";
import { i } from "./index-wI714H_N.js";
import { r as G } from "./sentry-CAR5o4wv.js";
import { ErrorPage as Q } from "./ErrorPage.js";
import { W as Y } from "./Welcome-C4-GwyyJ.js";
import { P as Z } from "./PartnerLogin-BdkPSQka.js";
function U() {
  const [x, c] = l(!1), [g, d] = l("initial"), [t, j] = l(null), [u, _] = l([]), { fpapi: p } = D(K), [E, L] = l({}), F = [
    "purchase",
    "sip",
    "transfer_in",
    "dividend_reinvestment",
    "bonus",
    "switch_in"
  ], S = [
    "redemption",
    "transfer_out",
    "dividend_payout",
    "switch_out"
  ];
  P(() => {
    async function s() {
      var a;
      try {
        c(!0);
        const r = await p.tenantFpClient.other_reports().fetch_transaction_type_wise_amount_summary(), f = {};
        r.data.rows.forEach((n) => {
          f[n[0]] = n[1];
        });
        const b = await p.tenantFpClient.other_reports().fund_scheme_category_wise_aum_summary(), o = (a = b == null ? void 0 : b.data) == null ? void 0 : a.rows.filter((n) => n[0] !== null || n[1] !== null);
        if (o.some((n) => n[0] === "liquid") || o.push([
          "liquid",
          0
        ]), o.some((n) => n[0] === "others") || o.push([
          "others",
          0
        ]), !T(o)) {
          const n = A(o.map((C) => C[1])), H = o.map((C) => W(C[0])), R = {
            labels: N(o),
            datasets: [
              {
                backgroundColor: [
                  "#2955D9",
                  "#009666",
                  "#E44632",
                  "#FA8C16",
                  "#FFD976"
                ],
                borderColor: "rgba(255, 255, 255, 0.4)",
                data: n,
                customLegendLabel: H,
                customLegendValue: $(o)
              }
            ]
          };
          L(R);
        }
        j(f), _(o), c(!1);
      } catch (r) {
        window.debug.error(r), c(!1), d("error"), G(r);
      }
    }
    s();
  }, []);
  const A = (s) => {
    const a = s.reduce((r, f) => r + f, 0);
    return s.map((r) => r * 100 / a);
  }, N = (s) => A(s.map((r) => r[1])).map((r) => r > 0 ? [
    `${r.toFixed(2)}%`
  ] : [
    ""
  ]), $ = (s) => s.map((a) => `₹ ${m(a[1], 2)}`);
  function q() {
    let s = 0;
    return F.forEach((a) => {
      s = s + ((t == null ? void 0 : t[a]) || 0);
    }), s;
  }
  function O() {
    let s = 0, a = 0;
    return F.forEach((r) => {
      s = s + ((t == null ? void 0 : t[r]) || 0);
    }), S.forEach((r) => {
      a = a + ((t == null ? void 0 : t[r]) || 0);
    }), s - a;
  }
  function v() {
    return /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(w, {
          type: "text",
          text: i._({
            id: "4kJ5xt"
          })
        }),
        /* @__PURE__ */ e.jsx(y, {
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(h, {
                  label: i._({
                    id: "QpsNNH"
                  }),
                  value: `₹ ${m(q(), 2)}`
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(h, {
                  label: i._({
                    id: "O7mMJ1"
                  }),
                  value: `₹ ${m(O(), 2)}`
                })
              })
            ]
          })
        }),
        /* @__PURE__ */ e.jsx(w, {
          type: "text",
          text: i._({
            id: "tZzKh5"
          }),
          customClass: "mb-4"
        }),
        u.length && !z(E) ? /* @__PURE__ */ e.jsx(y, {
          children: /* @__PURE__ */ e.jsx("div", {
            className: "-mr-2 mt-2 flex items-center",
            children: /* @__PURE__ */ e.jsx(M, {
              chartData: E,
              innerRadius: 80
            })
          })
        }) : /* @__PURE__ */ e.jsx(e.Fragment, {}),
        /* @__PURE__ */ e.jsx(w, {
          type: "text",
          text: i._({
            id: "l5BCaF"
          }),
          customClass: "mb-4"
        }),
        /* @__PURE__ */ e.jsx(y, {
          children: /* @__PURE__ */ e.jsxs("div", {
            className: "-mr-2 -ml-2 flex flex-wrap items-center",
            children: [
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(h, {
                  label: i._({
                    id: "1jB4oY"
                  }),
                  value: t != null && t.purchase ? `₹ ${m(t.purchase, 2)}` : "-"
                })
              }),
              /* @__PURE__ */ e.jsx("div", {
                className: "mb-3 basis-1/2 pr-2 pl-2",
                children: /* @__PURE__ */ e.jsx(h, {
                  label: i._({
                    id: "m6q2w2"
                  }),
                  value: t != null && t.sip ? `₹ ${m(t.sip, 2)}` : "-"
                })
              })
            ]
          })
        })
      ]
    });
  }
  function k() {
    switch (g) {
      case "initial":
        return v();
      case "error":
        return /* @__PURE__ */ e.jsx(Q, {
          title: i._({
            id: "KnE1fM"
          }),
          description: i._({
            id: "uHsknt"
          }),
          showAction: !0,
          actionText: i._({
            id: "Oem4yT"
          }),
          actionHandler: () => {
            d("initial");
          }
        });
      default:
        return v();
    }
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: x ? /* @__PURE__ */ e.jsx(I, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: k()
    })
  });
}
function pe() {
  const { loggedInPartner: x } = D(J), { AppState: { showWelcomePage: c }, setShowWelcomePage: g } = D(B), [d, t] = l(u), j = [
    {
      step: 1,
      content: "Partner login"
    },
    {
      step: 2,
      content: "View portfolio"
    }
  ];
  P(() => {
    t(u());
  }, [
    c
  ]);
  function u() {
    return c ? "welcome" : "portfolio";
  }
  function _() {
    g(!1), t("portfolio");
  }
  function p() {
    return d === "welcome" ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: /* @__PURE__ */ e.jsx(Y, {
        onAck: _,
        welcomeSteps: j
      })
    }) : /* @__PURE__ */ e.jsx(e.Fragment, {
      children: x ? /* @__PURE__ */ e.jsx(U, {}) : /* @__PURE__ */ e.jsx(Z, {
        context: window.location.href
      })
    });
  }
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(V, {
        title: "Portfolio"
      }),
      p()
    ]
  });
}
export {
  pe as PartnerPortfolio
};
