import { j as t } from "./jsx-runtime-DmTjM30f.js";
import { useContext as f, useState as r, useLayoutEffect as L, useEffect as E } from "react";
import { AppContext as y } from "./AppContext.js";
import { F as M } from "./provider-Diwup2pn.js";
import { LoginContext as k } from "./LoginContext.js";
import { P as m, a as I } from "./index.esm-G8KyR0tM.js";
import { r as N } from "./sentry-CAR5o4wv.js";
import { i as T } from "./utils-D5LKKDAj.js";
import { i as o } from "./index-wI714H_N.js";
import { W } from "./Welcome-C4-GwyyJ.js";
import { M as z } from "./MandateListing-D8XscYA_.js";
import { ErrorPage as K } from "./ErrorPage.js";
import { A as R } from "./Auth-BeDpKAhM.js";
function X({ mandate_id: l }) {
  const { fpapi: i } = f(M), { loggedInUser: e } = f(k), { AppState: { showWelcomePage: c }, setShowWelcomePage: x } = f(y), [g, p] = r(u), [h, j] = r({}), [P, v] = r({}), [S, s] = r(!1), A = [
    {
      step: 1,
      content: o._({
        id: "PMSqVk"
      })
    },
    {
      step: 2,
      content: o._({
        id: "k8TSk3"
      })
    }
  ];
  L(() => {
    var a;
    i && e && (s(!0), i.getInvestorProfile((a = e == null ? void 0 : e.profile) == null ? void 0 : a.fp_identifier).then((n) => {
      n && (j(n), i.fpClient.mf_investment_accounts().fetchAll({
        investor: n.pan
      }).then((C) => {
        const d = C.data.filter((F) => F.primary_investor === e.profile.fp_identifier);
        if (!d.length) {
          s(!1);
          return;
        }
        v(d[0]), s(!1);
      }));
    }).catch((n) => {
      s(!1), N(n);
    }));
  }, []), E(() => {
    p(u());
  }, [
    c
  ]);
  function u() {
    return c ? "welcome" : "mandate";
  }
  function _(a) {
    x(!1), p("mandate");
  }
  function w() {
    return g === "welcome" ? /* @__PURE__ */ t.jsxs(t.Fragment, {
      children: [
        /* @__PURE__ */ t.jsx(m, {
          title: o._({
            id: "zNK1+a"
          })
        }),
        /* @__PURE__ */ t.jsx(W, {
          onAck: _,
          welcomeSteps: A
        })
      ]
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: S ? /* @__PURE__ */ t.jsx(I, {
        variant: "fullpage"
      }) : e ? T(P) ? /* @__PURE__ */ t.jsx(K, {
        title: "No mandates found",
        description: "You have not setup any mandates",
        showDetails: !1
      }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
        children: [
          " ",
          /* @__PURE__ */ t.jsx(m, {
            title: o._({
              id: "zNK1+a"
            })
          }),
          /* @__PURE__ */ t.jsx(z, {
            investorProfile: h,
            mandate_id: l,
            status: [
              ""
            ],
            limit: null,
            isPartner: !1
          })
        ]
      }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
        children: [
          /* @__PURE__ */ t.jsx(m, {
            title: o._({
              id: "zNK1+a"
            })
          }),
          /* @__PURE__ */ t.jsx(R, {
            redirectURL: `/status/mandate?id=${l}`,
            allowRegistration: !1,
            showTestPan: {
              onboarded: !0
            }
          })
        ]
      })
    });
  }
  return /* @__PURE__ */ t.jsx(t.Fragment, {
    children: w()
  });
}
export {
  X as MandateStatus
};
