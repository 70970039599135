import { j as s } from "./jsx-runtime-DmTjM30f.js";
import { i as a } from "./index-wI714H_N.js";
import { S as p, c as r, B as o, D as x } from "./index.esm-G8KyR0tM.js";
import { m as c } from "./MandateListing-D8XscYA_.js";
import { C as u } from "./utils-D5LKKDAj.js";
import { u as j } from "./useConfiguration-B-lyaBB9.js";
function N({ mandate: i, handleMandateModalChange: l }) {
  var t;
  const { banks: n, tenantConfig: e } = j(), m = e == null ? void 0 : e.fallbackLogo;
  return /* @__PURE__ */ s.jsx(s.Fragment, {
    children: i && i.bank ? /* @__PURE__ */ s.jsx(p, {
      children: /* @__PURE__ */ s.jsxs("div", {
        className: "-mr-2 -ml-2 flex flex-wrap items-center justify-between",
        children: [
          /* @__PURE__ */ s.jsx("div", {
            className: "mb-3 w-full pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(r, {
              label: "Bank",
              value: i ? `${i.bank.bank_name} - ****${i.bank.number.slice(-4)}` : "-",
              logo: n[(t = i.bank.ifsc) == null ? void 0 : t.slice(0, 4)] || m
            })
          }),
          /* @__PURE__ */ s.jsx("div", {
            className: "pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(r, {
              label: "Amount limit",
              value: i ? `₹${u(i.mandate_limit)}` : "-"
            })
          }),
          /* @__PURE__ */ s.jsx("div", {
            className: "pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(r, {
              label: "Authorization",
              value: i ? c[i.mandate_status].value : "-",
              icon: i ? c[i.mandate_status].icon : "",
              iconPlacement: "left"
            })
          }),
          /* @__PURE__ */ s.jsx("div", {
            className: "pr-2 pl-2",
            children: /* @__PURE__ */ s.jsx(o, {
              variant: "text",
              onClick: l,
              children: `${a._({
                id: "ShFidp"
              })}`
            })
          })
        ]
      })
    }) : /* @__PURE__ */ s.jsx(x, {
      type: "borderedNotes",
      text: "Setup mandate",
      children: /* @__PURE__ */ s.jsx(o, {
        variant: "text",
        onClick: l,
        children: `${a._({
          id: "JW7jyx"
        })}`
      })
    })
  });
}
export {
  N as M
};
