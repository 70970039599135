import { j as d } from "./jsx-runtime-DmTjM30f.js";
import { useEffect as m } from "react";
import { a as R } from "./index.esm-G8KyR0tM.js";
function U() {
  return m(() => {
    var o, e, r, n, a, t;
    if (window.opener) {
      let i = "";
      if ((o = window == null ? void 0 : window.opener) != null && o.location && (window != null && window.location)) {
        const s = new URL((r = (e = window == null ? void 0 : window.opener) == null ? void 0 : e.location) == null ? void 0 : r.href), c = new URLSearchParams((a = (n = window == null ? void 0 : window.opener) == null ? void 0 : n.location) == null ? void 0 : a.search), w = new URLSearchParams((t = window == null ? void 0 : window.location) == null ? void 0 : t.search);
        for (const [p, f] of w)
          c.set(p, f);
        s.search = c.toString(), i = s.toString();
      }
      window.opener.postMessage({
        postBackURL: i,
        source: "fpapps_window_redirect"
      });
    }
  }), /* @__PURE__ */ d.jsx(R, {
    variant: "fullpage"
  });
}
export {
  U as Redirection
};
