import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useContext as K, useState as i, useEffect as B, useRef as ke, useCallback as he } from "react";
import { a as $, S as ot, c as G, y as ct, B as ne, z as De, A as Te, E as X, P as q, D as Pe, T as Ae, I as Me, M as _e, b as lt, G as dt, e as Ie, k as Le } from "./index.esm-G8KyR0tM.js";
import { AppContext as Fe } from "./AppContext.js";
import { u as ge } from "./useConfiguration-B-lyaBB9.js";
import { F as ee } from "./provider-Diwup2pn.js";
import { i as c } from "./index-wI714H_N.js";
import { r as T } from "./sentry-CAR5o4wv.js";
import { C as Y } from "./StatusMessage-BwNUc0rO.js";
import { O as mt, I as ut } from "./Investment-p7i9Nrkc.js";
import { LoginContext as He } from "./LoginContext.js";
import { i as A, k as ft, n as ht, C as pt, ao as Ge, ap as ze, o as Be, _ as le, aq as pe, ar as de, z as _t, as as gt, M as xt, B as Ne, j as $e, f as wt, at as jt } from "./utils-D5LKKDAj.js";
import { c as vt } from "./checkMigratedUser-D-TLMNz-.js";
import { p as bt } from "./partiallyOnboardMigratedUser-Do2b2Ofg.js";
import { ErrorPage as J } from "./ErrorPage.js";
import { P as yt } from "./PartnerLogin-BdkPSQka.js";
import { K as St } from "./KycCheckStatus-CuPG6c--.js";
import { O as Ct } from "./OtpForm-BTd51sDR.js";
import { P as se } from "./Product-Jr0LJCwm.js";
import { O as kt } from "./OrderStatus-BFxH2wTa.js";
import { FolioDetails as It } from "./FolioDetails.js";
import { W as Lt } from "./Welcome-C4-GwyyJ.js";
import { FundList as Nt } from "./FundList.js";
function Ot({ createdOrder: t, orderRequest: f, investorProfile: o, handleOrderOTPConfirmation: _ }) {
  const { fpapi: g } = K(ee), [b, u] = i(!1), y = async () => {
    u(!0);
    try {
      const m = await g.fpClient.email_addresses().fetchAll({
        profile: o == null ? void 0 : o.id
      }), a = m == null ? void 0 : m.data[0], h = await g.fpClient.phone_numbers().fetchAll({
        profile: o == null ? void 0 : o.id
      }), r = h == null ? void 0 : h.data[0];
      return u(!1), {
        emailData: a,
        phoneData: r
      };
    } catch (m) {
      window.debug.log(m), T(m);
    }
  }, P = async () => {
    const m = A(t) ? f : t, { emailData: a, phoneData: h } = await y();
    if (u(!0), (m == null ? void 0 : m.object) === "mf_redemption")
      try {
        const r = await g.fpClient.mf_redemptions().update({
          id: m.id,
          state: "confirmed",
          consent: {
            email: a.email,
            isd_code: h.isd,
            mobile: h.number
          }
        });
        _(r);
      } catch (r) {
        window.debug.log(r), T(r);
      }
    else if ((m == null ? void 0 : m.object) === "mf_switch")
      try {
        const r = await g.fpClient.mf_switches().update({
          id: m.id,
          state: "confirmed",
          consent: {
            email: a.email,
            isd_code: h.isd,
            mobile: h.number
          }
        });
        _(r);
      } catch (r) {
        window.debug.log(r), T(r);
      }
    else if ((m == null ? void 0 : m.object) === "mf_switch_plan") {
      let r = {
        ...f
      };
      delete r.object, r = {
        ...r,
        consent: {
          email: a.email,
          isd_code: h.isd,
          mobile: h.number
        }
      };
      try {
        const j = await g.fpClient.mf_switch_plans().create(r);
        _(j);
      } catch (j) {
        window.debug.log(j), T(j);
      }
    } else if ((m == null ? void 0 : m.object) === "mf_redemption_plan") {
      let r = {
        ...f
      };
      delete r.object, r = {
        ...r,
        consent: {
          email: a.email,
          isd_code: h.isd,
          mobile: h.number
        }
      };
      try {
        const j = await g.fpClient.mf_redemption_plans().create(r);
        _(j);
      } catch (j) {
        window.debug.log(j), T(j);
      }
    }
    u(!1);
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: b ? /* @__PURE__ */ e.jsx($, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsx(Ct, {
      handleSubmit: P,
      pageTitle: c._({
        id: "s/ORP1"
      }),
      investorProfile: o
    })
  });
}
function Et({ createdOrder: t, orderRequest: f, investorProfile: o, onConfirmation: _ }) {
  const { tenantConfig: g, amcs: b } = ge(), u = g == null ? void 0 : g.fallbackLogo, y = g == null ? void 0 : g.showSchemeLogo, [P, m] = i(!1), [a, h] = i(null), [r, j] = i("initial"), { fpapi: E } = K(ee), L = A(t) ? f : t, S = `mr-2 bg-primary-N10 rounded-full p-0.5  w-6 h-6
  md:w-9 md:h-9 flex-shrink-0 flex justify-center items-center mt-1`;
  B(() => {
    async function O() {
      m(!0);
      try {
        const l = await E.fpClient.master_data().fetchScheme(L.switch_out_scheme), N = y ? {
          ...l,
          schemeLogo: b[l == null ? void 0 : l.amc_id] || u
        } : {
          ...l
        };
        h(N), j("initial"), m(!1);
      } catch (l) {
        j("error"), T(l);
      }
    }
    O();
  }, [
    E,
    t,
    f,
    L,
    y,
    b,
    u
  ]);
  const F = async (O) => {
    _(O);
  };
  return P ? /* @__PURE__ */ e.jsx($, {
    variant: "fullpage"
  }) : (() => {
    switch (r) {
      case "initial":
        return /* @__PURE__ */ e.jsxs("div", {
          style: {
            marginTop: "-20px"
          },
          children: [
            /* @__PURE__ */ e.jsx(ot, {
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "-ml-2 -mr-2 flex flex-wrap items-center",
                children: [
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-full pl-2 pr-2",
                    children: /* @__PURE__ */ e.jsx(G, {
                      label: c._({
                        id: "6Xqu8f"
                      }),
                      value: a && /* @__PURE__ */ e.jsxs("div", {
                        className: "flex",
                        children: [
                          (a == null ? void 0 : a.schemeLogo) && /* @__PURE__ */ e.jsx("img", {
                            src: a == null ? void 0 : a.schemeLogo,
                            alt: "logo",
                            className: S
                          }),
                          /* @__PURE__ */ e.jsx("div", {
                            className: "flex flex-col",
                            children: /* @__PURE__ */ e.jsx("div", {
                              children: `${ft(a.name)}`
                            })
                          })
                        ]
                      })
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(G, {
                      label: c._({
                        id: "fezFD7"
                      }),
                      value: ht(a == null ? void 0 : a.investment_option)
                    })
                  }),
                  L.amount && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(G, {
                      label: c._({
                        id: "KqeLJ1"
                      }),
                      value: L.amount ? `₹ ${pt(L.amount, 2)}` : "-"
                    })
                  }),
                  L.units && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(G, {
                      label: c._({
                        id: "6ssn5V"
                      }),
                      value: L.units ? L.units : "-"
                    })
                  }),
                  L.folio_number && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(G, {
                      label: c._({
                        id: "cXcPd1"
                      }),
                      value: L.folio_number ? L.folio_number : "-"
                    })
                  }),
                  !L.amount && !L.units && /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2 pr-2 pl-2",
                    children: /* @__PURE__ */ e.jsx(G, {
                      label: c._({
                        id: "RBIA5y"
                      }),
                      value: "Switch All"
                    })
                  })
                ]
              })
            }),
            /* @__PURE__ */ e.jsx(Ot, {
              investorProfile: o,
              createdOrder: t,
              orderRequest: f,
              handleOrderOTPConfirmation: F
            })
          ]
        });
      case "error":
        return /* @__PURE__ */ e.jsx(J, {
          title: c._({
            id: "R9c5Jl"
          }),
          description: c._({
            id: "UFXd5p"
          }),
          showDetails: !1,
          showAction: !1
        });
      case "order_error":
        return /* @__PURE__ */ e.jsx(J, {
          title: c._({
            id: "SOfRak"
          }),
          description: c._({
            id: "jHbHXB"
          }),
          showDetails: !1,
          showAction: !0,
          actionText: c._({
            id: "wy5cp6"
          }),
          actionHandler: () => j("initial")
        });
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
    }
  })();
}
function Dt({ folio: t, handleTransact: f, handleViewFolioDetails: o }) {
  return /* @__PURE__ */ e.jsxs("div", {
    className: "flex items-center justify-around",
    children: [
      /* @__PURE__ */ e.jsx(Y, {
        check: !!(t != null && t.dp_id),
        children: /* @__PURE__ */ e.jsx(ct, {
          placement: "bottom-start",
          content: "Currently, transactions are not allowed in Demat folios",
          size: "sm",
          children: /* @__PURE__ */ e.jsx(ne, {
            variant: "text",
            size: "sm",
            onClick: () => f(t),
            disabled: !0,
            children: "Transact"
          })
        })
      }),
      /* @__PURE__ */ e.jsx(Y, {
        check: !(t != null && t.dp_id),
        children: /* @__PURE__ */ e.jsx(ne, {
          variant: "text",
          size: "sm",
          onClick: () => f(t),
          children: "Transact"
        })
      }),
      /* @__PURE__ */ e.jsx(ne, {
        variant: "text",
        size: "sm",
        onClick: () => o(t),
        children: "View details"
      })
    ]
  });
}
const Tt = ({ folio: t, handleTransact: f, handleViewFolioDetails: o }) => /* @__PURE__ */ e.jsx("div", {
  className: "mb-4",
  children: /* @__PURE__ */ e.jsx(De, {
    footer: /* @__PURE__ */ e.jsx(Dt, {
      folio: t,
      handleTransact: f,
      handleViewFolioDetails: o
    }),
    children: /* @__PURE__ */ e.jsxs(Te, {
      children: [
        /* @__PURE__ */ e.jsx(X, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(G, {
            label: "Folio number",
            value: (t == null ? void 0 : t.number) ?? "-"
          })
        }),
        /* @__PURE__ */ e.jsx(X, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(G, {
            label: "Primary investor name",
            value: (t == null ? void 0 : t.primary_investor_name) ?? "-"
          })
        })
      ]
    })
  })
});
function Ke(t, f) {
  const [o, _] = i(t);
  return B(() => {
    const g = setTimeout(() => {
      _(t);
    }, f);
    return () => {
      clearTimeout(g);
    };
  }, [
    t,
    f
  ]), o;
}
const Pt = (t) => t ? /^[A-Za-z\s]+$/.test(t) : !1, At = (t) => t ? /^[a-zA-Z]{3}[pcPC][a-zA-Z][0-9]{4}[a-zA-Z]$/.test(t) : !1;
function Mt({ licenseCode: t, handleTransact: f }) {
  const { fpapi: o } = K(ee), [_, g] = i([]), [b, u] = i(!1), [y, P] = i(null), [m, a] = i(""), h = Ke(m, 800), [r, j] = i(!1), [E, L] = i(), [S, F] = i(null), W = async (l, N) => {
    var z;
    const p = {
      license_code: l
    };
    At(N) ? p.primary_investor_pan = N == null ? void 0 : N.toUpperCase() : Pt(N) ? p.primary_investor_name = N : p.folio_number = N;
    const H = await ((z = o == null ? void 0 : o.fpClient) == null ? void 0 : z.mf_folios().fetchAll(ze(p)));
    return H == null ? void 0 : H.data;
  };
  B(() => {
    (async () => {
      try {
        if (u(!0), P(null), F(null), !t)
          throw new Error("License code is required");
        const l = await W(t, h == null ? void 0 : h.trim());
        if (!(l != null && l.length)) {
          F({
            title: "No folios found",
            description: "No folios match your search criteria. Try searching with a different folio number, PAN or name."
          });
          return;
        }
        g(l);
      } catch (l) {
        window.debug.error(l), P({
          title: "Error fetching data",
          description: Ge(l),
          showAction: !0,
          actionText: "Retry",
          actionHandler: () => window.location.reload()
        }), T(l);
      } finally {
        u(!1);
      }
    })();
  }, [
    t,
    h
  ]);
  const O = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(q, {
        title: "Invest"
      }),
      /* @__PURE__ */ e.jsx(Pe, {
        type: "text",
        text: c._({
          id: "f6NNiy"
        })
      }),
      /* @__PURE__ */ e.jsx(Ae, {
        type: "text",
        placeholder: "Search by folio number, PAN, or name",
        value: m,
        onChange: (l) => a(l.target.value),
        suffix: /* @__PURE__ */ e.jsx(Me, {
          name: "Search"
        }),
        maxLength: 250,
        onInput: (l) => {
          l.target.value = l.target.value.replace(Be("A-Za-z0-9 "), "");
        },
        autoFocus: !0,
        theme: {
          bgColorClass: "bg-neutral-0"
        },
        customClass: "mb-4"
      }),
      /* @__PURE__ */ e.jsx(Y, {
        check: !!S,
        children: /* @__PURE__ */ e.jsx(_e, {
          type: "alert",
          variant: "spot",
          title: S == null ? void 0 : S.title,
          content: /* @__PURE__ */ e.jsx("p", {
            children: S == null ? void 0 : S.description
          })
        })
      }),
      /* @__PURE__ */ e.jsx(Y, {
        check: !!(_ != null && _.length && !S),
        children: _ == null ? void 0 : _.map((l) => /* @__PURE__ */ e.jsx(Tt, {
          folio: l,
          handleTransact: f,
          handleViewFolioDetails: (N) => {
            L(N), j(!0);
          }
        }, l.number))
      }),
      /* @__PURE__ */ e.jsx(lt, {
        variant: "fullPage",
        title: c._({
          id: "Fuk8S8"
        }),
        isOpen: r,
        contentHeightMax: !0,
        toggleModal: () => {
          j(!1);
        },
        children: /* @__PURE__ */ e.jsx(It, {
          folio: E,
          isPartner: !0
        })
      })
    ]
  });
  return y ? /* @__PURE__ */ e.jsx(J, {
    ...y
  }) : b ? /* @__PURE__ */ e.jsx($, {
    variant: "fullpage"
  }) : O();
}
const Ft = ({ scheme: t, handleSchemeSelection: f }) => /* @__PURE__ */ e.jsx("div", {
  className: "mb-4",
  children: /* @__PURE__ */ e.jsx(De, {
    footer: /* @__PURE__ */ e.jsx("div", {
      className: "flex items-center justify-around",
      children: /* @__PURE__ */ e.jsx(ne, {
        variant: "text",
        size: "sm",
        onClick: () => f(t),
        children: "Transact"
      })
    }),
    children: /* @__PURE__ */ e.jsxs(Te, {
      children: [
        /* @__PURE__ */ e.jsx(X, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(G, {
            label: "Category",
            value: le(t == null ? void 0 : t.fund_category)
          })
        }),
        /* @__PURE__ */ e.jsx(X, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(G, {
            label: "Type",
            value: le(t == null ? void 0 : t.plan_type)
          })
        }),
        /* @__PURE__ */ e.jsx(X, {
          span: 12,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(G, {
            label: "Name",
            value: le(t.name)
          })
        }),
        /* @__PURE__ */ e.jsx(X, {
          span: 6,
          verticalGap: !0,
          children: /* @__PURE__ */ e.jsx(G, {
            label: "ISIN",
            value: le(t == null ? void 0 : t.isin)
          })
        })
      ]
    })
  })
}), Oe = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Growth",
    value: "GROWTH"
  },
  {
    label: "IDCW - Payout",
    value: "DIV_PAYOUT"
  },
  {
    label: "IDCW - Reinvestment",
    value: "DIV_REINVESTMENT"
  }
], Ee = [
  {
    label: "All",
    value: ""
  },
  {
    label: "Equity",
    value: "Equity"
  },
  {
    label: "Debt",
    value: "Debt"
  },
  {
    label: "Liquid",
    value: "Liquid"
  },
  {
    label: "Others",
    value: "Others"
  }
];
function Ht({ onFundSelection: t, handleBack: f }) {
  const { fpapi: o } = K(ee), _ = K(He), [g, b] = i([]), [u, y] = i(!1), [P, m] = i(null), [a, h] = i(0), [r, j] = i(!1), [E, L] = i(void 0), [S, F] = i({
    investmentOption: Oe[0],
    fundCategory: Ee[0]
  }), W = ke(null), O = ke(null), l = Ke(E, 800);
  B(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);
  const N = (d) => {
    var k, R;
    if ((k = d == null ? void 0 : d.toLowerCase()) != null && k.startsWith("arn"))
      return "Regular";
    if ((R = d == null ? void 0 : d.toLowerCase()) != null && R.startsWith("ina"))
      return "Direct";
  }, p = he(async (d, k) => {
    var me, re, ue;
    const Z = {
      page: d,
      size: 20,
      investment_option: k.investmentOption.value,
      fund_category: (me = k.fundCategory) == null ? void 0 : me.value,
      name: (re = k == null ? void 0 : k.name) == null ? void 0 : re.toLowerCase(),
      plan_type: N(_ == null ? void 0 : _.loggedInPartnerLicenceCode)
    }, M = await (o == null ? void 0 : o.fpClient.master_data().fetchSchemes(ze(Z)));
    if (!(M != null && M.fund_schemes))
      throw new Error("Failed to fetch schemes");
    return {
      schemes: (ue = M == null ? void 0 : M.fund_schemes) == null ? void 0 : ue.filter((U) => (U == null ? void 0 : U.purchase_allowed) || (U == null ? void 0 : U.sip_allowed)),
      isLastPage: M.last
    };
  }, [
    o,
    _ == null ? void 0 : _.loggedInPartnerLicenceCode
  ]), H = he(async (d = !1) => {
    if (!(u || r && !d))
      try {
        y(!0);
        const k = d ? 0 : a, { schemes: R, isLastPage: Z } = await p(k, S);
        b((M) => d ? R : [
          ...M,
          ...R
        ]), h((M) => d ? 1 : M + 1), j(Z);
      } catch (k) {
        window.debug.error(k), m({
          title: "Error fetching data",
          description: Ge(k)
        }), T(k);
      } finally {
        y(!1);
      }
  }, [
    u,
    r,
    a,
    S,
    p
  ]), z = he((d) => {
    F((k) => ({
      ...k,
      ...d
    })), b([]), h(0), j(!1);
  }, []);
  B(() => {
    H(!0);
  }, [
    S
  ]), B(() => {
    const d = new IntersectionObserver((k) => {
      k[0].isIntersecting && !r && !u && H();
    }, {
      threshold: 0.5
    });
    return W.current = d, O.current && d.observe(O.current), () => {
      d && d.disconnect();
    };
  }, [
    r,
    u,
    H
  ]), B(() => {
    l != null && z({
      name: l == null ? void 0 : l.trim()
    });
  }, [
    l
  ]);
  const v = () => g.length > 0 ? g.map((d, k) => /* @__PURE__ */ e.jsx("div", {
    ref: k === g.length - 1 ? O : null,
    children: /* @__PURE__ */ e.jsx(Ft, {
      scheme: d,
      handleSchemeSelection: t
    })
  }, d.isin)) : u ? null : /* @__PURE__ */ e.jsx(dt, {
    children: /* @__PURE__ */ e.jsx(_e, {
      type: "helper",
      variant: "field",
      title: c._({
        id: "HwCVf3"
      })
    })
  }), V = () => u && /* @__PURE__ */ e.jsx("div", {
    style: {
      display: "flex",
      justifyContent: "center",
      padding: "20px"
    },
    children: /* @__PURE__ */ e.jsx($, {})
  }), Q = () => r && g.length > 0 && /* @__PURE__ */ e.jsx(_e, {
    type: "helper",
    variant: "field",
    title: c._({
      id: "vn1YrE"
    })
  }), ae = () => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(Ae, {
        type: "text",
        placeholder: "Search by scheme name",
        value: E,
        onChange: (d) => L(d.target.value),
        maxLength: 250,
        suffix: /* @__PURE__ */ e.jsx(Me, {
          name: "Search"
        }),
        theme: {
          bgColorClass: "bg-neutral-0"
        },
        onInput: (d) => {
          d.target.value = d.target.value.replace(Be("A-Za-z0-9- "), "");
        },
        customClass: "mb-2"
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-2",
        children: [
          /* @__PURE__ */ e.jsx(Ie, {
            message: "Investment option"
          }),
          /* @__PURE__ */ e.jsx(Le, {
            value: S.investmentOption,
            onChange: (d) => d && z({
              investmentOption: d
            }),
            options: Oe,
            placeholder: c._({
              id: "ND8Qfv"
            })
          })
        ]
      }),
      /* @__PURE__ */ e.jsxs("div", {
        className: "mb-2",
        children: [
          /* @__PURE__ */ e.jsx(Ie, {
            message: "Fund category"
          }),
          /* @__PURE__ */ e.jsx(Le, {
            value: S.fundCategory,
            onChange: (d) => d && z({
              fundCategory: d
            }),
            options: Ee,
            placeholder: c._({
              id: "wGq+bf"
            })
          })
        ]
      })
    ]
  });
  return P ? /* @__PURE__ */ e.jsx(J, {
    ...P
  }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(q, {
        title: c._({
          id: "8f2dQh"
        }),
        icon: "Back",
        onClick: f
      }),
      /* @__PURE__ */ e.jsx(Pe, {
        type: "text",
        text: c._({
          id: "LyK3CS"
        })
      }),
      ae(),
      v(),
      V(),
      Q()
    ]
  });
}
const Gt = (t, f) => {
  const o = f === "mf_purchase_plan" ? "Starting SIP in" : "Investing in";
  return /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      o,
      " ",
      t != null && t.primary_investor_name ? `${$e(t.primary_investor_name)}'s folio` : "",
      /* @__PURE__ */ e.jsx("span", {
        className: "text-neutral-60 text-size-14 relative bottom-px ml-1 font-normal normal-case",
        children: `( ${t == null ? void 0 : t.number} )`
      })
    ]
  });
};
function zt({ switch_out: t, isGroupInvestment: f, isPartner: o }) {
  var ve, be, ye;
  const { AppState: { tenant: _ } } = K(Fe), [g, b] = i(() => sessionStorage.getItem("investment_workflow_stage") || "order"), [u, y] = i(), [P, m] = i({}), [a, h] = i(() => {
    const s = sessionStorage.getItem("investment_workflow_order_id");
    return s ? JSON.parse(s) : null;
  }), [r, j] = i(null), [E, L] = i(null), [S, F] = i([]), [W, O] = i(!1), [l, N] = i("purchase"), { fpapi: p } = K(ee), { loggedInPartner: H, loggedInPartnerLicenceCode: z } = K(He), { tenantConfig: v, amcs: V } = ge(), Q = (ve = v == null ? void 0 : v.groupInvestment) == null ? void 0 : ve.allowedEmailDomains, [ae, d] = i(null);
  (be = r == null ? void 0 : r.annexure) == null || be.isonboarded;
  const k = ((ye = v == null ? void 0 : v.app_constants) == null ? void 0 : ye.kyc_refetch_interval_in_months) || 3, [R, Z] = i(!1), [M, xe] = i(null), [me, re] = i(!1), [ue, U] = i(!1), [Ve, ie] = i(!1), [We, te] = i("FOLIO_LISTING"), [x, we] = i(pe.get()), [C, oe] = i(de.get()), Re = v == null ? void 0 : v.fallbackLogo;
  B(() => {
    (async () => o && await Ue())();
  }, [
    x == null ? void 0 : x.primary_investor_pan,
    C
  ]);
  const Ue = async () => {
    if (!A(x) && A(C) && te("SCHEME_LISTING"), !H)
      O(!0);
    else if (x != null && x.primary_investor_pan && C) {
      if (p.fetchUser(x == null ? void 0 : x.primary_investor_pan, _).then((s) => {
        p.fpClient.investor_profiles().fetchAll({
          pan: x == null ? void 0 : x.primary_investor_pan
        }).then((n) => {
          const I = n == null ? void 0 : n.data[0];
          I ? (vt(s == null ? void 0 : s[0], p).then(async (w) => {
            re(w.isMigratedUser), w.isMigratedUser && (await bt(p, s[0], w == null ? void 0 : w.migratedMfiaList, w == null ? void 0 : w.migratedFolioList), tt(s == null ? void 0 : s[0]).then((D) => {
              D != null && D.status && U(!0);
            }));
          }), p.fpClient.mf_investment_accounts().fetchAll({
            investor: x == null ? void 0 : x.primary_investor_pan
          }).then((w) => {
            L(I), F(w.data), j(s[0]), Je(w == null ? void 0 : w.data[0]), f && p.fpClient.email_addresses().fetchAll({
              profile: I == null ? void 0 : I.id
            }).then((D) => {
              var Se;
              if (!(Q ? (Se = D == null ? void 0 : D.data) == null ? void 0 : Se.some((fe) => {
                var Ce;
                return Q[(Ce = fe == null ? void 0 : fe.email) == null ? void 0 : Ce.split("@")[1]];
              }) : !1)) {
                b("invalid_email_domain");
                return;
              }
            });
          })) : O(!0);
        }).catch((n) => {
          window.debug.error(n), T(n);
        }).finally(() => {
          O(!0);
        });
      }), !A(x) && !A(C) && ie(!0), !(C != null && C.active) || !(C != null && C.purchase_allowed) && !(C != null && C.sip_allowed)) {
        b("purchase_not_allowed");
        return;
      } else A(a) ? b("order") : b("finished");
      await qe();
    } else
      O(!0);
  };
  async function qe() {
    if (!a)
      return;
    const s = a.object;
    if (s === "mf_purchase")
      try {
        const n = await p.fpClient.mf_purchases().fetch(a == null ? void 0 : a.id);
        y(n);
      } catch (n) {
        window.debug.log(n), T(n);
      }
    else if (s === "mf_switch")
      try {
        const n = await p.fpClient.mf_switches().fetch(a == null ? void 0 : a.id);
        y(n), N("switch");
      } catch (n) {
        window.debug.log(n), T(n);
      }
    else if (s === "mf_purchase_plan")
      try {
        const n = await p.fpClient.mf_purchase_plans().fetch(a == null ? void 0 : a.id);
        y(n);
      } catch (n) {
        window.debug.log(n), T(n);
      }
    else if (s === "mf_switch_plan")
      try {
        const n = await p.fpClient.mf_switch_plans().fetch(a == null ? void 0 : a.id);
        y(n);
      } catch (n) {
        window.debug.error(n), T(n);
      }
  }
  const Je = async (s) => {
    try {
      const n = await p.fpClient.investor_reports().fetchHoldings({
        investment_account_id: s == null ? void 0 : s.old_id
      });
      xe(n);
    } catch (n) {
      window.debug.log(n, "Error");
    }
  };
  async function Qe(s, n, I) {
    if (Z(I), !I || !I.orderId) {
      b("finished"), y(n), sessionStorage.setItem("investment_workflow_stage", "finished"), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
        ...s,
        object: n.object
      }));
      return;
    }
    O(!1);
    try {
      const w = await p.fpClient.mf_purchases().fetch(I.orderId);
      sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
        id: w.id,
        old_id: w.old_id,
        object: w.object
      })), y(n);
    } catch (w) {
      window.debug.log(w), T(w);
    }
    b("finished"), sessionStorage.setItem("investment_workflow_stage", "finished"), O(!0);
  }
  function Ze(s) {
    const { mf_order: n, isGenerateFirstInstallment: I, shouldOnboardMigratedUser: w } = s, D = {
      id: n.id,
      old_id: n.old_id
    };
    if (h(D), jt.set(I ?? !1), w) {
      y(n), b("onboard_migrated_user");
      return;
    }
    n.object === "mf_purchase" && (y(n), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
      ...D,
      object: n.object
    })), b("finished"), sessionStorage.setItem("investment_workflow_stage", "finished")), (n.object === "mf_switch" || n.object === "mf_switch_plan") && (y(n), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
      ...D,
      object: n.object
    })), b("finished"), sessionStorage.setItem("investment_workflow_stage", "finished")), n.object === "mf_purchase_plan" && (Z(I), Qe(D, n, I));
  }
  function Xe() {
    const s = sessionStorage.getItem("investment_workflow_order_id"), n = s ? JSON.parse(s) : null;
    sessionStorage.removeItem("investment_workflow_stage"), sessionStorage.removeItem("investment_workflow_order_id"), y(null), m({}), b("order"), n.object === "mf_purchase" || n.object === "mf_purchase_plan" ? N("purchase") : N("switch");
  }
  const Ye = (s) => {
    const n = {
      id: s.id,
      old_id: s.old_id
    };
    h(n), y(s), sessionStorage.setItem("investment_workflow_order_id", JSON.stringify({
      ...n,
      object: s.object
    })), b("finished"), sessionStorage.setItem("investment_workflow_stage", "finished");
  }, et = () => {
    var s;
    (s = p == null ? void 0 : p.userAuthService) == null || s.initiateLogout(window.location.href).then((n) => {
      window.location.href = n.userState;
    });
  }, tt = async (s) => {
    const n = _t(s == null ? void 0 : s.annexure);
    if (n != null && n.kycCheckId) {
      const I = await p.fpClient.kyc_checks().fetch(n == null ? void 0 : n.kycCheckId);
      return I.status && !gt(I.updated_at, k) ? I : xt(I.updated_at) ? await p.fpClient.kyc_checks().refetch(n == null ? void 0 : n.kycCheckId) : I;
    } else {
      const I = s == null ? void 0 : s.id, w = await p.fpClient.kyc_checks().create({
        pan: s == null ? void 0 : s.username.toUpperCase()
      }), D = {
        ...n,
        kycCheckId: w.id
      }, ce = await p.updateUser(I, D, null, _);
      if (ce)
        return w;
      throw ce;
    }
  }, je = (s) => /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      "Investing in",
      " ",
      s != null && s.primary_investor_name ? `${$e(s.primary_investor_name)}'s folio` : "",
      /* @__PURE__ */ e.jsx("span", {
        className: "text-neutral-60 text-size-14 relative bottom-px ml-1 font-normal normal-case",
        children: `( ${s == null ? void 0 : s.number} )`
      })
    ]
  }), nt = (s) => {
    const n = A(V) ? {
      ...s
    } : {
      ...s,
      schemeLogo: V[s == null ? void 0 : s.amc_id] || Re
    };
    oe(n), de.set(n);
  }, st = () => {
    de.remove(), oe(null), h(null), te("SCHEME_LISTING"), b("order"), ie(!1);
  }, at = () => !A(C) && !wt(S) && /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(q, {
        title: je(x),
        icon: "Back",
        onClick: st
      }),
      /* @__PURE__ */ e.jsx(se, {
        scheme: C
      }),
      /* @__PURE__ */ e.jsx(mt, {
        scheme: C,
        switch_out: t,
        investment_account_id: S == null ? void 0 : S[0].id,
        investment_accounts: S,
        defaultInvestmentType: l,
        investorProfile: E,
        isGroupInvestment: f,
        onSuccess: Ze,
        isPartner: o,
        user: r,
        holdings: M,
        assistedWorkflowPAN: x == null ? void 0 : x.primary_investor_pan,
        assistedWorkflowFolioNumber: x == null ? void 0 : x.number
      })
    ]
  });
  function rt() {
    switch (g) {
      default:
        return /* @__PURE__ */ e.jsx($, {
          variant: "fullpage"
        });
      case "order":
        return at();
      case "finished":
        return !A(C) && !A(u) && /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(q, {
              title: Gt(x, u == null ? void 0 : u.object)
            }),
            /* @__PURE__ */ e.jsx(se, {
              scheme: C
            }),
            /* @__PURE__ */ e.jsx(kt, {
              investorProfile: E,
              scheme: C,
              mf_order: u,
              isGroupInvestment: f,
              handleInvestAgain: Xe,
              isPartner: o,
              isFotOrder: R
            })
          ]
        });
      case "purchase_not_allowed":
        return /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(q, {
              title: je(x)
            }),
            /* @__PURE__ */ e.jsx(se, {
              scheme: C
            }),
            /* @__PURE__ */ e.jsx("div", {
              className: "flex flex-1 items-center",
              children: /* @__PURE__ */ e.jsx(J, {
                title: c._({
                  id: "9VGPlF"
                }),
                description: c._({
                  id: "INP47b"
                }),
                showDetails: !1,
                showAction: !0,
                actionText: c._({
                  id: "L2gqm2"
                }),
                actionHandler: () => {
                  oe(null), ie(!1), te("SCHEME_LISTING");
                }
              })
            })
          ]
        });
      case "switch_confirmation":
        return !A(C) && (!A(u) || !A(P)) && /* @__PURE__ */ e.jsx(Et, {
          createdOrder: u,
          orderRequest: P,
          investorProfile: E,
          onConfirmation: Ye
        });
      case "invalid_email_domain":
        return /* @__PURE__ */ e.jsx(J, {
          title: c._({
            id: "N98vRu"
          }),
          description: c._({
            id: "y8Ur5q"
          }),
          showDetails: !1,
          actionText: c._({
            id: "+2LBoP"
          }),
          showAction: !0,
          actionHandler: et
        });
      case "onboard_migrated_user":
        return /* @__PURE__ */ e.jsx(Bt, {
          onContinue: () => {
            window.location.href = `${window.location.origin + Ne}/onboarding?isPartner=${o}&redirectURL=${encodeURIComponent(`${window.location.origin + Ne}/assisted-checkout/?isin=${C == null ? void 0 : C.isin}&isPartner=${o}`)}`;
          }
        });
      case "kyc_non_compliant":
        return ae && /* @__PURE__ */ e.jsx(St, {
          kycCheckObject: ae,
          customMessage: {
            unverified: c._({
              id: "V2UMyt"
            })
          }
        });
    }
  }
  const it = () => {
    switch (We) {
      case "FOLIO_LISTING":
        return /* @__PURE__ */ e.jsx(Mt, {
          licenseCode: z,
          handleTransact: (s) => {
            we(s), pe.set(s), te("SCHEME_LISTING");
          }
        });
      case "SCHEME_LISTING":
        return /* @__PURE__ */ e.jsx(Ht, {
          onFundSelection: (s) => {
            nt(s), ie(!0);
          },
          handleBack: () => {
            de.remove(), pe.remove(), oe(null), we(null), te("FOLIO_LISTING");
          }
        });
      default:
        return /* @__PURE__ */ e.jsx($, {
          variant: "fullpage"
        });
    }
  };
  return o ? /* @__PURE__ */ e.jsx(e.Fragment, {
    children: W ? H ? Ve ? /* @__PURE__ */ e.jsx(e.Fragment, {
      children: rt()
    }) : it() : /* @__PURE__ */ e.jsx(yt, {
      context: `${window.location.pathname}${window.location.search}`
    }) : /* @__PURE__ */ e.jsx($, {
      variant: "fullpage"
    })
  }) : /* @__PURE__ */ e.jsx($, {
    variant: "fullpage"
  });
}
const Bt = ({ onContinue: t }) => /* @__PURE__ */ e.jsxs("div", {
  children: [
    /* @__PURE__ */ e.jsx(q, {
      title: c._({
        id: "dzVeEG"
      }),
      customClass: "mb-4"
    }),
    /* @__PURE__ */ e.jsx("p", {
      className: "dark:text-neutral-dark-110 mb-5",
      children: "Complete the onboarding process to initiate orders"
    }),
    /* @__PURE__ */ e.jsx("div", {
      className: "initial:bottom-32 sm:max-w-29 fixed left-0 right-0 m-auto w-full px-8",
      children: /* @__PURE__ */ e.jsx(ne, {
        fullwidth: !0,
        customClass: "w-full mb-6",
        onClick: () => t(),
        children: "Continue"
      })
    })
  ]
});
function $t({ isin: t, switch_out: f, isPartner: o }) {
  const { fpapi: _ } = K(ee), { AppState: { showWelcomePage: g }, setShowWelcomePage: b } = K(Fe), { tenantConfig: u, amcs: y } = ge(), P = u == null ? void 0 : u.fallbackLogo, m = u == null ? void 0 : u.showSchemeLogo, [a, h] = i(""), [r, j] = i(""), [E, L] = i(), [S, F] = i(!1), W = o ? [
    {
      step: 1,
      content: c._({
        id: "q/FBy6"
      })
    },
    {
      step: 2,
      content: c._({
        id: "8KJEuv"
      })
    },
    {
      step: 3,
      content: c._({
        id: "AKwgbv"
      })
    },
    {
      step: 4,
      content: c._({
        id: "Nuos0E"
      })
    }
  ] : [
    {
      step: 1,
      content: c._({
        id: "m5ZGkL"
      })
    },
    {
      step: 2,
      content: c._({
        id: "v4fm2D"
      })
    },
    {
      step: 3,
      content: c._({
        id: "nKQKlx"
      })
    }
  ];
  B(() => {
    t && (F(!0), _.semiPublicFpClient.master_data().fetchScheme(t).then((v) => {
      O(v);
    }).catch((v) => {
      F(!1), window.debug.error(v), T(v);
    }));
  }, [
    t
  ]);
  function O(v) {
    if (!v)
      j("schemeNotFound");
    else {
      const V = m ? {
        ...v,
        schemeLogo: y[v == null ? void 0 : v.amc_id] || P
      } : {
        ...v
      };
      L(V);
    }
    F(!1);
  }
  B(() => {
    j(l()), h(l());
  }, [
    g
  ]);
  function l() {
    return g && !o ? "welcome" : o || t ? "investment" : "fund_list";
  }
  function N() {
    b(!1), j("investment"), h("investment");
  }
  function p(v) {
    const V = new URLSearchParams(window.location.search);
    V.set("isin", v);
    const Q = V.toString();
    window.location.href = window.location.origin + window.location.pathname + `?${Q}`;
  }
  function H() {
    switch (a) {
      case "investment":
        return /* @__PURE__ */ e.jsx(zt, {
          // scheme={scheme}
          isGroupInvestment: !1,
          switch_out: f ?? "",
          isPartner: o ?? !1
        });
      default:
        return /* @__PURE__ */ e.jsx($, {
          variant: "fullpage"
        });
    }
  }
  function z() {
    if (r === "welcome")
      return /* @__PURE__ */ e.jsxs(e.Fragment, {
        children: [
          /* @__PURE__ */ e.jsx(q, {
            title: E ? c._({
              id: "Qx20fI"
            }) : c._({
              id: "FhpR0a"
            })
          }),
          /* @__PURE__ */ e.jsx(se, {
            scheme: E
          }),
          /* @__PURE__ */ e.jsx(Lt, {
            onAck: N,
            welcomeSteps: W
          })
        ]
      });
    if (r === "fund_list")
      return /* @__PURE__ */ e.jsx(Nt, {
        onFundSelection: p
      });
    if (r === "investment")
      return /* @__PURE__ */ e.jsx(e.Fragment, {
        children: /* @__PURE__ */ e.jsxs(e.Fragment, {
          children: [
            /* @__PURE__ */ e.jsx(q, {
              title: c._({
                id: "Qx20fI"
              })
            }),
            /* @__PURE__ */ e.jsx(se, {
              scheme: E
            }),
            /* @__PURE__ */ e.jsx(ut, {
              scheme: E,
              switch_out: f
            })
          ]
        })
      });
    if (r === "schemeNotFound")
      return /* @__PURE__ */ e.jsx(J, {
        title: c._({
          id: "/+1l7D"
        }),
        description: c._({
          id: "H8AXWD"
        }),
        showDetails: !1,
        showAction: !1
      });
  }
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: S ? /* @__PURE__ */ e.jsx($, {
      variant: "fullpage"
    }) : /* @__PURE__ */ e.jsxs(e.Fragment, {
      children: [
        /* @__PURE__ */ e.jsx(Y, {
          check: !!o,
          children: H()
        }),
        /* @__PURE__ */ e.jsx(Y, {
          check: !o,
          children: z()
        })
      ]
    })
  });
}
const hn = $t;
export {
  hn as Checkout
};
