import { j as s } from "./jsx-runtime-DmTjM30f.js";
import { I as x } from "./index.esm-G8KyR0tM.js";
const c = ({ check: t, children: e }) => t ? /* @__PURE__ */ s.jsx(s.Fragment, {
  children: e
}) : /* @__PURE__ */ s.jsx(s.Fragment, {});
function j({ customClass: t, title: e, description: n, iconName: r, message: a, children: o }) {
  const l = t ? `${t}` : "";
  return /* @__PURE__ */ s.jsxs("div", {
    className: l,
    children: [
      /* @__PURE__ */ s.jsx(x, {
        name: r,
        className: "mx-auto mb-2 block h-12 w-12"
      }),
      /* @__PURE__ */ s.jsx("h3", {
        className: "text-neutral-dark-0 text-center font-semibold text-size-20 pb-2 font-opensans dark:text-neutral-dark-110",
        children: e
      }),
      /* @__PURE__ */ s.jsx(c, {
        check: !!a,
        children: a
      }),
      /* @__PURE__ */ s.jsx("p", {
        className: "mb-5 text-neutral-60 text-center text-size-14 mt-0",
        children: n
      }),
      /* @__PURE__ */ s.jsx("div", {
        children: o
      })
    ]
  });
}
export {
  c as C,
  j as S
};
