import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useContext as p, useState as l, useEffect as h } from "react";
import { i as c } from "./index-wI714H_N.js";
import { a as C } from "./index.esm-G8KyR0tM.js";
import { F as j } from "./provider-Diwup2pn.js";
import { LoginContext as _ } from "./LoginContext.js";
import { r as w } from "./sentry-CAR5o4wv.js";
import { A as P } from "./Auth-BeDpKAhM.js";
import { ErrorPage as g } from "./ErrorPage.js";
import { S as v, a as R } from "./SwitchPlanConsent-C2-0e1aD.js";
import { R as S, a as F } from "./RedemptionPlanConsent-BWNG3q_n.js";
import { P as L, a as A } from "./PurchasePlanConsent-D48kuOSA.js";
function E({ object_type: a, action: r, id: t }) {
  const { loggedInUser: n } = p(_), [u, f] = l(!1), [s, d] = l(null), { fpapi: i } = p(j);
  h(() => {
    var m;
    i && i.getInvestorProfile((m = n == null ? void 0 : n.profile) == null ? void 0 : m.fp_identifier).then((o) => {
      o && d(o), f(!1);
    }).catch((o) => {
      f(!1), w(o);
    });
  }, [
    i
  ]);
  const x = () => {
    switch (a || (a = "invalid_id"), a) {
      default:
        return /* @__PURE__ */ e.jsx(e.Fragment, {});
      case "mf_purchase":
        return /* @__PURE__ */ e.jsx(A, {
          investorProfile: s,
          action: r,
          id: t
        });
      case "mf_redemption":
        return /* @__PURE__ */ e.jsx(F, {
          investorProfile: s,
          action: r,
          id: t
        });
      case "mf_switch":
        return /* @__PURE__ */ e.jsx(R, {
          investorProfile: s,
          action: r,
          id: t
        });
      case "mf_purchase_plan":
        return /* @__PURE__ */ e.jsx(L, {
          investorProfile: s,
          action: r,
          id: t
        });
      case "mf_redemption_plan":
        return /* @__PURE__ */ e.jsx(S, {
          investorProfile: s,
          action: r,
          id: t
        });
      case "mf_switch_plan":
        return /* @__PURE__ */ e.jsx(v, {
          investorProfile: s,
          action: r,
          id: t
        });
      case "invalid_id":
        return /* @__PURE__ */ e.jsx(g, {
          title: c._({
            id: "ZdjdxA"
          }),
          description: c._({
            id: "Sh6ppf"
          }),
          showDetails: !1
        });
    }
  };
  return /* @__PURE__ */ e.jsx(e.Fragment, {
    children: u ? /* @__PURE__ */ e.jsx(C, {
      variant: "fullpage"
    }) : n ? x() : /* @__PURE__ */ e.jsx(P, {
      redirectURL: window.location.href,
      allowRegistration: !1,
      showTestPan: {
        onboarded: !0,
        kycCompliant: !1,
        kycNonCompliant: !1
      }
    })
  });
}
const J = E;
export {
  J as Consent,
  E as ConsentFlow
};
