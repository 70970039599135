import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { useState as b, useLayoutEffect as S } from "react";
import { P as _, D as g, d as k, S as w, c as n, F as y, B as E } from "./index.esm-G8KyR0tM.js";
import { u as I } from "./useConfiguration-B-lyaBB9.js";
import { i as l } from "./index-wI714H_N.js";
import { k as v, n as D } from "./utils-D5LKKDAj.js";
function U({ onFundSelection: u, employer: r }) {
  var p, x, h, j;
  const { tenantConfig: s, amcs: L } = I(), d = s == null ? void 0 : s.fallbackLogo, m = s == null ? void 0 : s.showSchemeLogo, t = r && ((p = s == null ? void 0 : s.groupInvestment) != null && p.allowedEmployerSchemes) && ((x = s == null ? void 0 : s.groupInvestment) != null && x.allowedEmployerSchemes[r]) ? (h = s == null ? void 0 : s.groupInvestment) == null ? void 0 : h.allowedEmployerSchemes[r] : s == null ? void 0 : s.fundList, [i, F] = b(t), [c, N] = b({
    index: 0,
    isin: t ? (j = t[0]) == null ? void 0 : j.isin : ""
  });
  return S(() => {
    if (m) {
      const a = t && t.map((o) => ({
        ...o,
        schemeLogo: L[o == null ? void 0 : o.amc_id] || d
      }));
      F(a);
    }
  }, []), /* @__PURE__ */ e.jsxs(e.Fragment, {
    children: [
      /* @__PURE__ */ e.jsx(_, {
        title: l._({
          id: "UNFXH2"
        })
      }),
      /* @__PURE__ */ e.jsx(g, {
        type: "text",
        text: l._({
          id: "KbNU4Q"
        })
      }),
      i && c && /* @__PURE__ */ e.jsxs("div", {
        className: "pb-24",
        children: [
          /* @__PURE__ */ e.jsx(k, {
            onChange: (a) => {
              N({
                index: a.target.value,
                isin: i[a.target.value].isin
              });
            },
            variant: "selectable",
            selected: c.index,
            children: i != null && i.length ? i == null ? void 0 : i.map((a) => /* @__PURE__ */ e.jsx(w, {
              header: m ? {} : {
                title: /* @__PURE__ */ e.jsx("span", {
                  className: "neutral-dark-0 font-open-sans font-size-14 ml-2 block font-semibold",
                  children: v(a.name)
                })
              },
              footer: {
                buttonText: l._({
                  id: "OM8Iy1"
                })
              },
              onClick: () => {
                window.open(a.scheme_external_link, "_blank");
              },
              children: /* @__PURE__ */ e.jsxs("div", {
                className: "flex flex-wrap items-center py-2",
                children: [
                  m ? /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-full",
                    children: /* @__PURE__ */ e.jsx(n, {
                      // label="Fund Name"
                      value: v(a.name),
                      logo: "schemeLogo" in a ? a == null ? void 0 : a.schemeLogo : d
                    })
                  }) : null,
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2",
                    children: /* @__PURE__ */ e.jsx(n, {
                      label: l._({
                        id: "FsNpOH"
                      }),
                      value: a.plan_type
                    })
                  }),
                  /* @__PURE__ */ e.jsx("div", {
                    className: "mb-3 basis-1/2",
                    children: /* @__PURE__ */ e.jsx(n, {
                      label: l._({
                        id: "fezFD7"
                      }),
                      value: D(a.investmentOption)
                    })
                  })
                ]
              })
            }, a.isin)) : /* @__PURE__ */ e.jsx(e.Fragment, {})
          }),
          /* @__PURE__ */ e.jsx(y, {
            children: /* @__PURE__ */ e.jsx("div", {
              className: "mb-4",
              children: /* @__PURE__ */ e.jsx(E, {
                variant: i.length ? "primary" : "disabled",
                fullwidth: !0,
                onClick: (a) => {
                  a.preventDefault(), u(c.isin);
                },
                children: l._({
                  id: "H/E6fZ"
                })
              })
            })
          })
        ]
      })
    ]
  });
}
export {
  U as FundList
};
