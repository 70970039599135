import { j as t } from "./jsx-runtime-DmTjM30f.js";
import { useState as x } from "react";
import { I as y } from "./index.esm-G8KyR0tM.js";
const d = ({ value: r, textBeforeCopy: a = "Copy", textAfterCopy: i = "Copied", onClick: o }) => {
  const [n, e] = x(!1), p = 2e3, c = () => {
    e(!0), setTimeout(() => e(!1), p);
  }, m = async () => {
    if (navigator.clipboard)
      try {
        await navigator.clipboard.writeText(r), c();
      } catch {
      }
  };
  return /* @__PURE__ */ t.jsx("button", {
    onClick: (s) => {
      s.stopPropagation(), m(), o == null || o(s);
    },
    type: "button",
    className: "flex items-center",
    children: n ? /* @__PURE__ */ t.jsx("span", {
      className: "text-primary text-size-14",
      children: i
    }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
      children: [
        /* @__PURE__ */ t.jsx(y, {
          name: "Copy"
        }),
        /* @__PURE__ */ t.jsx("span", {
          className: "text-primary text-size-14",
          children: a
        })
      ]
    })
  });
};
export {
  d as C
};
