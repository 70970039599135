import { j as t } from "./jsx-runtime-DmTjM30f.js";
import { useContext as l, useState as s, useEffect as g } from "react";
import { P as m, a as H, D as b } from "./index.esm-G8KyR0tM.js";
import { AppContext as D } from "./AppContext.js";
import { LoginContext as O } from "./LoginContext.js";
import { i as e } from "./index-wI714H_N.js";
import { F as T } from "./provider-Diwup2pn.js";
import { r as R } from "./sentry-CAR5o4wv.js";
import { W as U } from "./Welcome-C4-GwyyJ.js";
import { O as G } from "./OrderHistoryListing-CtGV_goc.js";
import { A as K } from "./Auth-BeDpKAhM.js";
import { P as M } from "./PartnerLogin-BdkPSQka.js";
function nt({ type: P, folio_number: w, status: h, isin: j }) {
  const { loggedInPartner: A } = l(O), { fpapi: i } = l(T), [v, y] = s({}), [C, r] = s(!0), [_, S] = s({}), [n, L] = s(null), { AppState: { showWelcomePage: f }, setShowWelcomePage: W } = l(D), [k, p] = s(d), F = [
    {
      step: 1,
      content: e._({
        id: "SYXp7g"
      })
    },
    {
      step: 3,
      content: e._({
        id: "K4Pkx3"
      })
    },
    {
      step: 2,
      content: e._({
        id: "EI9fU0"
      })
    }
  ];
  g(() => {
    (async () => {
      if (i && n)
        try {
          r(!0);
          const o = i.fpClient.investor_profiles().fetchAll({
            pan: n
          }), E = i.fpClient.mf_investment_accounts().fetchAll({
            investor: n
          }), [a, c] = await Promise.all([
            o,
            E
          ]), u = a == null ? void 0 : a.data[0], x = c == null ? void 0 : c.data[0];
          if (!x || !u) {
            r(!1);
            return;
          }
          y(u), S(x);
        } catch (o) {
          window.debug.error(o), R(o);
        } finally {
          r(!1);
        }
      else
        r(!1);
    })();
  }, [
    n
  ]), g(() => {
    p(d());
  }, [
    f
  ]);
  function d() {
    return f ? "welcome" : "transactionHistory";
  }
  function I() {
    W(!1), p("transactionHistory");
  }
  const J = ({ pan: o }) => {
    L(o);
  };
  function N() {
    return k === "welcome" ? /* @__PURE__ */ t.jsxs(t.Fragment, {
      children: [
        /* @__PURE__ */ t.jsx(m, {
          title: e._({
            id: "ol5JJc"
          })
        }),
        /* @__PURE__ */ t.jsx(U, {
          onAck: I,
          welcomeSteps: F
        })
      ]
    }) : /* @__PURE__ */ t.jsx(t.Fragment, {
      children: /* @__PURE__ */ t.jsx("div", {
        className: "mb-20",
        children: C ? /* @__PURE__ */ t.jsx(H, {
          variant: "fullpage"
        }) : A ? n ? /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(m, {
              title: e._({
                id: "ol5JJc"
              })
            }),
            /* @__PURE__ */ t.jsx(b, {
              type: "text",
              text: e._({
                id: "/iaRLN"
              })
            }),
            /* @__PURE__ */ t.jsx(G, {
              isGroupInvestment: !1,
              investorProfile: v,
              mfInvestmentAccount: _,
              isPartner: !0,
              searchParams: {
                type: P,
                folio_number: w,
                status: h,
                isin: j
              }
            })
          ]
        }) : /* @__PURE__ */ t.jsx(K, {
          onPreviewData: J,
          allowRegistration: !1,
          isPartner: !0,
          redirectURL: window.location.href,
          showTestPan: {
            onboarded: !0,
            kycCompliant: !1,
            kycNonCompliant: !1
          }
        }) : /* @__PURE__ */ t.jsxs(t.Fragment, {
          children: [
            /* @__PURE__ */ t.jsx(m, {
              title: e._({
                id: "+VJ95o"
              })
            }),
            /* @__PURE__ */ t.jsx(M, {
              context: window.location.href
            })
          ]
        })
      })
    });
  }
  return N();
}
export {
  nt as AssistedOrderHistory
};
